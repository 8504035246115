import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Button from '@material-ui/core/Button';

const Home = () => {
  const { oktaAuth, authState } = useOktaAuth();
  if (!authState) {
    return null;
  }

  const login = async () => oktaAuth.signInWithRedirect();
  const logout = async () => oktaAuth.signOut('/');

  if (!authState) {
    return <div>Loading...</div>;
  }

  const button = authState.isAuthenticated ? (
    <Button variant="contained" color="default" onClick={logout}>
      Logout
    </Button>
  ) : (
    <Button variant="contained" color="primary" onClick={login}>
      Login
    </Button>
  );

  return (
    <div>
      <h2>Welcome to Gatekeeper</h2>
      {button}
    </div>
  );
};

export default Home;
