import * as managers from './manager/managers';
import * as managerClaims from './manager/managerClaims';
import * as rounds from './round/rounds';
import * as roundClaims from './round/roundClaims';
import * as officials from './official/officials';
import * as officialClaims from './official/officialClaims';
import * as lineups from './lineup/lineups';
import * as lineupClaims from './lineup/lineupClaims';
import * as fullLineups from './lineup/fullLineups';
import * as matches from './match/matches';
import * as matchClaims from './match/matchClaims';
import * as stadiums from './stadium/stadiums';
import * as stadiumClaims from './stadium/stadiumClaims';
import * as areas from './area/areas';
import * as areaClaims from './area/areaClaims';
import * as players from './player/playerList';
import * as playerClaims from './player/playerClaims';
import * as teams from './team/teams';
import * as teamClaims from './team/teamClaims';
import * as competitions from './competition/competitions';
import * as competitionSeasons from './competitionSeason/competitionSeasons';
import * as phases from './phase/phases';
import * as transferPlayers from './transferPlayer/transferPlayers';
import * as transferPlayerClaims from './transferPlayer/transferPlayerClaims';
import * as transferManagers from './transferManager/transferManagers';
import * as transferManagerClaims from './transferManager/transferManagerClaims';

const entityQueries = {
  ...areas,
  ...areaClaims,
  ...players,
  ...playerClaims,
  ...competitions,
  ...competitionSeasons,
  ...lineups,
  ...lineupClaims,
  ...fullLineups,
  ...teams,
  ...teamClaims,
  ...matches,
  ...matchClaims,
  ...stadiums,
  ...stadiumClaims,
  ...managers,
  ...managerClaims,
  ...officials,
  ...officialClaims,
  ...rounds,
  ...roundClaims,
  ...phases,
  ...transferPlayers,
  ...transferPlayerClaims,
  ...transferManagers,
  ...transferManagerClaims,
};

export default entityQueries;
