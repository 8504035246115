import gql from 'graphql-tag';

const MAP_TO_ROUND = gql`
  mutation MapAndApproveRound($round_id: Int!, $provider: source_provider_enum!, $provider_round_id: String!) {
    insert_mapping_round(objects: { round_id: $round_id, provider: $provider, provider_round_id: $provider_round_id }) {
      returning {
        round_id
        provider
        provider_round_id
      }
    }
    update_claim_round(
      where: { _and: { provider: { _eq: $provider }, provider_round_id: { _eq: $provider_round_id } } }
      _set: { approved_at: "now()" }
    ) {
      affected_rows
    }
  }
`;

// TO-DO: CJW, check this round name usage as replicated from area but potentially incorrect
const MAKE_AND_MAP_ROUND = gql`
  mutation MyMutation($round_name: String!, $provider: source_provider_enum!, $provider_round_id: String!) {
    insert_mapping_round(
      objects: {
        statsbomb_round: { data: { round_name: $round_name } }
        provider: $provider
        provider_round_id: $provider_round_id
      }
    ) {
      returning {
        round_id
        provider
        provider_round_id
      }
    }
  }
`;

const VIEW_ROUND_MAPPINGS = gql`
  subscription viewRoundMappings($id: Int!) {
    mapping_round(where: { round_id: { _eq: $id } }) {
      source_round {
        round_type_id
        phase_id
        round_order
        round_structure_id
        round_matches
        round_teams
        provider
        provider_round_id
      }
    }
  }
`;

const DELETE_ROUND_MAPPING = gql`
  mutation deleteRoundMappings(
    $claim_data: claim_round_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_round_one(object: $claim_data, on_conflict: { constraint: round_pkey, update_columns: [] }) {
      provider
      provider_round_id
    }

    delete_mapping_round_by_pk(provider: $provider, provider_round_id: $provider_id) {
      round_id
      provider_round_id
      provider
    }
  }
`;

export { MAP_TO_ROUND, MAKE_AND_MAP_ROUND, VIEW_ROUND_MAPPINGS, DELETE_ROUND_MAPPING };
