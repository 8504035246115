import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Main from './views/Main';
import './css/main.css';
import './css/table.css';
import './css/ul.css';
import './css/flag.css';
import './css/gender.css';

ReactDOM.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById('root')
);
