import _ from 'lodash';

function dynamicSort(property) {
  //stolen https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const nestedValues = key => entity => {
  return _.get(entity, key);
};

const concatValues = (key1, key2) => entity => {
  const firstValue = _.get(entity, key1);
  const secondValue = _.get(entity, key2);
  return firstValue && secondValue ? firstValue + ' ' + secondValue : '';
};

const concatValuesFromArray = array => entity => {
  if (array.every(({ key }) => _.get(entity, key) == null)) return ''; // Returns '' if all items are null / undefined
  return array.map(({ key, defaultValue }) => _.get(entity, key, defaultValue || '')).join(' ');
};

export { dynamicSort, nestedValues, concatValues, concatValuesFromArray };
