import gql from 'graphql-tag';

const WEATHERS = gql`
  query getAllWeathers {
    statsbomb_match_weather {
      match_weather
    }
  }
`;

export { WEATHERS };
