import React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { ClaimFilterWithCount } from '../claim/claimFilters';
import { TableCell } from '../table/table';

const EntityClaimRow = ({ claim, type, schema, claimViewState }) => {
  const mappingType = type === 'lineup' ? 'lineup' : 'default';

  const claimMap = {
    lineup: !claim.mapping_team || !claim.mapping_match || !claim.mapping_player,
    default: !claim[`mapping_${type}`],
  };
  const matchId = claim.mapping_match?.match_id;
  const teamId = claim.mapping_team?.team_id;
  const playerId = claim.mapping_player?.playerId;
  const providerEntityId = claim[`provider_${type}_id`];
  const mappedEntityId = claim[`mapping_${type}`]?.[`${type}_id`];

  const linkMap = {
    lineup: {
      mapped: `/lineup?match_id=${matchId}&team_id=${teamId}&player_id=${playerId}`,
    },
    default: {
      mapped: `/${type}?${type}_id=${mappedEntityId}`,
      unmapped: `/unmapped/${type}?provider_${type}_id=${providerEntityId}&provider=${claim.provider}`,
    },
  };

  const isUnmapped = claimMap[mappingType];
  const link = isUnmapped ? linkMap[mappingType].unmapped : linkMap[mappingType].mapped;
  return (
    <TableRow>
      {schema.map((item, index) => (
        <TableCell key={`entity_claim_row_${index}`}>{claim[item?.columnKey]}</TableCell>
      ))}
      <TableCell>
        {isUnmapped && type === 'lineup' ? (
          'Match, Team and Players Need Mapping'
        ) : (
          <Button
            component={Link}
            to={link}
            color={isUnmapped ? 'secondary' : 'primary'}
            variant="contained"
            size="small">
            {isUnmapped ? `Map ${type.replace('_', ' ')} ` : 'View Claims'}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const EntityWithClaims = ({ claimViewState, setClaimViewState, claims, claimSchema, claimsCount, type }) => {
  const filterResults = claimFilter =>
    setClaimViewState({
      ...claimViewState,
      claimFilter,
    });

  const filterSchema = {
    lineup: ['unmapped', 'mapped'],
    default: [],
  };

  return (
    <TableContainer component={Paper}>
      <ClaimFilterWithCount
        claimFilter={claimViewState.claimFilter}
        claimsCount={claimsCount}
        filterResultsFn={filterResults}
        hiddenFilters={filterSchema[type] ? filterSchema[type] : filterSchema['default']}
      />

      <Table size="small" aria-label="Claim Players Table">
        <TableHead>
          <TableRow>
            {claimSchema.map((item, index) => (
              <TableCell key={index}>{item.name}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {claims?.map((claim, key) => (
            <EntityClaimRow schema={claimSchema} {...{ key, claim, type, claimViewState }} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EntityWithClaims;
