import React, { useState } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import {
  TableContainer,
  Paper,
  Button,
  Card,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TextField,
  IconButton,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddCircle from '@material-ui/icons/AddCircle';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { TableCell } from '../components/table/table';

import { CompetitionSelector } from '../components/override/competition';
import { SeasonOverride } from '../components/override/season';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { updateItem, addItem, removeItem, reorderItemUp } from '../util/array';
import { INSERT_COMPETITION_STRUCTURE, COMPETITION_STRUCTURE } from '../hasura/queries/competitionStructure';
import { RepeatableError, RepeatableSuccess } from '../components/util/notifications';
import { QSLoading, mutationError } from '../components/graphql/util';
import { InlineProgress } from '../components/util/progress';
import { FlagIcon } from '../components/util/icon';
import { formatStructureForSave, newPhase } from '../util/competitionStructure';
import NestedItem from '../components/util/NestedItem';
import Phase from '../components/Phase';

const useStyle = makeStyles(theme => ({
  card: {
    marginTop: 20,
    padding: 10,
    '& h3': {
      paddingLeft: 5,
    },
  },
  table: {
    marginTop: 20,
  },
  phase: {
    marginTop: 20,
  },
  container: {
    padding: 10,
    margin: 10,
    marginBottom: 0,
    display: 'flow-root',
  },
  readonly: {
    color: theme.palette.secondary.dark,
    fontStyle: 'italic',
  },
}));
const alignRight = {
  margin: 10,
  marginRight: 0,
  float: 'right',
};
const StyledButton = withStyles({ root: alignRight })(Button);
const StyledProgress = withStyles({ root: alignRight })(InlineProgress);
const StyledFlagIcon = withStyles({ root: { margin: 10, height: 22, width: 22 } })(FlagIcon);

function CompetitionStructure() {
  const query = new URLSearchParams(useLocation().search);
  if (!!query.get('new')) return <NewCompetitionStructure />;

  const competitionSeasonId = query.get('competition_season_id');
  if (!competitionSeasonId) return <Redirect to="/competition_structure?new=new" />;

  return <EditCompetitionStructure {...{ competitionSeasonId }} />;
}

function EditCompetitionStructure({ competitionSeasonId }) {
  const [competitionSeasonName, setCompetitionSeasonName] = useState('');
  const [competitionId, setCompetitionId] = useState();
  const [competitionArea, setCompetitionArea] = useState({});
  const [competitionIsFemale, setCompetitionIsFemale] = useState();
  const [seasonId, setSeasonId] = useState(0);
  const [phases, setPhases] = useState([newPhase()]);

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { loading: queryLoading } = useQuery(COMPETITION_STRUCTURE, {
    variables: { competition_season_id: competitionSeasonId },
    onError: err => setErrorMessage(mutationError(err, 'Error fetching Competition Structure')),
    onCompleted: data => {
      const compStruct = (data?.statsbomb_competition_season || [{}])[0];

      setCompetitionSeasonName(compStruct.competition_season_name);
      setCompetitionId(compStruct.competition_id);
      setCompetitionArea(compStruct?.statsbomb_competition?.statsbomb_area);
      setCompetitionIsFemale(compStruct.statsbomb_competition.competition_is_female);
      setSeasonId(compStruct.season_id);
      const phases = compStruct.statsbomb_phases
        .map(phase => ({
          phaseId: phase.phase_id,
          type: phase.phase_type_id,
          phaseOrder: phase.phase_order,
          rounds: phase.statsbomb_rounds
            .map(round => ({
              roundId: round.round_id,
              structure: round.round_structure_id,
              type: round.round_type_id,
              teams: round.round_teams,
              matches: round.round_matches,
              weeks: round.round_match_weeks,
              firstMatchDate: round.round_first_match_date,
              lastMatchDate: round.round_last_match_date,
              roundOrder: round.round_order,
            }))
            .sort((thisRound, lastRound) => thisRound.roundOrder - lastRound.roundOrder),
        }))
        .sort((thisPhase, lastPhase) => thisPhase.phaseOrder - lastPhase.phaseOrder);

      setPhases(phases);
    },
  });

  if (queryLoading) return <QSLoading />;

  return (
    <CompetitionStructureView
      readonly={true}
      {...{
        competitionSeasonName,
        setCompetitionSeasonName,
        competitionId,
        setCompetitionId,
        competitionArea,
        setCompetitionArea,
        competitionIsFemale,
        setCompetitionIsFemale,
        seasonId,
        setSeasonId,
        phases,
        setPhases,
        successMessage,
        setSuccessMessage,
        saveCompetitionStructure: () => {},
        loading: false,
        errorMessage,
        setErrorMessage,
      }}
    />
  );
}

function NewCompetitionStructure() {
  const [competitionSeasonName, setCompetitionSeasonName] = useState('');
  const [competitionId, setCompetitionId] = useState();
  const [competitionArea, setCompetitionArea] = useState({});
  const [competitionIsFemale, setCompetitionIsFemale] = useState();
  const [seasonId, setSeasonId] = useState(43);
  const [phases, setPhases] = useState([newPhase()]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  const [saveCompetitionStructure, { loading }] = useMutation(INSERT_COMPETITION_STRUCTURE, {
    onError: err => setErrorMessage(mutationError(err, 'Error saving Competition Structure')),
    onCompleted: response => {
      const id = response.insert_statsbomb_competition_season.returning[0].competition_season_id;
      setSuccessMessage('Competition Structure Saved!');
      history.push(`/competition_structure?competition_season_id=${id}`);
    },
  });

  return (
    <CompetitionStructureView
      {...{
        competitionSeasonName,
        setCompetitionSeasonName,
        competitionId,
        setCompetitionId,
        competitionArea,
        setCompetitionArea,
        competitionIsFemale,
        setCompetitionIsFemale,
        seasonId,
        setSeasonId,
        phases,
        setPhases,
        successMessage,
        setSuccessMessage,
        saveCompetitionStructure,
        loading,
        errorMessage,
        setErrorMessage,
      }}
    />
  );
}

function CompetitionStructureView({
  readonly,
  competitionSeasonName,
  setCompetitionSeasonName,
  competitionId,
  setCompetitionId,
  competitionArea,
  setCompetitionArea,
  competitionIsFemale,
  setCompetitionIsFemale,
  seasonId,
  setSeasonId,
  phases,
  setPhases,
  successMessage,
  setSuccessMessage,
  saveCompetitionStructure,
  loading,
  errorMessage,
  setErrorMessage,
}) {
  const classes = useStyle();

  const addPhase = () => setPhases(addItem(phases, newPhase()));
  const removePhase = i => () => setPhases(removeItem(phases, i));
  const setPhase = i => phase => setPhases(updateItem(phases, i, phase));
  const reorderPhaseUp = i => () => setPhases(reorderItemUp(phases, i));

  return (
    <>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
      <RepeatableSuccess message={successMessage} setMessage={setSuccessMessage} />

      <Card className={classes.card}>
        <Typography variant="h6" component="h3">
          Competition Structure
        </Typography>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="2">Competition Structure</TableCell>
                <TableCell style={{ width: 30 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Competition</TableCell>
                <TableCell align="right">
                  <CompetitionSelector
                    currentValue={competitionId}
                    overrideChange={(_, value, comp) => {
                      setCompetitionArea(comp.statsbomb_area);
                      setCompetitionIsFemale(comp.is_female);
                      setCompetitionId(value);
                    }}
                    disabled={!!readonly}
                  />
                </TableCell>
                <TableCell>
                  <IconButton href="/competition?new=new" target="_blank" disabled={!!readonly}>
                    <AddCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Season</TableCell>
                <TableCell align="right">
                  <SeasonOverride
                    currentValue={seasonId}
                    overrideChange={(_, value) => setSeasonId(value)}
                    disabled={!!readonly}
                  />
                </TableCell>
                <TableCell>
                  <IconButton disabled href="/season?new=new" target="_blank">
                    <AddCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Sponsorship Name</TableCell>
                <TableCell align="right">
                  <TextField
                    value={competitionSeasonName}
                    onChange={e => setCompetitionSeasonName(e.target.value)}
                    disabled={!!readonly}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.readonly}>Area</TableCell>
                <TableCell align="right">
                  <StyledFlagIcon code={competitionArea?.area_code} />
                  <TextField disabled value={competitionArea?.area_name || ''} />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.readonly}>is Female?</TableCell>
                <TableCell align="right">
                  <LabelledCheckbox disabled currentValue={competitionIsFemale} />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <NestedItem>
          {phases.map((phase, i) => (
            <Phase
              key={i}
              {...{
                readonly,
                classes,
                phase,
                order: i,
                removePhase: removePhase(i),
                setPhase: setPhase(i),
                reorderPhaseUp: reorderPhaseUp(i),
              }}
            />
          ))}
          <StyledButton variant="contained" color="primary" onClick={addPhase} disabled={!!readonly}>
            Add phase
          </StyledButton>
        </NestedItem>
      </Card>
      {loading ? (
        <StyledProgress align="right" />
      ) : (
        <StyledButton
          variant="contained"
          color="primary"
          disabled={!!readonly}
          onClick={() => {
            try {
              saveCompetitionStructure({
                variables: formatStructureForSave({ competitionSeasonName, competitionId, seasonId, phases }),
              });
            } catch (e) {}
          }}>
          Save
        </StyledButton>
      )}
    </>
  );
}

export default CompetitionStructure;
