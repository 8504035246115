import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { MANAGERS, INSERT_SOURCE_MANAGER, MAP_MANAGER } from '../../hasura/queries/manager/managers';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';
import { FlagIcon, GenderIcon } from '../util/icon';
import { fullNoOp } from '../util/mapping';

const useStyles = makeStyles({
  name: {
    paddingLeft: 10,
  },
  option: {
    fontSize: 10,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 12,
    '& label': {
      fontSize: 12,
    },
  },
});

const ManagerOverride = ({ overrideChange, columnKey, provider }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(MANAGERS, {});

  const [mapManager] = useMutation(MAP_MANAGER, fullNoOp);

  const [insertSourceManager] = useMutation(INSERT_SOURCE_MANAGER, fullNoOp);

  if (loading) return <InlineProgress />;

  const managerOverrideSelected = async manager => {
    await insertSourceManager({
      variables: {
        manager_name: manager.manager_name,
        provider_manager_id: manager.manager_id.toString(),
      },
    });

    await mapManager({
      variables: {
        manager_id: manager.manager_id,
        provider_manager_id: manager.manager_id.toString(),
        provider: provider,
      },
    });

    overrideChange(columnKey, manager.manager_id.toString());
  };

  const managers = data?.statsbomb_manager || [];

  return (
    <>
      <Error message="Error fetching managers" error={error} />

      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        classes={{ option: classes.option, root: classes.root }}
        options={managers}
        autoHighlight
        openOnFocus
        disableClearable
        filterOptions={createFilterOptions({ ignoreAccents: false, limit: 300 })}
        getOptionLabel={manager => manager.manager_name}
        renderOption={({ nationality_statsbomb_area, manager_is_female, manager_name }) => (
          <>
            {nationality_statsbomb_area && <FlagIcon code={nationality_statsbomb_area.area_code} />}
            <GenderIcon isFemale={manager_is_female} />
            {manager_name}
          </>
        )}
        renderInput={params => <TextField {...params} label={'Pick a manager'} inputProps={{ ...params.inputProps }} />}
        onChange={(_, manager) => managerOverrideSelected(manager)}
      />
    </>
  );
};

export default ManagerOverride;
