import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { TableContainer, Button, Card, Grid, Link } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  OVERRIDE_LINEUP_CLAIM,
  GOLDEN_LINEUP_FOR_MATCH,
  GOLDEN_LINEUP_MATCH_DATA,
  INSERT_GOLDEN_LINEUP,
  OPEN_LINEUP_CLAIMS_AGAINST_GOLDEN_MATCH,
  UNMAPPED_PLAYER_LINEUP_CLAIMS,
} from '../hasura/queries/lineup/lineupClaims';
import {
  GOLDEN_PLAYERS_FOR_LINEUP_INCLUSION,
  SUGGESTED_PLAYERS,
  LINEUP_TYPES,
  REJECT_ENTIRE_LINEUP_CLAIM,
  CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_LINEUP_CLAIM,
} from '../hasura/queries/lineup/fullLineups';

import { DELETE_LINEUP_BY_ID } from '../hasura/queries/lineup/lineups';
import { DELETE_MATCH_MANAGER_BY_ID, DELETE_MATCH_OFFICIAL_BY_ID } from '../hasura/queries/match/matches';
import {
  GOLDEN_MANAGERS_FOR_MATCH,
  GOLDEN_MANAGERS_FOR_MATCH_MANAGER_INCLUSIONS,
  SUGGESTED_MANAGERS,
  MANAGER_TYPES,
} from '../hasura/queries/manager/managers';
import {
  INSERT_GOLDEN_MATCH_MANAGER,
  OPEN_MATCH_MANAGER_CLAIMS_AGAINST_GOLDEN_MATCH,
  CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_MANAGER_CLAIM,
  REJECT_ENTIRE_MATCH_MANAGER_CLAIM,
  UNMAPPED_MATCH_MANAGER_CLAIMS,
} from '../hasura/queries/manager/managerClaims';
import {
  OFFICIAL_TYPES,
  GOLDEN_OFFICIALS_FOR_MATCH,
  GOLDEN_OFFICIALS_FOR_MATCH_OFFICIALS_INCLUSIONS,
  SUGGESTED_OFFICIALS,
} from '../hasura/queries/official/officials';
import {
  INSERT_GOLDEN_MATCH_OFFICIAL,
  OPEN_MATCH_OFFICIAL_CLAIMS_AGAINST_GOLDEN_MATCH,
  CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_OFFICIAL_CLAIM,
  REJECT_ENTIRE_MATCH_OFFICIAL_CLAIM,
  UNMAPPED_MATCH_OFFICIAL_CLAIMS,
} from '../hasura/queries/official/officialClaims';
import { RepeatableError, RepeatableSuccess } from '../components/util/notifications';
import { MatchOverride } from '../components/override/match';
import { useMatchMapping, usePlayerMapping } from '../components/util/mapping';
import SuggestedEntities from '../components/fullLineup/suggestedEntities';
import OpenClaims from '../components/fullLineup/openClaims';
import {
  GoldenSelectionsSegregatedTable,
  GoldenSelectionsInlineTable,
} from '../components/fullLineup/goldenSelections';
import SearchEntityWithExclusions from '../components/fullLineup/searchEntityWithExclusions';
import { capitalise } from '../util/string';
import { SmallButton } from '../components/util/buttons';
import { InlineProgress } from '../components/util/progress';
import { navigateToEntity } from '../components/util/customHooks';

const useStyle = makeStyles({
  card: {
    marginTop: 10,
    padding: 10,
    '& h3': {
      paddingLeft: 5,
    },
  },
  leftTable: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'left',
    float: 'left',
  },
  rightTable: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'left',
  },
  container: {
    padding: 10,
    margin: 10,
    marginBottom: 0,
  },
  matchInfoContainer: {
    marginBottom: 0,
  },
  centralText: {
    textAlign: 'center',
  },
  rightText: {
    textAlign: 'right',
  },
  findPlayer: {
    width: '60%',
  },
  playerButton: {
    width: '30%',
  },
  playerLocating: {
    width: '100%',
  },
  tableSuggested: {
    maxHeight: 440,
  },
});
const marginExceptRight = {
  margin: 10,
  marginRight: 0,
};
const StyledButton = withStyles({ root: marginExceptRight })(Button);

const formatMatchDataObject = data => {
  return {
    id: data.statsbomb_match[0]?.match_id,
    name: data.statsbomb_match[0]?.match_name,
    date: data.statsbomb_match[0]?.match_date,
    roundId: data.statsbomb_match[0]?.round_id,
    homeTeam: {
      id: data.statsbomb_match[0]?.home_statsbomb_team.team_id,
      name: data.statsbomb_match[0]?.home_statsbomb_team.team_name,
    },
    awayTeam: {
      id: data.statsbomb_match[0]?.away_statsbomb_team.team_id,
      name: data.statsbomb_match[0]?.away_statsbomb_team.team_name,
    },
  };
};

const formatMappingData = data => {
  return {
    home_area_name: data.home_statsbomb_team.statsbomb_area?.area_name || '',
    home_area_code: data.home_statsbomb_team.statsbomb_area?.area_code || '',
    home_area_id: data.home_statsbomb_team.statsbomb_area?.area_id || '',
    away_area_name: data.away_statsbomb_team.statsbomb_area?.area_name || '',
    away_area_code: data.away_statsbomb_team.statsbomb_area?.area_code || '',
    away_area_id: data.away_statsbomb_team.statsbomb_area?.area_id || '',
    match_home_team_id: data.match_home_team_id,
    home_team_name: data.home_statsbomb_team.team_name,
    match_away_team_id: data.match_away_team_id,
    away_team_name: data.away_statsbomb_team.team_name,
    match_id: data.match_id,
    round_id: data.round_id,
  };
};

const FullLineupView = ({ isNewLineup, matchId, match_home_team_id, match_away_team_id }) => {
  const classes = useStyle();
  const history = useHistory();

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [matchData, setMatchData] = useState({});
  const [entityType, setEntityType] = useState('player');
  const doMatchMappings = useMatchMapping();
  const doPlayerMappings = usePlayerMapping();
  useQuery(GOLDEN_LINEUP_MATCH_DATA, {
    variables: { matchId: matchId },
    onCompleted: matchDataResponse => {
      if (!isNewLineup && matchDataResponse) {
        setMatchData(matchDataResponse.statsbomb_match[0] ? formatMatchDataObject(matchDataResponse) : {});
        doMatchMappings(formatMappingData(matchDataResponse.statsbomb_match[0]));
      }
    },
  });

  const matchSelected = selectedMatch => {
    history.push(
      `/full_lineup?match_id=${selectedMatch.match_id}&match_home_team_id=${selectedMatch.match_home_team_id}&match_away_team_id=${selectedMatch.match_away_team_id}`
    );
  };

  const { loading: lineupDataLoading, data: goldenRecordsResponse, refetch: refetchLineupData } = useQuery(
    GOLDEN_LINEUP_FOR_MATCH,
    {
      variables: { match_id: matchData.id },
    }
  );

  const goldenRecordData = goldenRecordsResponse && goldenRecordsResponse.statsbomb_lineup;

  const { loading: managerDataLoading, data: goldenManagersResponse, refetch: refetchManagerData } = useQuery(
    GOLDEN_MANAGERS_FOR_MATCH,
    {
      variables: { match_id: matchData.id },
    }
  );
  const goldenManagerData = goldenManagersResponse && goldenManagersResponse.statsbomb_match_manager;

  const { loading: officialsDataLoading, data: goldenOfficialsResponse, refetch: refetchOfficialData } = useQuery(
    GOLDEN_OFFICIALS_FOR_MATCH,
    {
      variables: { match_id: matchData.id },
    }
  );
  const goldenOfficialData = goldenOfficialsResponse && goldenOfficialsResponse.statsbomb_match_official;

  const [overrideLineupClaim, { loading: overridePlayerLoading }] = useMutation(OVERRIDE_LINEUP_CLAIM, {
    onError: err => setErrorMessage('problem updating claims: ' + err),
    onCompleted: () => setSuccessMessage('Player Lineup Claim Updated!'),
  });
  const [insertGoldenLineup, { loading: insertPlayerLoading }] = useMutation(INSERT_GOLDEN_LINEUP, {
    onCompleted: () => refetchLineupData(),
  });

  const [deleteLineupClaim] = useMutation(DELETE_LINEUP_BY_ID, {
    onError: err => setErrorMessage('problem updating claims: ' + err),
    onCompleted: () => refetchLineupData(),
  });

  const [deleteMatchManagerClaim] = useMutation(DELETE_MATCH_MANAGER_BY_ID, {
    onError: err => setErrorMessage('problem updating claims: ' + err),
    onCompleted: () => refetchManagerData(),
  });

  const [deleteMatchOfficialClaim] = useMutation(DELETE_MATCH_OFFICIAL_BY_ID, {
    onError: err => setErrorMessage('problem updating claims: ' + err),
    onCompleted: () => refetchOfficialData(),
  });

  const deleteLineupPlayer = async playerId => {
    await deleteLineupClaim({
      variables: {
        match_id: matchData.id,
        team_id: selectedTeam,
        player_id: playerId,
      },
    });
  };

  const deleteMatchManager = async ({ id: managerId }) => {
    await deleteMatchManagerClaim({
      variables: {
        match_id: matchData.id,
        manager_id: managerId,
      },
    });
  };

  const deleteMatchOfficial = async ({ id: officialId }) => {
    await deleteMatchOfficialClaim({
      variables: {
        match_id: matchData.id,
        official_id: officialId,
      },
    });
  };

  const createLineupClaim = async (playerId, name, selectionType, jersey) => {
    await doPlayerMappings(playerId, name);
    await insertGoldenLineup({
      variables: {
        statsbomb_entity: {
          match_id: matchData.id,
          team_id: selectedTeam,
          player_id: playerId,
          player_lineup_selection_type: selectionType,
          lineup_jersey: jersey,
        },
      },
    });
    await overrideLineupClaim({
      variables: {
        match_id: matchData.id.toString(),
        team_id: selectedTeam.toString(),
        player_id: playerId.toString(),
        source_entity: {
          player_lineup_selection_type: selectionType,
          lineup_jersey: jersey,
        },
      },
    });
    refetchLineupData();
  };

  const { data: managerTypes } = useQuery(MANAGER_TYPES);

  const [insertGoldenMatchManager, { loading: insertManagerLoading }] = useMutation(INSERT_GOLDEN_MATCH_MANAGER, {
    onCompleted: () => refetchManagerData(),
  });

  const createManagerClaim = async (managerId, name, managerType) => {
    await insertGoldenMatchManager({
      variables: {
        statsbomb_entity: {
          match_id: matchData.id,
          team_id: selectedTeam,
          manager_id: managerId,
          manager_type: managerType,
        },
      },
    });
  };

  const { data: officialTypes } = useQuery(OFFICIAL_TYPES);

  const [insertGoldenMatchOfficial, { loading: insertOfficialLoading }] = useMutation(INSERT_GOLDEN_MATCH_OFFICIAL, {
    onCompleted: () => refetchOfficialData(),
  });

  const createOfficialClaim = async (id, name, officialType) => {
    await insertGoldenMatchOfficial({
      variables: {
        statsbomb_entity: {
          match_id: matchData.id,
          official_id: id,
          official_type: officialType,
        },
      },
    });
  };

  const isLoading =
    officialsDataLoading ||
    managerDataLoading ||
    lineupDataLoading ||
    insertManagerLoading ||
    insertPlayerLoading ||
    insertOfficialLoading ||
    overridePlayerLoading;

  const isTeamSelected = selectedTeam !== 0;
  const isOfficialTabSelected = entityType === 'official';
  const commonPropsMap = {
    player: {
      goldenRecordData: goldenRecordData,
      createClaimFn: createLineupClaim,
      deleteLineupPlayerFn: deleteLineupPlayer,
      suggestedEntityLineupData: 'statsbomb_players_lineup_aggregate',
      typeTablePath: 'statsbomb_player_lineup_selection_type',
    },
    manager: {
      goldenRecordData: goldenManagerData?.filter(manager => manager.team_id === selectedTeam),
      createClaimFn: createManagerClaim,
      deleteEntityFn: deleteMatchManager,
      suggestedEntityLineupData: 'statsbomb_managers_match_manager_aggregate',
      typeTablePath: 'statsbomb_manager_type',
    },
    official: {
      goldenRecordData: goldenOfficialData,
      createClaimFn: createOfficialClaim,
      deleteEntityFn: deleteMatchOfficial,
      suggestedEntityLineupData: 'statsbomb_match_officials_aggregate',
      typeTablePath: 'statsbomb_official_type',
    },
  };

  const searchEntityMap = {
    player: {
      suggestedEntitiesQuery: GOLDEN_PLAYERS_FOR_LINEUP_INCLUSION,
      flagIconPath: { path: 'nationality_statsbomb_area', altPath: 'player_nationality_code' },
      lineupSelectionQuery: LINEUP_TYPES,
    },
    manager: {
      suggestedEntitiesQuery: GOLDEN_MANAGERS_FOR_MATCH_MANAGER_INCLUSIONS,
      flagIconPath: { path: 'nationality_statsbomb_area', altPath: 'manager_nationality_code' },
      lineupSelectionQuery: MANAGER_TYPES,
    },
    official: {
      suggestedEntitiesQuery: GOLDEN_OFFICIALS_FOR_MATCH_OFFICIALS_INCLUSIONS,
      flagIconPath: { path: 'statsbomb_area', altPath: 'official_nationality_code' },
      lineupSelectionQuery: OFFICIAL_TYPES,
    },
  };

  const suggestedEntityMap = {
    player: {
      suggestedEntitiesQuery: SUGGESTED_PLAYERS,
      suggestedEntitiesVariables: { team_ids: [match_home_team_id, match_away_team_id] },
      filterVariables: { selectedTeam },
      lineupSelectionQuery: LINEUP_TYPES,
    },
    manager: {
      suggestedEntitiesQuery: SUGGESTED_MANAGERS,
      suggestedEntitiesVariables: { team_ids: [match_home_team_id, match_away_team_id] },
      filterVariables: { selectedTeam },
      lineupSelectionQuery: MANAGER_TYPES,
    },
    official: {
      suggestedEntitiesQuery: SUGGESTED_OFFICIALS,
      suggestedEntitiesVariables: { round_id: matchData.roundId },
      filterVariables: {},
      lineupSelectionQuery: OFFICIAL_TYPES,
    },
  };

  const claimsComponents = {
    player: (
      <OpenClaims
        {...{ setErrorMessage, setSuccessMessage, matchData, selectedTeam }}
        entityType={entityType}
        claimPK="claim_lineup_uuid"
        claimsResponsePath="statsbomb_claim_for_golden_lineup"
        schema={{ name: 'player_name', selectionType: 'player_lineup_selection_type', lineupJersey: 'lineup_jersey' }}
        openClaimsQuery={OPEN_LINEUP_CLAIMS_AGAINST_GOLDEN_MATCH}
        approveClaimMutation={CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_LINEUP_CLAIM}
        rejectClaimMutation={REJECT_ENTIRE_LINEUP_CLAIM}
        unmappedClaimsQuery={UNMAPPED_PLAYER_LINEUP_CLAIMS}
        unmappedClaimsPath="claim_lineup"
        refetchData={refetchLineupData}
      />
    ),
    manager: (
      <OpenClaims
        {...{ setErrorMessage, setSuccessMessage, matchData, selectedTeam }}
        entityType={entityType}
        claimPK="claim_match_manager_uuid"
        claimsResponsePath="statsbomb_claim_for_golden_match_manager"
        schema={{ name: 'manager_name', selectionType: 'manager_type' }}
        openClaimsQuery={OPEN_MATCH_MANAGER_CLAIMS_AGAINST_GOLDEN_MATCH}
        approveClaimMutation={CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_MANAGER_CLAIM}
        rejectClaimMutation={REJECT_ENTIRE_MATCH_MANAGER_CLAIM}
        unmappedClaimsQuery={UNMAPPED_MATCH_MANAGER_CLAIMS}
        unmappedClaimsPath="claim_match_manager"
        refetchData={refetchManagerData}
      />
    ),
    official: (
      <OpenClaims
        {...{ setErrorMessage, setSuccessMessage, matchData }}
        entityType={entityType}
        claimPK="claim_match_official_uuid"
        claimsResponsePath="statsbomb_claim_for_golden_match_official"
        schema={{ name: 'official_name', selectionType: 'official_type' }}
        openClaimsQuery={OPEN_MATCH_OFFICIAL_CLAIMS_AGAINST_GOLDEN_MATCH}
        approveClaimMutation={CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_OFFICIAL_CLAIM}
        rejectClaimMutation={REJECT_ENTIRE_MATCH_OFFICIAL_CLAIM}
        unmappedClaimsQuery={UNMAPPED_MATCH_OFFICIAL_CLAIMS}
        unmappedClaimsPath="claim_match_official"
        refetchData={refetchOfficialData}
      />
    ),
  };

  const goldenComponents = {
    player: <GoldenSelectionsSegregatedTable {...{ matchData, selectedTeam, ...commonPropsMap[entityType] }} />,
    manager: (
      <GoldenSelectionsInlineTable
        headerName="Management Team"
        types={managerTypes?.statsbomb_manager_type}
        {...{ ...commonPropsMap[entityType], entityType, isLoading }}
      />
    ),
    official: (
      <GoldenSelectionsInlineTable
        headerName="Match Officials"
        types={officialTypes?.statsbomb_official_type}
        {...{ ...commonPropsMap[entityType], entityType, isLoading }}
      />
    ),
  };

  const clickTeamTab = (selectedTeam, selectedEntity) => {
    setSelectedTeam(selectedTeam);
    setEntityType(selectedEntity);
  };

  const ClaimEntity = () => claimsComponents[entityType];

  const GoldenRecords = () => goldenComponents[entityType];

  return (
    <>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
      <RepeatableSuccess message={successMessage} setMessage={setSuccessMessage} />

      <h2>Full Lineup</h2>
      <h4>
        <Link className="highlight" onClick={() => navigateToEntity('match', matchData.id, history)}>
          {matchData.name || `${matchData.homeTeam?.name} vs. ${matchData.awayTeam?.name}`}
        </Link>
      </h4>
      {isLoading ? (
        <InlineProgress />
      ) : (
        <>
          <Card className={classes.card}>
            {!matchData.homeTeam ? (
              <MatchOverride overrideChange={(_, matchData) => matchSelected(matchData)} />
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <StyledButton
                    variant="contained"
                    color={selectedTeam === matchData.homeTeam?.id ? 'primary' : 'secondary'}
                    onClick={() => clickTeamTab(matchData.homeTeam?.id, 'player')}>
                    {matchData.homeTeam?.name}
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    color={selectedTeam === matchData.awayTeam?.id ? 'primary' : 'secondary'}
                    onClick={() => clickTeamTab(matchData.awayTeam?.id, 'player')}>
                    {matchData.awayTeam?.name}
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    color={entityType === 'official' ? 'primary' : 'secondary'}
                    onClick={() => clickTeamTab(null, 'official')}>
                    Officials
                  </StyledButton>
                </Grid>
                {isTeamSelected && !isOfficialTabSelected && (
                  <Grid item xs={7}>
                    <StyledButton
                      variant="contained"
                      color={entityType === 'player' ? 'primary' : 'secondary'}
                      onClick={() => {
                        setEntityType('player');
                      }}>
                      Players
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color={entityType === 'manager' ? 'primary' : 'secondary'}
                      onClick={() => {
                        setEntityType('manager');
                      }}>
                      Managers
                    </StyledButton>
                  </Grid>
                )}
              </Grid>
            )}
          </Card>
          {isTeamSelected && (
            <Grid container spacing={1} justify="center" alignItems="flex-start">
              <Grid item xs={7}>
                <Card className={classes.card}>
                  <div>
                    <SearchEntityWithExclusions
                      entityType={entityType}
                      entitiesToExclude={commonPropsMap[entityType].goldenRecordData || []}
                      suggestedEntitiesQuery={searchEntityMap[entityType].suggestedEntitiesQuery}
                      overrideChange={({ id, name, selectionType }) =>
                        commonPropsMap[entityType].createClaimFn(id, name, selectionType)
                      }
                      flagIconPath={searchEntityMap[entityType].flagIconPath}
                      typeTablePath={commonPropsMap[entityType].typeTablePath}
                      lineupSelectionQuery={searchEntityMap[entityType].lineupSelectionQuery}
                    />
                    <SmallButton
                      color="secondary"
                      href="/scrape"
                      target="_blank"
                      text={`Scrape a ${capitalise(entityType)}`}
                    />
                    <SmallButton
                      color="secondary"
                      href={`/${entityType}?new=new`}
                      text={`Add New ${capitalise(entityType)}`}
                    />
                  </div>
                  <SuggestedEntities
                    createClaimFn={({ id, name, selectionType, jerseyNumber }) =>
                      commonPropsMap[entityType].createClaimFn(id, name, selectionType, jerseyNumber)
                    }
                    suggestedEntitiesQuery={suggestedEntityMap[entityType].suggestedEntitiesQuery}
                    suggestedEntitiesVariables={suggestedEntityMap[entityType].suggestedEntitiesVariables}
                    suggestedEntityLineupData={commonPropsMap[entityType].suggestedEntityLineupData}
                    entityType={entityType}
                    entitiesToExclude={commonPropsMap[entityType].goldenRecordData || []}
                    filterVariables={suggestedEntityMap[entityType].filterVariables}
                    typeTablePath={commonPropsMap[entityType].typeTablePath}
                    lineupSelectionQuery={suggestedEntityMap[entityType].lineupSelectionQuery}
                    loading={isLoading}
                  />
                  <ClaimEntity />
                </Card>
              </Grid>
              <Grid item xs={5}>
                <Card className={classes.card}>
                  <TableContainer>
                    <GoldenRecords />
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

function FullLineup() {
  const query = new URLSearchParams(useLocation().search);
  const isNewEntity = query.get('new');
  const matchId = isNewEntity ? '0' : query.get('match_id');
  const match_home_team_id = isNewEntity ? '0' : query.get('match_home_team_id');
  const match_away_team_id = isNewEntity ? '0' : query.get('match_away_team_id');

  return (
    <FullLineupView
      matchId={matchId}
      match_home_team_id={match_home_team_id}
      match_away_team_id={match_away_team_id}
      isNewLineup={!!isNewEntity}
    />
  );
}

export default FullLineup;
