export const deletionTree = {
  'statsbomb.competition': {
    id: 'competition_id',
    dependecies: {
      'statsbomb.competition_season': ['competition_id'],
    },
  },
  'statsbomb.competition_ladder': {
    id: 'competition_ladder_id',
    dependecies: {
      'claim.comeptition': ['competition_ladder_id'],
      'statsbomb.competition': ['competition_ladder_id'],
    },
  },
  'statsbomb.competition_group': {
    id: 'competition_group_id',
    dependecies: {
      'claim.comeptition': ['competition_group_id'],
      'statsbomb.competition': ['competition_group_id'],
    },
  },
  'statsbomb.competition_type': {
    id: 'competition_type_id',
    dependecies: {
      'claim.comeptition': ['competition_type_id'],
      'statsbomb.competition': ['competition_type_id'],
    },
  },
  'statsbomb.season': {
    id: 'season_id',
    dependecies: {
      'statsbomb.compettion_season': ['season_id'],
    },
  },
  'statsbomb.competition_season': {
    id: 'competition_season_id',
    dependecies: {
      'statsbomb.phase': ['competition_season_id'],
    },
  },
  'statsbomb.phase': {
    id: 'phase_id',
    dependecies: {
      'statsbomb.round': ['phase_id'],
    },
  },
  'statsbomb.phase_type': {
    id: 'phase_type_id',
    dependecies: {
      'statsbomb.phase': ['phase_type_id'],
    },
  },
  'statsbomb.round': {
    id: 'round_id',
    dependecies: {
      'statsbomb.team_round_group': ['round_id'],
      'statsbomb.match': ['round_id'],
    },
  },
  'statsbomb.round_structure': {
    id: 'round_structure_id',
    dependecies: {
      'statsbomb.round': ['round_structure_id'],
    },
  },
  'statsbomb.round_type': {
    id: 'round_type_id',
    dependecies: {
      'statsbomb.round': ['round_type_id'],
    },
  },
  'statsbomb.stadium': {
    id: 'stadium_id',
    dependecies: {
      'statsbomb.match': ['stadium_id'],
    },
  },
  'statsbomb.match': {
    id: 'match_id',
    dependecies: {
      'statsbomb.lineup': ['match_id'],
    },
  },
  'statsbomb.team': {
    id: 'team_id',
    dependecies: {
      'statsbomb.match': ['match_home_team_id', 'match_away_team_id'],
      'statsbomb.lineup': ['team_id'],
      'statsbomb.transfer_player': ['team_to_id', 'team_from_id'],
      'statsbomb.transfer_manager': ['team_to_id', 'team_from_id'],
      'statsbomb.team_round_group': ['team_id'],
    },
  },
  'statsbomb.player': {
    id: 'player_id',
    dependecies: {
      'statsbomb.lineup': ['player_id'],
      'statsbomb.transfer_player': ['player_id'],
    },
  },
  'statsbomb.area': {
    id: 'area_id',
    dependecies: {
      'statsbomb.player': ['player_nationality', 'player_country_of_birth'],
      'statsbomb.competition': ['area_id'],
      'statsbomb.team': ['area_id'],
      'statsbomb.stadium': ['area_id'],
      'statsbomb.official': ['official_nationality'],
      'statsbomb.manager': ['manager_nationality'],
    },
  },
  'statsbomb.official': {
    id: 'official_id',
    dependecies: {},
  },
  'statsbomb.manager': {
    id: 'manager_id',
    dependecies: {
      'statsbomb.transfer_manager': ['manager_id'],
    },
  },
  'statsbomb.group_name': {
    id: 'group_name',
    dependecies: {
      'statsbomb.team_round_group': ['group_name'],
    },
  },
  'statsbomb.manager_type': {
    id: 'manager_type',
    dependecies: {
      'statsbomb.match_manager': ['manager_type'],
      'claim.match_manager': ['manager_type'],
    },
  },
  'statsbomb.official_type': {
    id: 'official_type',
    dependecies: {
      'statsbomb.match_official': ['official_type'],
    },
  },
  'statsbomb.transfer_type': {
    id: 'transfer_type',
    dependecies: {
      'statsbomb.transfer_player': ['transfer_type'],
      'statsbomb.transfer_manager': ['transfer_type'],
    },
  },
  'statsbomb.player_lineup_selection_type': {
    id: 'player_lineup_selection_type',
    dependecies: {
      'statsbomb.lineup': ['player_lineup_selection_type'],
    },
  },
  'statsbomb.currency': {
    id: 'currency',
    dependecies: {
      'statsbomb.transfer_player': ['currency'],
      'statsbomb.transfer_manager': ['currency'],
    },
  },
  'statsbomb.lineup': {
    id: ['player_id', 'team_id', 'match_id'],
  },
  'statsbomb.transfer_manager': {
    id: 'transfer_manager_id',
  },
  'statsbomb.transfer_player': {
    id: 'transfer_player_id',
  },
  'statsbomb.match_official': {
    id: ['match_id', 'official_id'],
  },
  'statsbomb.match_manager': {
    id: ['match_id', 'manager_id'],
  },
  'statsbomb.team_round_group': {
    id: ['team_id', 'round_id '],
  },
};
