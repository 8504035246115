import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TEAMS, MAP_TEAM, INSERT_SOURCE_TEAM } from '../../hasura/queries/team/teams';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';
import { GenderIcon, FlagIcon } from '../util/icon';

const useStyles = makeStyles({
  option: {
    fontSize: 10,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 12,
    '& label': {
      fontSize: 12,
    },
  },
});

const TeamOverride = ({ overrideChange, columnKey, provider, currentValue }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(TEAMS, {});

  const [mapTeam] = useMutation(MAP_TEAM, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [insertSourceTeam] = useMutation(INSERT_SOURCE_TEAM, {
    onError: () => {},
    onCompleted: () => {},
  });

  if (loading) return <InlineProgress />;

  const teamOverrideSelected = async team => {
    const variables = {
      team_name: team.team_name,
      provider_team_id: team.team_id.toString(),
    };

    await insertSourceTeam({
      variables,
    });
    await mapTeam({
      variables: {
        team_id: team.team_id,
        provider_team_id: team.team_id.toString(),
        provider: provider,
      },
    });

    overrideChange(columnKey, team.team_id.toString());
  };

  const teams = data?.statsbomb_team || [];

  const value = currentValue ? teams.filter(team => team.team_id === parseInt(currentValue))[0] : null;

  return (
    <>
      <Error message="Error fetching teams" error={error} />

      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        classes={{ option: classes.option, root: classes.root }}
        value={value}
        options={teams}
        autoHighlight
        openOnFocus
        disableClearable
        filterOptions={createFilterOptions({
          ignoreAccents: false,
          limit: 300,
          stringify: team => `${team.team_name} ${team.team_id}`,
        })}
        getOptionLabel={team => team.team_name}
        renderOption={team => (
          <>
            <GenderIcon className={`${classes.right} list-only`} isFemale={team.team_is_female} />
            {team.statsbomb_area && <FlagIcon code={team.statsbomb_area.area_code} />}
            {team.team_name} ({team.team_id})
          </>
        )}
        renderInput={params => <TextField {...params} label={'Pick a team'} inputProps={{ ...params.inputProps }} />}
        onChange={(_, team) => teamOverrideSelected(team)}
      />
    </>
  );
};

export { TeamOverride };
