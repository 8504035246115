import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button, TableContainer, Paper, TableHead, Table } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MAP_TO_ROUND } from '../../hasura/queries/round/roundMapping';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import { ROUND_CLAIM_TO_MAP_SCHEMA } from '../../schema/roundSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import { RoundModalOverride } from '../../components/override/round';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));
const RoundMapping = props => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [roundMapViewState, setRoundMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedGolden: null,
  });

  const filterResults = claimFilter => {
    setRoundMapViewState({
      ...roundMapViewState,
      claimFilter,
    });
  };

  const filterClaims = claims => {
    if (roundMapViewState.claimFilter === 'undecided') {
      return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    } else if (roundMapViewState.claimFilter === 'approved') {
      return claims.filter(c => c.approved_at != null);
    } else if (roundMapViewState.claimFilter === 'rejected') {
      return claims.filter(c => c.rejected_at != null);
    } else {
      return claims;
    }
  };

  const selectClaim = selectedClaim => {
    setRoundMapViewState({
      ...roundMapViewState,
      selectedClaim,
    });
  };

  const selectGoldenRound = selectedGolden => {
    const newValue = {
      ...roundMapViewState,
      selectedGolden,
    };
    setRoundMapViewState(newValue);
  };

  const filteredClaims = filterClaims(props.claims);

  const returnToClaimsList = () => {
    history.push('/claims/round');
  };

  const [mapToRound] = useMutation(MAP_TO_ROUND, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Round Claim to Statsbomb Round')),
    onCompleted: returnToClaimsList,
  });

  const selClaim = props.claims.filter(c => c.claim_round_uuid === roundMapViewState.selectedClaim)[0] || {};

  const createRoundMapFn = async () => {
    try {
      await mapToRound({
        variables: {
          round_id: roundMapViewState.selectedGolden,
          provider: selClaim.provider,
          provider_round_id: selClaim.provider_round_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Round Mapper. Provider Round Id: {props.provider_round_id}</h2>
        <h3>Select A Claim to view Possible Rounds</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={roundMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={ROUND_CLAIM_TO_MAP_SCHEMA}
            rowClickHandler={claim => {
              selectClaim(claim.claim_round_uuid);
            }}
            entityKey="claim_round_uuid"
            selectedEntityKey={roundMapViewState?.selectedClaim}
          />
        </TableContainer>

        {roundMapViewState.selectedClaim && (
          <>
            <TableContainer component={Paper} classes={classes}>
              <h3 style={{ margin: '10px 5px' }}>Select Golden Round</h3>
              <p style={{ margin: '10px 0 10px 10px' }}>Select a round to map to before being able to continue.</p>
              <Table>
                <TableHead style={{ float: 'left', marginLeft: '5px' }}>
                  <RoundModalOverride
                    overrideChange={(_, roundId) => {
                      selectGoldenRound(roundId);
                    }}
                    columnKey={''}
                    provider={'Info_Team'}
                  />
                </TableHead>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} classes={classes}>
              {roundMapViewState.selectedGolden && (
                <Button variant="contained" color="primary" onClick={createRoundMapFn}>
                  Map to {roundMapViewState.selectedGolden}
                </Button>
              )}
            </TableContainer>
          </>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export { RoundMapping };
