import React from 'react';
import { TableContainer, Paper, TableHead, TableRow, Table, TableBody, TextField, IconButton } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

import { TableCell } from '../components/table/table';
import { RoundStructure } from '../components/override/roundStructure';
import { DateOverride } from '../components/override/dateTime';
import { RoundType } from '../components/override/roundType';

export default function Round({ classes, round, removeRound, order, reorderRoundUp, setRound, readonly }) {
  return (
    <TableContainer className={classes.phase} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Round</TableCell>
            <TableCell align="right">
              {!!order && (
                <IconButton onClick={reorderRoundUp} disabled={!!readonly}>
                  <ArrowUpward />
                </IconButton>
              )}
              <IconButton onClick={removeRound} disabled={!!readonly}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Structure</TableCell>
            <TableCell align="right">
              <RoundStructure
                currentValue={round.structure}
                overrideChange={(_, value) => setRound({ ...round, structure: value })}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">
              <RoundType
                currentValue={round.type}
                overrideChange={(_, value) => setRound({ ...round, type: value })}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Teams</TableCell>
            <TableCell align="right">
              <TextField
                type="number"
                error={round.teams < 1}
                value={round.teams}
                onChange={({ target: { value } }) => setRound({ ...round, teams: value })}
                InputProps={{ inputProps: { min: 0 } }}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Matches</TableCell>
            <TableCell align="right">
              <TextField
                type="number"
                error={round.matches < 1}
                value={round.matches}
                onChange={({ target: { value } }) => setRound({ ...round, matches: value })}
                InputProps={{ inputProps: { min: 0 } }}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Match Weeks</TableCell>
            <TableCell align="right">
              <TextField
                type="number"
                error={round.weeks < 1}
                value={round.weeks}
                align="right"
                onChange={({ target: { value } }) => setRound({ ...round, weeks: value })}
                InputProps={{ inputProps: { min: 0 } }}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>First Match Date</TableCell>
            <TableCell align="right">
              <DateOverride
                label="First Match Date"
                error={!round.firstMatchDate}
                currentValue={round.firstMatchDate}
                overrideChange={(_, firstMatchDate) => setRound({ ...round, firstMatchDate })}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Match Date</TableCell>
            <TableCell align="right">
              <DateOverride
                label="Last Match Date"
                error={!round.lastMatchDate}
                currentValue={round.lastMatchDate}
                overrideChange={(_, lastMatchDate) => setRound({ ...round, lastMatchDate })}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!!readonly}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
