const getSchemaBasedOnAction = (defaultSchema, action) => {
  const componentsToRemove = {
    in: ['team_to_id'],
    out: ['player_id', 'team_from_id'],
  };
  return defaultSchema.filter(item => !componentsToRemove[action].includes(item.columnKey));
};

const filterSchema = (schema, componentsToFilter) =>
  schema.filter(item => !componentsToFilter.includes(item.columnKey));

export { getSchemaBasedOnAction, filterSchema };
