import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_TEAM_BY_PROVIDER } from '../../hasura/queries/team/teamClaims';
import { TeamMapping } from '../../components/team/teamMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

function TeamMapper(props) {
  const query = new URLSearchParams(useLocation().search); //get provider playerid from the query string ~ better than using props?
  const provider_team_id = query.get('provider_team_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_TEAM_BY_PROVIDER, {
    variables: {
      provider_team_id: provider_team_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Team" error={error} />
      <TeamMapping claims={data?.claim_team || []} provider_team_id={provider_team_id} />
    </>
  );
}

export default TeamMapper;
