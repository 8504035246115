import gql from 'graphql-tag';

export const COMPETITION_TYPES = gql`
  query competitionTypes {
    statsbomb_competition_type(order_by: { competition_type_name: asc }) {
      competition_type_name
      competition_type_id
    }
  }
`;
