import React from 'react';
import { TableContainer, Paper, TableHead, TableRow, Table, TableBody, IconButton, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowUpward } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { TableCell } from '../components/table/table';

import { addItem, removeItem, updateItem, reorderItemUp } from '../util/array';
import { newRound } from '../util/competitionStructure';
import { PhaseType } from '../components/override/phaseType';
import NestedItem from '../components/util/NestedItem';
import Round from '../components/Round';

const alignRight = {
  margin: 10,
  marginRight: 0,
  float: 'right',
};
const StyledButton = withStyles({ root: alignRight })(Button);

export default function Phase({ classes, phase, order, removePhase, setPhase, reorderPhaseUp, readonly }) {
  const { rounds } = phase;
  const setRounds = rounds => setPhase({ ...phase, rounds });
  const addRound = () => setRounds(addItem(rounds, newRound()));
  const removeRound = i => () => setRounds(removeItem(rounds, i));
  const setRound = i => round => setRounds(updateItem(rounds, i, round));
  const reorderRoundUp = i => () => setRounds(reorderItemUp(rounds, i));

  return (
    <>
      <TableContainer className={classes.phase} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Phase</TableCell>
              <TableCell align="right">
                {!!order && (
                  <IconButton onClick={reorderPhaseUp} disabled={!!readonly}>
                    <ArrowUpward />
                  </IconButton>
                )}
                <IconButton onClick={removePhase} disabled={!!readonly}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Phase</TableCell>
              <TableCell align="right">
                <PhaseType
                  currentValue={phase.type}
                  overrideChange={(_, type) => setPhase({ ...phase, type })}
                  disabled={!!readonly}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <NestedItem>
        {rounds.map((round, i) => (
          <Round
            key={i}
            {...{
              readonly,
              classes,
              round,
              order: i,
              setRound: setRound(i),
              removeRound: removeRound(i),
              reorderRoundUp: reorderRoundUp(i),
            }}
          />
        ))}

        <StyledButton variant="contained" color="primary" onClick={addRound} disabled={!!readonly}>
          Add round
        </StyledButton>
      </NestedItem>
    </>
  );
}
