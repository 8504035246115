import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { WEATHERS } from '../../hasura/queries/weather';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const WeatherStatusOverride = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(WEATHERS, {});

  if (loading) return <InlineProgress />;

  const matchWeathers = data.statsbomb_match_weather || [];

  return (
    <>
      <Error message="Error fetching Weather Statuses" error={error} />

      <InputLabel id="weather-status-input">Weather</InputLabel>
      <Select
        onChange={e => overrideChange(columnKey, e.target.value)}
        value={currentValue || matchWeathers[0].match_weather}
        labelId="weather-status-input">
        {matchWeathers.map(({ match_weather }) => (
          <MenuItem key={match_weather} value={match_weather}>
            {match_weather}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { WeatherStatusOverride };
