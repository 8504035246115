import React from 'react';
import { Select, MenuItem, InputLabel, makeStyles } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  COMPETITIONS,
  INSERT_SOURCE_COMPETITION,
  MAP_COMPETITION,
} from '../../hasura/queries/competition/competitions';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';
import { FlagIcon, GenderIcon } from '../util/icon';

const useStyle = makeStyles(theme => ({
  right: { float: 'right' },
  left: { float: 'left' },
  subtle: { color: theme.palette.secondary.dark },
  block: { display: 'block' },
}));

const CompetitionOverride = ({ overrideChange, currentValue, columnKey, provider }) => {
  const [mapCompetition] = useMutation(MAP_COMPETITION, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [insertSourceCompetition] = useMutation(INSERT_SOURCE_COMPETITION, {
    onError: () => {},
    onCompleted: () => {},
  });

  const competitionOverrideSelected = async (columnKey, _, competition) => {
    const variables = {
      competition_name: competition.competition_name,
      provider_competition_id: competition.competition_id.toString(),
    };

    await insertSourceCompetition({
      variables,
    });
    await mapCompetition({
      variables: {
        competition_id: competition.competition_id,
        provider_competition_id: competition.competition_id.toString(),
        provider: provider,
      },
    });

    overrideChange(columnKey, competition.competition_id.toString());
  };

  return (
    <CompetitionSelector overrideChange={competitionOverrideSelected} {...{ currentValue, columnKey, provider }} />
  );
};

const CompetitionSelector = ({ overrideChange, currentValue, columnKey, provider, ...props }) => {
  const classes = useStyle();
  const { loading, error, data } = useQuery(COMPETITIONS, {});

  if (loading) return <InlineProgress />;

  const competitionOverrideSelected = async competitionId => {
    overrideChange(
      columnKey,
      competitionId,
      competitions.find(comp => comp.competition_id === competitionId)
    );
  };

  const competitions = data?.statsbomb_competition || [];

  return (
    <>
      <Error message="Error fetching Competitions" error={error} />

      <InputLabel id="comp-input">Competition</InputLabel>
      <Select
        error={!currentValue}
        onChange={event => competitionOverrideSelected(event.target.value)}
        value={currentValue ? currentValue : ''}
        labelId="comp-input"
        {...props}>
        {competitions.map((comp, key) => (
          <MenuItem key={key} value={comp.competition_id} className={classes.block}>
            <span className={classes.left}>{comp.competition_name}</span>
            <GenderIcon className={`${classes.right} list-only`} isFemale={comp.competition_is_female} />
            <span className={`${classes.right} ${classes.subtle} list-only`}>
              {comp.statsbomb_area?.area_name} <FlagIcon code={comp.statsbomb_area?.area_code} />
            </span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { CompetitionOverride, CompetitionSelector };
