import { nestedValues } from '../util/useful';
import { TextOverride } from '../components/override/text';
import { CompetitionOverride } from '../components/override/competition';
import { SeasonOverride } from '../components/override/season';
import { TextFilter } from '../components/table/entityTableFilters';

const FULL_COMPETITION_SEASON_SCHEMA = [
  {
    columnKey: 'claim_competition_season_uuid',
    name: 'Claim UUId',
    type: 'String',
  },
  {
    columnKey: 'competition_season_name',
    name: 'Competition Season',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'competition_id',
    name: 'Competition',
    type: 'String',
    mapping_function: nestedValues('source_competition.competition_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_competition.competition_name'),
    OverrideControl: CompetitionOverride,
  },
  {
    columnKey: 'season_id',
    name: 'Season',
    type: 'String',
    mapping_function: nestedValues('statsbomb_season.season_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_season.season_name'),
    OverrideControl: SeasonOverride,
    notCompositePK: true,
  },
  { columnKey: 'competition_name', name: 'Competition', type: 'String' },
  { columnKey: 'season_name', name: 'Season', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_competition_season_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'deleted', name: 'Deleted?', type: 'Boolean' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const COMPETITION_SEASON_SCHEMA = FULL_COMPETITION_SEASON_SCHEMA.filter(a =>
  ['competition_season_id', 'competition_season_name', 'competition_id', 'season_id', 'deleted'].includes(a.columnKey)
);

const GOLDEN_COMPETITION_SEASON_SCHEMA = FULL_COMPETITION_SEASON_SCHEMA.filter(a =>
  ['competition_season_name', 'competition_name', 'season_name'].includes(a.columnKey)
);

const COMPETITION_SEASON_CLAIM_SCHEMA = [
  'competition_season_id',
  'competition_season_name',
  'competition_id',
  'season_id',
];

const COMPETITION_SEASON_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'text',
      queryVariableName: 'name',
      displayName: 'Name',
    },
  },
];

export {
  COMPETITION_SEASON_SCHEMA,
  GOLDEN_COMPETITION_SEASON_SCHEMA,
  COMPETITION_SEASON_CLAIM_SCHEMA,
  COMPETITION_SEASON_FILTER_SCHEMA,
};
