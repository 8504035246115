export const capitalise = words =>
  words &&
  words
    .split(/ |_/)
    .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');

export const pluralise = word => {
  if (['match', 'Match', 'MATCH'].some(uglyWord => uglyWord.match(word))) return `${word}es`;
  return `${word}s`;
};

export const unaccent = str => {
  const map = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  Object.keys(map).forEach(key => {
    str = str.replace(new RegExp(map[key], 'g'), key);
  });

  return str;
};

export const truncate = (str, length) => (str.length > length ? str.substr(0, length - 1) + '..' : str);
