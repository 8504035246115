import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GOLDEN_FULL_LINEUPS_QUERY } from '../../hasura/queries/lineup/fullLineups';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';
import { FlagIcon } from '../util/icon';
import { useMatchMapping } from '../util/mapping';

const useStyles = makeStyles({
  option: {
    fontSize: 10,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 12,
    '& label': {
      fontSize: 12,
    },
  },
});

const MatchOverride = ({ overrideChange, columnKey }) => {
  const classes = useStyles();

  const doMatchMappings = useMatchMapping();

  const { loading, error, data } = useQuery(GOLDEN_FULL_LINEUPS_QUERY, {});

  if (loading) return <InlineProgress />;

  const matchOverrideSelected = match => {
    doMatchMappings(match);
    overrideChange(columnKey, match);
  };

  const matches = data?.statsbomb_golden_full_lineup || [];

  return (
    <>
      <Error message="Error fetching teams" error={error} />

      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        id="matchOverride"
        classes={{ option: classes.option, root: classes.root }}
        options={matches}
        autoHighlight
        openOnFocus
        disableClearable
        getOptionLabel={match => (match.match_name ? match.match_name : '')}
        renderOption={match => (
          <>
            <span>{match.home_area_code && <FlagIcon code={match.home_area_code} />}</span>
            <span>
              {match.home_team_name} v {match.away_team_name}
            </span>
            <span>{match.away_area_code && <FlagIcon code={match.away_area_code} />}</span>
          </>
        )}
        renderInput={params => <TextField {...params} label={'Pick a match'} inputProps={{ ...params.inputProps }} />}
        onChange={(_, match) => {
          matchOverrideSelected(match);
        }}
      />
    </>
  );
};

export { MatchOverride };
