import gql from 'graphql-tag';

const SEASONS = gql`
  query getAllSeasons {
    statsbomb_season(order_by: { season_name: asc }) {
      season_id
      season_name
    }
  }
`;

export { SEASONS };
