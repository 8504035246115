import gql from 'graphql-tag';

const MAP_TRANSFER_MANAGER = gql`
  mutation insertMapTransferManager(
    $transfer_manager_id: Int!
    $provider: source_provider_enum!
    $provider_transfer_manager_id: String!
  ) {
    insert_mapping_transfer_manager_one(
      object: {
        transfer_manager_id: $transfer_manager_id
        provider: $provider
        provider_transfer_id: $provider_transfer_id
      }
      on_conflict: { constraint: transfer_manager_pkey, update_columns: [] }
    ) {
      transfer_manager_id
      provider
      provider_transfer_manager_id
    }
  }
`;

const MAP_TO_TRANSFER_MANAGER = gql`
  mutation mapToTransferManager(
    $transfer_manager_id: Int!
    $provider: source_provider_enum!
    $provider_transfer_manager_id: String!
  ) {
    insert_mapping_transfer_manager(
      objects: {
        transfer_manager_id: $transfer_manager_id
        provider: $provider
        provider_transfer_manager_id: $provider_transfer_manager_id
      }
    ) {
      returning {
        provider_transfer_manager_id
        provider
        transfer_manager_id
      }
    }
  }
`;

const MAKE_AND_MAP_TRANSFER_MANAGER = gql`
  mutation makeAndMapTransferManager(
    $provider: source_provider_enum!
    $provider_transfer_manager_id: String!
    $manager_id: Int!
    $team_from_id: Int!
    $team_to_id: Int!
    $transfer_currency: String
    $transfer_value: float8
    $transfer_type: String!
    $transfer_date: timestamp
  ) {
    insert_mapping_transfer_manager(
      objects: {
        statsbomb_transfer_manager: {
          data: {
            manager_id: $manager_id
            team_from_id: $team_from_id
            team_to_id: $team_to_id
            transfer_currency: $transfer_currency
            transfer_value: $transfer_value
            transfer_type: $transfer_type
            transfer_date: $transfer_date
          }
        }
        provider: $provider
        provider_transfer_manager_id: $provider_transfer_manager_id
      }
    ) {
      returning {
        provider_transfer_manager_id
        provider
        transfer_manager_id
      }
    }
  }
`;

const VIEW_TRANSFER_MANAGER_MAPPINGS = gql`
  subscription viewTransferManagerMappings($id: Int!) {
    mapping_transfer_manager(where: { transfer_manager_id: { _eq: $id } }) {
      source_transfer_manager {
        provider_transfer_manager_id
        provider
        manager_id
        team_to_id
        team_from_id
        transfer_date
        transfer_type
        transfer_currency
        transfer_value
      }
    }
  }
`;

const DELETE_TRANSFER_MANAGER_MAPPING = gql`
  mutation deleteTransferManagerMappings(
    $claim_data: claim_transfer_manager_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_transfer_manager_one(
      object: $claim_data
      on_conflict: { constraint: transfer_manager_pkey, update_columns: [] }
    ) {
      provider
      provider_transfer_manager_id
    }

    delete_mapping_transfer_manager_by_pk(provider: $provider, provider_transfer_manager_id: $provider_id) {
      transfer_manager_id
      provider_transfer_manager_id
      provider
    }
  }
`;

export {
  MAP_TRANSFER_MANAGER,
  MAP_TO_TRANSFER_MANAGER,
  MAKE_AND_MAP_TRANSFER_MANAGER,
  VIEW_TRANSFER_MANAGER_MAPPINGS,
  DELETE_TRANSFER_MANAGER_MAPPING,
};
