import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING } from '../../hasura/queries/competitionSeason/competitionSeasons';
import { INSERT_COMPETITION_SOURCE_AND_MAPPING } from '../../hasura/queries/competition/competitions';
import { INSERT_SOURCE_AREA_AND_MAPPING } from '../../hasura/queries/area/areas';
import CompetitionSeasonSelector from './competitionSeasonSelector';

const CompetitionSeasonOverride = ({ overrideChange, value, resetChildState, provider }) => {
  const [insertAreaSourceAndMapping] = useMutation(INSERT_SOURCE_AREA_AND_MAPPING, {
    onError: () => {},
    onCompleted: () => {},
  });
  const [insertCompetitionSourceAndMapping] = useMutation(INSERT_COMPETITION_SOURCE_AND_MAPPING, {
    onError: () => {},
    onCompleted: () => {},
  });
  const [insertCompetitionSeasonSourceAndMapping] = useMutation(INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING, {
    onError: () => {},
    onCompleted: () => {},
  });

  const handleOnChange = async compSzn => {
    const { area_id, area_name, area_code } = compSzn.statsbomb_competition.statsbomb_area || {};
    await insertAreaSourceAndMapping({
      variables: {
        area_id: area_id,
        area_name,
        area_code,
        provider,
        provider_area_id: area_id?.toString(),
      },
    });
    await insertCompetitionSourceAndMapping({
      variables: {
        provider,
        provider_competition_id: compSzn.competition_id.toString(),
        competition_id: compSzn.competition_id,
        competition_name: compSzn.statsbomb_competition.competition_name,
      },
    });

    await insertCompetitionSourceAndMapping({
      variables: {
        provider,
        provider_competition_id: compSzn.competition_id.toString(),
        competition_id: compSzn.competition_id,
        competition_name: compSzn.statsbomb_competition.competition_name,
      },
    });

    await insertCompetitionSeasonSourceAndMapping({
      variables: {
        provider,
        provider_competition_season_id: compSzn.competition_season_id.toString(),
        competition_season_id: compSzn.competition_season_id,
        competition_season_name: compSzn.competition_season_name,
        competition_id: compSzn.competition_id.toString(),
        season_id: compSzn.season_id,
      },
    });
    if (resetChildState) resetChildState();
    overrideChange(compSzn);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <CompetitionSeasonSelector onChange={handleOnChange} {...{ value }} />
    </div>
  );
};

export { CompetitionSeasonOverride };
