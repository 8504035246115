import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const EnumOverride = ({ overrideChange, currentValue, columnKey, overrideProps }) => {
  const { query, statsbombPath, path, label } = overrideProps;
  const { loading, error, data } = useQuery(query, {});

  if (loading) return <InlineProgress />;

  const entities = data[statsbombPath] || [];

  return (
    <>
      <Error message={`Error fetching ${label} data`} error={error} />

      <InputLabel id={`${path}-input`}>{label}</InputLabel>
      <Select
        onChange={e => overrideChange(columnKey, e.target.value)}
        value={currentValue || entities[0][path]}
        labelId={`${path}-input`}>
        {entities.map(entity => (
          <MenuItem key={entity[path]} value={entity[path]}>
            {entity.display_name || entity[path]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default EnumOverride;
