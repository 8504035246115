import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useSubscription } from '@apollo/react-hooks';
import { InlineProgress } from '../util/progress';
import { getDependencyTree } from '../../components/util/tree';
import { DeleteDialog } from '../util/DeleteDialog';
import { capitalise } from '../../util/string';
import { CellContent } from '../table/table';
import { GET_LINEUP_BY_PLAYER, GET_LINEUP_BY_MATCH, GET_LINEUP_BY_TEAM } from '../../hasura/queries/lineup/lineups';

const useStyles = makeStyles(theme => ({
  sizeSmall: { padding: 5 },
  checkbox: { padding: 0 },
  table: {
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  clearButton: {
    '&:hover': {
      color: '#990000',
    },
    fontSize: '30px',
    color: '#DA0000',
    height: 30,
    padding: 5,
    marginLeft: 7,
  },
  button: { margin: theme.spacing(), float: 'right' },
}));

const LineupCustomDeletion = ({ records, deleteEntityMutation, parentType, parentId }) => {
  const classes = useStyles();
  const [dependencies, setDependencies] = useState({});
  const [open, setOpen] = useState(false);
  const [variables, setVariables] = useState({});

  const querySchema = {
    player: GET_LINEUP_BY_PLAYER,
    team: GET_LINEUP_BY_TEAM,
    match: GET_LINEUP_BY_MATCH,
  };

  const schema = ['player_id', 'match_id', 'team_id'];

  const { loading, data } = useSubscription(querySchema[parentType], {
    variables: {
      [`${parentType}_id`]: parentId,
    },
  });

  if (loading) return <InlineProgress />;
  const lineupData = data?.['statsbomb_golden_lineup'];

  const getDependencies = async (entityType, item, index) => {
    setDependencies(await getDependencyTree(`statsbomb.${entityType}`, item[`${entityType}_id`]));
    setOpen(true);
    setVariables({ match_id: item['match_id'], player_id: item['player_id'], team_id: item['team_id'] });
  };

  return (
    <>
      <h1>Delete Lineup</h1>
      <h3>
        Listed down {lineupData?.length} Lineups related to {parentType} with {parentType} id : {parentId}
      </h3>
      <DeleteDialog
        entityIsLineup
        // onDelete={removeLineup}
        modal={open}
        handleClose={() => setOpen(false)}
        dependencyStructure={dependencies}
        type={'lineup'}
        {...{ open, deleteEntityMutation, variables }}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" component={Paper}>
          <TableHead>
            <TableRow>
              {schema.map(item => (
                <TableCell style={{ fontSize: '12px', padding: 10, fontWeight: 'bold' }} key={item}>
                  {capitalise(item)}
                </TableCell>
              ))}
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineupData?.map((item, index) => (
              <TableRow key={index}>
                {schema.map(item2 => (
                  <TableCell
                    style={{ fontSize: '10px', padding: 10 }}
                    onClick={() =>
                      window.open(
                        `/lineup?match_id=${item.match_id}&team_id=${item.team_id}&player_id=${item.player_id}`
                      ).focus
                    }>
                    <CellContent
                      entity={item}
                      columnKey={item2}
                      icon={item2.icon}
                      mappedIcon={item2.mapped_icon}
                      mappingFn={item2.mapping_function}>
                      {' '}
                      {item[item2]}
                    </CellContent>
                  </TableCell>
                ))}
                <TableCell>
                  <DeleteIcon
                    className={classes.clearButton}
                    style={{ cursor: 'pointer' }}
                    onClick={() => getDependencies('lineup', item, index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LineupCustomDeletion;
