import React from 'react';
import DeletionComponent from '../components/deletion/deletionComponent';
import LineupCustomDeletion from '../components/deletion/lineupCustomDeletion';
import { SECTION_CONFIG } from '../util/consts';

const DeletionPageView = ({ location }) => {
  const { entityType, parentId, parentType, records, variables } = location.state;
  const isTypeLineup = entityType === 'lineup';

  const { schema, deleteEntityMutation } = SECTION_CONFIG.find(c => c.type === entityType);
  const fetchById = !isTypeLineup && SECTION_CONFIG.find(c => c.type === entityType).fetchById;
  return isTypeLineup ? (
    <LineupCustomDeletion {...{ records, deleteEntityMutation, schema, parentType, parentId }} />
  ) : (
    <DeletionComponent
      {...{ entityType, records, fetchById, deleteEntityMutation, variables, schema, parentType, parentId }}
    />
  );
};

export default DeletionPageView;
