import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { capitalise } from '../../util/string';

const useStyles = makeStyles(theme => ({
  sizeSmall: { padding: 12 },
  checkbox: { padding: 0 },
  table: {
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
  },
  button: { margin: theme.spacing(), float: 'right' },
}));

const DeleteDialog = ({
  open,
  handleClose,
  variables,
  deleteEntityMutation,
  entityIsFullLineup,
  entityIsLineup,
  onDelete,
  dependencyStructure,
  entityId,
  type,
  refetchData,
  refetchCount,
}) => {
  const classes = useStyles();

  const [deleteEntity] = useMutation(deleteEntityMutation, {
    onError: err => {},
    onCompleted: () => {
      if (entityIsLineup) return onDelete();
    },
  });
  const handleAccept = async () => {
    await deleteEntity({
      variables,
    });
    refetchData();
    refetchCount();
    handleClose();
  };
  const checkEntityDependencies = _.isEmpty(dependencyStructure);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      {checkEntityDependencies ? (
        <>
          <DialogTitle id="alert-dialog-slide-title"> Delete this record ?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <span style={{ fontWeight: 'bold' }}>Warning!</span> this action can not be undone{' '}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleAccept} className={classes.clearButton}>
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-slide-title"> You must delete the following entities first</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              listed down the entities that should be deleted
            </DialogContentText>
            {Object.keys(dependencyStructure).map((item, index) => (
              <div
                key={`dependency_${index}`}
                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>{capitalise(item)}</span>
                <Link
                  style={{ fontSize: 16 }}
                  onClick={handleClose}
                  to={{
                    pathname: `/delete`,
                    state: {
                      records: dependencyStructure[`${item}`],
                      entityType: item,
                      parentType: type,
                      parentId: entityId,
                    },
                  }}>
                  Go to {capitalise(item)}
                </Link>
              </div>
            ))}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export { DeleteDialog };
