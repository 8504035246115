import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableContainer, TableHead, TableCell, TableRow, Paper, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { getXMinutesAgo, createDateFromUTC, DateValue } from '../../util/date';

import {
  scrapePlayerFromSource,
  scrapeManagerFromSource,
  scrapeOfficialFromSource,
} from '../../integration/trawlerClient';
import { Progress } from '../../components/util/progress';

import { capitalise } from '../../util/string';
import { ADD_SCRAPE_REQUEST, SCHEDULED_REQUESTS, DELETE_SCRAPE_REQUEST } from '../../hasura/queries/scheduler';
import { CLAIM_MATCH } from '../../hasura/queries/match/matchClaims';
import ScrapeEntity from './scrapeEntity';

const styles = {
  root: {
    marginRight: 10,
    marginBottom: 30,
  },
  scrapeMessage: {
    textAlign: 'center',
  },
  scrapeMessageHeading: {
    fontSize: 20,
    fontWeight: 700,
  },
  scrapeMessagePara: {
    fontSize: 16,
  },
};

function EntityScraping({ classes }) {
  const [scrapePlayerId, setScrapePlayerId] = useState('');
  const [scrapeManagerId, setScrapeManagerId] = useState('');
  const [scrapeOfficialId, setScrapeOfficialId] = useState('');
  const [scrapeMatchId, setScrapeMatchId] = useState('');
  const [scrapeRoundId, setScrapeRoundId] = useState('');

  const emptyScrapeMessage = { message: '', info: '' };
  const [scrapeMessage, setScrapeMessage] = useState(emptyScrapeMessage);

  const setTemporaryScrapeMessage = (message, info = '', time = 5000) => {
    setScrapeMessage({ message, info });
    setTimeout(function () {
      setScrapeMessage(emptyScrapeMessage);
    }, time);
  };

  const [checkMatchHasRound, { data: checkMatchData }] = useLazyQuery(CLAIM_MATCH, {
    onCompleted: () => {
      const claimData = checkMatchData.claim_match;
      const approved = claimData[0]?.approved_at != null;

      if (!approved)
        setTemporaryScrapeMessage('This match claim has not been approved, please approve claim for match.');
      else {
        const provider_match_id = claimData[0].provider_match_id;
        requestMatchScrape({
          variables: {
            data: {
              provider: { id: 2, name: 'Soccerway' },
              params: { match_id: provider_match_id },
            },
            type: 'match',
          },
        });
      }
    },
  });

  const scrapePlayer = () => {
    setTemporaryScrapeMessage('Requesting player scrape with ID: ', scrapePlayerId);

    scrapePlayerFromSource(scrapePlayerId)
      .then(() => setTemporaryScrapeMessage('Player Scrape Completed'))
      .catch(error => setTemporaryScrapeMessage('Player Scrape Failed: ', error.toString()));

    setScrapePlayerId('');
  };

  const scrapeManager = () => {
    setTemporaryScrapeMessage('Requesting manager scrape with ID: ', scrapeManagerId);

    scrapeManagerFromSource(scrapeManagerId)
      .then(() => setTemporaryScrapeMessage('Manager Scrape Completed'))
      .catch(error => setTemporaryScrapeMessage('Manager Scrape Failed: ', error.toString()));

    setScrapeManagerId('');
  };

  const scrapeOfficial = () => {
    setTemporaryScrapeMessage('Requesting official scrape with ID: ', scrapeOfficialId);

    scrapeOfficialFromSource(scrapeOfficialId)
      .then(() => setTemporaryScrapeMessage('Official Scrape Completed'))
      .catch(error => setTemporaryScrapeMessage('Official Scrape Failed: ', error.toString()));

    setScrapeOfficialId('');
  };

  const [requestMatchScrape] = useMutation(ADD_SCRAPE_REQUEST, {
    onError: error => {
      setTemporaryScrapeMessage('Scrape Request Failed: ', error.toString());
    },
    onCompleted: () => {
      setTemporaryScrapeMessage('Scrape Request Complete');
      setScrapeMatchId('');
      setScrapeRoundId('');
    },
  });

  const [deleteMatchScrapeRequest] = useMutation(DELETE_SCRAPE_REQUEST, {
    onError: error => {
      setTemporaryScrapeMessage('Deletion Failed: ', error.toString());
    },
    onCompleted: () => {
      setTemporaryScrapeMessage('Deletion Complete');
    },
  });

  const scrapeMatchClicked = () => {
    setTemporaryScrapeMessage('Match Scrape Requested');
    checkMatchHasRound({
      variables: {
        provider_match_id: scrapeMatchId,
      },
    });
  };

  const scrapeRoundClicked = () => {
    setTemporaryScrapeMessage('Matches in Round Scrape Requested');
    requestMatchScrape({
      variables: {
        data: {
          provider: { id: 2, name: 'Soccerway' },
          params: { round_id: scrapeRoundId },
        },
        type: 'round_matches',
      },
    });
  };

  const deleteClicked = id => {
    setTemporaryScrapeMessage('Deletion Requested');
    deleteMatchScrapeRequest({
      variables: {
        id: id,
      },
    });
  };

  const scrapePlayerChanged = ({ target: { value: playerId } = {} }) => setScrapePlayerId(playerId);
  const scrapeManagerChanged = ({ target: { value: managerId } = {} }) => setScrapeManagerId(managerId);
  const scrapeOfficialChanged = ({ target: { value: officialId } = {} }) => setScrapeOfficialId(officialId);
  const scrapeMatchChanged = ({ target: { value: matchId } = {} }) => setScrapeMatchId(matchId);
  const scrapeRoundChanged = ({ target: { value: roundId } = {} }) => setScrapeRoundId(roundId);

  const showDelete = (status, started) =>
    status === 'failed' || (status === 'running' && createDateFromUTC(started) < new Date(getXMinutesAgo(5)));

  const actionButton = request =>
    showDelete(request.status, request.started_at) && <DeleteIcon onClick={() => deleteClicked(request.id)} />;

  const formatDate = date => (date ? <DateValue>{date}</DateValue> : <></>);

  const scheduledRequestRow = requests => {
    return requests.map(request => (
      <TableRow key={request.id}>
        <TableCell>{request.id}</TableCell>
        <TableCell>{capitalise(request.request_type)}</TableCell>
        <TableCell>{capitalise(request.status)}</TableCell>
        <TableCell>{request.data.params.match_id || request.data.params.round_id}</TableCell>
        <TableCell>{formatDate(request.started_at)}</TableCell>
        <TableCell>{actionButton(request)}</TableCell>
      </TableRow>
    ));
  };

  const { loading, data } = useSubscription(SCHEDULED_REQUESTS);
  if (loading) return <Progress />;
  const scheduledRequests = data.scheduler_requests ? scheduledRequestRow(data.scheduler_requests) : <></>;

  return (
    <>
      <h2>Scrape Entities</h2>
      <Grid justify="flex-start" container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={4}>
          <ScrapeEntity
            textFieldProps={{
              placeholder: 'Soccerway Player ID',
              onChange: scrapePlayerChanged,
              value: scrapePlayerId,
              description: `To scrape a player, go to Soccerway and use the ID provided in the URL. For example, Cristiano Ronaldo's ID is 382.`,
            }}
            buttonProps={{ onClick: scrapePlayer, text: 'Player' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ScrapeEntity
            textFieldProps={{
              placeholder: 'Soccerway Manager ID',
              onChange: scrapeManagerChanged,
              value: scrapeManagerId,
              description: `To scrape a manager, go to Soccerway and use the ID provided in the URL. For example, Brendan Rodger's ID is 154853.`,
            }}
            buttonProps={{ onClick: scrapeManager, text: 'Manager' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ScrapeEntity
            textFieldProps={{
              placeholder: 'Soccerway Official ID',
              onChange: scrapeOfficialChanged,
              value: scrapeOfficialId,
              description: `To scrape an official, go to Soccerway and use the ID provided in the URL. For example, Craig Pawson's ID is 60832.`,
            }}
            buttonProps={{ onClick: scrapeOfficial, text: 'Official' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ScrapeEntity
            textFieldProps={{
              placeholder: 'Soccerway Round ID',
              onChange: scrapeRoundChanged,
              value: scrapeRoundId,
              description: `To scrape matches in a round, go to Soccerway and get the ID from the match page. For example, the English Championship (2020/2021) league round ID is r59440, you need to use the ID without the letter 'r'.`,
            }}
            buttonProps={{
              onClick: scrapeRoundClicked,
              text: 'Matches in Round',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ScrapeEntity
            textFieldProps={{
              placeholder: 'Soccerway Match ID',
              onChange: scrapeMatchChanged,
              value: scrapeMatchId,
              description: `To scrape a match, go to Soccerway and use the ID provided in the URL. For example, Leicester City vs Chelsea's (19/01/2021) ID is 3344306. You cannot scrape a match without a round being provided and claim being approved.`,
            }}
            buttonProps={{ onClick: scrapeMatchClicked, text: 'Match' }}
          />
        </Grid>
      </Grid>
      <div className={classes.scrapeMessage}>
        <h4 className={classes.scrapeMessageHeading}>{scrapeMessage.message}</h4>
        <p className={classes.scrapeMessagePara}>{scrapeMessage.info}</p>
      </div>
      {data.scheduler_requests && data.scheduler_requests.length > 0 ? (
        <>
          <h3>Scheduled Requests</h3>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Scheduled Requests">
              <TableHead>
                <TableRow>
                  <TableCell>Request ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Entity ID</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{scheduledRequests}</TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default withStyles(styles)(EntityScraping);
