import React from 'react';
import TextField from '@material-ui/core/TextField';

const DOBOverride = ({ overrideChange, currentValue, columnKey }) => {
  const selectedDate = currentValue
    ? new Date(currentValue)
    : new Date(new Date().setFullYear(new Date().getFullYear() - 18));

  return (
    <TextField
      type="date"
      label="Date of Birth"
      defaultValue={selectedDate.toISOString().split('T')[0]}
      onChange={e => e.target.value && overrideChange(columnKey, new Date(e.target.value))}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export { DOBOverride };
