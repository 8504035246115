import React from 'react';
import { Select, MenuItem, InputLabel, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { SEASONS } from '../../hasura/queries/season/seasons';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const useStyle = makeStyles(theme => ({
  subtle: { color: theme.palette.secondary.dark },
  block: { display: 'block' },
}));

const SeasonOverride = ({ overrideChange, currentValue, columnKey, provider }) => {
  return <SeasonSelector {...{ overrideChange, currentValue, columnKey, provider }} />;
};

const SeasonSelector = ({ overrideChange, currentValue, columnKey, provider, ...props }) => {
  const classes = useStyle();
  const { loading, error, data } = useQuery(SEASONS, {});

  if (loading) return <InlineProgress />;
  const seasonOverrideSelected = async seasonId => {
    overrideChange(
      columnKey,
      seasonId,
      seasons.find(season => season.season_id === seasonId)
    );
  };

  const seasons = data?.statsbomb_season || [];

  return (
    <>
      <Error message="Error fetching Seasons" error={error} />

      <InputLabel id="season-input">Season</InputLabel>
      <Select
        error={!currentValue}
        onChange={event => seasonOverrideSelected(event.target.value)}
        value={currentValue ? currentValue : ''}
        labelId="season-input"
        {...props}>
        {seasons.map((season, key) => (
          <MenuItem key={key} value={season.season_id} className={classes.block}>
            <span>{season.season_name}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { SeasonOverride, SeasonSelector };
