import gql from 'graphql-tag';

const GOLDEN_STADIUMS = gql`
  query getGoldenStadiums(
    $name: String
    $area: String
    $min_iq: Boolean
    $duplicate_count: bigint = 0
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_stadium_order_by!
  ) {
    statsbomb_golden_stadium(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            area_name: { _ilike: $area }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      stadium_id
      deleted
      stadium_name
      area_name
      area_code
      stadium_city
      stadium_capacity
      min_spec
    }
  }
`;

const GOLDEN_STADIUMS_COUNT = gql`
  query getGoldenStadiumsCount(
    $name: String
    $area: String
    $duplicate_count: bigint = 0
    $min_iq: Boolean
    $deleted: Boolean = false
  ) {
    statsbomb_golden_stadium_aggregate(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            area_name: { _ilike: $area }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const SIMILAR_STADIUMS = gql`
  query similarStadiums($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_stadiums(
      args: { n: $name, p: $provider, o: $offset }
      order_by: { name_similarity: desc_nulls_last }
    ) {
      stadium_id
      stadium_name
      name_similarity
      mapped
      area_id
    }
  }
`;

const STADIUMS = gql`
  query getAllStadiums {
    statsbomb_stadium(order_by: { stadium_name: asc }) {
      stadium_name
      stadium_id
      statsbomb_area {
        area_name
        area_code
      }
    }
  }
`;

const INSERT_SOURCE_STADIUM = gql`
  mutation insertSourceStadium($stadium_name: String!, $provider_stadium_id: String!) {
    insert_source_stadium(
      objects: {
        stadium_name: $stadium_name
        last_updated: "now()"
        provider: Info_Team
        provider_stadium_id: $provider_stadium_id
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_STADIUM = gql`
  mutation insertMapStadium($stadium_id: Int!, $provider: source_provider_enum!, $provider_stadium_id: String!) {
    insert_mapping_stadium_one(
      object: { stadium_id: $stadium_id, provider: $provider, provider_stadium_id: $provider_stadium_id }
    ) {
      stadium_id
      provider
      provider_stadium_id
    }
  }
`;
const DELETE_STADIUM = gql`
  mutation DeleteStadiums($stadium_id: Int!) {
    update_statsbomb_stadium_by_pk(pk_columns: { stadium_id: $stadium_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;
const GET_STADIUMS_BY_ID = gql`
  query getStadiumsByIds($entityIds: [Int!]) {
    statsbomb_golden_stadium(where: { stadium_id: { _in: $entityIds } }) {
      stadium_id
      deleted
      stadium_name
      area_name
      area_code
      stadium_city
      stadium_capacity
    }
  }
`;
const DELETE_STADIUM_BY_ID = gql`
  mutation deleteStadium($stadium_id: Int!) {
    delete_claim_stadium(where: { mapping_stadium: { stadium_id: { _eq: $stadium_id } } }) {
      affected_rows
    }

    delete_mapping_stadium(where: { stadium_id: { _eq: $stadium_id } }) {
      affected_rows
    }
    delete_statsbomb_stadium(where: { stadium_id: { _eq: $stadium_id } }) {
      affected_rows
    }
  }
`;
export {
  STADIUMS,
  INSERT_SOURCE_STADIUM,
  MAP_STADIUM,
  GOLDEN_STADIUMS,
  GOLDEN_STADIUMS_COUNT,
  SIMILAR_STADIUMS,
  DELETE_STADIUM,
  GET_STADIUMS_BY_ID,
  DELETE_STADIUM_BY_ID,
};
