import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import {
  TRANSFER_MANAGER_SCHEMA,
  TRANSFER_MANAGER_CLAIM_SCHEMA,
  MAPPED_TRANSFER_MANAGER_SCHEMA,
} from '../schema/transferManagerSchema';
import { ClaimFilters } from '../components/claim/claimFilters';
import ClaimHistoryTable from '../components/table/claimHistoryTable';
import { Progress } from '../components/util/progress';
import {
  TRANSFER_MANAGERS_AND_CLAIMS,
  SAVE_TRANSFER_MANAGER_CLAIM,
  OVERRIDE_TRANSFER_MANAGER_CLAIM,
  INSERT_GOLDEN_TRANSFER_MANAGER,
} from '../hasura/queries/transferManager/transferManagerClaims';
import { Error } from '../components/util/notifications';
import { blankSchema } from '../util/schema';
import MappingView from '../components/mapping/mappingView';
import {
  DELETE_TRANSFER_MANAGER_MAPPING,
  VIEW_TRANSFER_MANAGER_MAPPINGS,
} from '../hasura/queries/transferManager/transferManagerMapping';
import { DELETE_TRANSFER_MANAGER } from '../hasura/queries/transferManager/transferManagers';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenTransferManagerView = ({ transferManagerId, isNewTransferManager, history }) => {
  const historyPath = history.location.search;

  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewTransferManager ? blankSchema(TRANSFER_MANAGER_SCHEMA) : {});
  const [overrides, setOverrides] = useState({});
  const [claimMode, setClaimMode] = useState(isNewTransferManager ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);

  const setNewEntityMode = () => {
    setClaimMode(CLAIM_MODE_NEW_ENTITY);
    setClaimStatuses(blankSchema(TRANSFER_MANAGER_SCHEMA));
  };

  const setViewMode = () => {
    setClaimMode(CLAIM_MODE_VIEW);
    setClaimStatuses({});
  };

  useEffect(() => (historyPath === '?new=new' ? setNewEntityMode() : setViewMode()), [historyPath]);

  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loading, error, data, refetch } = useQuery(TRANSFER_MANAGERS_AND_CLAIMS, {
    variables: {
      transfer_manager_id: transferManagerId,
    },
  });

  if (loading) return <Progress />;
  let allClaims = [];

  data &&
    data.statsbomb_transfer_manager &&
    data.statsbomb_transfer_manager[0] &&
    data.statsbomb_transfer_manager[0].mapping_transfer_managers.forEach(mappingEntry => {
      allClaims = [...allClaims, ...mappingEntry.claim_transfer_managers];
    });

  let filteredClaims = allClaims;
  if (claimFilter === 'undecided') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }

  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_transfer_manager_uuid === claimUUID;
    })[0];
    if (claim) {
      TRANSFER_MANAGER_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    TRANSFER_MANAGER_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(blankSchema(TRANSFER_MANAGER_SCHEMA));
  };

  const getGoldenTransferManagerId = insertedTransfer =>
    insertedTransfer?.data?.insert_statsbomb_transfer_manager?.returning?.[0]?.transfer_manager_id;

  const claimSelected = claim => {
    selectAClaim(claim.claim_transfer_manager_uuid);
  };

  return (
    <>
      <Error message="" error={error} />
      <div className="container-row">
        <h2>Transfer Singular: {isNewTransferManager ? ' New Transfer' : transferManagerId}</h2>

        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="transfer_manager"
              schema={TRANSFER_MANAGER_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{
                headerValue: 'Transfer Name',
                bodyValue: `${data.statsbomb_transfer_manager[0]?.statsbomb_manager.manager_name}`,
              }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <MappingView
            type="transfer_manager"
            recordId={transferManagerId}
            schema={MAPPED_TRANSFER_MANAGER_SCHEMA}
            subscription={VIEW_TRANSFER_MANAGER_MAPPINGS}
            deleteMutation={DELETE_TRANSFER_MANAGER_MAPPING}
            deleteEntityMutation={DELETE_TRANSFER_MANAGER}
            entityKey="provider_team_id"
            styles={{ tableContatinerClasses, buttonClasses }}
          />
        )}
        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Transfer Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={data.statsbomb_transfer_manager[0]}
            claim={
              allClaims.filter(c => {
                return c.claim_transfer_manager_uuid === selectedClaimId;
              })[0]
            }
            rowSchema={TRANSFER_MANAGER_SCHEMA}
            saveMutation={SAVE_TRANSFER_MANAGER_CLAIM}
            overrideMutation={OVERRIDE_TRANSFER_MANAGER_CLAIM}
            createMutation={INSERT_GOLDEN_TRANSFER_MANAGER}
            sourcePK="provider_transfer_manager_id"
            claimPK="claim_transfer_manager_uuid"
            goldenEntityPK="transfer_manager_id"
            getGoldenEntityId={getGoldenTransferManagerId}
            entityType="transfer_manager"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
            {...{
              claimStatuses,
              setClaimStatuses,
              selectAClaim,
              overrides,
              setOverrides,
              claimMode,
              refetch,
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

const TransferManager = ({ history }) => {
  const query = new URLSearchParams(useLocation().search);
  const isNewTransferManager = query.get('new');
  const transferManagerId = isNewTransferManager ? '0' : query.get('transfer_manager_id');

  return (
    <GoldenTransferManagerView isNewTransferManager={!!isNewTransferManager} {...{ transferManagerId, history }} />
  );
};

export default TransferManager;
