import { nestedValues } from '../util/useful';
import { TextOverride } from '../components/override/text';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { PlayerLineupTypeOverride } from '../components/override/lineupTypes';

const FULL_LINEUP_SCHEMA = [
  { columnKey: 'claim_lineup_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'match_id', name: 'Match ID', type: 'Integer', OverrideControl: TextOverride },
  {
    columnKey: 'team_id',
    name: 'Team',
    type: 'Integer',
    OverrideControl: TextOverride,
    mapping_function: nestedValues('team_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_team.team_name'),
  },
  {
    columnKey: 'player_id',
    name: 'Player',
    type: 'Integer',
    OverrideControl: TextOverride,
    mapping_function: nestedValues('player_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_player.player_name'),
  },
  { columnKey: 'provider', name: 'Provider', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'provider_team_id', name: 'Provider Team Id', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'provider_match_id', name: 'Provider Match Id', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'provider_player_id', name: 'Provider Player Id', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'lineup_jersey', name: 'Shirt #', type: 'String', OverrideControl: TextOverride },
  {
    columnKey: 'player_lineup_selection_type',
    name: 'Selection Type',
    type: 'String',
    OverrideControl: PlayerLineupTypeOverride,
    initialOverrideValue: 'starting_xi',
  },
  {
    columnKey: 'selection_type_display_name',
    name: 'Selection Type',
    type: 'String',
  },
  {
    columnKey: 'deleted',
    name: 'Deleted',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const GOLDEN_LINEUP_SCHEMA = FULL_LINEUP_SCHEMA.filter(field =>
  [
    'team_id',
    'team_name',
    'match_id',
    'match_date',
    'player_id',
    'player_name',
    'lineup_jersey',
    'selection_type_display_name',
  ].includes(field.columnKey)
);

const LINEUP_SCHEMA = FULL_LINEUP_SCHEMA.filter(a =>
  ['team_id', 'match_id', 'player_id', 'lineup_jersey', 'player_lineup_selection_type', 'deleted'].includes(a.columnKey)
);

const LINEUP_CLAIM_SCHEMA = ['team_id', 'match_id', 'player_id', 'lineup_jersey', 'player_lineup_selection_type'];

const LINEUP_ALL_CLAIMS_SCHEMA = FULL_LINEUP_SCHEMA.filter(a =>
  [
    'claim_lineup_uuid',
    'provider_team_id',
    'provider_match_id',
    'provider_player_id',
    'lineup_jersey',
    'player_lineup_selection_type',
    'provider',
    'provider_lineup_id',
    'created_at',
  ].includes(a.columnKey)
);

export { FULL_LINEUP_SCHEMA, GOLDEN_LINEUP_SCHEMA, LINEUP_SCHEMA, LINEUP_CLAIM_SCHEMA, LINEUP_ALL_CLAIMS_SCHEMA };
