import gql from 'graphql-tag';

const TRANSFER_TYPE = gql`
  query getAllTransferTypes {
    statsbomb_transfer_type {
      transfer_type
      display_name
    }
  }
`;

export { TRANSFER_TYPE };
