import React from 'react';
import { TextOverride } from '../components/override/text';
import { TextFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { FlagIcon } from '../components/util/icon';

const FULL_AREA_SCHEMA = [
  { columnKey: 'claim_area_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_area_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'area_name', name: 'Name', type: 'String', OverrideControl: TextOverride },
  {
    columnKey: 'area_code',
    name: 'Code',
    type: 'String',
    OverrideControl: TextOverride,
    icon: e => <FlagIcon code={e.area_code} />,
    statsbomb_icon: e => <FlagIcon code={e.area_code} />,
  },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
  { columnKey: 'deleted', name: 'Delete?', type: 'Boolean' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const AREA_SCHEMA = FULL_AREA_SCHEMA.filter(a => ['area_name', 'area_code', 'deleted'].includes(a.columnKey));

const AREA_CLAIM_SCHEMA = ['area_name', 'area_code'];

const AREA_ALL_CLAIMS_SCHEMA = FULL_AREA_SCHEMA.filter(a =>
  ['claim_area_uuid', 'area_name', 'area_code', 'provider_area_id', 'provider', 'created_at'].includes(a.columnKey)
);

const AREA_CLAIM_TO_MAP_SCHEMA = FULL_AREA_SCHEMA.filter(a =>
  [
    'area_name',
    'area_code',
    'claim_area_uuid',
    'created_at',
    'provider',
    'provider_area_id',
    'approved_at',
    'rejected_at',
  ].includes(a.columnKey)
);

const GOLDEN_AREA_SCHEMA = FULL_AREA_SCHEMA.filter(a => ['area_name', 'area_code', 'min_spec'].includes(a.columnKey));

const MAPPED_AREA_SCHEMA = FULL_AREA_SCHEMA.filter(a =>
  ['area_name', 'area_code', 'provider', 'provider_area_id'].includes(a.columnKey)
);

const AREA_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
];

const AREA_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
  },
];
const AREA_MIN_IQ_SCHEMA = FULL_AREA_SCHEMA.filter(a => ['area_name', 'area_code'].includes(a.columnKey));

export {
  AREA_SCHEMA,
  GOLDEN_AREA_SCHEMA,
  AREA_CLAIM_SCHEMA,
  AREA_ALL_CLAIMS_SCHEMA,
  AREA_CLAIM_TO_MAP_SCHEMA,
  MAPPED_AREA_SCHEMA,
  AREA_FILTER_SCHEMA,
  AREA_MIN_IQ_SCHEMA,
  AREA_CLAIM_FILTER_SCHEMA,
};
