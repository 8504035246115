import { Button, Grid, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dateFormat } from '../override/dateTime';

const TransferHistoryModal = ({
  classes,
  entity,
  type,
  editing,
  setEditing,
  selectedTransferId,
  transferDetails: initialTransferDetails,
  resetTransferDetails,
  modalOpen,
  setModalOpen,
  modalSchema,
  createGoldenRecord,
  insertSourceAndMap,
  insertSourceAndMapEntity,
  setErrorMessage,
}) => {
  const [modalTransferDetails, setModalTransferDetails] = useState(initialTransferDetails);

  useEffect(() => setModalTransferDetails({ ...initialTransferDetails }), [initialTransferDetails]);

  const hasTransferOnDate = (entity, date) =>
    entity[`statsbomb_transfer_${type}s`]
      .map(({ transfer_date }) => dateFormat(transfer_date))
      .includes(dateFormat(date));

  const createTransferRecord = async (details, type) => {
    const { team_from_id, team_to_id, transfer_currency, transfer_date, transfer_type, transfer_value } = details;

    const formComplete = team_from_id != null && team_to_id != null && transfer_date != null && transfer_type != null;

    if (!formComplete)
      return setErrorMessage('Transfers require a team from, team to, transfer date and transfer type');

    if (hasTransferOnDate(entity, transfer_date))
      return setErrorMessage(
        `This ${type} already has a transfer on this date. If they were transferred twice on the same day, please use the next day.`
      );

    const entityId = details[`${type}_id`];
    const { data } = await createGoldenRecord({
      variables: {
        statsbomb_entity: {
          [`${type}_id`]: entityId,
          team_from_id,
          team_to_id,
          transfer_currency,
          transfer_date,
          transfer_type,
          transfer_value,
        },
      },
    });

    await insertSourceAndMapEntity({
      variables: {
        [`${type}_id`]: parseInt(entityId),
        [`provider_${type}_id`]: entityId.toString(),
        source_entity: {
          [`${type}_name`]: entity[`${type}_name`],
        },
      },
    });

    const transferEntityId = data[`insert_statsbomb_transfer_${type}`].returning[0][`transfer_${type}_id`];
    await insertSourceAndMap({
      variables: {
        [`transfer_${type}_id`]: transferEntityId,
        [`provider_transfer_${type}_id`]: transferEntityId.toString(),
        source_entity: {
          provider: 'Info_Team',
          [`${type}_id`]: entityId.toString(),
          team_from_id: team_from_id.toString(),
          team_to_id: team_to_id.toString(),
          transfer_currency,
          transfer_date,
          transfer_type,
          transfer_value,
        },
      },
    });
  };

  const editTransferRecord = async (details, type) => {
    const itemsToConvertToString = [`${type}_id`, 'team_from_id', 'team_to_id'];
    const newDetails = itemsToConvertToString.reduce(
      (acc, curr) => ({ ...acc, [curr]: acc[curr].toString() }),
      details
    );

    await insertSourceAndMap({
      variables: {
        [`transfer_${type}_id`]: selectedTransferId,
        [`provider_transfer_${type}_id`]: selectedTransferId.toString(),
        source_entity: newDetails,
      },
    });
  };

  const closeModalAndReset = () => {
    setEditing(false);
    setModalOpen(false);
    resetTransferDetails();
  };

  const TransferButton = ({ editing, modalTransferDetails, type }) => {
    const editingMap = {
      true: {
        text: 'Edit Transfer',
        function: editTransferRecord,
      },
      false: {
        text: 'Create Transfer',
        function: createTransferRecord,
      },
    };

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => editingMap[editing].function(modalTransferDetails, type)}>
        {editingMap[editing].text}
      </Button>
    );
  };

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <div className={classes.modalParent}>
        <div className={classes.modal}>
          <h2 style={{ marginTop: 0 }}>{entity[`${type}_name`]}</h2>
          <p>Select the values below to create a new transfer record.</p>
          <Grid className={classes.modalGrid} container spacing={0}>
            {modalSchema.map(({ label, columnKey, Component, provider, overrideProps, currentValue }) => (
              <Grid className={classes.gridRow} container item xs={12} key={columnKey}>
                <Grid className={classes.gridItemLeft} item xs={6}>
                  {label}
                </Grid>
                <Grid className={classes.gridItemRight} item xs={6}>
                  <Component
                    overrideChange={(columnKey, value) =>
                      setModalTransferDetails({ ...modalTransferDetails, [columnKey]: value })
                    }
                    currentValue={modalTransferDetails[columnKey] || currentValue}
                    {...{ columnKey, provider, overrideProps }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
            <TransferButton {...{ editing, modalTransferDetails, type }} />
            <Button
              style={{ backgroundColor: 'red', color: 'white', marginLeft: 10 }}
              variant="contained"
              onClick={() => closeModalAndReset()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransferHistoryModal;
