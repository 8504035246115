import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_OFFICIAL_BY_PROVIDER } from '../../hasura/queries/official/officialClaims';
import { OfficialMapping } from '../../components/official/officialMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

const OfficialMapper = () => {
  const query = new URLSearchParams(useLocation().search);
  const provider_official_id = query.get('provider_official_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_OFFICIAL_BY_PROVIDER, {
    variables: {
      provider_official_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Official" error={error} />
      <OfficialMapping claims={data?.claim_official || []} provider_official_id={provider_official_id} />
    </>
  );
};

export default OfficialMapper;
