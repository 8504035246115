import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_MANAGER_BY_PROVIDER } from '../../hasura/queries/manager/managerClaims';
import { ManagerMapping } from '../../components/manager/managerMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

function ManagerMapper(props) {
  const query = new URLSearchParams(useLocation().search);
  const provider_manager_id = query.get('provider_manager_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_MANAGER_BY_PROVIDER, {
    variables: {
      provider_manager_id: provider_manager_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Manager" error={error} />
      <ManagerMapping claims={data?.claim_manager || []} provider_manager_id={provider_manager_id} />
    </>
  );
}

export default ManagerMapper;
