import React from 'react';
import { TextField } from '@material-ui/core';
import { DOBOverride } from '../components/override/dob';
import { nestedValues } from '../util/useful';
import { TextOverride } from '../components/override/text';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { FootPreferenceOverrride } from '../components/override/footPreference';
import { AreaOverride, AreaSelector } from '../components/override/area';
import { FlagIcon } from '../components/util/icon';
import { ListFilter, TextFilter, DateFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { AREAS } from '../hasura/queries/area/areas';
import { dateYearMinusEighteen } from '../components/override/dateTime';

const FULL_PLAYER_SCHEMA = [
  { columnKey: 'claim_player_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_player_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'player_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  { columnKey: 'player_name', name: 'Name', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'player_firstname', name: 'First Names', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'player_lastname', name: 'Last Names', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'player_nickname', name: 'Nickname', type: 'String', OverrideControl: TextOverride },
  {
    columnKey: 'player_country_of_birth',
    name: 'Country of Birth',
    type: 'String',
    MappingControl: AreaSelector,
    mapping_function: nestedValues('country_of_birth_mapping_area.statsbomb_area.area_name'),
    statsbomb_mapping_function: nestedValues('country_of_birth_statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('country_of_birth_mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('country_of_birth_statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'player_nationality',
    name: 'Nationality',
    type: 'String',
    mapping_function: nestedValues('nationality_mapping_area.statsbomb_area.area_name'),
    statsbomb_mapping_function: nestedValues('nationality_statsbomb_area.area_name'),
    MappingControl: AreaSelector,
    mapped_icon: e => <FlagIcon code={nestedValues('nationality_mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('nationality_statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'player_date_of_birth',
    name: 'Date of Birth',
    type: 'Date',
    OverrideControl: DOBOverride,
    initialOverrideValue: dateYearMinusEighteen(),
  },
  {
    columnKey: 'player_height',
    name: 'Height',
    type: 'Float',
    OverrideControl: function ({ overrideChange, columnKey }) {
      return <TextField type="number" onChange={({ target: { value } }) => overrideChange(columnKey, value)} />;
    },
  },
  {
    columnKey: 'player_weight',
    name: 'Weight',
    type: 'Float',
    OverrideControl: function ({ overrideChange, columnKey }) {
      return <TextField type="number" onChange={({ target: { value } }) => overrideChange(columnKey, value)} />;
    },
  },
  {
    columnKey: 'player_is_female',
    name: 'Female?',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  { columnKey: 'player_photo_url', name: 'Image URL', type: 'String', OverrideControl: TextOverride, longText: true },
  {
    columnKey: 'player_preferred_foot',
    name: 'Preferred Foot',
    type: 'String',
    OverrideControl: FootPreferenceOverrride,
    initialOverrideValue: 'Left',
  },
  {
    columnKey: 'player_is_retired',
    name: 'Is Retired?',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  {
    columnKey: 'country_of_birth_name',
    name: 'Country of Birth',
    type: 'String',
    icon: e => <FlagIcon code={e.country_of_birth_code} />,
  },
  {
    columnKey: 'nationality_name',
    name: 'Nationality',
    type: 'String',
    icon: e => <FlagIcon code={e.nationality_code} />,
  },
  {
    columnKey: 'deleted',
    name: 'Delete?',
    type: 'Boolean',
  },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
  { columnKey: 'iq_spec', name: 'Good-IQ?', type: 'Boolean' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const PLAYER_SCHEMA = FULL_PLAYER_SCHEMA.filter(a =>
  [
    'player_name',
    'player_firstname',
    'player_lastname',
    'player_nickname',
    'player_country_of_birth',
    'player_nationality',
    'player_date_of_birth',
    'player_height',
    'player_weight',
    'player_is_female',
    'player_photo_url',
    'player_preferred_foot',
    'player_is_retired',
    'deleted',
  ].includes(a.columnKey)
);

// This one is for claimsHistoryTable
const PLAYER_CLAIM_SCHEMA = [
  'player_name',
  'player_firstname',
  'player_lastname',
  'player_nickname',
  'player_country_of_birth',
  'player_nationality',
  'player_date_of_birth',
  'player_height',
  'player_weight',
  'player_is_female',
  'player_photo_url',
  'player_preferred_foot',
  'player_is_retired',
];

const PLAYER_ALL_CLAIMS_SCHEMA = FULL_PLAYER_SCHEMA.filter(a =>
  [
    'player_name',
    'player_firstname',
    'player_lastname',
    'player_nickname',
    'player_country_of_birth',
    'player_nationality',
    'player_date_of_birth',
    'player_is_retired',
    'claim_player_uuid',
    'created_at',
    'provider',
    'provider_player_id',
    'area_id',
    'player_height',
    'player_weight',
    'player_is_female',
    'player_photo_url',
    'player_preferred_foot',
    'player_is_retired',
  ].includes(a.columnKey)
);

const PLAYER_CLAIM_TO_MAP_SCHEMA = FULL_PLAYER_SCHEMA.filter(a =>
  [
    'player_name',
    'player_firstname',
    'player_lastname',
    'player_nickname',
    'player_country_of_birth',
    'player_nationality',
    'player_date_of_birth',
    'player_is_retired',
    'claim_player_uuid',
    'created_at',
    'provider',
    'area_id',
    'approved_at',
    'rejected_at',
  ].includes(a.columnKey)
);

const GOLDEN_PLAYER_SCHEMA = FULL_PLAYER_SCHEMA.filter(a =>
  [
    'player_name',
    'player_firstname',
    'player_lastname',
    'player_nickname',
    'country_of_birth_name',
    'nationality_name',
    'player_date_of_birth',
    'player_height',
    'player_weight',
    'player_is_female',
    'player_preferred_foot',
    'player_is_retired',
    'min_spec',
    'iq_spec',
  ].includes(a.columnKey)
);

const MAPPED_PLAYER_SCHEMA = FULL_PLAYER_SCHEMA.filter(a =>
  [
    'player_name',
    'player_firstname',
    'player_lastname',
    'player_nickname',
    'player_country_of_birth',
    'player_nationality',
    'player_date_of_birth',
    'player_height',
    'player_weight',
    'player_is_female',
    'player_photo_url',
    'player_preferred_foot',
    'provider',
    'provider_player_id',
    'player_is_retired',
  ].includes(a.columnKey)
);

const PLAYER_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'text',
      queryVariableName: 'name',
      displayName: 'Name',
    },
  },
  {
    type: 'area',
    Component: ListFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Nationality',
      flagIconPath: 'area_code',
      searchQuery: AREAS,
      idPath: 'area_name',
      variablePaths: ['area_name'],
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Birth Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Birth Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'goodIQ', queryVariableName: 'good_iq', displayName: 'Not Good-IQ' },
  },
  {
    type: 'duplicate',
    Component: BooleanFilter,
    attributes: { key: 'duplicateCount', queryVariableName: 'duplicate_count', displayName: 'Show Duplicates' },
  },
];

const PLAYER_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'playerName',
      queryVariableName: 'name',
      displayName: 'Name',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'nationality',
      queryVariableName: 'area',
      displayName: 'Nationality',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Birth Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Birth Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'isFemale', queryVariableName: 'is_female', displayName: 'Is Female ?' },
  },
];

const PLAYER_MIN_IQ_SCHEMA = FULL_PLAYER_SCHEMA.filter(a =>
  ['player_id', 'player_name', 'player_date_of_birth', 'player_nationality'].includes(a.columnKey)
);

export {
  PLAYER_SCHEMA,
  GOLDEN_PLAYER_SCHEMA,
  PLAYER_CLAIM_SCHEMA,
  PLAYER_CLAIM_TO_MAP_SCHEMA,
  MAPPED_PLAYER_SCHEMA,
  PLAYER_FILTER_SCHEMA,
  PLAYER_MIN_IQ_SCHEMA,
  PLAYER_ALL_CLAIMS_SCHEMA,
  PLAYER_CLAIM_FILTER_SCHEMA,
};
