import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import { COMPETITION_SEASON_SCHEMA, COMPETITION_SEASON_CLAIM_SCHEMA } from '../schema/competitionSeasonSchema';
import { ClaimFilters } from '../components/claim/claimFilters';
import { Progress } from '../components/util/progress';
import {
  GOLDEN_COMPETITION_SEASON_AND_CLAIMS,
  SAVE_COMPETITION_SEASON_CLAIM,
  OVERRIDE_COMPETITION_SEASON_CLAIM,
  INSERT_GOLDEN_COMPETITION_SEASON,
} from '../hasura/queries/competitionSeason/competitionSeasonClaims';
import { Error } from '../components/util/notifications';
import ClaimHistoryTable from '../components/table/claimHistoryTable';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenCompetitionSeasonView = ({ competitionSeasonId, isNewEntity }) => {
  const ignoreEverything = {};
  COMPETITION_SEASON_SCHEMA.forEach(row => {
    ignoreEverything[row.columnKey] = 'Ignored';
  });

  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewEntity ? ignoreEverything : {});
  const [overrides, setOverrides] = useState({});
  const [claimMode, setClaimMode] = useState(isNewEntity ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);

  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loading, error, data, refetch } = useQuery(GOLDEN_COMPETITION_SEASON_AND_CLAIMS, {
    variables: {
      competition_season_id: competitionSeasonId,
    },
  });

  if (loading) return <Progress />;

  let allClaims = [];
  data &&
    data.statsbomb_competition_season &&
    data.statsbomb_competition_season[0] &&
    data.statsbomb_competition_season[0].mapping_competition_seasons.forEach(mappingEntry => {
      allClaims = [...allClaims, ...mappingEntry.claim_competition_seasons];
    });

  let filteredClaims = allClaims;
  if (claimFilter === 'undecided') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }
  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_competition_season_uuid === claimUUID;
    })[0];
    if (claim) {
      COMPETITION_SEASON_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    COMPETITION_SEASON_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(ignoreEverything);
  };

  const getGoldenCompetitionSeasonId = insertedEntity =>
    insertedEntity?.data?.insert_statsbomb_competition_season?.returning?.[0]?.competition_season_id;

  const claimSelected = claim => {
    selectAClaim(claim.claim_competition_season_uuid);
  };

  const formatBodyIndentifierValue = () => {
    const { statsbomb_competition, statsbomb_season } = data.statsbomb_competition_season[0];

    return `${statsbomb_competition?.competition_name} ${statsbomb_season?.season_name}`;
  };

  return (
    <>
      <Error message="" error={error} />
      <div className="container-row">
        <h2>Competition Season Singular: {isNewEntity ? ' New Competition Season' : competitionSeasonId}</h2>

        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="competition_season"
              schema={COMPETITION_SEASON_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{
                headerValue: 'Competition Season Name',
                bodyValue: data.statsbomb_competition_season[0] ? formatBodyIndentifierValue() : '',
              }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}

        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Competition Season Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={data.statsbomb_competition_season[0]}
            claim={
              allClaims.filter(c => {
                return c.claim_competition_season_uuid === selectedClaimId;
              })[0]
            }
            rowSchema={COMPETITION_SEASON_SCHEMA}
            saveMutation={SAVE_COMPETITION_SEASON_CLAIM}
            overrideMutation={OVERRIDE_COMPETITION_SEASON_CLAIM}
            createMutation={INSERT_GOLDEN_COMPETITION_SEASON}
            sourcePK="provider_competition_season_id"
            claimPK="claim_competition_season_uuid"
            goldenEntityPK="competition_season_id"
            getGoldenEntityId={getGoldenCompetitionSeasonId}
            entityType="competition_season"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
            {...{
              claimStatuses,
              setClaimStatuses,
              selectAClaim,
              overrides,
              setOverrides,
              claimMode,
              refetch,
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

function CompetitionSeason(props) {
  const query = new URLSearchParams(useLocation().search);
  const isNewEntity = query.get('new');
  const competitionSeasonId = isNewEntity ? '0' : query.get('competition_season_id');

  return <GoldenCompetitionSeasonView competitionSeasonId={competitionSeasonId} isNewEntity={!!isNewEntity} />;
}

export default CompetitionSeason;
