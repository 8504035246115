import gql from 'graphql-tag';

const GOLDEN_MANAGERS = gql`
  query getGoldenManagers(
    $name: String
    $area: String
    $start_date: date
    $end_date: date
    $duplicate_count: bigint = 0
    $min_iq: Boolean
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_manager_order_by!
  ) {
    statsbomb_golden_manager(
      where: {
        _and: [
          {
            manager_name: { _ilike: $name }
            nationality_name: { _ilike: $area }
            manager_date_of_birth: { _gte: $start_date, _lte: $end_date }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      manager_id
      manager_name
      manager_country_of_birth
      manager_date_of_birth
      country_of_birth_name
      country_of_birth_code
      manager_is_female
      manager_lastname
      manager_firstname
      manager_nationality
      manager_nickname
      manager_photo_url
      nationality_code
      nationality_name
      search_name
      deleted
      min_spec
    }
  }
`;

const GOLDEN_MANAGERS_COUNT = gql`
  query getManagersCount(
    $name: String
    $area: String
    $start_date: date
    $end_date: date
    $duplicate_count: bigint = 0
    $min_iq: Boolean
    $deleted: Boolean = false
  ) {
    statsbomb_golden_manager_aggregate(
      where: {
        _and: [
          {
            manager_name: { _ilike: $name }
            nationality_name: { _ilike: $area }
            manager_date_of_birth: { _gte: $start_date, _lte: $end_date }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const SIMILAR_MANAGERS = gql`
  query similarManagers($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_managers(
      args: { n: $name, p: $provider, o: $offset }
      order_by: { name_similarity: desc_nulls_last }
    ) {
      manager_id
      manager_name
      name_similarity
      mapped
      manager_date_of_birth
      manager_nationality
    }
  }
`;

const GOLDEN_MANAGERS_FOR_MATCH_MANAGER_INCLUSIONS = gql`
  query suggestedManagersForLineup($search_string: String, $search_int: Int) {
    statsbomb_manager(
      where: { _or: [{ manager_name: { _ilike: $search_string } }, { manager_id: { _eq: $search_int } }] }
      order_by: { manager_name: asc }
      limit: 15
    ) {
      manager_id
      manager_name
      nationality_statsbomb_area {
        area_name
        area_id
        area_code
      }
      manager_date_of_birth
    }
  }
`;

const INSERT_SOURCE_MANAGER = gql`
  mutation createSourceManager($provider_manager_id: String!, $manager_name: String!) {
    insert_source_manager(
      objects: {
        provider_manager_id: $provider_manager_id
        manager_name: $manager_name
        last_updated: "now()"
        provider: Info_Team
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_MANAGER = gql`
  mutation mapManager($manager_id: Int!, $provider: source_provider_enum!, $provider_manager_id: String!) {
    insert_mapping_manager_one(
      object: { manager_id: $manager_id, provider: $provider, provider_manager_id: $provider_manager_id }
    ) {
      manager_id
      provider
      provider_manager_id
    }
  }
`;

const SUGGESTED_MANAGERS = gql`
  query suggestedManagersForLineup($team_ids: [Int!]) {
    statsbomb_managers_match_manager_aggregate(
      where: { team_id: { _in: $team_ids } }
      order_by: { manager_name: asc }
    ) {
      appearances
      manager_id
      manager_name
      team_id
      manager_date_of_birth
      manager_nationality_code
      manager_nationality_name
    }
  }
`;

const GOLDEN_MANAGERS_FOR_MATCH = gql`
  query goldenManagersForMatch($match_id: Int!) {
    statsbomb_match_manager(
      where: { match_id: { _eq: $match_id }, deleted: { _neq: true } }
      order_by: { statsbomb_manager: { manager_name: asc } }
    ) {
      match_id
      manager_id
      statsbomb_manager {
        manager_name
      }
      team_id
      statsbomb_team {
        team_name
      }
      manager_type
      deleted
    }
  }
`;

const MANAGER_TYPES = gql`
  query getAllManagerTypes {
    statsbomb_manager_type(order_by: { sort_order: asc }) {
      selection_type: manager_type
      display_name
    }
  }
`;

const DELETE_MANAGER = gql`
  mutation DeleteManagers($manager_id: Int!) {
    update_statsbomb_manager_by_pk(pk_columns: { manager_id: $manager_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;

const MANAGERS = gql`
  query getAllManager {
    statsbomb_manager(where: { deleted: { _eq: false } }, order_by: { manager_name: asc }) {
      manager_id
      manager_name
      manager_nickname
      manager_is_female
      nationality_statsbomb_area {
        area_code
      }
    }
  }
`;
const GET_MANAGERS_BY_ID = gql`
  query getManagersByIds($entityIds: [Int!]) {
    statsbomb_golden_manager(where: { manager_id: { _in: $entityIds } }) {
      manager_id
      manager_name
      manager_country_of_birth
      manager_date_of_birth
      country_of_birth_name
      country_of_birth_code
      manager_is_female
      manager_lastname
      manager_firstname
      manager_nationality
      manager_nickname
      manager_photo_url
      nationality_code
      nationality_name
      search_name
    }
  }
`;
const DELETE_MANAGER_BY_ID = gql`
  mutation deleteManager($manager_id: Int!) {
    delete_statsbomb_match_manager(where: { manager_id: { _eq: $manager_id } }) {
      affected_rows
    }
    delete_claim_manager(where: { mapping_manager: { manager_id: { _eq: $manager_id } } }) {
      affected_rows
    }
    delete_mapping_manager(where: { manager_id: { _eq: $manager_id } }) {
      affected_rows
    }
    delete_statsbomb_manager(where: { manager_id: { _eq: $manager_id } }) {
      affected_rows
    }
  }
`;
const GET_MANAGER_BY_ID = gql`
  query getPlayerById($manager_id: Int!) {
    statsbomb_manager(where: { manager_id: { _eq: $manager_id } }) {
      manager_id
      manager_name
      manager_nickname
      manager_is_female
      nationality_statsbomb_area {
        area_code
      }
    }
  }
`;

export {
  GOLDEN_MANAGERS,
  GOLDEN_MANAGERS_COUNT,
  SIMILAR_MANAGERS,
  GOLDEN_MANAGERS_FOR_MATCH_MANAGER_INCLUSIONS,
  GOLDEN_MANAGERS_FOR_MATCH,
  MAP_MANAGER,
  INSERT_SOURCE_MANAGER,
  SUGGESTED_MANAGERS,
  MANAGER_TYPES,
  DELETE_MANAGER,
  MANAGERS,
  GET_MANAGERS_BY_ID,
  DELETE_MANAGER_BY_ID,
  GET_MANAGER_BY_ID,
};
