import React from 'react';
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pluralise } from '../../util/string';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const ScrapeEntity = ({ textFieldProps, buttonProps }) => {
  const classes = useStyles();
  return (
    <div data-scrape-entity={buttonProps.text.toLowerCase()}>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            How To
          </Typography>
          <Typography className={classes.pos} variant="h5" component="h2">
            Scrape {pluralise(buttonProps.text)}
          </Typography>
          <Typography variant="body2" component="p">
            {textFieldProps.description}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <TextField
            style={{ marginRight: 10 }}
            placeholder={textFieldProps.placeholder}
            onChange={textFieldProps.onChange}
            value={textFieldProps.value}
          />
          <Button color="primary" variant="contained" onClick={buttonProps.onClick}>
            Scrape {buttonProps.text}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default ScrapeEntity;
