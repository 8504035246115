import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const LabelledCheckbox = ({ overrideChange, columnKey, currentValue, name, overrideProps, ...props }) => {
  const checked = currentValue == null ? false : currentValue;
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          color="primary"
          onChange={e => overrideChange(columnKey, e.target.checked)}
          checked={checked}
        />
      }
      label={name}
    />
  );
};

export { LabelledCheckbox };
