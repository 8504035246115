import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { MATCH_PLAY_STATUS } from '../../hasura/queries/match/matches';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const MatchPlayStatusOverride = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(MATCH_PLAY_STATUS, {});

  if (loading) return <InlineProgress />;

  const matchPlayStatuses = data.statsbomb_match_play_status || [];

  return (
    <>
      <Error message="Error fetching Match Play Statuses" error={error} />

      <InputLabel id="match-play-status-input">Status</InputLabel>
      <Select
        onChange={e => overrideChange(columnKey, e.target.value)}
        value={currentValue || matchPlayStatuses[0].match_play_status}
        labelId="match-play-status-input">
        {matchPlayStatuses.map(({ match_play_status }) => (
          <MenuItem key={match_play_status} value={match_play_status}>
            {match_play_status}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { MatchPlayStatusOverride };
