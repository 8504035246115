import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { PLAYERS, INSERT_SOURCE_PLAYER } from '../../hasura/queries/player/playerList';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';
import { FlagIcon, GenderIcon } from '../util/icon';
import { MAP_PLAYER } from '../../hasura/queries/player/playerMapping';

const useStyles = makeStyles({
  name: {
    paddingLeft: 10,
  },
  option: {
    fontSize: 10,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 12,
    '& label': {
      fontSize: 12,
    },
  },
});

const PlayerOverride = ({ overrideChange, currentValue, columnKey, provider }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(PLAYERS, {});

  const [mapPlayer] = useMutation(MAP_PLAYER, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [insertSourcePlayer] = useMutation(INSERT_SOURCE_PLAYER, {
    onError: () => {},
    onCompleted: () => {},
  });

  if (loading) return <InlineProgress />;

  const playerOverrideSelected = async player => {
    const variables = {
      player_name: player.player_name,
      provider_player_id: player.player_id.toString(),
    };

    await insertSourcePlayer({
      variables,
    });

    await mapPlayer({
      variables: {
        player_id: player.player_id,
        provider_player_id: player.player_id.toString(),
        provider: provider,
      },
    });

    overrideChange(columnKey, player.player_id.toString());
  };

  const players = data?.statsbomb_player || [];

  return (
    <>
      <Error message="Error fetching players" error={error} />

      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        classes={{ option: classes.option, root: classes.root }}
        options={players}
        autoHighlight
        openOnFocus
        disableClearable
        filterOptions={createFilterOptions({
          ignoreAccents: false,
          limit: 300,
          stringify: player => `${player.player_name} ${player.player_id}`,
        })}
        getOptionLabel={player => player.player_name}
        renderOption={({ nationality_statsbomb_area, player_is_female, player_name, player_id }) => (
          <>
            {nationality_statsbomb_area && <FlagIcon code={nationality_statsbomb_area.area_code} />}
            <GenderIcon isFemale={player_is_female} />
            {player_name} ({player_id})
          </>
        )}
        renderInput={params => <TextField {...params} label={'Pick a player'} inputProps={{ ...params.inputProps }} />}
        onChange={(event, player) => playerOverrideSelected(player)}
      />
    </>
  );
};

export { PlayerOverride };
