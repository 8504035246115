import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';

const FootPreferenceOverrride = ({ overrideChange, currentValue, columnKey }) => {
  return (
    <>
      <InputLabel id="demo-simple-select-label">Preferred Foot</InputLabel>
      <Select
        onChange={e => overrideChange(columnKey, e.target.value)}
        value={currentValue || 'Left'}
        labelId="demo-simple-select-label">
        <MenuItem value="Left">Left</MenuItem>
        <MenuItem value="Right">Right</MenuItem>
        <MenuItem value="Both">Both</MenuItem>
      </Select>
    </>
  );
};

export { FootPreferenceOverrride };
