import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const InlineProgress = CircularProgress;

const Progress = withStyles({
  root: {
    margin: '40px auto',
    display: 'block',
  },
})(CircularProgress);

export { Progress, InlineProgress };
