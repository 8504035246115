import React, { useState } from 'react';
import { TableHead, TableRow, Table, TableBody, TextField, makeStyles, Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { SmallButton } from '../util/buttons';
import { TableRowWithNote, TableCell } from '../table/table';
import { pluralise } from '../../util/string';
import { FlagIcon } from '../util/icon';

const useStyle = makeStyles({
  change: { color: 'blue', textDecoration: 'underline' },
  required: { color: 'red', textDecoration: 'underline' },
  reducedPadding: { padding: '5px' },
  removeUnderline: { textDecoration: 'none' },
  hover: {
    '&:hover': {
      color: 'red',
      cursor: 'pointer',
    },
  },
});

const GoldenSelectionsSegregatedTable = ({
  matchData,
  createClaimFn,
  deleteLineupPlayerFn,
  selectedTeam,
  goldenRecordData,
}) => {
  const classes = useStyle();

  const [editingJersey, setEditingJersey] = useState({ playerId: 0, new: 0 });

  const isFromTeamAndType = (id, type) => record =>
    record.team_id === id && record.player_lineup_selection_type === type && !record.deleted;

  const goldenRecords = goldenRecordData
    ? {
        home: {
          startingXi: goldenRecordData.filter(isFromTeamAndType(matchData.homeTeam?.id, 'starting_xi')),
          bench: goldenRecordData.filter(isFromTeamAndType(matchData.homeTeam?.id, 'substitute')),
          deleted: goldenRecordData.filter(
            goldenRecord => goldenRecord.team_id === matchData.homeTeam?.id && goldenRecord.deleted
          ),
        },
        away: {
          startingXi: goldenRecordData.filter(isFromTeamAndType(matchData.awayTeam?.id, 'starting_xi')),
          bench: goldenRecordData.filter(isFromTeamAndType(matchData.awayTeam?.id, 'substitute')),
          deleted: goldenRecordData.filter(
            goldenRecord => goldenRecord.team_id === matchData.awayTeam?.id && goldenRecord.deleted
          ),
        },
      }
    : {};

  const submitJerseyNumberClaim = async (id, name, selectionType) => {
    await createClaimFn(id, name, selectionType, editingJersey.new);
  };

  const deletePlayerFromLineup = async id => {
    await deleteLineupPlayerFn(id);
  };

  const numberChanged = number => {
    const newEditingJerseyValue = { ...editingJersey, new: number };
    setEditingJersey(newEditingJerseyValue);
  };

  const goldenJerseyNumber = player =>
    editingJersey.playerId === player.player_id ? (
      <>
        <TextField
          onChange={e => numberChanged(e.target.value)}
          value={editingJersey.new}
          type="text"
          placeholder={player.lineup_jersey ? player.lineup_jersey.toString() : ''}
        />

        <SmallButton
          color="primary"
          onClick={() =>
            submitJerseyNumberClaim(
              player.player_id,
              player.statsbomb_player.player_name,
              player.player_lineup_selection_type
            )
          }
          text="Submit"
        />
      </>
    ) : (
      player.lineup_jersey
    );

  const goldenRecordElements = goldenRecords =>
    goldenRecords &&
    goldenRecords.map(player => (
      <TableRow key={player.player_id + player.match_id}>
        <TableCell
          data-entity-id={player.player_id}
          data-entity-type={player.player_lineup_selection_type}
          className={[
            classes.removeUnderline,
            classes.reducedPadding,
            goldenJerseyNumber(player) ? classes.change : classes.required,
          ].join(' ')}
          onClick={() => setEditingJersey({ playerId: player.player_id, new: '' })}>
          {goldenJerseyNumber(player) ? goldenJerseyNumber(player) : '+'}
        </TableCell>
        <TableCell className={classes.reducedPadding}>{player.player_id}</TableCell>
        <TableCell className={classes.reducedPadding}>
          <Link className="highlight" href={`/player?player_id=${player.player_id}`} target="_blank">
            {player.statsbomb_player.player_name}
          </Link>
        </TableCell>
        <TableCell className={classes.reducedPadding}> {player.statsbomb_player?.player_date_of_birth} </TableCell>
        <TableCell>
          <FlagIcon code={player.statsbomb_player?.nationality_statsbomb_area?.area_code} />
          {player.statsbomb_player?.nationality_statsbomb_area?.area_name}
        </TableCell>
        <TableCell className={classes.reducedPadding} style={{ textAlign: 'center' }}>
          <HighlightOff
            aria-label="delete"
            fontSize="small"
            className={classes.hover}
            onClick={() =>
              deletePlayerFromLineup(
                player.player_id,
                player.statsbomb_player.player_name,
                player.player_lineup_selection_type,
                player.lineup_jersey
              )
            }
          />{' '}
        </TableCell>
      </TableRow>
    ));

  const selectedTeamsGoldenRecordsStarted =
    selectedTeam === matchData.homeTeam?.id
      ? goldenRecordElements(goldenRecords.home?.startingXi)
      : goldenRecordElements(goldenRecords.away?.startingXi);

  const selectedTeamsGoldenRecordsBench =
    selectedTeam === matchData.homeTeam?.id
      ? goldenRecordElements(goldenRecords.home?.bench)
      : goldenRecordElements(goldenRecords.away?.bench);

  return (
    <>
      <h4>Started</h4>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              # <EditIcon fontSize={'small'} />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell> DOB </TableCell>
            <TableCell> Nationality </TableCell>
            <TableCell>Remove from Lineup</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedTeamsGoldenRecordsStarted && selectedTeamsGoldenRecordsStarted.length > 0 ? (
            selectedTeamsGoldenRecordsStarted
          ) : (
            <TableRowWithNote note="Starting xi will appear here" />
          )}
        </TableBody>
      </Table>
      <h4>Bench</h4>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              # <EditIcon fontSize={'small'} />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell> DOB </TableCell>
            <TableCell> Nationality </TableCell>
            <TableCell>Remove from Lineup</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedTeamsGoldenRecordsBench && selectedTeamsGoldenRecordsBench.length > 0 ? (
            selectedTeamsGoldenRecordsBench
          ) : (
            <TableRowWithNote note="Bench players will appear here" />
          )}
        </TableBody>
      </Table>
    </>
  );
};

const SelectionTableRow = ({
  displayName,
  filteredRecord,
  index,
  length,
  isLoading,
  entityType,
  deleteEntityFn,
  createClaimFn,
}) => {
  const classes = useStyle();
  const borderBottom = index === length - 1 ? { padding: '5px' } : { borderBottom: 'none', padding: '5px' };
  const entityName = filteredRecord[`statsbomb_${entityType}`][`${entityType}_name`];
  const entityTypeCurrentValue = filteredRecord[`${entityType}_type`];
  const entityId = filteredRecord[`${entityType}_id`];

  const deleteClicked = ({ selectedEntityId, entityName, selectionType }) => {
    deleteEntityFn({ id: selectedEntityId });
  };

  return (
    <TableRow key={entityId}>
      {index === 0 ? (
        <TableCell style={{ ...borderBottom, fontWeight: 900 }}>{pluralise(displayName)}</TableCell>
      ) : (
        <TableCell style={borderBottom}></TableCell>
      )}
      <TableCell style={borderBottom}>
        <Link className="highlight" href={`/${entityType}?${entityType}_id=${entityId}`} target="_blank">
          {entityName}
        </Link>
      </TableCell>
      <TableCell style={borderBottom}>
        {!isLoading && (
          <HighlightOff
            data-entity-id={entityId}
            data-action="delete"
            className={classes.hover}
            fontSize="small"
            onClick={() =>
              deleteClicked({ selectedEntityId: entityId, entityName, selectionType: entityTypeCurrentValue })
            }
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const GoldenSelectionsInlineTable = ({
  goldenRecordData,
  types,
  entityType,
  headerName,
  isLoading,
  createClaimFn,
  deleteEntityFn,
}) => {
  const goldenRecordFilter = type => goldenRecordData.filter(record => record[`${entityType}_type`] === type);

  const TableSection = ({ selectionType, displayName }) => {
    const recordsForType = goldenRecordFilter(selectionType);
    const length = recordsForType?.length;
    return recordsForType.map((filteredRecord, index) => (
      <SelectionTableRow
        key={index}
        {...{ displayName, filteredRecord, index, length, isLoading, entityType, deleteEntityFn, createClaimFn }}
      />
    ));
  };

  const emptyNote = `${headerName} will appear here`;

  return (
    <>
      <h4>{headerName}</h4>
      <Table>
        <TableBody>
          {goldenRecordData && goldenRecordData[0] ? (
            types.map(({ selection_type: selectionType, display_name: displayName }) => (
              <TableSection key={selectionType} {...{ selectionType, displayName }} />
            ))
          ) : (
            <TableRowWithNote note={emptyNote} />
          )}
        </TableBody>
      </Table>
    </>
  );
};

export { GoldenSelectionsSegregatedTable, GoldenSelectionsInlineTable };
