import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { mutationError } from '../graphql/util';
import { RepeatableError, RepeatableSuccess } from '../util/notifications';
import { formatDateString } from '../../util/date';
import { OVERRIDE_MANAGER_CLAIM } from '../../hasura/queries/manager/managerClaims';
import { GET_MANAGER_BY_ID } from '../../hasura/queries/manager/managers';
import { OVERRIDE_PLAYER_CLAIM } from '../../hasura/queries/player/playerClaims';
import { GET_PLAYER_BY_ID } from '../../hasura/queries/player/playerList';
import { OVERRIDE_TEAM_CLAIM } from '../../hasura/queries/team/teamClaims';
import { GET_TEAM_BY_ID } from '../../hasura/queries/team/teams';
import {
  INSERT_GOLDEN_TRANSFER_MANAGER,
  OVERRIDE_TRANSFER_MANAGER_CLAIM,
} from '../../hasura/queries/transferManager/transferManagerClaims';
import { GET_MANAGER_CURRENT_TEAM } from '../../hasura/queries/transferManager/transferManagers';
import {
  INSERT_GOLDEN_TRANSFER_PLAYER,
  OVERRIDE_TRANSFER_PLAYER_CLAIM,
} from '../../hasura/queries/transferPlayer/transferPlayerClaims';
import { GET_PLAYER_CURRENT_TEAM } from '../../hasura/queries/transferPlayer/transferPlayers';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: 10,
  },
  tableTitle: {
    marginLeft: 10,
  },
  tableHeader: {
    background: theme.palette.secondary.main,
  },
  actionButton: {
    marginRight: 10,
  },
  modalParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  modal: {
    padding: 10,
    borderRadius: 5,
    minWidth: '35%',
    maxWidth: '45%',
    backgroundColor: 'white',
    boxShadow: 'rgba(0,23,130, 0.5) 0px 8px 24px',
  },
  modalGrid: {
    borderRadius: 5,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  },
  gridRow: {
    padding: 20,
    '&:nth-last-child(1)': {
      borderRadius: '0 0 5px 5px',
    },
    '&:nth-child(even)': {
      background: theme.palette.secondary.main,
    },
  },
  gridItemLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItemRight: {
    display: 'grid',
    marginLeft: 'auto',
  },
  linkStyle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const TeamSquadModal = ({ teamName, schema, transferType, initialTransferDetails, setModalOpen, modalOpen }) => {
  const classes = useStyles();
  const type = transferType.type;

  const [hasCurrentTeam, setHasCurrentTeam] = useState(true);
  const [modalSuccessMessage, setModalSuccessMessage] = useState(null);
  const [modalErrorMessage, setModalErrorMessage] = useState(null);

  const [transferDetails, setTransferDetails] = useState({
    transfer_date: formatDateString(new Date(), 'YYYY-MM-DD'),
    transfer_value: 0,
    transfer_type: transferType.transferType,
    transfer_currency: 'GBP',
  });

  const client = useApolloClient();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setTransferDetails({ ...transferDetails, ...initialTransferDetails }), [initialTransferDetails]);

  useEffect(() => {
    if (!modalOpen) {
      setHasCurrentTeam(true);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (transferDetails.entity_id != null) {
      const getEntityCurrentTransferData = async () => {
        const { error, data } = await client.query({
          query: queries.getEntityCurrentTeam,
          variables: {
            [`${type}_id`]: parseInt(transferDetails.entity_id),
          },
        });

        if (error) setModalErrorMessage(mutationError(error, 'Problem getting entity current team'));

        const transferData = data[`statsbomb_current_transfer_${type}`][0];
        const hasCurrentTeam = transferData?.team_to_id;
        if (hasCurrentTeam) {
          setHasCurrentTeam(true);
          setTransferDetails({ ...transferDetails, team_from_id: transferData?.team_to_id });
        } else setHasCurrentTeam(false);
      };
      getEntityCurrentTransferData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferDetails.entity_id]);

  const queries = {
    player: {
      insertGoldenTransfer: INSERT_GOLDEN_TRANSFER_PLAYER,
      overrideTransferClaim: OVERRIDE_TRANSFER_PLAYER_CLAIM,
      overrideEntityClaim: OVERRIDE_PLAYER_CLAIM,
      getEntityById: GET_PLAYER_BY_ID,
      getEntityCurrentTeam: GET_PLAYER_CURRENT_TEAM,
    },
    manager: {
      insertGoldenTransfer: INSERT_GOLDEN_TRANSFER_MANAGER,
      overrideTransferClaim: OVERRIDE_TRANSFER_MANAGER_CLAIM,
      overrideEntityClaim: OVERRIDE_MANAGER_CLAIM,
      getEntityById: GET_MANAGER_BY_ID,
      getEntityCurrentTeam: GET_MANAGER_CURRENT_TEAM,
    },
  }[type];

  const [createGoldenRecord] = useMutation(queries.insertGoldenTransfer, {
    onError: err => setModalErrorMessage(mutationError(err, 'Problem creating golden transfer')),
    onCompleted: () => setModalSuccessMessage('Saved!'),
  });

  const [insertSourceAndMapTransfer] = useMutation(queries.overrideTransferClaim, {
    onError: err => setModalErrorMessage(mutationError(err, 'Problem inserting and mapping transfer')),
  });

  const [insertSourceAndMapEntity] = useMutation(queries.overrideEntityClaim, {
    onError: err => setModalErrorMessage(mutationError(err, `Problem inserting and mapping ${type}`)),
  });

  const [insertSourceAndMapTeam] = useMutation(OVERRIDE_TEAM_CLAIM, {
    onError: err => setModalErrorMessage(mutationError(err, 'Problem inserting and mapping team')),
  });

  const getObjectKey = columnKey => (columnKey === 'player_id' || columnKey === 'manager_id' ? 'entity_id' : columnKey);

  const isFormComplete = requiredValues =>
    requiredValues.reduce((acc, curr) => {
      if (curr === null) return false;
      return acc;
    }, true);

  const createTransferRecord = async details => {
    const {
      entity_id,
      team_from_id,
      team_to_id,
      transfer_date,
      transfer_type,
      transfer_currency,
      transfer_value,
    } = details;

    const requiredValues = [entity_id, team_from_id, team_to_id, transfer_date, transfer_type];

    if (!isFormComplete(requiredValues)) return setModalErrorMessage('Please fill in all of the relevant information');

    const variablesId = `${type}_id`;

    const { data: currentTransferData } = await client.query({
      query: queries.getEntityCurrentTeam,
      variables: {
        [variablesId]: parseInt(entity_id),
      },
    });

    const currentTransferDate = currentTransferData[`statsbomb_current_transfer_${type}`][0]?.transfer_date;

    if (
      currentTransferDate &&
      formatDateString(currentTransferDate, 'YYYY-MM-DD') === formatDateString(transfer_date, 'YYYY-MM-DD')
    )
      return setModalErrorMessage(
        `This ${type} has already been transferred on this date. If this ${type} got transferred twice in one day, please use the next days date.`
      );

    const { data: goldenData } = await createGoldenRecord({
      variables: {
        statsbomb_entity: {
          [variablesId]: entity_id,
          team_from_id,
          team_to_id,
          transfer_currency,
          transfer_date,
          transfer_type,
          transfer_value,
        },
      },
    });

    const { data: entityData } = await client.query({
      query: queries.getEntityById,
      variables: {
        [variablesId]: parseInt(entity_id),
      },
    });

    await insertSourceAndMapEntity({
      variables: {
        [variablesId]: entity_id,
        [`provider_${type}_id`]: entity_id.toString(),
        source_entity: {
          [`${type}_name`]: entityData[`statsbomb_${type}`][0][`${type}_name`],
        },
      },
    });

    const { data: teamFromData } = await client.query({
      query: GET_TEAM_BY_ID,
      variables: {
        team_id: parseInt(team_from_id),
      },
    });

    await insertSourceAndMapTeam({
      variables: {
        team_id: team_from_id,
        provider_team_id: team_from_id.toString(),
        source_entity: {
          team_name: teamFromData.statsbomb_team[0].team_name,
        },
      },
    });

    const { data: teamToData } = await client.query({
      query: GET_TEAM_BY_ID,
      variables: {
        team_id: parseInt(team_to_id),
      },
    });

    await insertSourceAndMapTeam({
      variables: {
        team_id: team_to_id,
        provider_team_id: team_to_id.toString(),
        source_entity: {
          team_name: teamToData.statsbomb_team[0].team_name,
        },
      },
    });

    const transferId = goldenData[`insert_statsbomb_transfer_${type}`].returning[0][`transfer_${type}_id`];
    await insertSourceAndMapTransfer({
      variables: {
        [`transfer_${type}_id`]: transferId,
        [`provider_transfer_${type}_id`]: transferId.toString(),
        source_entity: {
          provider: 'Info_Team',
          [variablesId]: entity_id.toString(),
          team_from_id: team_from_id.toString(),
          team_to_id: team_to_id.toString(),
          transfer_currency,
          transfer_date,
          transfer_type,
          transfer_value,
        },
      },
    });

    setModalOpen(false);
  };

  const filterSchema = (schema, hasCurrentTeam) =>
    hasCurrentTeam ? schema.filter(item => item.columnKey !== 'team_from_id') : schema;

  return (
    <>
      <RepeatableError message={modalErrorMessage} setMessage={setModalErrorMessage} />
      <RepeatableSuccess message={modalSuccessMessage} setMessage={setModalSuccessMessage} />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={classes.modalParent}>
          <div className={classes.modal}>
            <h2 style={{ marginTop: 0 }}>{teamName}</h2>
            <p>Select the values below to create a new transfer record.</p>
            <Grid className={classes.modalGrid} container spacing={0}>
              {filterSchema(schema, hasCurrentTeam).map(({ label, columnKey, Component, overrideProps }) => (
                <Grid className={classes.gridRow} container item xs={12} key={columnKey}>
                  <Grid className={classes.gridItemLeft} item xs={6}>
                    {label}
                  </Grid>
                  <Grid className={classes.gridItemRight} item xs={6}>
                    <Component
                      overrideChange={(columnKey, value) =>
                        setTransferDetails({ ...transferDetails, [getObjectKey(columnKey)]: value })
                      }
                      currentValue={transferDetails[getObjectKey(columnKey)]}
                      {...{ columnKey, overrideProps }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={() => createTransferRecord(transferDetails)}>
                Create Transfer
              </Button>
              <Button
                style={{ backgroundColor: 'red', color: 'white', marginLeft: 10 }}
                variant="contained"
                onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TeamSquadModal;
