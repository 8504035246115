import gql from 'graphql-tag';

const GOLDEN_ROUND_AND_CLAIMS = gql`
  query goldenRoundAndClaims($round_id: Int!) {
    statsbomb_round(where: { round_id: { _eq: $round_id } }) {
      phase_id
      round_first_match_date
      round_order
      round_matches
      round_match_weeks
      round_last_match_date
      round_id
      round_structure_id
      round_teams
      round_type_id
      deleted
      statsbomb_round_structure {
        round_structure_id
        round_structure_name
      }
      statsbomb_round_type {
        round_type_id
        round_type_name
      }
      statsbomb_phase {
        statsbomb_phase_type {
          phase_type_name
        }
        statsbomb_competition_season {
          statsbomb_competition {
            competition_name
          }
          statsbomb_season {
            season_name
          }
        }
      }
      mapping_rounds {
        provider
        provider_round_id
        claim_rounds {
          provider
          provider_round_id
          round_matches
          round_teams
          deleted
          rejected_at
          claim_round_uuid
          round_order
          round_last_match_date
          round_structure_id
          round_type_id
          phase_id
          created_at
          rejected_at
          mapping_phase {
            statsbomb_phase {
              statsbomb_phase_type {
                phase_type_name
              }
            }
          }
          source_round {
            statsbomb_round_type {
              round_type_name
            }
            statsbomb_round_structure {
              round_structure_name
            }
          }
          approved_at
        }
      }
    }
  }
`;

const SAVE_ROUND_CLAIM = gql`
  mutation SaveRound($claim_round_uuid: uuid!, $claims: [claim_round_insert_input!]!) {
    delete_claim_round(where: { claim_round_uuid: { _eq: $claim_round_uuid } }) {
      affected_rows
    }
    insert_claim_round(objects: $claims) {
      affected_rows
    }
  }
`;

const ALL_CLAIM_ROUNDS = gql`
  query getAllClaimRounds($recent_claim_time: timestamp, $offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_round(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_round_id: { _ilike: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_round_uuid
      created_at
      phase_id
      provider
      provider_round_id
      round_first_match_date
      round_last_match_date
      round_matches
      round_teams
      round_order
      round_structure_id
      round_type_id
      deleted
      rejected_at
      approved_at
      mapping_round {
        round_id
        statsbomb_round {
          round_id
        }
      }
      source_round {
        statsbomb_round_type {
          round_type_name
        }
        statsbomb_round_structure {
          round_structure_name
        }
      }
      mapping_phase {
        statsbomb_phase {
          phase_id
          statsbomb_phase_type {
            phase_type_name
          }
        }
      }
    }
  }
`;

const MAPPED_CLAIM_ROUNDS = gql`
  query getAllClaimRounds($offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_round(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        mapping_round: {}
        provider_round_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_round_uuid
      created_at
      phase_id
      provider
      provider_round_id
      round_first_match_date
      round_last_match_date
      round_matches
      round_teams
      round_order
      round_structure_id
      round_type_id
      deleted
      rejected_at
      approved_at
      mapping_round {
        statsbomb_round {
          round_id
        }
      }
      source_round {
        statsbomb_round_type {
          round_type_name
        }
        statsbomb_round_structure {
          round_structure_name
        }
      }
      mapping_phase {
        statsbomb_phase {
          phase_id
          statsbomb_phase_type {
            phase_type_name
          }
        }
      }
    }
  }
`;

const UNMAPPED_CLAIM_ROUNDS = gql`
  query getAllClaimRounds($offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_round(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_round: {} }
        provider_round_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_round_uuid
      created_at
      phase_id
      provider
      provider_round_id
      round_first_match_date
      round_last_match_date
      round_matches
      round_teams
      round_order
      round_structure_id
      round_type_id
      deleted
      rejected_at
      approved_at
      mapping_round {
        statsbomb_round {
          round_id
        }
      }
      source_round {
        statsbomb_round_type {
          round_type_name
        }
        statsbomb_round_structure {
          round_structure_name
        }
      }
      mapping_phase {
        statsbomb_phase {
          phase_id
          statsbomb_phase_type {
            phase_type_name
          }
        }
      }
    }
  }
`;

const OVERRIDE_ROUND_CLAIM = gql`
  mutation infoTeamOverride($round_id: Int!, $provider_round_id: String!, $source_entity: source_round_set_input!) {
    insert_mapping_round(
      objects: {
        source_round: {
          data: { provider: Info_Team, provider_round_id: $provider_round_id, last_updated: "now()" }
          on_conflict: { constraint: round_pkey, update_columns: last_updated }
        }
        round_id: $round_id
      }
      on_conflict: { constraint: round_pkey, update_columns: round_id }
    ) {
      returning {
        round_id
      }
    }
    update_source_round(
      where: { provider: { _eq: Info_Team }, provider_round_id: { _eq: $provider_round_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_ROUND = gql`
  mutation insertGoldenRound($statsbomb_entity: statsbomb_round_insert_input!) {
    insert_statsbomb_round(objects: [$statsbomb_entity]) {
      returning {
        round_id
      }
    }
  }
`;

const CLAIMS_FOR_ROUND_BY_PROVIDER = gql`
  subscription claimsForRoundByProvider($provider_round_id: String!, $provider: source_provider_enum!) {
    claim_round(where: { provider_round_id: { _eq: $provider_round_id }, provider: { _eq: $provider } }) {
      claim_round_uuid
      round_type_id
      round_structure_id
      phase_id
      round_order
      round_teams
      round_matches
      round_match_weeks
      round_last_match_date
      round_first_match_date
      provider
      provider_round_id
      rejected_at
      deleted
      created_at
      approved_at
    }
  }
`;

const CLAIMS_ROUNDS_COUNT = gql`
  query claimsRoundsCount {
    claim_round_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  ALL_CLAIM_ROUNDS,
  MAPPED_CLAIM_ROUNDS,
  UNMAPPED_CLAIM_ROUNDS,
  CLAIMS_FOR_ROUND_BY_PROVIDER,
  CLAIMS_ROUNDS_COUNT,
  GOLDEN_ROUND_AND_CLAIMS,
  INSERT_GOLDEN_ROUND,
  OVERRIDE_ROUND_CLAIM,
  SAVE_ROUND_CLAIM,
};
