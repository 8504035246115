import gql from 'graphql-tag';

const SIMILAR_PLAYERS = gql`
  query similarPlayers($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_players(
      args: { n: $name, p: $provider, o: $offset }
      order_by: { name_similarity: desc_nulls_last }
    ) {
      player_id
      player_name
      player_date_of_birth
      name_similarity
      mapped
      player_nationality
    }
  }
`;

const GOLDEN_PLAYERS = gql`
  query getGoldenPlayers(
    $area: String
    $name: String
    $min_iq: Boolean
    $good_iq: Boolean
    $start_date: date
    $end_date: date
    $duplicate_count: bigint = 0
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_player_order_by!
  ) {
    statsbomb_golden_player(
      where: {
        _and: [
          { search_name: { _ilike: $name } }
          { nationality_name: { _ilike: $area } }
          { player_date_of_birth: { _gte: $start_date, _lte: $end_date } }
          { duplicate_count: { _gte: $duplicate_count } }
          { min_spec: { _neq: $min_iq } }
          { iq_spec: { _neq: $good_iq } }
          { deleted: { _eq: $deleted } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      player_id
      player_name
      player_firstname
      player_lastname
      player_nickname
      player_date_of_birth
      player_nationality
      nationality_name
      nationality_code
      player_country_of_birth
      country_of_birth_name
      country_of_birth_code
      player_weight
      player_height
      player_preferred_foot
      player_is_female
      player_is_retired
      deleted
      min_spec
      iq_spec
    }
  }
`;

const GOLDEN_PLAYERS_COUNT = gql`
  query getGoldenPlayersCount(
    $name: String
    $area: String
    $min_iq: Boolean
    $good_iq: Boolean
    $start_date: date
    $end_date: date
    $duplicate_count: bigint = 0
    $deleted: Boolean = false
  ) {
    statsbomb_golden_player_aggregate(
      where: {
        _and: [
          { search_name: { _ilike: $name } }
          { nationality_name: { _ilike: $area } }
          { player_date_of_birth: { _gte: $start_date, _lte: $end_date } }
          { duplicate_count: { _gte: $duplicate_count } }
          { min_spec: { _neq: $min_iq } }
          { iq_spec: { _neq: $good_iq } }
          { deleted: { _eq: $deleted } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_SOURCE_PLAYER = gql`
  mutation createSourcePlayer($provider_player_id: String!, $player_name: String!) {
    insert_source_player(
      objects: {
        provider_player_id: $provider_player_id
        player_name: $player_name
        last_updated: "now()"
        provider: Info_Team
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const DELETE_PLAYER = gql`
  mutation deletePlayers($player_id: Int!) {
    update_statsbomb_player_by_pk(pk_columns: { player_id: $player_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;

const DELETE_PLAYER_BY_ID = gql`
  mutation deletePlayer($player_id: Int!) {
    delete_claim_player(where: { mapping_player: { player_id: { _eq: $player_id } } }) {
      affected_rows
    }
    delete_mapping_player(where: { player_id: { _eq: $player_id } }) {
      affected_rows
    }
    delete_statsbomb_player(where: { player_id: { _eq: $player_id } }) {
      affected_rows
    }
  }
`;

const PLAYERS = gql`
  query getAllPlayers {
    statsbomb_player(where: { deleted: { _eq: false } }, order_by: { player_name: asc }) {
      player_id
      player_name
      player_nickname
      player_is_female
      nationality_statsbomb_area {
        area_code
      }
    }
  }
`;

const GET_PLAYER_BY_ID = gql`
  query getPlayerById($player_id: Int!) {
    statsbomb_player(where: { player_id: { _eq: $player_id } }) {
      player_id
      player_name
      player_nickname
      player_is_female
      nationality_statsbomb_area {
        area_code
      }
    }
  }
`;
const GET_PLAYERS_BY_ID = gql`
  query getPlayersById($entityIds: [Int!]) {
    statsbomb_golden_player(where: { player_id: { _in: $entityIds } }) {
      player_id
      player_name
      player_firstname
      player_lastname
      player_nickname
      player_date_of_birth
      player_nationality
      nationality_name
      nationality_code
      player_country_of_birth
      country_of_birth_name
      country_of_birth_code
      player_weight
      player_height
      player_preferred_foot
      player_is_female
      player_is_retired
    }
  }
`;

export {
  SIMILAR_PLAYERS,
  GOLDEN_PLAYERS,
  GOLDEN_PLAYERS_COUNT,
  INSERT_SOURCE_PLAYER,
  DELETE_PLAYER,
  PLAYERS,
  GET_PLAYER_BY_ID,
  GET_PLAYERS_BY_ID,
  DELETE_PLAYER_BY_ID,
};
