import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING } from '../../hasura/queries/competitionSeason/competitionSeasons';
import CompetitionSeasonSelector from './competitionSeasonSelector';

const CompetitionSeasonOverride = ({ overrideChange, value, columnKey, provider }) => {
  const [insertCompetitionSeasonSourceAndMapping] = useMutation(INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [input, setInput] = useState('');

  const handleOnChange = async compSzn => {
    await insertCompetitionSeasonSourceAndMapping({
      variables: {
        provider_competition_season_id: compSzn.competition_season_id.toString(),
        provider: provider,
        competition_season_id: compSzn.competition_season_id,
        competition_season_name: compSzn.competition_season_name,
        competition_id: compSzn.competition_id.toString(),
        season_id: compSzn.season_id,
      },
    });

    overrideChange(columnKey, compSzn.competition_season_id.toString());
  };

  const handleOnInputChange = value => setInput(value);

  return (
    <div style={{ marginTop: 20 }}>
      <CompetitionSeasonSelector onChange={handleOnChange} onChangeInput={handleOnInputChange} {...{ input, value }} />
    </div>
  );
};

export { CompetitionSeasonOverride };
