import React, { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { claimSections } from '../../util/schema';
import { useAllClaimsCount } from '../../components/util/claims';
import Tabs from '../../components/util/tabs';
import { capitalise } from '../../util/string';
import Page from '../../components/view/Page';
import * as ClaimComponents from './entityClaims';

const Claims = () => {
  const location = useLocation();
  const locationPath = location.pathname.replace('/claims/', '');

  const [type, setType] = useState(locationPath);
  useEffect(() => setType(locationPath), [locationPath]);

  const claimsCount = useAllClaimsCount(claimSections.map(t => t.type));

  return (
    <>
      <Tabs
        source={'claims'}
        type={type}
        tabs={claimSections.map(t => ({
          ...t,
          path: t.claimPath,
          claimsCount: claimsCount?.[t.type],
        }))}
      />
      {claimSections.map((c, i) => (
        <Route
          path={c.claimPath}
          key={i}
          exact={true}
          render={() => {
            const Component =
              ClaimComponents[`${capitalise(c.type.replace(/_(\w)/, (_, letter) => letter.toUpperCase()))}Claims`]; // eslint-disable-line import/namespace
            return (
              <Page title={capitalise(c.type)}>
                <Component claimsCount={claimsCount?.[c.type]} />
              </Page>
            );
          }}
        />
      ))}
    </>
  );
};

export default Claims;
