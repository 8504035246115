import gql from 'graphql-tag';

const MAP_TO_STADIUM = gql`
  mutation MapToStadium($stadium_id: Int!, $provider: source_provider_enum!, $provider_stadium_id: String!) {
    insert_mapping_stadium(
      objects: { stadium_id: $stadium_id, provider: $provider, provider_stadium_id: $provider_stadium_id }
    ) {
      returning {
        stadium_id
        provider
        provider_stadium_id
      }
    }
  }
`;

const MAKE_AND_MAP_STADIUM = gql`
  mutation MakeAndMapStadium($stadium_name: String!, $provider: source_provider_enum!, $provider_stadium_id: String!) {
    insert_mapping_stadium(
      objects: {
        statsbomb_stadium: { data: { stadium_name: $stadium_name } }
        provider: $provider
        provider_stadium_id: $provider_stadium_id
      }
    ) {
      returning {
        stadium_id
        provider
        provider_stadium_id
      }
    }
  }
`;

const VIEW_STADIUM_MAPPINGS = gql`
  subscription viewTeamMappings($id: Int!) {
    mapping_stadium(where: { stadium_id: { _eq: $id } }) {
      source_stadium {
        provider
        provider_stadium_id
        stadium_name
        area_id
        stadium_city
        stadium_capacity
        stadium_pitch_x
        stadium_pitch_y
      }
    }
  }
`;

const DELETE_STADIUM_MAPPING = gql`
  mutation deleteTeamMappings(
    $claim_data: claim_stadium_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_stadium_one(object: $claim_data, on_conflict: { constraint: stadium_pkey, update_columns: [] }) {
      provider
      provider_stadium_id
    }

    delete_mapping_stadium_by_pk(provider: $provider, provider_stadium_id: $provider_id) {
      stadium_id
      provider_stadium_id
      provider
    }
  }
`;

export { MAP_TO_STADIUM, MAKE_AND_MAP_STADIUM, VIEW_STADIUM_MAPPINGS, DELETE_STADIUM_MAPPING };
