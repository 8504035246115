import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Select, MenuItem } from '@material-ui/core';

import { SmallButton } from '../util/buttons';
import { InlineProgress } from '../util/progress';

const EntityTypeSelectionOverride = ({ id, overrideChange, query, typeTablePath }) => {
  const { loading, data } = useQuery(query);
  if (loading) return <InlineProgress />;

  const entityTypes = data[typeTablePath] || [];
  return (
    <div style={{ display: 'inline-block' }}>
      {entityTypes.length < 4 ? (
        entityTypes.map(({ selection_type, display_name }) => (
          <SmallButton
            data-entity-id={id}
            data-action={selection_type}
            color="secondary"
            onClick={() =>
              overrideChange({
                id,
                selectionType: selection_type,
              })
            }
            key={selection_type}
            text={display_name || selection_type}
          />
        ))
      ) : (
        <Select
          style={{ width: '100%', display: 'block' }}
          defaultValue="placeholder"
          onChange={e => overrideChange({ id, selectionType: e.target.value })}>
          <MenuItem disabled value="placeholder">
            Select Role
          </MenuItem>
          {entityTypes.map(({ selection_type, display_name }) => (
            <MenuItem key={selection_type} value={selection_type}>
              {display_name || selection_type}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};

export { EntityTypeSelectionOverride };
