import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import EntityScraping from '../components/scraping/scrapeOptions';

const styles = {
  root: {
    padding: 20,
  },
};

const ScrapeView = ({ classes }) => (
  <section className={classes.root}>
    <EntityScraping />
  </section>
);

export default withStyles(styles)(ScrapeView);
