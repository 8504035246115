import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { COMPETITION_LADDERS } from '../../hasura/queries/competition/competitionLadder';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const CompetitionLadder = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(COMPETITION_LADDERS, {});

  if (loading) return <InlineProgress />;

  const competitionLadders = data?.statsbomb_competition_ladder || [];

  return (
    <>
      <Error message="Error fetching Competition Ladders" error={error} />

      <InputLabel id="comp-ladder-input">Ladder</InputLabel>
      <Select
        onChange={e => overrideChange(columnKey, e.target.value)}
        value={currentValue}
        labelId="comp-ladder-input">
        {competitionLadders.map((compLadders, key) => (
          <MenuItem key={key} value={compLadders.competition_ladder_id}>
            {compLadders.competition_ladder_name} ({compLadders.competitions_are_female ? 'F' : 'M'})
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { CompetitionLadder };
