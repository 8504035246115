import gql from 'graphql-tag';

const MAP_TO_AREA = gql`
  mutation MyMutation($area_id: Int!, $provider: source_provider_enum!, $provider_area_id: String!) {
    insert_mapping_area(objects: { area_id: $area_id, provider: $provider, provider_area_id: $provider_area_id }) {
      returning {
        area_id
        provider
        provider_area_id
      }
    }
  }
`;

const MAKE_AND_MAP_AREA = gql`
  mutation MyMutation($area_name: String!, $provider: source_provider_enum!, $provider_area_id: String!) {
    insert_mapping_area(
      objects: {
        statsbomb_area: { data: { area_name: $area_name } }
        provider: $provider
        provider_area_id: $provider_area_id
      }
    ) {
      returning {
        area_id
        provider
        provider_area_id
      }
    }
  }
`;

const VIEW_AREA_MAPPINGS = gql`
  subscription viewAreaMappings($id: Int!) {
    mapping_area(where: { area_id: { _eq: $id } }) {
      source_area {
        area_name
        area_code
        provider
        provider_area_id
      }
    }
  }
`;

const DELETE_AREA_MAPPINGS = gql`
  mutation deleteAreaMappings(
    $claim_data: claim_area_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_area_one(object: $claim_data, on_conflict: { constraint: area_pkey, update_columns: [] }) {
      provider
      provider_area_id
    }

    delete_mapping_area_by_pk(provider: $provider, provider_area_id: $provider_id) {
      area_id
      provider_area_id
      provider
    }
  }
`;

export { MAP_TO_AREA, MAKE_AND_MAP_AREA, VIEW_AREA_MAPPINGS, DELETE_AREA_MAPPINGS };
