import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { TableRowWithNote, TableCell } from '../table/table';
import { Error } from '../util/notifications';
import { FlagIcon } from '../util/icon';
import { capitalise, pluralise } from '../../util/string';
import { EntityTypeSelectionOverride } from '../override/entityTypeSelection';
import { InlineProgress } from '../util/progress';
import { SmallButton } from '../util/buttons';
import { useDebouncedCallback } from '../util/customHooks';

const useStyles = makeStyles(theme => {
  return {
    name: {
      paddingLeft: 10,
    },
    option: {
      fontSize: 10,
      '& > span': {
        marginRight: 10,
        fontSize: 14,
      },
    },
    root: {
      fontSize: 12,
      '& label': {
        fontSize: 12,
      },
    },
    dialogPaper: {
      minHeight: '80vh',
      maxHeight: '80vh',
    },
    selected: { backgroundColor: theme.palette.secondary.dark },
  };
});

const DialogContentLayout = ({
  suggestedEntitiesQuery,
  entityType,
  classes,
  overrideChange,
  lineupSelectionQuery,
  typeTablePath,
  idsToExclude,
  flagIconPath,
  setOpen,
}) => {
  const handleSearchStringChangeDebounced = useDebouncedCallback(
    string => setSearchStringWithDelayedUpdate(string),
    1500
  );

  const [fullSearchString, setFullSearchString] = useState('');
  const [searchStringWithDelayedUpdate, setSearchStringWithDelayedUpdate] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);

  const { loading, error, data } = useQuery(suggestedEntitiesQuery, {
    variables: {
      search_string: `%${searchStringWithDelayedUpdate}%`,
      search_int: parseInt(searchStringWithDelayedUpdate) ? parseInt(searchStringWithDelayedUpdate) : 0,
    },
  });

  if (loading) return <InlineProgress />;

  const handleSearchStringChange = searchString => {
    setFullSearchString(searchString);
    handleSearchStringChangeDebounced(searchString);
  };

  const handleTypeSelection = ({ selectionType }) => {
    overrideChange({
      id: selectedEntity[`${entityType}_id`],
      name: selectedEntity[`${entityType}_name`],
      selectionType,
    });
    setOpen(false);
  };

  return (
    <>
      <Error message={`Error fetching ${pluralise(entityType)}`} error={error} />

      <TextField
        style={{ paddingBottom: '20px', minWidth: '75%' }}
        autoFocus={true}
        placeholder={`${capitalise(entityType)} Name`}
        value={fullSearchString}
        onChange={({ target: { value } }) => handleSearchStringChange(value)}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell />
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data[`statsbomb_${entityType}`] && data[`statsbomb_${entityType}`].length > 0 ? (
            data[`statsbomb_${entityType}`]
              .filter(entity => !_.includes(idsToExclude, entity[`${entityType}_id`]))
              .map(entity => (
                <TableRow
                  key={entity[`${entityType}_id`]}
                  onClick={() => setSelectedEntity(entity)}
                  className={
                    selectedEntity && entity[`${entityType}_id`] === selectedEntity[`${entityType}_id`]
                      ? classes.selected
                      : ''
                  }>
                  <TableCell>{entity[`${entityType}_id`]}</TableCell>
                  <TableCell>
                    <FlagIcon code={entity[flagIconPath.path]?.['area_code'] || flagIconPath.altPath} />
                  </TableCell>
                  <TableCell>
                    {entity[`${entityType}_name`]}
                    {entity[`${entityType}_date_of_birth`] ? ` (${entity[`${entityType}_date_of_birth`]})` : ''}
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRowWithNote note={`${capitalise(pluralise(entityType))} will appear here`} />
          )}
        </TableBody>
      </Table>
      <DialogActions>
        {selectedEntity && (
          <EntityTypeSelectionOverride
            overrideChange={handleTypeSelection}
            query={lineupSelectionQuery}
            typeTablePath={typeTablePath}
          />
        )}
      </DialogActions>
    </>
  );
};

const SearchEntityWithExclusions = ({
  overrideChange,
  entitiesToExclude,
  suggestedEntitiesQuery,
  entityType,
  flagIconPath,
  typeTablePath,
  lineupSelectionQuery,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const idsToExclude = entitiesToExclude.map(entity => parseInt(entity[`${entityType}_id`]));

  return (
    <>
      <SmallButton color="secondary" onClick={() => setOpen(true)} text={`Find ${pluralise(capitalise(entityType))}`} />
      <Dialog open={open} classes={{ paper: classes.dialogPaper }} onBackdropClick={() => setOpen(false)} fullWidth>
        <DialogTitle id="round-dialog-title">{`Find ${pluralise(capitalise(entityType))}`}</DialogTitle>
        <DialogContent>
          {
            <DialogContentLayout
              {...{
                suggestedEntitiesQuery,
                entityType,
                classes,
                overrideChange,
                lineupSelectionQuery,
                typeTablePath,
                idsToExclude,
                flagIconPath,
                setOpen,
              }}
            />
          }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchEntityWithExclusions;
