import React, { useState } from 'react';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import {
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import { Progress } from '../util/progress';
import { EntityLinkTable } from '../table/entityLinkTable';

const MappingView = ({
  schema,
  entityKey,
  type,
  recordId,
  subscription,
  deleteMutation,
  deleteEntityMutation,
  styles: { tableContatinerClasses, buttonClasses },
}) => {
  const [selectedMapping, setSelectedMapping] = useState({
    provider: '',
    provider_type_id: null,
  });

  const [showDialog, setShowDialog] = useState(false);
  const [deleteMappingMutation] = useMutation(deleteMutation);

  const { loading, data } = useSubscription(subscription, {
    variables: {
      id: recordId,
    },
  });

  if (loading) return <Progress />;

  const mappings = data[`mapping_${type}`].map(current => current[`source_${type}`]);

  const openDialog = () => {
    if (selectedMapping.provider_type_id === null) return;
    setShowDialog(true);
  };

  const deleteMappingFn = async () => {
    const { provider, provider_type_id } = selectedMapping;
    const claimObject = mappings.filter(
      item => item.provider === provider && item[`provider_${type}_id`] === provider_type_id
    )[0];

    await deleteMappingMutation({
      variables: {
        claim_data: claimObject,
        provider: provider,
        provider_id: provider_type_id,
      },
    });
    setShowDialog(false);
  };

  const selectMapping = mapping => {
    setSelectedMapping({
      provider: mapping.provider,
      provider_type_id: mapping[`provider_${type}_id`],
    });
  };

  const infoMapping = selectedMapping.provider === 'Info_Team';

  return (
    <TableContainer classes={tableContatinerClasses} component={Paper}>
      <h3>Mappings</h3>
      <EntityLinkTable
        entities={mappings}
        rowClickHandler={selectMapping}
        selectedEntityKey={selectedMapping.provider_type_id}
        dontUseMappedFunction
        {...{ deleteEntityMutation, schema, entityKey, type }}
      />

      <Dialog
        PaperComponent={Paper}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="mapping-dialog-title"
        aria-describedby="mapping-dialog-description">
        <DialogTitle id="mapping-dialog-title">Delete Mapping</DialogTitle>
        <DialogContent id="mapping-dialog-description">
          <DialogContentText>Are you sure you want to delete the mapping</DialogContentText>
          <DialogContentText>
            Provider: {selectedMapping.provider}, Provider Id: {selectedMapping.provider_type_id}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={buttonClasses.button}
            style={{ backgroundColor: 'red' }}
            variant="contained"
            color="primary"
            onClick={deleteMappingFn}>
            Yes
          </Button>
          <Button
            className={buttonClasses.button}
            variant="contained"
            color="primary"
            onClick={() => setShowDialog(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        className={buttonClasses.button}
        disabled={infoMapping}
        variant="contained"
        color="primary"
        onClick={openDialog}>
        {infoMapping ? "Can't Delete Info Mapping" : 'Delete Selected Mapping'}
      </Button>
    </TableContainer>
  );
};

export default MappingView;
