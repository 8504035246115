import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Error } from '../util/notifications';
import { InlineProgress } from '../util/progress';
import { ROUNDS_BY_PHASE, INSERT_ROUND_SOURCE_AND_MAPPING } from '../../hasura/queries/round/round';
import { CompetitionSeasonOverride } from './competitionSeasonModal';
import { PhaseByCompSznOverride } from './phaseModal';

const useStyles = makeStyles({
  root: {
    fontSize: 14,
  },
  select: {
    fontSize: 14,
  },
});

const RoundByPhaseOverride = ({ overrideChange, value, phaseId }) => {
  const { loading, error, data } = useQuery(ROUNDS_BY_PHASE, {
    variables: {
      phase_id: phaseId,
    },
  });

  const classes = useStyles();

  if (loading) return <InlineProgress />;

  const rounds = data?.statsbomb_round || [];

  const onChange = ({ obj, value, name }) => overrideChange({ value, name, obj });

  return (
    <div style={{ marginTop: 20 }}>
      <Error message="Error fetching Rounds By Phases" error={error} />

      <InputLabel id="round-input">Round</InputLabel>
      <Select
        placeholder="Select Round"
        onChange={(e, round) => onChange(round.props)}
        value={value}
        labelId="round-input"
        classes={{ select: classes.select, root: classes.root }}>
        {rounds.map(round => (
          <MenuItem
            key={round.round_id}
            obj={round}
            value={round.round_id}
            name={`${round.statsbomb_round_type.round_type_name} ${
              round.statsbomb_round_structure?.round_structure_name || ''
            }`}>
            {round.statsbomb_round_type.round_type_name} {round.statsbomb_round_structure?.round_structure_name || ''}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const RoundModalOverride = ({ overrideChange, columnKey, provider }) => {
  const [open, setOpen] = useState(false);

  // Empty States
  const emptyCompSzn = {
    competition_season_id: null,
    statsbomb_season: {
      season_name: '',
    },
    statsbomb_competition: {
      competition_name: '',
    },
  };
  const emptyPhase = { value: null, name: null };
  const emptyRound = { value: null, name: null, obj: null };

  // Temporary states before saving
  const [compSznState, setCompSznState] = useState(emptyCompSzn);
  const [phaseState, setPhaseState] = useState(emptyPhase);
  const [roundState, setRoundState] = useState(emptyRound);

  // Saved state: when save is clicked
  const [savedState, setSavedState] = useState({
    saved: false,
    compSzn: emptyCompSzn,
    phase: emptyPhase,
    round: emptyRound,
  });

  const [insertRoundSourceAndMapping] = useMutation(INSERT_ROUND_SOURCE_AND_MAPPING, {
    onError: () => {},
    onCompleted: () => {},
  });

  const handleSave = async () => {
    setSavedState({
      saved: true,
      compSzn: compSznState,
      phase: phaseState,
      round: roundState,
    });

    await insertRoundSourceAndMapping({
      variables: {
        provider_round_id: roundState.value.toString(),
        provider: provider,
        round_id: roundState.value,
        phase_id: phaseState.value.toString(),
        round_type_id: roundState.obj.statsbomb_round_type.round_type_id,
        round_structure_id: roundState.obj.statsbomb_round_structure?.round_structure_id,
      },
    });

    overrideChange(columnKey, roundState.value.toString());
    setOpen(false);
  };

  const handleCancel = () => {
    // If there's a saved state, set state to the saved state else reset
    if (savedState.saved !== false) {
      setCompSznState(savedState.compSzn);
      setPhaseState(savedState.phase);
      setRoundState(savedState.round);
    } else resetCompSznState();

    setOpen(false);
  };

  const resetCompSznState = () => {
    setCompSznState(emptyCompSzn);
    resetPhaseState();
  };

  const resetPhaseState = () => {
    setPhaseState(emptyPhase);
    resetRoundState();
  };

  const resetRoundState = () => setRoundState(emptyRound);

  const displayRoundName = (compSzn, phase, round) =>
    `${compSzn.statsbomb_season.season_name} ${compSzn.statsbomb_competition.competition_name}, ${phase.name}, ${round.name}`;

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {!savedState.saved ? `Select Round` : `${displayRoundName(compSznState, phaseState, roundState)}`}
      </Button>
      <Dialog open={open} aria-labelledby="round-dialog-title" maxWidth="sm" fullWidth disableBackdropClick>
        <DialogTitle id="round-dialog-title">Select Round</DialogTitle>
        <DialogContent>
          <CompetitionSeasonOverride
            overrideChange={setCompSznState}
            resetChildState={resetPhaseState}
            value={compSznState}
            provider={provider}
          />
          {compSznState.competition_season_id != null && (
            <PhaseByCompSznOverride
              overrideChange={setPhaseState}
              resetChildState={resetRoundState}
              value={phaseState.value}
              compSznId={compSznState.competition_season_id}
              provider={provider}
            />
          )}
          {phaseState.value != null && (
            <RoundByPhaseOverride overrideChange={setRoundState} value={roundState.value} phaseId={phaseState.value} />
          )}
        </DialogContent>
        <DialogActions>
          {roundState.value == null ? (
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
          ) : (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export { RoundModalOverride, RoundByPhaseOverride };
