import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div>
        <h2>Gatekeeper</h2>
        <p>Sorry, this page probably hasn't been added yet</p>
      </div>
    );
  }
}
