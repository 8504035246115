import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { MANAGER_SCHEMA, MANAGER_CLAIM_SCHEMA, MAPPED_MANAGER_SCHEMA } from '../schema/managerSchema';
import {
  GOLDEN_MANAGER_AND_CLAIMS,
  SAVE_MANAGER_CLAIM,
  OVERRIDE_MANAGER_CLAIM,
  INSERT_GOLDEN_MANAGER,
} from '../hasura/queries/manager/managerClaims';
import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import { ClaimFilters } from '../components/claim/claimFilters';
import ClaimHistoryTable from '../components/table/claimHistoryTable';
import { Progress } from '../components/util/progress';
import { Error } from '../components/util/notifications';
import { SIMILAR_MANAGERS, DELETE_MANAGER } from '../hasura/queries/manager/managers';
import { DeleteDialog } from '../components/util/DeleteDialog';
import { blankSchema } from '../util/schema';
import { LinkButton } from '../components/util/buttons';
import MappingView from '../components/mapping/mappingView';
import { DELETE_MANAGER_MAPPING, VIEW_MANAGER_MAPPINGS } from '../hasura/queries/manager/managerMapping';
import { SimilarEntity } from '../components/mapping/SimilarEntity';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenManagerView = ({ managerId, isNewManager, history }) => {
  const historyPath = history.location.search;

  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewManager ? blankSchema(MANAGER_SCHEMA) : {});
  const [overrides, setOverrides] = useState({});
  const [claimMode, setClaimMode] = useState(isNewManager ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);
  const [open, setOpen] = useState(false);

  const setNewEntityMode = () => {
    setClaimMode(CLAIM_MODE_NEW_ENTITY);
    setClaimStatuses(blankSchema(MANAGER_SCHEMA));
  };

  const setViewMode = () => {
    setClaimMode(CLAIM_MODE_VIEW);
    setClaimStatuses({});
  };

  useEffect(() => (historyPath === '?new=new' ? setNewEntityMode() : setViewMode()), [historyPath]);

  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loading, error, data, refetch } = useQuery(GOLDEN_MANAGER_AND_CLAIMS, {
    variables: {
      manager_id: managerId,
    },
  });

  if (loading) return <Progress />;
  let allClaims = [];
  data &&
    data.statsbomb_manager &&
    data.statsbomb_manager[0] &&
    data.statsbomb_manager[0].mapping_managers.forEach(mappingEntry => {
      allClaims = [...allClaims, ...mappingEntry.claim_managers];
    });

  let filteredClaims = allClaims;
  if (claimFilter === 'undecided') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }
  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_manager_uuid === claimUUID;
    })[0];
    if (claim) {
      MANAGER_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    MANAGER_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(blankSchema(MANAGER_SCHEMA));
  };

  const getGoldenManagerId = insertedManager =>
    insertedManager?.data?.insert_statsbomb_manager?.returning?.[0]?.manager_id;

  const claimSelected = claim => {
    selectAClaim(claim.claim_manager_uuid);
  };

  return (
    <>
      <Error message="" error={error} />
      <div className="container-row">
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={6}>
            <h2>Manager Singular: {isNewManager ? ' New Manager' : managerId}</h2>
          </Grid>
          <Grid item xs={6}>
            {claimMode !== CLAIM_MODE_NEW_ENTITY && (
              <Link
                to={{
                  pathname: `/transfer-history`,
                  search: `?manager_id=${managerId}`,
                }}
                component={LinkButton}
                label="View Transfer History"
              />
            )}
          </Grid>
        </Grid>

        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="manager"
              schema={MANAGER_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{ headerValue: 'Manager Name', bodyValue: data.statsbomb_manager[0]?.manager_name }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <MappingView
            type="manager"
            recordId={managerId}
            schema={MAPPED_MANAGER_SCHEMA}
            subscription={VIEW_MANAGER_MAPPINGS}
            deleteMutation={DELETE_MANAGER_MAPPING}
            deleteEntityMutation={DELETE_MANAGER}
            entityKey="provider_manager_id"
            styles={{ tableContatinerClasses, buttonClasses }}
          />
        )}
        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Manager Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={data.statsbomb_manager[0]}
            claim={
              allClaims.filter(c => {
                return c.claim_manager_uuid === selectedClaimId;
              })[0]
            }
            rowSchema={MANAGER_SCHEMA}
            saveMutation={SAVE_MANAGER_CLAIM}
            overrideMutation={OVERRIDE_MANAGER_CLAIM}
            createMutation={INSERT_GOLDEN_MANAGER}
            sourcePK="provider_manager_id"
            claimPK="claim_manager_uuid"
            goldenEntityPK="manager_id"
            getGoldenEntityId={getGoldenManagerId}
            entityType="manager"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
            similarEntityTable={SimilarEntity}
            similarEntityQuery={SIMILAR_MANAGERS}
            similarEntityQueryObject="statsbomb_similar_managers"
            {...{
              claimStatuses,
              setClaimStatuses,
              selectAClaim,
              overrides,
              setOverrides,
              claimMode,
              refetch,
            }}
          />
        </TableContainer>
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              className={buttonClasses.clearButton}
              disabled={data.statsbomb_manager[0]?.deleted}
              variant="contained">
              {data.statsbomb_manager[0]?.deleted ? 'Deleted' : 'Delete'}
            </Button>
            <DeleteDialog
              open={open}
              handleClose={() => setOpen(false)}
              deleteEntityMutation={DELETE_MANAGER}
              variables={{ manager_id: managerId }}
            />
          </>
        )}
      </div>
    </>
  );
};

const Manager = ({ history }) => {
  const query = new URLSearchParams(useLocation().search);
  const isNewManager = query.get('new');
  const managerId = isNewManager ? '0' : query.get('manager_id');

  return <GoldenManagerView isNewManager={!!isNewManager} {...{ managerId, history }} />;
};

export default Manager;
