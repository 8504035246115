import { useCallback, useRef } from 'react';
import _ from 'lodash';

const useDebouncedCallback = (callback, delay) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    _.debounce((...args) => callbackRef.current(...args), delay),
    []
  );
};

const navigateToEntity = (entity, id, history) => {
  history.push(`/${entity}?${entity}_id=${id}`);
};

export { useDebouncedCallback, navigateToEntity };
