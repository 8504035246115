import gql from 'graphql-tag';

const GOLDEN_MATCHES = gql`
  query getGoldenMatches(
    $name: String
    $home_team: String
    $away_team: String
    $competition_season: Int
    $min_iq: Boolean
    $start_date: date
    $end_date: date
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_match_order_by!
  ) {
    statsbomb_golden_match(
      where: {
        _and: [
          {
            home_team_name: { _eq: $home_team }
            away_team_name: { _eq: $away_team }
            competition_season_id: { _eq: $competition_season }
            match_date: { _gte: $start_date, _lte: $end_date }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      match_id
      deleted
      match_name
      match_home_team_id
      match_away_team_id
      home_team_name
      away_team_name
      match_date
      match_local_kick_off
      stadium_id
      match_week
      min_spec
    }
  }
`;

const GOLDEN_MATCHES_COUNT = gql`
  query getGoldenMatchesCount(
    $name: String
    $home_team: String
    $away_team: String
    $competition_season: Int
    $min_iq: Boolean
    $deleted: Boolean = false
    $start_date: date
    $end_date: date
  ) {
    statsbomb_golden_match_aggregate(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            home_team_name: { _eq: $home_team }
            away_team_name: { _eq: $away_team }
            competition_season_id: { _eq: $competition_season }
            match_date: { _gte: $start_date, _lte: $end_date }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const MATCH_PLAY_STATUS = gql`
  query getAllMatchPlayStatus {
    statsbomb_match_play_status {
      match_play_status
    }
  }
`;

// $n is matchName and $p is the provider.
const SIMILAR_MATCHES = gql`
  query similarMatches($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_matches(args: { n: $name, p: $provider, o: $offset }) {
      match_id
      match_name
      name_similarity
      mapped
      match_date
      home_team_name
      away_team_name
    }
  }
`;
const DELETE_MATCH = gql`
  mutation DeleteMatches($match_id: Int!) {
    update_statsbomb_match_by_pk(pk_columns: { match_id: $match_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;

const GET_MATCHES_BY_ID = gql`
  query getMatchesByIds($entityIds: [Int!]) {
    statsbomb_golden_match(where: { match_id: { _in: $entityIds } }) {
      match_id
      match_name
      match_home_team_id
      match_away_team_id
      home_team_name
      away_team_name
      match_date
      stadium_id
      match_week
    }
  }
`;

const DELETE_MATCH_BY_ID = gql`
  mutation deleteMatch($match_id: Int!, $match_name: String!) {
    delete_statsbomb_match_review: delete_statsbomb_match(where: { match_name: { _eq: $match_name } }) {
      affected_rows
    }
    delete_statsbomb_match_manager(where: { match_id: { _eq: $match_id } }) {
      affected_rows
    }
    delete_statsbomb_match_official(where: { match_id: { _eq: $match_id } }) {
      affected_rows
    }
    delete_claim_match(where: { mapping_match: { match_id: { _eq: $match_id } } }) {
      affected_rows
    }
    delete_mapping_match(where: { match_id: { _eq: $match_id } }) {
      affected_rows
    }
    delete_statsbomb_match(where: { match_id: { _eq: $match_id } }) {
      affected_rows
    }
  }
`;

const DELETE_MATCH_MANAGER_BY_ID = gql`
  mutation deleteMatchManager($match_id: Int!, $manager_id: Int!) {
    delete_statsbomb_match_manager(where: { match_id: { _eq: $match_id }, manager_id: { _eq: $manager_id } }) {
      affected_rows
    }
  }
`;

const DELETE_MATCH_OFFICIAL_BY_ID = gql`
  mutation deleteMatchOfficial($match_id: Int!, $official_id: Int!) {
    delete_statsbomb_match_official(where: { match_id: { _eq: $match_id }, official_id: { _eq: $official_id } }) {
      affected_rows
    }
  }
`;

export {
  GOLDEN_MATCHES,
  GOLDEN_MATCHES_COUNT,
  MATCH_PLAY_STATUS,
  SIMILAR_MATCHES,
  DELETE_MATCH,
  GET_MATCHES_BY_ID,
  DELETE_MATCH_BY_ID,
  DELETE_MATCH_MANAGER_BY_ID,
  DELETE_MATCH_OFFICIAL_BY_ID,
};
