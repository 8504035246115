import React from 'react';
import { TableCell, Checkbox, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DateValue } from '../../util/date';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    padding: 6,
    fontWeight: 700,
    fontSize: 12,
  },
  body: {
    padding: 6,
    fontSize: 10.5,
  },
}))(TableCell);

const isSomething = value => value != null;

const getIcon = (entity, icon, mappedIcon) => {
  if (icon !== undefined) {
    return icon(entity);
  }
  if (mappedIcon !== undefined) {
    return mappedIcon(entity);
  }
  return <></>;
};

const TableRowWithNote = ({ note }) => (
  <TableRow>
    <TableCell>{note}</TableCell>
  </TableRow>
);

const CellContent = ({ entity, columnKey, type, mappingFn, icon, mappedIcon, dontUseMappedFunction }) => {
  let populatedIcon = getIcon(entity, icon, mappedIcon);
  let claimValueFormatted = null;
  const value = mappingFn && !dontUseMappedFunction ? mappingFn(entity) : entity[columnKey];
  if (isSomething(value)) {
    //has to be !=null because False is a valid claim value
    switch (type) {
      case 'Date':
        claimValueFormatted = (
          <DateValue withoutTZ dateTime={false}>
            {value}
          </DateValue>
        );
        break;
      case 'Timestamp':
        claimValueFormatted = <DateValue dateTime>{value}</DateValue>;
        break;
      case 'Boolean':
        claimValueFormatted = <Checkbox disabled readOnly checked={!!value} />;
        break;
      default:
        claimValueFormatted = value;
    }
  }
  return (
    <>
      {populatedIcon}
      {claimValueFormatted}
    </>
  );
};

export { StyledTableCell as TableCell, CellContent, isSomething, TableRowWithNote };
