import React from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { TableRow, Table, TableHead, TableBody, TableContainer, makeStyles, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { EntityTypeSelectionOverride } from '../override/entityTypeSelection';
import { TableCell } from '../table/table';
import { InlineProgress } from '../util/progress';
import { capitalise, pluralise } from '../../util/string';
import { navigateToEntity } from '../../components/util/customHooks';
import { FlagIcon } from '../util/icon';

const useStyles = makeStyles({
  tableHeight: {
    maxHeight: 500,
  },
});

const SuggestedEntities = ({
  filterVariables,
  loading,
  suggestedEntitiesQuery,
  suggestedEntitiesVariables,
  suggestedEntityLineupData,
  createClaimFn,
  entityType,
  typeTablePath,
  lineupSelectionQuery,
  entitiesToExclude,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = useQuery(suggestedEntitiesQuery, {
    variables: suggestedEntitiesVariables,
  });
  const suggestedData = data && data[suggestedEntityLineupData];

  const idsToExclude =
    entityType !== 'team' ? entitiesToExclude.map(entity => parseInt(entity[`${entityType}_id`])) : [];

  const suggestionsForEntity = () => {
    if (!suggestedData) return {};
    const excludedEntities = suggestedData.filter(entity => !_.includes(idsToExclude, entity[`${entityType}_id`]));
    if (entityType === 'player' || entityType === 'manager') {
      return excludedEntities.filter(entity => entity.team_id === filterVariables.selectedTeam);
    }
    return excludedEntities;
  };

  const suggestedEntities = suggestionsForEntity();

  return (
    <div>
      <h4>Suggested {pluralise(capitalise(entityType))}</h4>
      <TableContainer className={classes.tableHeight}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Nationality</TableCell>
              {entityType === 'player' && <TableCell style={{ textAlign: 'center' }}>Common #</TableCell>}
              <TableCell>{`${capitalise(entityType)} Role`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suggestedEntities && suggestedEntities.length ? (
              suggestedEntities.map(entity => (
                <TableRow key={entity[`${entityType}_id`]}>
                  <TableCell>{entity[`${entityType}_id`]}</TableCell>
                  <TableCell>
                    <Link
                      className="highlight"
                      onClick={() => navigateToEntity(entityType, entity[`${entityType}_id`], history)}>
                      {entity[`${entityType}_name`]}
                    </Link>{' '}
                  </TableCell>
                  <TableCell>{entity[`${entityType}_date_of_birth`]}</TableCell>
                  <TableCell>
                    <FlagIcon code={entity[`${entityType}_nationality_code`]} />
                    {entity[`${entityType}_nationality_name`]}
                  </TableCell>
                  {entityType === 'player' && (
                    <TableCell style={{ textAlign: 'center' }}>{entity.mode_jersey}</TableCell>
                  )}
                  <TableCell>
                    {loading ? (
                      <InlineProgress />
                    ) : (
                      <>
                        {lineupSelectionQuery && (
                          <EntityTypeSelectionOverride
                            overrideChange={value =>
                              createClaimFn({
                                ...value,
                                name: entity[`${entityType}_name`],
                                jerseyNumber: entity.mode_jersey,
                              })
                            }
                            id={entity[`${entityType}_id`]}
                            query={lineupSelectionQuery}
                            typeTablePath={typeTablePath}
                          />
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>Suggested {pluralise(capitalise(entityType))} will appear here</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SuggestedEntities;
