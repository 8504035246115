import React, { useState } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {
  TableContainer,
  Paper,
  Card,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TextField,
  Grid,
  makeStyles,
  withStyles,
  Link,
} from '@material-ui/core';

import Box from '@material-ui/core/Box';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { TableCell } from '../components/table/table';
import { Progress } from '../components/util/progress';

import CompetitionSeasonSelector from '../components/override/competitionSeasonSelector';
import { navigateToEntity } from '../components/util/customHooks';
import {
  SUGGESTED_TEAMS,
  INSERT_GOLDEN_TEAM_ROUND_GROUP,
  GOLDEN_GROUPS_AND_TEAMS,
  ALL_GROUP_NAME,
  DELETE_TEAM_ROUND_GROUP,
  OVERRIDE_TEAM_ROUND_GROUP_CLAIM,
} from '../hasura/queries/group/group';
import {
  useCompSznMapping,
  usePhaseMapping,
  useRoundMapping,
  useTeamMapping,
  fullNoOp,
} from '../components/util/mapping';
import { FlagIcon } from '../components/util/icon';
import { PhaseByCompSznSelector } from '../components/override/phaseModal';
import { RoundByPhaseOverride } from '../components/override/round';
import SuggestedEntities from '../components/fullLineup/suggestedEntities';

const useStyle = makeStyles(theme => ({
  card: {
    marginTop: 20,
    padding: 10,
    '& h3': {
      paddingLeft: 5,
    },
  },
  table: {
    marginTop: 20,
  },
  container: {
    padding: 10,
    margin: 10,
    marginBottom: 0,
    display: 'flow-root',
  },
  readonly: {
    color: theme.palette.secondary.dark,
    fontStyle: 'italic',
  },
  SuggestedTeamsAndGroups: {
    width: '100%',
  },
  GoldenGroupsTeams: {
    marginLeft: 'auto',
    order: 2,
    width: '500px',
    marginBottom: '10px',
  },
  change: { color: 'blue', textDecoration: 'underline' },
  required: { color: 'red', textDecoration: 'underline' },
  removeUnderline: { textDecoration: 'none' },
  hover: {
    '&:hover': {
      color: 'red',
      cursor: 'pointer',
    },
  },
}));
const StyledFlagIcon = withStyles({ root: { margin: 10, height: 22, width: 22 } })(FlagIcon);

function GetGroupsAndTeams(overrideChange) {
  const classes = useStyle();
  const history = useHistory();
  const [selectedGroups, setSelectedGroups] = useState({});
  const [key, setKey] = useState(0);
  const [deleteTeamRoundGroup] = useMutation(DELETE_TEAM_ROUND_GROUP, {
    onCompleted: () => setKey(key + 1),
  });

  const { loading } = useSubscription(GOLDEN_GROUPS_AND_TEAMS, {
    variables: {
      round_id: overrideChange.currentRound,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      setSelectedGroups(
        data &&
          data.statsbomb_team_round_group.reduce((acc, group) => {
            return {
              ...acc,
              [group.group_name]: (acc[group.group_name] || []).concat([group.statsbomb_team]),
            };
          }, {})
      );
    },
  });
  if (loading) return <Progress />;

  const deleteTeam = async team_id => {
    await deleteTeamRoundGroup({
      variables: {
        team_id: team_id,
      },
    });
  };

  return (
    <Box className={classes.SuggestedTeamsAndGroups}>
      <Grid container spacing={1} justify="center" alignItems="flex-start">
        <Grid item xs={7}>
          <Card className={classes.card}>
            {overrideChange.currentRound && (
              <SuggestedTeams
                key={key}
                selectedGroups={selectedGroups}
                roundId={overrideChange.currentRound}></SuggestedTeams>
            )}
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card className={classes.card}>
            {selectedGroups && Object.keys(selectedGroups).length > 0 ? (
              Object.keys(selectedGroups)
                .sort()
                .map(function (key, index) {
                  return (
                    <Box key={key}>
                      <h4>Group {key}</h4>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.reducedPadding}>Name</TableCell>
                            <TableCell className={classes.reducedPadding} style={{ textAlign: 'right' }}>
                              Remove from Group
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedGroups[key].map(team => {
                            return (
                              <TableRow key={team.team_id}>
                                <TableCell className={classes.reducedPadding}>
                                  <Link
                                    className="highlight"
                                    onClick={() => navigateToEntity('team', team.team_id, history)}>
                                    {team.team_name}
                                  </Link>
                                </TableCell>
                                <TableCell className={classes.reducedPadding} style={{ textAlign: 'right' }}>
                                  <HighlightOff
                                    aria-label="delete"
                                    fontSize="small"
                                    className={classes.hover}
                                    onClick={() => deleteTeam(team.team_id)}
                                  />{' '}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  );
                })
            ) : (
              <h3 style={{ textAlign: 'center' }}>No Groups created yet </h3>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
function SuggestedTeams({ roundId }) {
  const [createTeamRoundGroupClaim] = useMutation(OVERRIDE_TEAM_ROUND_GROUP_CLAIM, fullNoOp);
  const [insertTeamRoundGroup] = useMutation(INSERT_GOLDEN_TEAM_ROUND_GROUP, {
    onCompleted: () => setKey(key + 1),
  });
  const [key, setKey] = useState(0);

  const doTeamMapping = useTeamMapping();

  const createGroupClaim = async (id, name, selectionType) => {
    await insertTeamRoundGroup({
      variables: {
        round_id: roundId,
        group_name: selectionType,
        team_id: id.toString(),
      },
    });
    await createTeamRoundGroupClaim({
      variables: {
        round_id: roundId.toString(),
        team_id: id.toString(),
        group_name: selectionType.toString(),
        source_entity: {
          deleted: false,
          group_name: selectionType,
        },
      },
    });

    doTeamMapping(id, name);
  };

  return (
    <SuggestedEntities
      key={key}
      suggestedEntitiesVariables={{ round_id: roundId }}
      typeTablePath="statsbomb_group_name"
      suggestedEntitiesQuery={SUGGESTED_TEAMS}
      suggestedEntityLineupData="statsbomb_team_match_aggregate"
      createClaimFn={({ id, name, selectionType }) => createGroupClaim(id, name, selectionType)}
      entityType="team"
      lineupSelectionQuery={ALL_GROUP_NAME}></SuggestedEntities>
  );
}

function Group() {
  const query = new URLSearchParams(useLocation().search);
  if (!!query.get('new')) return <NewGroup />;

  const teamId = query.get('team_id');
  const roundId = query.get('round_id');
  if (!teamId || !roundId) return <Redirect to="/group?new=new" />;

  return <EditGroup {...{ teamId, roundId }} />;
}

const EditGroup = NewGroup;

function NewGroup() {
  return <GroupView {...{}} />;
}

function GroupView() {
  const classes = useStyle();
  const [competitionSeason, setCompetitionSeason] = useState({ competition_season_id: null });
  const [phase, setPhase] = useState({ phase_id: null, statsbomb_phase_type: null, phase_order: null });
  const [round, setRound] = useState({ value: null, name: null, obj: null });
  const doCompSznMapping = useCompSznMapping();
  const doPhaseMapping = usePhaseMapping();
  const doRoundMapping = useRoundMapping();
  const setCSResetPhaseRound = async compSeason => {
    doCompSznMapping(compSeason);
    setCompetitionSeason(compSeason);
    setPhase({ phase_id: null, statsbomb_phase_type: null, phase_order: null });
    setRound({ value: null, name: null, obj: null });
  };
  const setPhaseResetRound = async phase => {
    doPhaseMapping(phase, competitionSeason);
    setPhase(phase);
    setRound({ value: null, name: null, obj: null });
  };
  const setRoundByPhase = async round => {
    doRoundMapping(round, phase.phase_id);
    setRound(round.value);
  };

  return (
    <>
      <Card className={classes.card}>
        <Typography variant="h6" component="h3">
          Group
        </Typography>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="3">Groups</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan="2">Competition Season</TableCell>
                <TableCell align="right">
                  <div style={{ float: 'right', minWidth: 400 }}>
                    <CompetitionSeasonSelector value={competitionSeason} onChange={setCSResetPhaseRound} />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">Phase</TableCell>
                <TableCell align="right">
                  {competitionSeason?.competition_season_id && (
                    <PhaseByCompSznSelector
                      onChange={({ obj }) => setPhaseResetRound(obj)}
                      value={phase.phase_id || ''}
                      compSznId={competitionSeason.competition_season_id}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">Round</TableCell>
                <TableCell align="right">
                  {phase.phase_id && (
                    <RoundByPhaseOverride
                      overrideChange={round => setRoundByPhase(round)}
                      value={round}
                      phaseId={phase.phase_id}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2" className={classes.readonly}>
                  Area
                </TableCell>
                <TableCell align="right">
                  <StyledFlagIcon code={competitionSeason?.statsbomb_competition?.statsbomb_area?.area_code} />
                  <TextField
                    disabled
                    value={competitionSeason?.statsbomb_competition?.statsbomb_area?.area_name || ''}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {round.value !== null && <GetGroupsAndTeams currentRound={round}></GetGroupsAndTeams>}
    </>
  );
}

export default Group;
