import React, { useState } from 'react';
import { InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/react-hooks';
import { COMPETITION_SEASONS } from '../../hasura/queries/competitionSeason/competitionSeasons';
import { InlineProgress } from '../util/progress';
import { FlagIcon, GenderIcon } from '../util/icon';
import { Error } from '../util/notifications';

const useStyles = makeStyles(theme => ({
  option: {
    fontSize: 14,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 14,
  },
  left: { float: 'left' },
  right: { float: 'right' },
  text_right: { textAlign: 'right' },
  subtle: { color: theme.palette.secondary.dark },
}));

function CompetitionSeasonSelector({ value, onChangeInput, onChange, style }) {
  const classes = useStyles();
  const { loading, error, data } = useQuery(COMPETITION_SEASONS, {});

  const [input, setInput] = useState('');
  const competitionSeasons = data?.statsbomb_competition_season || [];

  if (loading) return <InlineProgress />;

  return (
    <>
      <Error message="Error fetching Competition Seasons" error={error} />

      <InputLabel id="comp-season-input">Competition Season</InputLabel>
      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        classes={{ option: classes.option, root: classes.root }}
        options={competitionSeasons}
        autoHighlight
        openOnFocus
        disableClearable
        inputValue={input}
        value={value}
        onInputChange={(e, value) => setInput(value)}
        getOptionLabel={compSzn =>
          compSzn.competition_season_id
            ? `${compSzn?.statsbomb_competition?.competition_name} ${compSzn?.statsbomb_season?.season_name}`
            : ''
        }
        renderOption={compSzn => (
          <div style={{ width: '100%' }}>
            <span className={classes.left} style={{ width: '45%' }}>
              {compSzn.statsbomb_competition.competition_name}
            </span>

            <span style={{ width: '15%' }} className={`${classes.left} ${classes.subtle}`}>
              {compSzn.statsbomb_season.season_name}
            </span>

            <span
              className={`${classes.right} ${classes.text_right} ${classes.subtle} list-only`}
              style={{ width: '40%' }}>
              <FlagIcon code={compSzn.statsbomb_competition.statsbomb_area?.area_code} />
              <GenderIcon isFemale={compSzn.statsbomb_competition.competition_is_female} />
            </span>
          </div>
        )}
        renderInput={params => <TextField {...params} inputProps={{ ...params.inputProps }} />}
        onChange={(e, compSzn) => onChange(compSzn)}
      />
    </>
  );
}

export default CompetitionSeasonSelector;
