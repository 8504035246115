import React from 'react';
import { Error } from '../util/notifications';
import { InlineProgress } from '../util/progress';

const QSError = ({ errors }) => {
  return (
    <>
      {errors.map((error, key) => {
        console.error(error);
        return <Error message="Query Error" {...{ key, error }} />;
      })}
    </>
  );
};

const QSLoading = InlineProgress;

const mutationError = (error, msg) => {
  console.error(error);
  return (msg || 'Unexpected Error') + '; ' + error?.message;
};

export { QSError, QSLoading, mutationError };
