import gql from 'graphql-tag';

const PHASES = gql`
  query getAllPhases {
    statsbomb_phase(where: { deleted: { _eq: false } }) {
      phase_id
      phase_type_id
      statsbomb_phase_type {
        phase_type_name
      }
      phase_order
      competition_season_id
      deleted
    }
  }
`;

const GOLDEN_PHASES = gql`
  query getGoldenPhases(
    $name: String
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_phase_order_by!
  ) {
    statsbomb_golden_phase(
      where: { _and: [{ search_name: { _ilike: $name }, deleted: { _eq: $deleted } }] }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      phase_id
      phase_type_name
      phase_order
      competition_season_id
      competition_name
      area_code
      season_name
      deleted
    }
  }
`;

const GOLDEN_PHASES_COUNT = gql`
  query getGoldenPhasesCount($name: String, $deleted: Boolean = false) {
    statsbomb_golden_phase_aggregate(
      where: { _and: [{ search_name: { _ilike: $name }, deleted: { _eq: $deleted } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_SOURCE_PHASE = gql`
  mutation createSourcePhase(
    $phase_type_id: Int!
    $phase_order: smallint!
    $competition_season_id: Int!
    $provider_phase_id: String!
  ) {
    insert_source_phase(
      objects: {
        phase_type_id: $phase_type_id
        phase_order: $phase_order
        competition_season_id: $competition_season_id
        last_updated: "now()"
        provider: Info_Team
        provider_phase_id: $provider_phase_id
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_PHASE = gql`
  mutation MyMutation($phase_id: Int!, $provider: source_provider_enum!, $provider_pahse_id: String!) {
    insert_mapping_phase_one(
      object: { phase_id: $phase_id, provider: $provider, provider_phase_id: $provider_pahse_id }
    ) {
      phase_id
      provider
      provider_phase_id
    }
  }
`;

// TODO move into phases claims
const ALL_CLAIM_PHASES = gql`
  query getAllClaimPhases($offset: Int = 0, $limit: Int = 10) {
    claim_phase(
      where: { approved_at: { _is_null: true }, rejected_at: { _is_null: true } }
      offset: $offset
      limit: $limit
    ) {
      claim_phase_uuid
      created_at
      deleted
      provider
      provider_phase_id
      phase_type_id
      phase_order
      mapping_competition_season {
        competition_season_id
        source_competition_season {
          source_competition {
            competition_name
          }
          statsbomb_season {
            season_name
          }
        }
        competition_season_id
      }
      statsbomb_phase_type {
        phase_type_name
      }
    }
  }
`;

const DELETE_PHASE = gql`
  mutation DeletePhases($phase_id: Int!) {
    update_statsbomb_phase_by_pk(pk_columns: { phase_id: $phase_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;
const DELETE_PHASE_BY_ID = gql`
  mutation deletePhase($phase_id: Int!) {
    delete_mapping_phase(where: { phase_id: { _eq: $phase_id } }) {
      affected_rows
    }
    delete_statsbomb_phase(where: { phase_id: { _eq: $phase_id } }) {
      affected_rows
    }
  }
`;
const GET_PHASES_BY_ID = gql`
  query getPhasesById($entityIds: [Int!]) {
    statsbomb_golden_phase(where: { phase_id: { _in: $entityIds } }) {
      phase_id
      phase_type_name
      phase_order
      competition_season_id
      competition_name
      area_code
      season_name
      deleted
    }
  }
`;
export {
  MAP_PHASE,
  PHASES,
  INSERT_SOURCE_PHASE,
  GOLDEN_PHASES,
  GOLDEN_PHASES_COUNT,
  ALL_CLAIM_PHASES,
  DELETE_PHASE,
  DELETE_PHASE_BY_ID,
  GET_PHASES_BY_ID,
};
