import React, { useState } from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { getDependencyTree } from '../../components/util/tree';
import { InlineProgress } from '../util/progress';
import { DeleteDialog } from '../util/DeleteDialog';
import { CellContent } from '../table/table';
import { capitalise } from '../../util/string';

const useStyles = makeStyles(theme => ({
  sizeSmall: { padding: 5 },
  checkbox: { padding: 0 },
  table: {
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  clearButton: {
    '&:hover': {
      color: '#990000',
    },
    fontSize: '30px',
    color: '#DA0000',
    height: 30,
    padding: 5,
    marginLeft: 7,
  },
  button: { margin: theme.spacing(), float: 'right' },
}));

const DeleteComponent = ({ entityType, records, fetchById, deleteEntityMutation, schema, parentType, parentId }) => {
  const classes = useStyles();
  let entityIds = [];
  records.forEach(record => {
    entityIds.push(record[`${entityType}_id`]);
  });

  const [dependencies, setDependencies] = useState({});
  const [open, setOpen] = useState(false);
  const [variables, setVariables] = useState({});

  const getDependencies = async (entityType, item) => {
    setDependencies(await getDependencyTree(`statsbomb.${entityType}`, item[`${entityType}_id`]));
    setOpen(true);
    setVariables({ [`${entityType}_id`]: item[`${entityType}_id`] });
  };
  const { loading, data } = useSubscription(fetchById, {
    variables: {
      entityIds,
    },
  });
  if (loading) return <InlineProgress />;
  const entityData = data?.[`statsbomb_golden_${entityType}`];
  return (
    <>
      <h1>Delete {entityType}</h1>
      <h3>
        Listed down {entityData.length} {entityType}s related to {parentType} with {parentType} id : {parentId}
      </h3>
      <DeleteDialog
        modal={open}
        handleClose={() => setOpen(false)}
        dependencyStructure={dependencies}
        type={entityType}
        {...{ open, deleteEntityMutation, variables }}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" component={Paper}>
          <TableHead>
            <TableRow>
              {schema.map(item => (
                <TableCell style={{ fontSize: '12px', padding: 10, fontWeight: 'bold' }} key={item.columnKey}>
                  <span>{capitalise(item.columnKey)}</span>
                </TableCell>
              ))}
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entityData?.map((item, index) => (
              <TableRow key={index}>
                {schema.map((item2, index) => (
                  <TableCell
                    onClick={() => window.open(`/${entityType}?${entityType}_id=${item[`${entityType}_id`]}`).focus()}
                    key={index}
                    style={{ fontSize: '10px', padding: 10 }}>
                    <CellContent
                      entity={item}
                      columnKey={item2.columnKey}
                      type={item2.type}
                      icon={item2.icon}
                      mappedIcon={item2.mapped_icon}
                      mappingFn={item2.mapping_function}></CellContent>
                  </TableCell>
                ))}
                <TableCell>
                  <DeleteIcon
                    className={classes.clearButton}
                    style={{ cursor: 'pointer' }}
                    onClick={() => getDependencies(entityType, item)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DeleteComponent;
