import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { COMPETITION_TYPES } from '../../hasura/queries/competition/competitionType';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const CompetitionType = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(COMPETITION_TYPES, {});

  if (loading) return <InlineProgress />;

  const competitionTypes = data?.statsbomb_competition_type || [];

  return (
    <>
      <Error message="Error fetching Competition Types" error={error} />

      <InputLabel id="comp-type-input">Type</InputLabel>
      <Select onChange={e => overrideChange(columnKey, e.target.value)} value={currentValue} labelId="comp-type-input">
        {competitionTypes.map((compType, key) => (
          <MenuItem key={key} value={compType.competition_type_id}>
            {compType.competition_type_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { CompetitionType };
