import gql from 'graphql-tag';

const AREAS = gql`
  query UndeletedAreas {
    statsbomb_area(where: { deleted: { _eq: false } }, order_by: { area_name: asc }) {
      area_code
      area_id
      area_name
      deleted
    }
  }
`;

const GOLDEN_AREAS = gql`
  query getGoldenAreas(
    $name: String
    $min_iq: Boolean
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_area_order_by!
  ) {
    statsbomb_golden_area(
      where: { _and: [{ search_name: { _ilike: $name }, min_spec: { _neq: $min_iq }, deleted: { _eq: $deleted } }] }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      area_id
      area_code
      area_name
      deleted
      min_spec
    }
  }
`;

const GOLDEN_AREAS_COUNT = gql`
  query getGoldenAreasCount($name: String, $min_iq: Boolean, $deleted: Boolean = false) {
    statsbomb_golden_area_aggregate(
      where: { _and: [{ search_name: { _ilike: $name }, min_spec: { _neq: $min_iq }, deleted: { _eq: $deleted } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_SOURCE_AREA = gql`
  mutation createSourceArea($area_name: String!, $area_code: String!, $provider_area_id: String!) {
    insert_source_area(
      objects: {
        area_name: $area_name
        area_code: $area_code
        last_updated: "now()"
        provider: Info_Team
        provider_area_id: $provider_area_id
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_AREA = gql`
  mutation MyMutation($area_id: Int!, $provider: source_provider_enum!, $provider_area_id: String!) {
    insert_mapping_area_one(object: { area_id: $area_id, provider: $provider, provider_area_id: $provider_area_id }) {
      area_id
      provider
      provider_area_id
    }
  }
`;

const INSERT_SOURCE_AREA_AND_MAPPING = gql`
  mutation createSourceAreaAndMapping(
    $area_name: String!
    $area_code: String!
    $provider_area_id: String!
    $provider: source_provider_enum!
    $area_id: Int!
  ) {
    insert_source_area(
      objects: {
        area_name: $area_name
        area_code: $area_code
        last_updated: "now()"
        provider: Info_Team
        provider_area_id: $provider_area_id
      }
      on_conflict: { constraint: area_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
    insert_mapping_area_one(
      object: { area_id: $area_id, provider: $provider, provider_area_id: $provider_area_id }
      on_conflict: { constraint: area_pkey, update_columns: [] }
    ) {
      area_id
      provider
      provider_area_id
    }
  }
`;

const SIMILAR_AREAS = gql`
  query similarAreas($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_areas(args: { n: $name, p: $provider, o: $offset }) {
      area_id
      area_name
      area_code
      name_similarity
      mapped
    }
  }
`;
const DELETE_AREA = gql`
  mutation DeleteAreas($area_id: Int!) {
    update_statsbomb_area_by_pk(pk_columns: { area_id: $area_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;

const DELETE_AREA_BY_ID = gql`
  mutation deleteArea($area_id: Int!) {
    delete_statsbomb_competition_ladder(where: { area_id: { _eq: $area_id } }) {
      affected_rows
    }
    delete_claim_area(where: { mapping_area: { area_id: { _eq: $area_id } } }) {
      affected_rows
    }

    delete_mapping_area(where: { area_id: { _eq: $area_id } }) {
      affected_rows
    }
    delete_statsbomb_area(where: { area_id: { _eq: $area_id } }) {
      affected_rows
    }
  }
`;

const GET_AREAS_BY_ID = gql`
  query getAreasById($entityIds: [Int!]) {
    statsbomb_golden_area(where: { area_id: { _in: $entityIds } }) {
      area_id
    }
  }
`;
export {
  MAP_AREA,
  AREAS,
  INSERT_SOURCE_AREA,
  GOLDEN_AREAS,
  GOLDEN_AREAS_COUNT,
  SIMILAR_AREAS,
  INSERT_SOURCE_AREA_AND_MAPPING,
  DELETE_AREA,
  DELETE_AREA_BY_ID,
  GET_AREAS_BY_ID,
};
