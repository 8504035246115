import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import {
  TRANSFER_MANAGER_CLAIM_TO_MAP_SCHEMA,
  TRANSFER_MANAGER_DEPENDANCY_MAPPING_SCHEMA,
} from '../../schema/transferManagerSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import {
  MAP_TO_TRANSFER_MANAGER,
  MAKE_AND_MAP_TRANSFER_MANAGER,
} from '../../hasura/queries/transferManager/transferManagerMapping';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import SimilarTransferManagersTable from '../../components/transferManager/similarTransferManager';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const TransferManagerMapping = ({ claims, provider_transfer_manager_id }) => {
  const classes = useStyles();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [transferManagerMapViewState, setTransferManagerMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter =>
    setTransferManagerMapViewState({
      ...transferManagerMapViewState,
      claimFilter,
    });

  const filterClaims = claims => {
    const { claimFilter } = transferManagerMapViewState;
    if (claimFilter === 'undecided') return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    else if (claimFilter === 'approved') return claims.filter(c => c.approved_at != null);
    else if (claimFilter === 'rejected') return claims.filter(c => c.rejected_at != null);
    else return claims;
  };

  const selectClaim = async selectedClaim => {
    setTransferManagerMapViewState({
      ...transferManagerMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarTransferManager = selectedSim => {
    setTransferManagerMapViewState({
      ...transferManagerMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(claims);

  const returnToClaimsList = () => history.push('/claims/transfer_manager');

  const [mapToTransferManager] = useMutation(MAP_TO_TRANSFER_MANAGER, {
    onError: err =>
      setErrorMessage(mutationError(err, 'Problem Mapping Transfer manager Claim to Statsbomb Transfer manager')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapTransferManager] = useMutation(MAKE_AND_MAP_TRANSFER_MANAGER, {
    onError: err =>
      setErrorMessage(mutationError(err, 'Problem Mapping Transfer manager Claim to Statsbomb Transfer manager')),
    onCompleted: returnToClaimsList,
  });

  const selClaim =
    claims.filter(c => c.claim_transfer_manager_uuid === transferManagerMapViewState.selectedClaim)[0] || {};

  const createTransferManagerMapping = async e => {
    e.preventDefault();
    try {
      await mapToTransferManager({
        variables: {
          transfer_manager_id: transferManagerMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_transfer_manager_id: selClaim.provider_transfer_manager_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createTransferManagerAndMap = async e => {
    e.preventDefault();
    try {
      await makeAndMapTransferManager({
        variables: {
          provider: selClaim.provider,
          provider_transfer_manager_id: selClaim.provider_transfer_manager_id,
          manager_id: selClaim.mapping_manager.manager_id,
          team_from_id: selClaim.team_from_mapping_team.team_id,
          team_to_id: selClaim.team_to_mapping_team.team_id,
          transfer_type: 'transfer',
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Transfer manager Mapper. Provider Transfer manager Id: {provider_transfer_manager_id}</h2>
        <h3>Select A Claim to view Possible manager Transfers</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={transferManagerMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={TRANSFER_MANAGER_CLAIM_TO_MAP_SCHEMA}
            rowClickHandler={claim => selectClaim(claim.claim_transfer_manager_uuid)}
            entityKey="claim_transfer_manager_uuid"
            selectedEntityKey={transferManagerMapViewState?.selectedClaim}
          />
        </TableContainer>

        {transferManagerMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarTransferManagersTable
              selectSimilarTransferManagerFn={selectSimilarTransferManager}
              selectedSim={transferManagerMapViewState.selectedSim}
              manager_id={selClaim?.mapping_manager?.manager_id}
            />

            <ClaimMappingButtons
              displayName="Transfer Manager"
              claim={selClaim}
              similarRecord={transferManagerMapViewState.selectedSim}
              schema={TRANSFER_MANAGER_DEPENDANCY_MAPPING_SCHEMA}
              mapEntityFn={createTransferManagerMapping}
              createNewEntityFn={createTransferManagerAndMap}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export default TransferManagerMapping;
