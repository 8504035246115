import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_ROUND_BY_PROVIDER } from '../../hasura/queries/round/roundClaims';
import { RoundMapping } from '../../components/round/roundMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

function RoundMapper(props) {
  const query = new URLSearchParams(useLocation().search); //get provider playerid from the query string ~ better than using props?
  const provider_round_id = query.get('provider_round_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_ROUND_BY_PROVIDER, {
    variables: {
      provider_round_id: provider_round_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Round" error={error} />
      <RoundMapping claims={data?.claim_round || []} provider_round_id={provider_round_id} />
    </>
  );
}

export default RoundMapper;
