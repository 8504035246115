import gql from 'graphql-tag';

const GOLDEN_OFFICIAL_AND_CLAIMS = gql`
  query goldenOfficialAndClaims($official_id: Int!) {
    statsbomb_official(where: { official_id: { _eq: $official_id } }) {
      official_id
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      deleted
      statsbomb_area {
        area_code
        area_name
      }
      official_is_female
      official_photo_url
      mapping_officials {
        claim_officials {
          claim_official_uuid
          official_name
          official_nickname
          official_date_of_birth
          official_nationality
          mapping_area {
            statsbomb_area {
              area_code
              area_name
            }
          }
          official_is_female
          official_photo_url
          provider
          provider_official_id
          deleted
          created_at
          approved_at
          rejected_at
        }
      }
    }
  }
`;

const ALL_CLAIM_OFFICIALS = gql`
  query getAllClaimOfficials(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
    $area: String
    $is_female: Boolean
  ) {
    claim_official(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_official_id: { _like: $provider_id }
        official_name: { _like: $name }
        official_date_of_birth: { _gte: $start_date, _lte: $end_date }
        official_nationality: { _ilike: $area }
        official_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_official_uuid
      provider_official_id
      provider
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      official_is_female
      mapping_official {
        official_id
      }
      deleted
      created_at
      approved_at
      rejected_at
    }
  }
`;

const MAPPED_CLAIM_OFFICIALS = gql`
  query getAllClaimOfficials(
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
    $area: String
    $is_female: Boolean
  ) {
    claim_official(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        mapping_official: {}
        provider_official_id: { _like: $provider_id }
        official_name: { _like: $name }
        official_date_of_birth: { _gte: $start_date, _lte: $end_date }
        official_nationality: { _ilike: $area }
        official_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_official_uuid
      provider_official_id
      provider
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      official_is_female
      mapping_official {
        official_id
      }
      deleted
      created_at
      approved_at
      rejected_at
    }
  }
`;

const UNMAPPED_CLAIM_OFFICIALS = gql`
  query getAllClaimOfficials(
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
    $area: String
    $is_female: Boolean
  ) {
    claim_official(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_official: {} }
        provider_official_id: { _like: $provider_id }
        official_name: { _like: $name }
        official_date_of_birth: { _gte: $start_date, _lte: $end_date }
        official_nationality: { _ilike: $area }
        official_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_official_uuid
      provider_official_id
      provider
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      official_is_female
      mapping_official {
        official_id
      }
      deleted
      created_at
      approved_at
      rejected_at
    }
  }
`;

const SAVE_OFFICIAL_CLAIM = gql`
  mutation saveOfficialClaim($claim_official_uuid: uuid!, $claims: [claim_official_insert_input!]!) {
    delete_claim_official(where: { claim_official_uuid: { _eq: $claim_official_uuid } }) {
      affected_rows
    }
    insert_claim_official(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_OFFICIAL_CLAIM = gql`
  mutation infoTeamOverrideOfficial(
    $official_id: Int!
    $provider_official_id: String!
    $source_entity: source_official_set_input!
  ) {
    insert_mapping_official(
      objects: {
        source_official: {
          data: { provider: Info_Team, provider_official_id: $provider_official_id, last_updated: "now()" }
          on_conflict: { constraint: official_pkey, update_columns: last_updated }
        }
        official_id: $official_id
      }
      on_conflict: { constraint: official_pkey, update_columns: official_id }
    ) {
      returning {
        official_id
      }
    }
    update_source_official(
      where: { provider: { _eq: Info_Team }, provider_official_id: { _eq: $provider_official_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_OFFICIAL = gql`
  mutation insertGoldenOfficial($statsbomb_entity: statsbomb_official_insert_input!) {
    insert_statsbomb_official(objects: [$statsbomb_entity]) {
      returning {
        official_id
      }
    }
  }
`;

const CLAIMS_FOR_OFFICIAL_BY_PROVIDER = gql`
  subscription claimsForOfficialByProvider($provider_official_id: String!, $provider: source_provider_enum!) {
    claim_official(where: { provider_official_id: { _eq: $provider_official_id }, provider: { _eq: $provider } }) {
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      mapping_area {
        statsbomb_area {
          area_code
          area_name
        }
      }
      official_is_female
      official_photo_url
      provider
      provider_official_id
      claim_official_uuid
      created_at
      approved_at
      rejected_at
      deleted
    }
  }
`;

const INSERT_GOLDEN_MATCH_OFFICIAL = gql`
  mutation insertGoldenMatchOfficial($statsbomb_entity: statsbomb_match_official_insert_input!) {
    insert_statsbomb_match_official(
      objects: [$statsbomb_entity]
      on_conflict: { constraint: match_official_pkey, update_columns: match_id }
    ) {
      returning {
        match_id
        official_id
      }
    }
  }
`;

const UNMAPPED_MATCH_OFFICIAL_CLAIMS = gql`
  subscription unmappedMatchOfficialClaims($match_id: Int!) {
    claim_match_official(where: { mapping_match: { match_id: { _eq: $match_id } } }) {
      provider
      mapping_official {
        provider_official_id
      }
      source_official {
        official_name
        provider_official_id
      }
      provider_official_id
      source_official {
        official_name
      }
    }
  }
`;

const OPEN_MATCH_OFFICIAL_CLAIMS_AGAINST_GOLDEN_MATCH = gql`
  subscription openOfficialClaimsAgainstGoldenMatch($match_id: Int!) {
    statsbomb_claim_for_golden_match_official(
      where: { match_id: { _eq: $match_id }, approved_at: { _is_null: true }, rejected_at: { _is_null: true } }
      order_by: { official_name: asc }
    ) {
      match_id
      official_id
      official_name
      claim_match_official_uuid
      provider
      provider_match_id
      provider_official_id
      official_type
      deleted
      rejected_at
      approved_at
      created_at
    }
  }
`;

const CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_OFFICIAL_CLAIM = gql`
  mutation updateOfficialClaimApproveAt(
    $match_id: Int!
    $official_id: Int!
    $official_type: String!
    $claim_match_official_uuid: uuid!
  ) {
    insert_statsbomb_match_official_one(
      object: { match_id: $match_id, official_id: $official_id, official_type: $official_type }
      on_conflict: { constraint: match_official_pkey, update_columns: [] }
    ) {
      match_id
      official_id
    }
    update_claim_match_official_by_pk(
      pk_columns: { claim_match_official_uuid: $claim_match_official_uuid }
      _set: { approved_at: "now()" }
    ) {
      claim_match_official_uuid
    }
  }
`;

const REJECT_ENTIRE_MATCH_OFFICIAL_CLAIM = gql`
  mutation updateOfficialClaimApproveAt($claim_match_official_uuid: uuid!) {
    update_claim_match_official_by_pk(
      pk_columns: { claim_match_official_uuid: $claim_match_official_uuid }
      _set: { rejected_at: "now()" }
    ) {
      claim_match_official_uuid
    }
  }
`;
const CLAIMS_OFFICIALS_COUNT = gql`
  query claimsOfficialsCount {
    claim_official_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;
export {
  GOLDEN_OFFICIAL_AND_CLAIMS,
  ALL_CLAIM_OFFICIALS,
  MAPPED_CLAIM_OFFICIALS,
  UNMAPPED_CLAIM_OFFICIALS,
  SAVE_OFFICIAL_CLAIM,
  OVERRIDE_OFFICIAL_CLAIM,
  INSERT_GOLDEN_OFFICIAL,
  CLAIMS_FOR_OFFICIAL_BY_PROVIDER,
  INSERT_GOLDEN_MATCH_OFFICIAL,
  OPEN_MATCH_OFFICIAL_CLAIMS_AGAINST_GOLDEN_MATCH,
  CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_OFFICIAL_CLAIM,
  REJECT_ENTIRE_MATCH_OFFICIAL_CLAIM,
  CLAIMS_OFFICIALS_COUNT,
  UNMAPPED_MATCH_OFFICIAL_CLAIMS,
};
