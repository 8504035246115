import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import { OFFICIAL_SCHEMA, OFFICIAL_CLAIM_SCHEMA, MAPPED_OFFICIAL_SCHEMA } from '../schema/officialSchema';
import { ClaimFilters } from '../components/claim/claimFilters';
import ClaimHistoryTable from '../components/table/claimHistoryTable';
import { Progress } from '../components/util/progress';
import {
  GOLDEN_OFFICIAL_AND_CLAIMS,
  SAVE_OFFICIAL_CLAIM,
  OVERRIDE_OFFICIAL_CLAIM,
  INSERT_GOLDEN_OFFICIAL,
} from '../hasura/queries/official/officialClaims';
import { DELETE_OFFICIAL, SIMILAR_OFFICIALS } from '../hasura/queries/official/officials';
import { Error } from '../components/util/notifications';
import { blankSchema } from '../util/schema';
import MappingView from '../components/mapping/mappingView';
import { DELETE_OFFICIAL_MAPPING, VIEW_OFFICIAL_MAPPINGS } from '../hasura/queries/official/officialMapping';
import { SimilarEntity } from '../components/mapping/SimilarEntity';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenOfficialView = ({ officialId, isNewOfficial, history }) => {
  const historyPath = history.location.search;

  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewOfficial ? blankSchema(OFFICIAL_SCHEMA) : {});
  const [overrides, setOverrides] = useState({});
  const [claimMode, setClaimMode] = useState(isNewOfficial ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);

  const setNewEntityMode = () => {
    setClaimMode(CLAIM_MODE_NEW_ENTITY);
    setClaimStatuses(blankSchema(OFFICIAL_SCHEMA));
  };

  const setViewMode = () => {
    setClaimMode(CLAIM_MODE_VIEW);
    setClaimStatuses({});
  };

  useEffect(() => (historyPath === '?new=new' ? setNewEntityMode() : setViewMode()), [historyPath]);

  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loading, error, data, refetch } = useQuery(GOLDEN_OFFICIAL_AND_CLAIMS, {
    variables: {
      official_id: officialId,
    },
  });
  if (loading) return <Progress />;
  let allClaims = [];
  data &&
    data.statsbomb_official &&
    data.statsbomb_official[0] &&
    data.statsbomb_official[0].mapping_officials.forEach(mappingEntry => {
      allClaims = [...allClaims, ...mappingEntry.claim_officials];
    });

  let filteredClaims = allClaims;
  if (claimFilter === 'undecided') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }

  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_official_uuid === claimUUID;
    })[0];
    if (claim) {
      OFFICIAL_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    OFFICIAL_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(blankSchema(OFFICIAL_SCHEMA));
  };

  const getGoldenOfficialId = insertedOfficial =>
    insertedOfficial?.data?.insert_statsbomb_official?.returning?.[0]?.official_id;

  const claimSelected = claim => selectAClaim(claim.claim_official_uuid);

  return (
    <>
      <Error message="" error={error} />
      <div className="container-row">
        <h2>Official Singular: {isNewOfficial ? ' New Official' : officialId}</h2>

        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="official"
              schema={OFFICIAL_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{ headerValue: 'Official Name', bodyValue: data.statsbomb_official[0]?.official_name }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <MappingView
            type="official"
            recordId={officialId}
            schema={MAPPED_OFFICIAL_SCHEMA}
            subscription={VIEW_OFFICIAL_MAPPINGS}
            deleteMutation={DELETE_OFFICIAL_MAPPING}
            deleteEntityMutation={DELETE_OFFICIAL}
            entityKey="provider_official_id"
            styles={{ tableContatinerClasses, buttonClasses }}
          />
        )}
        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Official Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={data.statsbomb_official[0]}
            claim={
              allClaims.filter(c => {
                return c.claim_official_uuid === selectedClaimId;
              })[0]
            }
            rowSchema={OFFICIAL_SCHEMA}
            saveMutation={SAVE_OFFICIAL_CLAIM}
            overrideMutation={OVERRIDE_OFFICIAL_CLAIM}
            createMutation={INSERT_GOLDEN_OFFICIAL}
            sourcePK="provider_official_id"
            claimPK="claim_official_uuid"
            goldenEntityPK="official_id"
            getGoldenEntityId={getGoldenOfficialId}
            entityType="official"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
            similarEntityTable={SimilarEntity}
            similarEntityQuery={SIMILAR_OFFICIALS}
            similarEntityQueryObject="statsbomb_similar_officials"
            {...{
              claimStatuses,
              setClaimStatuses,
              selectAClaim,
              overrides,
              setOverrides,
              claimMode,
              refetch,
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

const Official = ({ history }) => {
  const query = new URLSearchParams(useLocation().search);
  const isNewOfficial = query.get('new');
  const officialId = isNewOfficial ? '0' : query.get('official_id');

  return <GoldenOfficialView isNewOfficial={!!isNewOfficial} {...{ officialId, history }} />;
};

export default Official;
