import AreaMapper from './AreaMapper';
import ManagerMapper from './ManagerMapper';
import MatchMapper from './MatchMapper';
import OfficialMapper from './OfficialMapper';
import PlayerMapper from './PlayerMapper';
import RoundMapper from './RoundMapper';
import StadiumMapper from './StadiumMapper';
import TeamMapper from './TeamMapper';
import TransferManagerMapper from './TransferManagerMapper';
import TransferPlayerMapper from './TransferPlayerMapper';

const entityMappers = {
  area: AreaMapper,
  manager: ManagerMapper,
  match: MatchMapper,
  official: OfficialMapper,
  player: PlayerMapper,
  round: RoundMapper,
  stadium: StadiumMapper,
  team: TeamMapper,
  transfer_manager: TransferManagerMapper,
  transfer_player: TransferPlayerMapper,
};

export default entityMappers;
