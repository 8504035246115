import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Tabs as TabsMUI, Tab, Link } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  countButton: {
    display: 'inline-block',
    width: 'auto',
    padding: '0 5px',
    borderRadius: 5,
    color: 'white',
  },
});

const NarrowTab = withStyles({
  root: {
    minWidth: 100,
    maxWidth: 250,
  },
})(Tab);

const Tabs = withStyles({
  root: { minWidth: 100 },
})(({ type, classes, tabs, typeDisplay, source }) => {
  const countStyles = useStyles();
  const history = useHistory();
  const [tab, setTab] = useState(tabs.find(tab => tab.type === type).order);
  const tabChange = value => {
    const newTab = tabs.find(tab => tab.order === value);
    history.push(newTab.path);
    setTab(newTab.order);
  };

  return (
    <Paper classes={classes} style={{ marginTop: 20 }}>
      <TabsMUI
        value={tab}
        onChange={(_, v) => tabChange(v)}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary">
        {tabs.map(t => (
          <NarrowTab
            label={
              <Link href={`${t.path}#`} underline="none" rel="noreferrer" color="inherit">
                {t.typeDisplay}{' '}
                {t.claimsCount?.mappedCount > 0 && (
                  <span style={{ marginRight: 5, backgroundColor: '#457b9d' }} className={countStyles.countButton}>
                    {t.claimsCount.mappedCount > 999 ? '999+' : t.claimsCount.mappedCount}
                  </span>
                )}
                {t.claimsCount?.unmappedCount > 0 && (
                  <span style={{ backgroundColor: 'orange' }} className={countStyles.countButton}>
                    {t.claimsCount.unmappedCount > 999 ? '999+' : t.claimsCount.unmappedCount}
                  </span>
                )}
              </Link>
            }
            value={t.order}
            key={t.order}
          />
        ))}
      </TabsMUI>
    </Paper>
  );
});
export default Tabs;
