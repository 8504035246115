import gql from 'graphql-tag';

const GOLDEN_LINEUP = gql`
  subscription goldenLineup($match_id: Int!, $team_id: Int!, $player_id: Int!) {
    statsbomb_lineup(
      where: { match_id: { _eq: $match_id }, player_id: { _eq: $player_id }, team_id: { _eq: $team_id } }
    ) {
      match_id
      player_id
      statsbomb_player {
        player_name
      }
      team_id
      statsbomb_team {
        team_name
      }
      lineup_jersey
      player_lineup_selection_type
      deleted
    }
  }
`;

const GOLDEN_LINEUP_FOR_MATCH = gql`
  query goldenLineup($match_id: Int!) {
    statsbomb_lineup(
      where: { match_id: { _eq: $match_id }, deleted: { _neq: true } }
      order_by: { statsbomb_player: { player_name: asc } }
    ) {
      match_id
      player_id
      statsbomb_player {
        player_name
        player_date_of_birth
        nationality_statsbomb_area {
          area_name
          area_code
        }
      }
      team_id
      statsbomb_team {
        team_name
      }
      lineup_jersey
      player_lineup_selection_type
      deleted
    }
  }
`;

const ALL_CLAIM_LINEUPS = gql`
  query getAllClaimLineups($recent_claim_time: timestamp, $offset: Int = 0, $limit: Int = 10) {
    claim_lineup(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_lineup_uuid
      provider_team_id
      provider_match_id
      provider_player_id
      lineup_jersey
      player_lineup_selection_type
      deleted
      created_at
      provider
      approved_at
      rejected_at
      mapping_match {
        match_id
      }
      mapping_player {
        player_id
        statsbomb_player {
          player_name
        }
      }
      mapping_team {
        team_id
        statsbomb_team {
          team_name
        }
      }
      source_player {
        player_name
      }
    }
  }
`;

const CLAIMS_AGAINST_GOLDEN_LINEUP = gql`
  subscription claimsAgainstGoldenLineup($match_id: Int!, $team_id: Int!, $player_id: Int!) {
    statsbomb_claim_for_golden_lineup(
      where: { match_id: { _eq: $match_id }, player_id: { _eq: $player_id }, team_id: { _eq: $team_id } }
    ) {
      match_id
      team_id
      team_name
      player_id
      player_name
      claim_lineup_uuid
      provider
      provider_match_id
      provider_player_id
      provider_team_id
      lineup_jersey
      player_lineup_selection_type
      deleted
      rejected_at
      approved_at
      created_at
    }
  }
`;

const UNMAPPED_PLAYER_LINEUP_CLAIMS = gql`
  subscription unmappedPlayerLineupClaims($match_id: Int!, $team_id: Int!) {
    claim_lineup(
      where: { mapping_match: { match_id: { _eq: $match_id } }, mapping_team: { team_id: { _eq: $team_id } } }
    ) {
      provider
      mapping_player {
        provider_player_id
      }
      source_player {
        player_name
        provider_player_id
      }
      provider_player_id
      source_player {
        player_name
      }
    }
  }
`;

const OPEN_LINEUP_CLAIMS_AGAINST_GOLDEN_MATCH = gql`
  subscription claimsAgainstGoldenMatch($match_id: Int!) {
    statsbomb_claim_for_golden_lineup(
      where: { match_id: { _eq: $match_id }, approved_at: { _is_null: true }, rejected_at: { _is_null: true } }
      order_by: { player_lineup_selection_type: desc, player_name: asc }
    ) {
      match_id
      team_id
      team_name
      player_id
      player_name
      claim_lineup_uuid
      provider
      provider_match_id
      provider_player_id
      provider_team_id
      lineup_jersey
      player_lineup_selection_type
      deleted
      rejected_at
      approved_at
      created_at
    }
  }
`;

const SAVE_LINEUP_CLAIM = gql`
  mutation SaveLineup($claim_lineup_uuid: uuid!, $claims: [claim_lineup_insert_input!]!) {
    delete_claim_lineup(where: { claim_lineup_uuid: { _eq: $claim_lineup_uuid } }) {
      affected_rows
    }
    insert_claim_lineup(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_LINEUP_CLAIM = gql`
  mutation infoTeamOverride(
    $team_id: String!
    $match_id: String!
    $player_id: String!
    $source_entity: source_lineup_set_input!
  ) {
    insert_source_lineup(
      objects: {
        provider: Info_Team
        provider_match_id: $match_id
        provider_player_id: $player_id
        provider_team_id: $team_id
      }
      on_conflict: { constraint: lineup_pkey, update_columns: [last_updated, lineup_jersey] }
    ) {
      returning {
        provider_match_id
        provider_team_id
        provider_player_id
      }
    }
    update_source_lineup(
      where: {
        provider: { _eq: Info_Team }
        provider_team_id: { _eq: $team_id }
        provider_match_id: { _eq: $match_id }
        provider_player_id: { _eq: $player_id }
      }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_LINEUP = gql`
  mutation insertGoldenLineup($statsbomb_entity: statsbomb_lineup_insert_input!) {
    insert_statsbomb_lineup(
      objects: [$statsbomb_entity]
      on_conflict: { constraint: lineup_pkey, update_columns: match_id }
    ) {
      returning {
        team_id
        match_id
        player_id
      }
    }
  }
`;

const GOLDEN_LINEUP_MATCH_DATA = gql`
  query matchDataQuery($matchId: Int!) {
    statsbomb_match(where: { match_id: { _eq: $matchId } }) {
      match_name
      match_id
      match_date
      match_home_team_id
      match_away_team_id
      round_id
      home_statsbomb_team {
        team_name
        team_id
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      away_statsbomb_team {
        team_id
        team_name
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
    }
  }
`;

const CLAIMS_FOR_LINEUP_BY_PROVIDER = gql`
  subscription claimsForLineupByProvider(
    $provider_team_id: String!
    $provider_match_id: String!
    $provider_player_id: String!
    $provider: source_provider_enum!
  ) {
    claim_team(
      where: {
        provider_team_id: { _eq: $provider_team_id }
        provider_match_id: { _eq: $provider_match_id }
        provider_player_id: { _eq: $provider_player_id }
        provider: { _eq: $provider }
      }
    ) {
      provider
      claim_lineup_uuid
      provider_team_id
      provider_match_id
      provider_player_id
      lineup_jersey
      player_lineup_selection_type
      deleted
      created_at
      rejected_at
      approved_at
    }
  }
`;

const CLAIMS_LINEUPS_COUNT = gql`
  query claimsLineupsCount {
    claim_lineup_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  CLAIMS_AGAINST_GOLDEN_LINEUP,
  CLAIMS_LINEUPS_COUNT,
  ALL_CLAIM_LINEUPS,
  CLAIMS_FOR_LINEUP_BY_PROVIDER,
  GOLDEN_LINEUP,
  GOLDEN_LINEUP_FOR_MATCH,
  GOLDEN_LINEUP_MATCH_DATA,
  INSERT_GOLDEN_LINEUP,
  OPEN_LINEUP_CLAIMS_AGAINST_GOLDEN_MATCH,
  OVERRIDE_LINEUP_CLAIM,
  SAVE_LINEUP_CLAIM,
  UNMAPPED_PLAYER_LINEUP_CLAIMS,
};
