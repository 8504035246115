import gql from 'graphql-tag';

const GOLDEN_MANAGER_AND_CLAIMS = gql`
  query goldenManagerAndClaims($manager_id: Int!) {
    statsbomb_manager(where: { manager_id: { _eq: $manager_id } }) {
      manager_name
      manager_country_of_birth
      manager_date_of_birth
      manager_id
      manager_firstname
      manager_lastname
      manager_is_female
      manager_nationality
      manager_nickname
      manager_photo_url
      deleted
      nationality_statsbomb_area {
        area_code
        area_name
      }
      cob_statsbomb_area {
        area_code
        area_name
      }
      mapping_managers {
        claim_managers {
          claim_manager_uuid
          provider
          provider_manager_id
          manager_country_of_birth
          manager_date_of_birth
          manager_firstname
          manager_is_female
          manager_lastname
          manager_name
          manager_nationality
          manager_nickname
          manager_photo_url
          created_at
          deleted
          approved_at
          rejected_at
          cob_mapping_area {
            statsbomb_area {
              area_name
              area_code
            }
          }
          nationality_mapping_area {
            statsbomb_area {
              area_name
              area_code
            }
          }
        }
      }
    }
  }
`;

const ALL_CLAIM_MANAGERS = gql`
  query getAllClaimManager(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
    $area: String
    $is_female: Boolean
  ) {
    claim_manager(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_manager_id: { _like: $provider_id }
        manager_name: { _ilike: $name }
        manager_date_of_birth: { _gte: $start_date, _lte: $end_date }
        manager_nationality: { _ilike: $area }
        manager_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_manager_uuid
      manager_name
      manager_firstname
      manager_lastname
      manager_nickname
      manager_date_of_birth
      manager_nationality
      manager_country_of_birth
      manager_photo_url
      manager_is_female
      provider
      provider_manager_id
      deleted
      approved_at
      created_at
      rejected_at
      cob_mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      nationality_mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      mapping_manager {
        manager_id
        statsbomb_manager {
          nationality_statsbomb_area {
            area_code
            area_id
            area_name
          }
          cob_statsbomb_area {
            area_code
            area_id
            area_name
          }
        }
      }
    }
  }
`;

const MAPPED_CLAIM_MANAGERS = gql`
  query getAllClaimManager($offset: Int = 0, $limit: Int = 10, $provider_id: String, $area: String, $name: String) {
    claim_manager(
      where: {
        manager_name: { _ilike: $name }
        manager_nationality: { _ilike: $area }
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        mapping_manager: {}
        provider_manager_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_manager_uuid
      manager_name
      manager_firstname
      manager_lastname
      manager_nickname
      manager_date_of_birth
      manager_nationality
      manager_country_of_birth
      manager_photo_url
      manager_is_female
      provider
      provider_manager_id
      deleted
      approved_at
      created_at
      rejected_at
      cob_mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      nationality_mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      mapping_manager {
        manager_id
        statsbomb_manager {
          nationality_statsbomb_area {
            area_code
            area_id
            area_name
          }
          cob_statsbomb_area {
            area_code
            area_id
            area_name
          }
        }
      }
    }
  }
`;

const UNMAPPED_CLAIM_MANAGERS = gql`
  query getAllClaimManager($offset: Int = 0, $limit: Int = 10, $provider_id: String, $area: String, $name: String) {
    claim_manager(
      where: {
        manager_name: { _ilike: $name }
        manager_nationality: { _ilike: $area }
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_manager: {} }
        provider_manager_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_manager_uuid
      manager_name
      manager_firstname
      manager_lastname
      manager_nickname
      manager_date_of_birth
      manager_nationality
      manager_country_of_birth
      manager_photo_url
      manager_is_female
      provider
      provider_manager_id
      deleted
      approved_at
      created_at
      rejected_at
      cob_mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      nationality_mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      mapping_manager {
        manager_id
        statsbomb_manager {
          nationality_statsbomb_area {
            area_code
            area_id
            area_name
          }
          cob_statsbomb_area {
            area_code
            area_id
            area_name
          }
        }
      }
    }
  }
`;

const SAVE_MANAGER_CLAIM = gql`
  mutation saveManagerClaim($claim_manager_uuid: uuid!, $claims: [claim_manager_insert_input!]!) {
    delete_claim_manager(where: { claim_manager_uuid: { _eq: $claim_manager_uuid } }) {
      affected_rows
    }
    insert_claim_manager(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_MANAGER_CLAIM = gql`
  mutation infoTeamOverride(
    $manager_id: Int!
    $provider_manager_id: String!
    $source_entity: source_manager_set_input!
  ) {
    insert_mapping_manager(
      objects: {
        source_manager: {
          data: { provider: Info_Team, provider_manager_id: $provider_manager_id, last_updated: "now()" }
          on_conflict: { constraint: manager_pkey, update_columns: last_updated }
        }
        manager_id: $manager_id
      }
      on_conflict: { constraint: manager_pkey, update_columns: manager_id }
    ) {
      returning {
        manager_id
      }
    }
    update_source_manager(
      where: { provider: { _eq: Info_Team }, provider_manager_id: { _eq: $provider_manager_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_MANAGER = gql`
  mutation insertGoldenmanager($statsbomb_entity: statsbomb_manager_insert_input!) {
    insert_statsbomb_manager(objects: [$statsbomb_entity]) {
      returning {
        manager_id
      }
    }
  }
`;

const INSERT_GOLDEN_MATCH_MANAGER = gql`
  mutation insertGoldenMatchManager($statsbomb_entity: statsbomb_match_manager_insert_input!) {
    insert_statsbomb_match_manager(
      objects: [$statsbomb_entity]
      on_conflict: { constraint: match_manager_pkey, update_columns: match_id }
    ) {
      returning {
        team_id
        match_id
        manager_id
      }
    }
  }
`;

const CLAIMS_FOR_MANAGER_BY_PROVIDER = gql`
  subscription claimsForManagerByProvider($provider_manager_id: String!, $provider: source_provider_enum!) {
    claim_manager(where: { provider_manager_id: { _eq: $provider_manager_id }, provider: { _eq: $provider } }) {
      manager_name
      deleted
      created_at
      provider
      rejected_at
      provider_manager_id
      approved_at
      claim_manager_uuid
      manager_country_of_birth
      manager_date_of_birth
      manager_firstname
      manager_is_female
      manager_lastname
      manager_nationality
      manager_nickname
      manager_photo_url
      cob_mapping_area {
        statsbomb_area {
          area_id
          area_name
        }
      }
      nationality_mapping_area {
        statsbomb_area {
          area_id
          area_name
        }
      }
      mapping_manager {
        statsbomb_manager {
          deleted
          manager_country_of_birth
          manager_date_of_birth
          manager_firstname
          manager_id
          manager_is_female
          manager_lastname
          manager_name
          manager_nationality
          manager_nickname
          manager_photo_url
          cob_statsbomb_area {
            area_name
          }
          nationality_statsbomb_area {
            area_name
          }
        }
      }
    }
  }
`;

const UNMAPPED_MATCH_MANAGER_CLAIMS = gql`
  subscription unmappedMatchManagerClaims($match_id: Int!, $team_id: Int!) {
    claim_match_manager(
      where: { mapping_match: { match_id: { _eq: $match_id } }, mapping_team: { team_id: { _eq: $team_id } } }
    ) {
      provider
      mapping_manager {
        provider_manager_id
      }
      source_manager {
        manager_name
        provider_manager_id
      }
      provider_manager_id
      source_manager {
        manager_name
      }
    }
  }
`;

const OPEN_MATCH_MANAGER_CLAIMS_AGAINST_GOLDEN_MATCH = gql`
  subscription openManagerClaimsAgainstGoldenMatch($match_id: Int!) {
    statsbomb_claim_for_golden_match_manager(
      where: { match_id: { _eq: $match_id }, approved_at: { _is_null: true }, rejected_at: { _is_null: true } }
      order_by: { manager_name: asc }
    ) {
      match_id
      team_id
      manager_id
      manager_name
      claim_match_manager_uuid
      provider
      provider_match_id
      provider_manager_id
      manager_type
      deleted
      rejected_at
      approved_at
      created_at
    }
  }
`;

const CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_MANAGER_CLAIM = gql`
  mutation updateManagerClaimApproveAt(
    $match_id: Int!
    $team_id: Int!
    $manager_id: Int!
    $claim_match_manager_uuid: uuid!
  ) {
    insert_statsbomb_match_manager_one(
      object: { match_id: $match_id, team_id: $team_id, manager_id: $manager_id }
      on_conflict: { constraint: match_manager_pkey, update_columns: [] }
    ) {
      match_id
      manager_id
      team_id
    }
    update_claim_match_manager_by_pk(
      pk_columns: { claim_match_manager_uuid: $claim_match_manager_uuid }
      _set: { approved_at: "now()" }
    ) {
      claim_match_manager_uuid
    }
  }
`;

const REJECT_ENTIRE_MATCH_MANAGER_CLAIM = gql`
  mutation updateManagerClaimApproveAt($claim_match_manager_uuid: uuid!) {
    update_claim_match_manager_by_pk(
      pk_columns: { claim_match_manager_uuid: $claim_match_manager_uuid }
      _set: { rejected_at: "now()" }
    ) {
      claim_match_manager_uuid
    }
  }
`;
const CLAIMS_MANAGERS_COUNT = gql`
  query claimsManagersCount {
    claim_manager_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  GOLDEN_MANAGER_AND_CLAIMS,
  ALL_CLAIM_MANAGERS,
  MAPPED_CLAIM_MANAGERS,
  UNMAPPED_CLAIM_MANAGERS,
  SAVE_MANAGER_CLAIM,
  OVERRIDE_MANAGER_CLAIM,
  INSERT_GOLDEN_MANAGER,
  CLAIMS_FOR_MANAGER_BY_PROVIDER,
  INSERT_GOLDEN_MATCH_MANAGER,
  OPEN_MATCH_MANAGER_CLAIMS_AGAINST_GOLDEN_MATCH,
  CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_MATCH_MANAGER_CLAIM,
  REJECT_ENTIRE_MATCH_MANAGER_CLAIM,
  CLAIMS_MANAGERS_COUNT,
  UNMAPPED_MATCH_MANAGER_CLAIMS,
};
