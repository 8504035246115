import gql from 'graphql-tag';

const GOLDEN_TEAM_AND_CLAIMS = gql`
  query goldenTeamAndClaims($team_id: Int!) {
    statsbomb_team(where: { team_id: { _eq: $team_id } }) {
      team_id
      team_name
      team_nickname
      team_colour_primary
      team_colour_secondary
      team_is_female
      statsbomb_area {
        area_name
        area_code
      }
      deleted
      area_id
      mapping_teams {
        provider
        provider_team_id
        claim_teams {
          provider
          provider_team_id
          claim_team_uuid
          team_name
          team_nickname
          team_colour_primary
          team_colour_secondary
          team_is_female
          mapping_area {
            statsbomb_area {
              area_name
              area_code
            }
          }
          area_id
          deleted
          created_at
          rejected_at
          approved_at
          mapping_team {
            team_id
          }
        }
      }
    }
  }
`;

const ALL_CLAIM_TEAMS = gql`
  query getAllClaimTeams(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $area: String
    $name: String
    $provider_id: String
  ) {
    claim_team(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        team_name: { _ilike: $name }
        area_id: { _ilike: $area }
        provider_team_id: { _ilike: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      approved_at
      claim_team_uuid
      team_name
      team_nickname
      team_colour_primary
      team_colour_secondary
      team_is_female
      area_id
      mapping_area {
        statsbomb_area {
          area_name
          area_code
        }
      }
      deleted
      created_at
      deleted
      provider
      provider_team_id
      rejected_at
      mapping_team {
        team_id
      }
    }
  }
`;

const MAPPED_CLAIM_TEAMS = gql`
  query getAllClaimTeams($offset: Int = 0, $limit: Int = 10, $area: String, $name: String, $provider_id: String) {
    claim_team(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        mapping_team: {}
        team_name: { _ilike: $name }
        area_id: { _ilike: $area }
        provider_team_id: { _ilike: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      approved_at
      claim_team_uuid
      team_name
      team_nickname
      team_colour_primary
      team_colour_secondary
      team_is_female
      area_id
      mapping_area {
        statsbomb_area {
          area_name
          area_code
        }
      }
      deleted
      created_at
      deleted
      provider
      provider_team_id
      rejected_at
      mapping_team {
        team_id
      }
    }
  }
`;

const UNMAPPED_CLAIM_TEAMS = gql`
  query getAllClaimTeams($offset: Int = 0, $limit: Int = 10, $area: String, $name: String, $provider_id: String) {
    claim_team(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_team: {} }
        team_name: { _ilike: $name }
        area_id: { _ilike: $area }
        provider_team_id: { _ilike: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      approved_at
      claim_team_uuid
      team_name
      team_nickname
      team_colour_primary
      team_colour_secondary
      team_is_female
      area_id
      mapping_area {
        statsbomb_area {
          area_name
          area_code
        }
      }
      deleted
      created_at
      deleted
      provider
      provider_team_id
      rejected_at
      mapping_team {
        team_id
      }
    }
  }
`;

const SAVE_TEAM_CLAIM = gql`
  mutation SaveTeam($claim_team_uuid: uuid!, $claims: [claim_team_insert_input!]!) {
    delete_claim_team(where: { claim_team_uuid: { _eq: $claim_team_uuid } }) {
      affected_rows
    }
    insert_claim_team(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_TEAM_CLAIM = gql`
  mutation infoTeamTeamOverride($team_id: Int!, $provider_team_id: String!, $source_entity: source_team_set_input!) {
    insert_mapping_team(
      objects: {
        source_team: {
          data: { provider: Info_Team, provider_team_id: $provider_team_id, last_updated: "now()" }
          on_conflict: { constraint: team_pkey, update_columns: last_updated }
        }
        team_id: $team_id
      }
      on_conflict: { constraint: team_pkey, update_columns: team_id }
    ) {
      returning {
        team_id
      }
    }
    update_source_team(
      where: { provider: { _eq: Info_Team }, provider_team_id: { _eq: $provider_team_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_TEAM = gql`
  mutation insertGoldenTeam($statsbomb_entity: statsbomb_team_insert_input!) {
    insert_statsbomb_team(objects: [$statsbomb_entity]) {
      returning {
        team_id
      }
    }
  }
`;

// TO-DO: CJW Check how this works for area as enum was expected before changing but string is passed for both
const CLAIMS_FOR_TEAM_BY_PROVIDER = gql`
  subscription claimsForTeamByProvider($provider_team_id: String!, $provider: source_provider_enum!) {
    claim_team(where: { provider_team_id: { _eq: $provider_team_id }, provider: { _eq: $provider } }) {
      team_name
      team_nickname
      team_colour_primary
      team_colour_secondary
      team_is_female
      area_id
      mapping_area {
        statsbomb_area {
          area_name
          area_code
        }
      }
      deleted
      created_at
      claim_team_uuid
      provider
      rejected_at
      provider_team_id
      approved_at
      mapping_team {
        team_id
      }
    }
  }
`;

const CLAIMS_TEAMS_COUNT = gql`
  query claimsTeamsCount {
    claim_team_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  ALL_CLAIM_TEAMS,
  MAPPED_CLAIM_TEAMS,
  UNMAPPED_CLAIM_TEAMS,
  CLAIMS_FOR_TEAM_BY_PROVIDER,
  CLAIMS_TEAMS_COUNT,
  GOLDEN_TEAM_AND_CLAIMS,
  INSERT_GOLDEN_TEAM,
  OVERRIDE_TEAM_CLAIM,
  SAVE_TEAM_CLAIM,
};
