import React from 'react';
import { Modal, Button, makeStyles, Grid, Paper } from '@material-ui/core';
import SelectedFilters from './selectedFilters';

const useStyle = makeStyles(theme => ({
  row: {
    marginBottom: 5,
  },
  modalButton: {
    maxHeight: 80,
    minWidth: 140,
    marginRight: 20,
    textTransform: 'none',
  },
  modal: {
    position: 'absolute',
    top: 100,
    left: '20%',
    width: '60%',
    margin: 'auto',
    padding: '5px 20px',
    backgroundColor: '#FFF',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    boxShadow: '0px 3px 9px 2px rgba(0,0,0,0.1)',
  },
  filtersGrid: {
    marginBottom: 10,
  },
  componentBackground: { height: '100%', backgroundColor: '#EEEEEE', padding: theme.spacing(1) },
  buttonsDiv: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  clearButton: {
    '&:hover': {
      backgroundColor: '#E00007 !important',
      color: 'white',
    },
    backgroundColor: '#FF0009',
    color: 'white',
    marginLeft: 20,
  },
}));

const AllFiltersModal = ({
  schema,
  filter,
  setFilter,
  tempFilter,
  setTempFilter,
  emptyFilter,
  clearFilters,
  removeFilter,
  filtersModalOpen,
  setFiltersModalOpen,
}) => {
  const classes = useStyle();
  const itemsPerRow = 4;

  const closeModal = () => {
    setFiltersModalOpen(false);
    setTempFilter(filter);
  };

  return (
    <>
      <Button
        className={classes.modalButton}
        variant="contained"
        color="primary"
        onClick={() => setFiltersModalOpen(true)}>
        View All Filters
      </Button>
      <Modal open={filtersModalOpen} onClose={closeModal}>
        <div className={classes.modal}>
          <h2>All Filters</h2>
          <div className={classes.filtersGrid}>
            <Grid container spacing={1}>
              {schema.map(({ label, type, Component, attributes }, index) => (
                <Grid item xs={12 / itemsPerRow} key={index}>
                  <Paper className={classes.componentBackground}>
                    <Component
                      filter={tempFilter}
                      updateFilter={(key, value) => setTempFilter({ ...tempFilter, [key]: value, page: 1 })}
                      onModal={true}
                      {...{
                        label,
                        attributes,
                        filtersModalOpen,
                        type,
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
          <SelectedFilters
            label="Saved Filters"
            onModal
            {...{
              filter,
              emptyFilter,
              schema,
              filtersModalOpen,
              removeFilter,
            }}
          />
          <div className={classes.buttonsDiv}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setFiltersModalOpen(false);
                setFilter({ ...filter, ...tempFilter });
              }}>
              Save Filters
            </Button>
            <Button variant="contained" className={classes.clearButton} onClick={clearFilters}>
              Clear All Filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AllFiltersModal;
