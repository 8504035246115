import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MAP_TO_MANAGER, MAKE_AND_MAP_MANAGER } from '../../hasura/queries/manager/managerMapping';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import { MANAGER_CLAIM_TO_MAP_SCHEMA } from '../../schema/managerSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import { SimilarEntity } from '../mapping/SimilarEntity';
import { SIMILAR_MANAGERS } from '../../hasura/queries/manager/managers';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));
const ManagerMapping = props => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [managerMapViewState, setManagerMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter => {
    setManagerMapViewState({
      ...managerMapViewState,
      claimFilter,
    });
  };

  const filterClaims = claims => {
    if (managerMapViewState.claimFilter === 'undecided') {
      return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    } else if (managerMapViewState.claimFilter === 'approved') {
      return claims.filter(c => c.approved_at != null);
    } else if (managerMapViewState.claimFilter === 'rejected') {
      return claims.filter(c => c.rejected_at != null);
    } else {
      return claims;
    }
  };

  const selectClaim = selectedClaim => {
    setManagerMapViewState({
      ...managerMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarManager = selectedSim => {
    setManagerMapViewState({
      ...managerMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(props.claims);

  const returnToClaimsList = () => {
    history.push('/claims/manager');
  };

  const [mapToManager] = useMutation(MAP_TO_MANAGER, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Manager Claim to Statsbomb Manager')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapManager] = useMutation(MAKE_AND_MAP_MANAGER, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Manager Claim to Statsbomb Manager')),
    onCompleted: returnToClaimsList,
  });

  const selClaim = props.claims.filter(c => c.claim_manager_uuid === managerMapViewState.selectedClaim)[0] || {};

  const createManagerMapFn = async e => {
    e.preventDefault();
    try {
      await mapToManager({
        variables: {
          manager_id: managerMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_manager_id: selClaim.provider_manager_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createManagerAndMapFn = async e => {
    e.preventDefault();
    try {
      await makeAndMapManager({
        variables: {
          manager_name: selClaim.manager_name,
          provider: selClaim.provider,
          provider_manager_id: selClaim.provider_manager_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Manager Mapper. Provider Manager Id: {props.provider_manager_id}</h2>
        <h3>Select A Claim to view Possible Managers</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={managerMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={MANAGER_CLAIM_TO_MAP_SCHEMA}
            rowClickHandler={claim => {
              selectClaim(claim.claim_manager_uuid);
            }}
            entityKey="claim_manager_uuid"
            selectedEntityKey={managerMapViewState?.selectedClaim}
          />
        </TableContainer>

        {managerMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarEntity
              provider="Soccerway"
              entityName={selClaim.manager_name}
              selectSimilarEntityFn={selectSimilarManager}
              selectedSim={managerMapViewState.selectedSim}
              type="manager"
              query={SIMILAR_MANAGERS}
            />

            <ClaimMappingButtons
              displayName="Manager"
              claim={selClaim}
              similarRecord={managerMapViewState.selectedSim}
              schema={[]}
              mapEntityFn={createManagerMapFn}
              createNewEntityFn={createManagerAndMapFn}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export { ManagerMapping };
