import React from 'react';

const FlagIcon = ({ code, classes }) => {
  const cssFlagClass = 'flag-icon flag-icon-' + (code || 'unknown').toLowerCase();
  return <span className={(classes?.root || '') + ' ' + cssFlagClass}>&nbsp;</span>;
};

const ColourIcon = ({ colour }) => {
  const style = { backgroundColor: colour };
  if (colour) {
    return (
      <span className="flag-icon" style={style}>
        &nbsp;
      </span>
    );
  }
  return <></>;
};

const GenderIcon = ({ isFemale, ...props }) => (
  <span {...props} className={'gender-icon ' + (isFemale ? 'female' : 'male') + ' ' + props.className}>
    &nbsp;
  </span>
);

export { ColourIcon, FlagIcon, GenderIcon };
