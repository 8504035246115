import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_TRANSFER_MANAGER_BY_PROVIDER } from '../../hasura/queries/transferManager/transferManagerClaims';
import TransferManagerMapping from '../../components/transferManager/transferManagerMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

const TransferManagerMapper = () => {
  const query = new URLSearchParams(useLocation().search);
  const providerTransferManagerId = query.get('provider_transfer_manager_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_TRANSFER_MANAGER_BY_PROVIDER, {
    variables: {
      provider_transfer_manager_id: providerTransferManagerId,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Transfer Manager" error={error} />
      <TransferManagerMapping
        claims={data?.claim_transfer_manager || []}
        provider_transfer_manager_id={providerTransferManagerId}
      />
    </>
  );
};

export default TransferManagerMapper;
