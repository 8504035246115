import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MAP_TO_TEAM, MAKE_AND_MAP_TEAM } from '../../hasura/queries/team/teamMapping';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import { TEAM_CLAIM_TO_MAP_SCHEMA } from '../../schema/teamSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import { SIMILAR_TEAMS } from '../../hasura/queries/team/teams';
import { SimilarEntity } from '../mapping/SimilarEntity';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));
const TeamMapping = props => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [teamMapViewState, setTeamMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter => {
    setTeamMapViewState({
      ...teamMapViewState,
      claimFilter,
    });
  };

  const filterClaims = claims => {
    if (teamMapViewState.claimFilter === 'undecided') {
      return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    } else if (teamMapViewState.claimFilter === 'approved') {
      return claims.filter(c => c.approved_at != null);
    } else if (teamMapViewState.claimFilter === 'rejected') {
      return claims.filter(c => c.rejected_at != null);
    } else {
      return claims;
    }
  };

  const selectClaim = selectedClaim => {
    setTeamMapViewState({
      ...teamMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarTeam = selectedSim => {
    setTeamMapViewState({
      ...teamMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(props.claims);

  const returnToClaimsList = () => {
    history.push('/claims/team');
  };

  const [mapToTeam] = useMutation(MAP_TO_TEAM, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Team Claim to Statsbomb Team')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapTeam] = useMutation(MAKE_AND_MAP_TEAM, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Team Claim to Statsbomb Team')),
    onCompleted: returnToClaimsList,
  });

  const selClaim = props.claims.filter(c => c.claim_team_uuid === teamMapViewState.selectedClaim)[0] || {};

  const createTeamMapFn = async e => {
    e.preventDefault();
    try {
      await mapToTeam({
        variables: {
          team_id: teamMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_team_id: selClaim.provider_team_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createTeamAndMapFn = async e => {
    e.preventDefault();
    try {
      await makeAndMapTeam({
        variables: {
          team_name: selClaim.team_name,
          provider: selClaim.provider,
          provider_team_id: selClaim.provider_team_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Team Mapper. Provider Team Id: {props.provider_team_id}</h2>
        <h3>Select A Claim to view Possible Matches</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={teamMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={TEAM_CLAIM_TO_MAP_SCHEMA}
            rowClickHandler={claim => {
              selectClaim(claim.claim_team_uuid);
            }}
            entityKey="claim_team_uuid"
            selectedEntityKey={teamMapViewState?.selectedClaim}
          />
        </TableContainer>

        {teamMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarEntity
              provider="Soccerway"
              entityName={selClaim.team_name}
              selectSimilarEntityFn={selectSimilarTeam}
              selectedSim={teamMapViewState.selectedSim}
              type="team"
              query={SIMILAR_TEAMS}
            />

            <ClaimMappingButtons
              displayName="Team"
              claim={selClaim}
              similarRecord={teamMapViewState.selectedSim}
              schema={[]}
              mapEntityFn={createTeamMapFn}
              createNewEntityFn={createTeamAndMapFn}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export { TeamMapping };
