import { useMutation } from '@apollo/react-hooks';
import {
  INSERT_SOURCE_MATCH,
  INSERT_SOURCE_PLAYER,
  MAP_MATCH,
  MAP_PLAYER,
} from '../../hasura/queries/lineup/fullLineups';
import { INSERT_SOURCE_TEAM, MAP_TEAM } from '../../hasura/queries/team/teams';
import { INSERT_SOURCE_AREA, MAP_AREA } from '../../hasura/queries/area/areas';
import { INSERT_SOURCE_MANAGER, MAP_MANAGER } from '../../hasura/queries/manager/managers';
import { INSERT_SOURCE_AND_MAP_OFFICIAL } from '../../hasura/queries/official/officials';
import { INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING } from '../../hasura/queries/competitionSeason/competitionSeasons';
import { INSERT_PHASE_SOURCE_AND_MAPPING } from '../../hasura/queries/phase/phase';
import { INSERT_ROUND_SOURCE_AND_MAPPING } from '../../hasura/queries/round/round';
import { INSERT_COMPETITION_SOURCE_AND_MAPPING } from '../../hasura/queries/competition/competitions';

const noOp = () => {};
const fullNoOp = {
  onError: noOp,
  onCompleted: noOp,
};

const useMatchMapping = () => {
  const [insertSourceMatch] = useMutation(INSERT_SOURCE_MATCH, fullNoOp);
  const [insertSourceTeam] = useMutation(INSERT_SOURCE_TEAM, fullNoOp);
  const [mapMatch] = useMutation(MAP_MATCH, fullNoOp);
  const [mapTeam] = useMutation(MAP_TEAM, fullNoOp);
  const [mapArea] = useMutation(MAP_AREA, fullNoOp);
  const [insertSourceArea] = useMutation(INSERT_SOURCE_AREA, fullNoOp);

  return async match => {
    match.home_area_id &&
      (await insertSourceArea({
        variables: {
          area_name: match.home_area_name,
          area_code: match.home_area_code,
          provider_area_id: match.home_area_id.toString(),
        },
      }));

    match.away_area_id &&
      (await insertSourceArea({
        variables: {
          area_name: match.away_area_name,
          area_code: match.away_area_code,
          provider_area_id: match.away_area_id.toString(),
        },
      }));

    match.home_area_id &&
      (await mapArea({
        variables: {
          area_id: match.home_area_id.toString(),
          provider_area_id: match.home_area_id.toString(),
          provider: 'Info_Team',
        },
      }));

    match.away_area_id &&
      (await mapArea({
        variables: {
          area_id: match.away_area_id.toString(),
          provider_area_id: match.away_area_id.toString(),
          provider: 'Info_Team',
        },
      }));

    await insertSourceTeam({
      variables: {
        provider_team_id: match.match_home_team_id.toString(),
        team_name: match.home_team_name,
      },
    });

    await insertSourceTeam({
      variables: {
        provider_team_id: match.match_away_team_id.toString(),
        team_name: match.away_team_name,
      },
    });
    await mapTeam({
      variables: {
        team_id: match.match_home_team_id,
        provider_team_id: match.match_home_team_id.toString(),
        provider: 'Info_Team',
      },
    });
    await mapTeam({
      variables: {
        team_id: match.match_away_team_id,
        provider_team_id: match.match_away_team_id.toString(),
        provider: 'Info_Team',
      },
    });

    await insertSourceMatch({
      variables: {
        match_home_team_id: match.match_home_team_id.toString(),
        match_away_team_id: match.match_away_team_id.toString(),
        match_id: match.match_id.toString(),
      },
    });
    await mapMatch({
      variables: {
        match_id: match.match_id,
        provider_match_id: match.match_id.toString(),
        provider: 'Info_Team',
      },
    });
  };
};

const usePlayerMapping = () => {
  const [insertSourceEntity] = useMutation(INSERT_SOURCE_PLAYER, fullNoOp);

  const [mapEntity] = useMutation(MAP_PLAYER, fullNoOp);

  return async (id, name) => {
    await insertSourceEntity({
      variables: {
        player_id: id.toString(),
        player_name: name,
      },
    });

    await mapEntity({
      variables: {
        provider_player_id: id.toString(),
        player_id: id,
        provider: 'Info_Team',
      },
    });
  };
};

const useManagerMapping = () => {
  const [insertSourceEntity] = useMutation(INSERT_SOURCE_MANAGER, fullNoOp);

  const [mapEntity] = useMutation(MAP_MANAGER, fullNoOp);

  return async (id, name) => {
    await insertSourceEntity({
      variables: {
        manager_id: id.toString(),
        manager_name: name,
      },
    });

    await mapEntity({
      variables: {
        provider_manager_id: id.toString(),
        manager_id: id,
        provider: 'Info_Team',
      },
    });
  };
};

const useTeamMapping = () => {
  const [insertSourceTeam] = useMutation(INSERT_SOURCE_TEAM, fullNoOp);

  const [mappingTeam] = useMutation(MAP_TEAM, fullNoOp);
  return async (id, name) => {
    await insertSourceTeam({
      variables: {
        team_name: name,
        provider_team_id: id.toString(),
      },
    });

    await mappingTeam({
      variables: {
        team_id: id,
        provider: 'Info_Team',
        provider_team_id: id.toString(),
      },
    });
  };
};

const useOfficialMapping = () => {
  const [insertSourceAndMapEntity] = useMutation(INSERT_SOURCE_AND_MAP_OFFICIAL, fullNoOp);

  return async (id, name) => {
    await insertSourceAndMapEntity({
      variables: {
        provider_official_id: id.toString(),
        official_id: id,
        official_name: name,
      },
    });
  };
};

const useCompSznMapping = () => {
  const [insertCompetitionSourceAndMapping] = useMutation(INSERT_COMPETITION_SOURCE_AND_MAPPING, fullNoOp);
  const [insertCompetitionSeasonSourceAndMapping] = useMutation(INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING, fullNoOp);
  return async compSeason => {
    await insertCompetitionSourceAndMapping({
      variables: {
        provider: 'Info_Team',
        provider_competition_id: compSeason.competition_id.toString(),
        competition_id: compSeason.competition_id,
        competition_name: compSeason.statsbomb_competition.competition_name,
      },
    });
    await insertCompetitionSeasonSourceAndMapping({
      variables: {
        provider_competition_season_id: compSeason.competition_season_id.toString(),
        provider: 'Info_Team',
        competition_season_id: compSeason.competition_season_id,
        competition_season_name: compSeason.competition_season_name,
        competition_id: compSeason.competition_id.toString(),
        season_id: compSeason.season_id,
      },
    });
  };
};

const usePhaseMapping = () => {
  const [insertPhaseSourceAndMapping] = useMutation(INSERT_PHASE_SOURCE_AND_MAPPING, fullNoOp);
  return async (phase, competitionSeason) => {
    await insertPhaseSourceAndMapping({
      variables: {
        provider_phase_id: phase.phase_id.toString(),
        provider: 'Info_Team',
        phase_id: phase.phase_id,
        phase_type_id: phase.statsbomb_phase_type.phase_type_id,
        competition_season_id: competitionSeason.competition_season_id.toString(),
      },
    });
  };
};

const useRoundMapping = () => {
  const [insertRoundSourceAndMapping] = useMutation(INSERT_ROUND_SOURCE_AND_MAPPING, fullNoOp);
  return async (round, phase) => {
    await insertRoundSourceAndMapping({
      variables: {
        provider_round_id: round.value.toString(),
        provider: 'Info_Team',
        round_id: round.value,
        phase_id: phase.toString(),
        round_type_id: round.obj.statsbomb_round_type.round_type_id,
        round_structure_id: round.obj.statsbomb_round_structure?.round_structure_id,
      },
    });
  };
};

export {
  useMatchMapping,
  usePlayerMapping,
  useManagerMapping,
  useOfficialMapping,
  useTeamMapping,
  useCompSznMapping,
  usePhaseMapping,
  useRoundMapping,
  fullNoOp,
};
