import React from 'react';
import { FlagIcon } from '../components/util/icon';

const FULL_FULL_LINEUP_SCHEMA = [
  { columnKey: 'match_id', name: 'Match ID', type: 'String' },
  { columnKey: 'match_home_team_id', name: 'Home Team ID', type: 'String' },
  { columnKey: 'match_away_team_id', name: 'Away Team ID', type: 'String' },
  { columnKey: 'home_team_name', name: 'Home Team', type: 'String', icon: e => <FlagIcon code={e.home_area_code} /> },
  { columnKey: 'away_team_name', name: 'Away Team', type: 'String', icon: e => <FlagIcon code={e.away_area_code} /> },
  { columnKey: 'match_date', name: 'Date', type: 'String' },
  { columnKey: 'players_added', name: 'Players in Lineup', type: 'Integer' },
];

const GOLDEN_FULL_LINEUP_SCHEMA = FULL_FULL_LINEUP_SCHEMA.filter(field =>
  ['match_id', 'competition_name', 'home_team_name', 'away_team_name', 'match_date', 'players_added'].includes(
    field.columnKey
  )
);

const FULL_LINEUP_SCHEMA = FULL_FULL_LINEUP_SCHEMA.filter(a =>
  [
    'match_id',
    'match_home_team_id',
    'match_away_team_id',
    'competition_name',
    'home_team_name',
    'away_team_name',
    'match_date',
    'players_added',
  ].includes(a.columnKey)
);

const FULL_LINEUP_CLAIM_SCHEMA = FULL_FULL_LINEUP_SCHEMA.filter(a =>
  [
    'match_id',
    'match_home_team_id',
    'match_away_team_id',
    'competition_name',
    'home_team_name',
    'away_team_name',
    'match_date',
    'players_added',
  ].includes(a.columnKey)
);

export { FULL_FULL_LINEUP_SCHEMA, GOLDEN_FULL_LINEUP_SCHEMA, FULL_LINEUP_SCHEMA, FULL_LINEUP_CLAIM_SCHEMA };
