import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MAP_TO_OFFICIAL, MAKE_AND_MAP_OFFICIAL } from '../../hasura/queries/official/officialMapping';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import { OFFICIAL_CLAIM__TO_MAP_SCHEMA } from '../../schema/officialSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import { SIMILAR_OFFICIALS } from '../../hasura/queries/official/officials';
import { SimilarEntity } from '../mapping/SimilarEntity';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const OfficialMapping = props => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [officialMapViewState, setOfficialMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter => {
    setOfficialMapViewState({
      ...officialMapViewState,
      claimFilter,
    });
  };

  const filterClaims = claims => {
    if (officialMapViewState.claimFilter === 'undecided') {
      return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    } else if (officialMapViewState.claimFilter === 'approved') {
      return claims.filter(c => c.approved_at != null);
    } else if (officialMapViewState.claimFilter === 'rejected') {
      return claims.filter(c => c.rejected_at != null);
    } else {
      return claims;
    }
  };

  const selectClaim = selectedClaim => {
    setOfficialMapViewState({
      ...officialMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarOfficial = selectedSim => {
    setOfficialMapViewState({
      ...officialMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(props.claims);

  const returnToClaimsList = () => {
    history.push('/claims/official');
  };

  const [mapToOfficial] = useMutation(MAP_TO_OFFICIAL, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Official Claim to Statsbomb Official')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapOfficial] = useMutation(MAKE_AND_MAP_OFFICIAL, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Official Claim to Statsbomb Official')),
    onCompleted: returnToClaimsList,
  });

  const selClaim = props.claims.filter(c => c.claim_official_uuid === officialMapViewState.selectedClaim)[0] || {};

  const createOfficialMapFn = async e => {
    e.preventDefault();
    try {
      await mapToOfficial({
        variables: {
          official_id: officialMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_official_id: selClaim.provider_official_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createOfficialAndMapFn = async e => {
    e.preventDefault();
    try {
      await makeAndMapOfficial({
        variables: {
          official_name: selClaim.official_name,
          provider: selClaim.provider,
          provider_official_id: selClaim.provider_official_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Official Mapper. Provider Official Id: {props.provider_official_id}</h2>
        <h3>Select A Claim to view Possible Officials</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={officialMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={OFFICIAL_CLAIM__TO_MAP_SCHEMA}
            rowClickHandler={claim => {
              selectClaim(claim.claim_official_uuid);
            }}
            entityKey="claim_official_uuid"
            selectedEntityKey={officialMapViewState?.selectedClaim}
          />
        </TableContainer>

        {officialMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarEntity
              provider="Soccerway"
              entityName={selClaim.official_name}
              selectSimilarEntityFn={selectSimilarOfficial}
              selectedSim={officialMapViewState.selectedSim}
              type="official"
              query={SIMILAR_OFFICIALS}
            />

            <ClaimMappingButtons
              displayName="Official"
              claim={selClaim}
              similarRecord={officialMapViewState.selectedSim}
              schema={[]}
              mapEntityFn={createOfficialMapFn}
              createNewEntityFn={createOfficialAndMapFn}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export { OfficialMapping };
