import gql from 'graphql-tag';

const GOLDEN_AREA_AND_CLAIMS = gql`
  query goldenAreaAndClaims($area_id: Int!) {
    statsbomb_area(where: { area_id: { _eq: $area_id } }) {
      area_id
      area_name
      area_code
      deleted
      mapping_areas {
        provider
        provider_area_id
        claim_areas {
          claim_area_uuid
          provider
          provider_area_id
          approved_at
          created_at
          deleted
          rejected_at
          area_name
          area_code
        }
      }
    }
  }
`;

const ALL_CLAIM_AREAS = gql`
  query getAllClaimAreas($recent_claim_time: timestamp, $offset: Int = 0, $limit: Int = 10, $name: String) {
    claim_area(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        area_name: { _ilike: $name }
      }
      offset: $offset
      limit: $limit
    ) {
      area_name
      area_code
      claim_area_uuid
      created_at
      deleted
      provider
      provider_area_id
      rejected_at
      approved_at
      mapping_area {
        area_id
      }
    }
  }
`;

const MAPPED_CLAIM_AREAS = gql`
  subscription getAllClaimAreas($offset: Int = 0, $limit: Int = 10, $name: String) {
    claim_area(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        mapping_area: {}
        area_name: { _ilike: $name }
      }
      offset: $offset
      limit: $limit
    ) {
      area_name
      area_code
      claim_area_uuid
      created_at
      deleted
      provider
      provider_area_id
      rejected_at
      approved_at
      mapping_area {
        area_id
      }
    }
  }
`;

const UNMAPPED_CLAIM_AREAS = gql`
  query getAllClaimAreas($offset: Int = 0, $limit: Int = 10, $name: String) {
    claim_area(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        _not: { mapping_area: {} }
        area_name: { _ilike: $name }
      }
      offset: $offset
      limit: $limit
    ) {
      area_name
      area_code
      claim_area_uuid
      created_at
      deleted
      provider
      provider_area_id
      rejected_at
      approved_at
      mapping_area {
        area_id
      }
    }
  }
`;

const SAVE_AREA_CLAIM = gql`
  mutation MyMutation($claim_area_uuid: uuid!, $claims: [claim_area_insert_input!]!) {
    delete_claim_area(where: { claim_area_uuid: { _eq: $claim_area_uuid } }) {
      affected_rows
    }
    insert_claim_area(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_AREA_CLAIM = gql`
  mutation infoTeamOverride($area_id: Int!, $provider_area_id: String!, $source_entity: source_area_set_input!) {
    insert_mapping_area(
      objects: {
        source_area: {
          data: { provider: Info_Team, provider_area_id: $provider_area_id, last_updated: "now()" }
          on_conflict: { constraint: area_pkey, update_columns: last_updated }
        }
        area_id: $area_id
      }
      on_conflict: { constraint: area_pkey, update_columns: area_id }
    ) {
      returning {
        area_id
      }
    }
    update_source_area(
      where: { provider: { _eq: Info_Team }, provider_area_id: { _eq: $provider_area_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_AREA = gql`
  mutation insertGoldenArea($statsbomb_entity: statsbomb_area_insert_input!) {
    insert_statsbomb_area(objects: [$statsbomb_entity]) {
      returning {
        area_id
      }
    }
  }
`;

const CLAIMS_FOR_AREA_BY_PROVIDER = gql`
  subscription claimsForAreaByProvider($provider_area_id: String!, $provider: source_provider_enum!) {
    claim_area(where: { provider_area_id: { _eq: $provider_area_id }, provider: { _eq: $provider } }) {
      area_name
      area_code
      deleted
      created_at
      claim_area_uuid
      provider
      rejected_at
      provider_area_id
      approved_at
      mapping_area {
        statsbomb_area {
          deleted
          area_id
          area_name
          area_code
        }
      }
    }
  }
`;
const CLAIMS_AREAS_COUNT = gql`
  query claimsAreasCount {
    claim_area_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  GOLDEN_AREA_AND_CLAIMS,
  ALL_CLAIM_AREAS,
  MAPPED_CLAIM_AREAS,
  UNMAPPED_CLAIM_AREAS,
  INSERT_GOLDEN_AREA,
  SAVE_AREA_CLAIM,
  CLAIMS_AREAS_COUNT,
  CLAIMS_FOR_AREA_BY_PROVIDER,
  OVERRIDE_AREA_CLAIM,
};
