import gql from 'graphql-tag';

export const ROUND_TYPE = gql`
  query roundType {
    statsbomb_round_type(order_by: { round_type_name: asc }) {
      round_type_name
      round_type_id
    }
  }
`;
