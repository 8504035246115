import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { MatchMapping } from '../../components/match/matchMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';
import { CLAIMS_FOR_MATCH_BY_PROVIDER } from '../../hasura/queries/match/matchClaims';

function MatchMapper() {
  const query = new URLSearchParams(useLocation().search);
  const provider_match_id = query.get('provider_match_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_MATCH_BY_PROVIDER, {
    variables: {
      provider_match_id: provider_match_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Match" error={error} />
      <MatchMapping claims={data?.claim_match || []} provider_match_id={provider_match_id} />
    </>
  );
}

export default MatchMapper;
