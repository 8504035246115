import React from 'react';
import Moment from 'react-moment';
import moment from 'moment-timezone';

import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from './consts';

const getXMinutesAgo = mins => {
  const xMinsAgo = new Date();
  return xMinsAgo.setMinutes(xMinsAgo.getMinutes() - mins);
};

const getUTCTimeXMinutesAgo = mins => moment.utc(new Date()).subtract(mins, 'minutes');

function createDateFromUTC(dateString) {
  const utCTimezoneIdentifier = 'Z';
  return new Date(dateString + utCTimezoneIdentifier);
}

function DateValue({ children, fromNow, dateTime, withoutTZ = false }) {
  const createDate = withoutTZ ? children : createDateFromUTC(children);
  if (fromNow) {
    return <Moment fromNow>{createDate}</Moment>;
  }
  return <Moment format={dateTime ? DATE_AND_TIME_FORMAT : DATE_FORMAT}>{createDate}</Moment>;
}

const formatDateString = (date, format = DATE_AND_TIME_FORMAT) => moment(date).format(format);

export { createDateFromUTC, getXMinutesAgo, getUTCTimeXMinutesAgo, DateValue, formatDateString };
