import gql from 'graphql-tag';

const GOLDEN_COMPETITIONS = gql`
  query getGoldenCompetitions(
    $name: String
    $area: String
    $min_iq: Boolean
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_competition_order_by!
  ) {
    statsbomb_golden_competition(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            area_name: { _ilike: $area }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      deleted
      competition_type_id
      competition_name
      competition_ladder_rank
      competition_ladder_id
      competition_is_female
      competition_id
      competition_group_id
      area_id
      competition_type_name
      competition_ladder_name
      competition_group_name
      area_name
      area_code
      min_spec
    }
  }
`;

const GOLDEN_COMPETITIONS_COUNT = gql`
  query getGoldenCompetitionsCount($name: String, $area: String, $min_iq: Boolean, $deleted: Boolean = false) {
    statsbomb_golden_competition_aggregate(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            area_name: { _ilike: $area }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ALL_CLAIM_COMPETITIONS = gql`
  subscription getAllClaimCompetitions($offset: Int = 0, $limit: Int = 10) {
    claim_competition(
      where: { rejected_at: { _is_null: true }, approved_at: { _is_null: true } }
      limit: $limit
      offset: $offset
    ) {
      mapping_area {
        area_id
      }
      approved_at
      competition_group_id
      claim_competition_uuid
      competition_is_female
      competition_ladder_id
      competition_ladder_rank
      competition_name
      competition_type_id
      created_at
      deleted
      provider
      provider_competition_id
      rejected_at
    }
  }
`;

const INSERT_SOURCE_COMPETITION = gql`
  mutation insertSourceCompetition($competition_name: String!, $provider_competition_id: String!) {
    insert_source_competition(
      objects: {
        competition_name: $competition_name
        last_updated: "now()"
        provider: Info_Team
        provider_competition_id: $provider_competition_id
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_COMPETITION = gql`
  mutation insertMapCompetition(
    $competition_id: Int!
    $provider: source_provider_enum!
    $provider_competition_id: String!
  ) {
    insert_mapping_competition_one(
      object: {
        competition_id: $competition_id
        provider: $provider
        provider_competition_id: $provider_competition_id
      }
    ) {
      competition_id
      provider
      provider_competition_id
    }
  }
`;

const INSERT_COMPETITION_SOURCE_AND_MAPPING = gql`
  mutation insertCompetitionSourceAndMapping(
    $competition_id: Int!
    $provider: source_provider_enum!
    $provider_competition_id: String!
    $competition_name: String!
  ) {
    insert_source_competition(
      objects: {
        competition_name: $competition_name
        last_updated: "now()"
        provider: Info_Team
        provider_competition_id: $provider_competition_id
      }
      on_conflict: { constraint: competition_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
    insert_mapping_competition_one(
      object: {
        competition_id: $competition_id
        provider: $provider
        provider_competition_id: $provider_competition_id
      }
      on_conflict: { constraint: competition_pkey, update_columns: [] }
    ) {
      competition_id
      provider
      provider_competition_id
    }
  }
`;

const COMPETITIONS = gql`
  query getAllCompetitions {
    statsbomb_competition(
      order_by: { competition_is_female: asc, statsbomb_area: { area_name: asc }, competition_name: asc }
    ) {
      competition_name
      competition_id
      statsbomb_area {
        area_name
        area_code
      }
      competition_is_female
    }
  }
`;
const DELETE_COMPETITION = gql`
  mutation DeleteCompetitions($competition_id: Int!) {
    update_statsbomb_competition_by_pk(pk_columns: { competition_id: $competition_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;
const DELETE_COMPETITION_BY_ID = gql`
  mutation deleteCompetition($competition_id: Int!) {
    delete_mapping_competition(where: { competition_id: { _eq: $competition_id } }) {
      affected_rows
    }
    delete_statsbomb_competition(where: { competition_id: { _eq: $competition_id } }) {
      affected_rows
    }
  }
`;
const GET_COMPETITIONS_BY_ID = gql`
  query getCompetitionsById($entityIds: [Int!]) {
    statsbomb_golden_competition(where: { competition_id: { _in: $entityIds } }) {
      competition_type_id
      competition_name
      competition_ladder_rank
      competition_ladder_id
      competition_is_female
      competition_id
      competition_group_id
      area_id
      competition_type_name
      competition_ladder_name
      competition_group_name
      area_name
      area_code
    }
  }
`;
export {
  GOLDEN_COMPETITIONS,
  GOLDEN_COMPETITIONS_COUNT,
  ALL_CLAIM_COMPETITIONS,
  INSERT_SOURCE_COMPETITION,
  MAP_COMPETITION,
  COMPETITIONS,
  INSERT_COMPETITION_SOURCE_AND_MAPPING,
  DELETE_COMPETITION,
  DELETE_COMPETITION_BY_ID,
  GET_COMPETITIONS_BY_ID,
};
