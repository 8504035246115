import allQueries from '../hasura/queries';
import { pluralise } from '../util/string';
import mappingsView from '../views/mapping/index';

import * as teamSchemas from '../schema/teamSchema';
import * as playerSchemas from '../schema/playerSchema';
import * as areaSchemas from '../schema/areaSchema';
import * as competitionSchemas from '../schema/competitionSchema';
import * as competitionSeasonSchemas from '../schema/competitionSeasonSchema';
import * as lineupSchemas from '../schema/lineupSchema';
import * as fullLineupSchemas from '../schema/fullLineupSchema';
import * as matchSchemas from '../schema/matchSchema';
import * as stadiumSchemas from '../schema/stadiumSchema';
import * as managerSchemas from '../schema/managerSchema';
import * as officialSchemas from '../schema/officialSchema';
import * as roundScheams from '../schema/roundSchema';
import * as phaseSchemas from '../schema/phaseSchema';
import * as transferPlayerSchemas from '../schema/transferPlayerSchema';
import * as transferManagerSchemas from '../schema/transferManagerSchema';

const allSchemas = {
  ...teamSchemas,
  ...playerSchemas,
  ...areaSchemas,
  ...competitionSchemas,
  ...competitionSeasonSchemas,
  ...lineupSchemas,
  ...fullLineupSchemas,
  ...matchSchemas,
  ...stadiumSchemas,
  ...managerSchemas,
  ...officialSchemas,
  ...roundScheams,
  ...phaseSchemas,
  ...transferPlayerSchemas,
  ...transferManagerSchemas,
};

const RESULTS_PER_PAGE = 10;

const SECTIONS = [
  'player',
  'team',
  'competition',
  'competition_season',
  'phase',
  'round',
  'match',
  'lineup',
  'full_lineup',
  'official',
  'manager',
  'stadium',
  'area',
  'transfer_player',
  'transfer_manager',
];

const HIDDEN_GOLDEN_RECORDS_SECTIONS = ['lineup'];
const HIDDEN_CLAIMS_SECTIONS = ['competition', 'competition_season', 'full_lineup', 'lineup', 'phase'];

const SECTION_OVERRIDES = {
  lineup: {
    entityPK: ['match_id', 'team_id', 'player_id'],
  },
  full_lineup: {
    entityPK: ['match_id', 'match_home_team_id', 'match_away_team_id'],
    preventNewGoldenEntity: true,
  },
};

const createSection = (
  queries,
  schemas,
  {
    type,
    typeDisplay = type.replace('_', ' '),
    typePlural = pluralise(type),
    goldenPath = `/golden/${type}`,
    claimPath = `/claims/${type}`,
    selfPath = `/${type}`,
    unmappedPath = `/unmapped/${type}`,
    fetchEntities = queries[`GOLDEN_${typePlural.toUpperCase()}`],
    deleteEntityMutation = queries[`DELETE_${type.toUpperCase()}_BY_ID`],
    fetchEntityCount = queries[`GOLDEN_${typePlural.toUpperCase()}_COUNT`],
    fetchClaims = queries[`ALL_CLAIM_${typePlural.toUpperCase()}`],
    fetchMappedClaims = queries[`MAPPED_CLAIM_${typePlural.toUpperCase()}`],
    fetchUnmappedClaims = queries[`UNMAPPED_CLAIM_${typePlural.toUpperCase()}`],
    fetchById = queries[`GET_${typePlural.toUpperCase()}_BY_ID`],
    similarEntities = queries[`SIMILAR_${typePlural.toUpperCase()}`],
    claimSchema = schemas[`${type.toUpperCase()}_ALL_CLAIMS_SCHEMA`],
    entityPK = `${type.replace('-', '_')}_id`,
    schema = schemas[`GOLDEN_${type.toUpperCase()}_SCHEMA`],
    disabled = !schemas[`GOLDEN_${type.toUpperCase()}_SCHEMA`],
    goldenFilterSchema = schemas[`${type.toUpperCase()}_FILTER_SCHEMA`],
    claimFilter = schemas[`${type.toUpperCase()}_CLAIM_FILTER_SCHEMA`],
    minIqAttributes = schemas[`${type.toUpperCase()}_MIN_IQ_SCHEMA`],
    mappingsPage = mappingsView[`${type}`],
  },
  order = 0
) => {
  let conf = {
    type,
    typePlural,
    typeDisplay,
    goldenPath,
    claimPath,
    selfPath,
    unmappedPath,
    deleteEntityMutation,
    fetchEntities,
    fetchEntityCount,
    fetchClaims,
    fetchMappedClaims,
    fetchUnmappedClaims,
    fetchById,
    similarEntities,
    claimSchema,
    entityPK,
    schema,
    disabled,
    goldenFilterSchema,
    claimFilter,
    order,
    minIqAttributes,
    mappingsPage,
  };
  return SECTION_OVERRIDES[type] ? { ...conf, ...SECTION_OVERRIDES[type] } : conf;
};

const SECTION_CONFIG = SECTIONS.map(sec => ({ type: sec })).map((section, i) =>
  createSection(allQueries, allSchemas, section, i)
);

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_AND_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export {
  DATE_FORMAT,
  DATE_AND_TIME_FORMAT,
  SECTION_CONFIG,
  createSection,
  SECTIONS,
  RESULTS_PER_PAGE,
  HIDDEN_GOLDEN_RECORDS_SECTIONS,
  HIDDEN_CLAIMS_SECTIONS,
};
