import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { config } from 'dotenv';

export const hasuraClient = () => {
  config();

  const { REACT_APP_DATA_HOST, REACT_APP_DATA_PORT, REACT_APP_KEY } = process.env;
  const protocol = process.env.REACT_APP_DATA_HOST === 'localhost' ? 'ws' : 'wss';
  const HASURA_GRAPHQL_ENDPOINT = `${protocol}://${REACT_APP_DATA_HOST}:${REACT_APP_DATA_PORT}/v1/graphql`;

  const wsLink = new WebSocketLink({
    uri: HASURA_GRAPHQL_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          'x-hasura-admin-secret': REACT_APP_KEY,
        },
      },
    },
  });

  return new ApolloClient({
    link: wsLink,
    cache: new InMemoryCache({ addTypename: false }),

    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
