import React, { useEffect, useState } from 'react';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import {
  Button,
  Checkbox,
  TableHead,
  TableRow,
  Table,
  TableBody,
  makeStyles,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { TableCell, TableRowWithNote } from '../table/table';
import { InlineProgress } from '../util/progress';

const useStyles = makeStyles({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const OpenClaims = ({
  entityType,
  claimPK,
  claimsResponsePath,
  schema,
  setErrorMessage,
  setSuccessMessage,
  matchData,
  selectedTeam,
  openClaimsQuery,
  approveClaimMutation,
  rejectClaimMutation,
  unmappedClaimsQuery,
  unmappedClaimsPath,
  refetchData,
}) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const [approveEntityClaim, { loading: approveClaimLoading }] = useMutation(approveClaimMutation, {
    onError: err => setErrorMessage('Problem updating claims: ' + err),
    onCompleted: () => {
      setSuccessMessage('Claim Updated!');
      refetchData();
    },
  });

  const approveClaim = async entity => {
    const commonVariables = {
      ...{
        match_id: matchData.id,
        [`${entityType}_id`]: entity[`${entityType}_id`],
        [claimPK]: entity[claimPK],
      },
    };
    const variablesSchema = {
      player: {
        team_id: selectedTeam,
      },
      manager: {
        team_id: selectedTeam,
      },
      official: {
        official_type: entity['official_type'],
      },
    };
    const variables = { ...variablesSchema[`${entityType}`], ...commonVariables };
    await approveEntityClaim({
      variables,
    });
  };

  const [rejectEntityClaim, { loading: rejectClaimLoading }] = useMutation(rejectClaimMutation, {
    onError: err => setErrorMessage('Problem updating claims: ' + err),
    onCompleted: () => setSuccessMessage('Claim Updated!'),
  });

  const { loading: claimsResponseLoading, data: claimsResponse } = useSubscription(openClaimsQuery, {
    variables: { match_id: matchData.id },
  });

  const { loading: unmappedClaimsLoading, data: unmappedClaimsData } = useSubscription(unmappedClaimsQuery, {
    variables: { match_id: matchData.id, team_id: selectedTeam },
  });
  const rejectClaim = async claim_uuid => {
    const variables = {};
    variables[claimPK] = claim_uuid;

    await rejectEntityClaim({
      variables,
    });
  };

  const isLoading = claimsResponseLoading || approveClaimLoading || rejectClaimLoading;

  const claimsData = claimsResponse && claimsResponse[claimsResponsePath];

  const claims = claimsData
    ? {
        home: claimsData.filter(claim => claim.team_id === matchData.homeTeam?.id),
        away: claimsData.filter(claim => claim.team_id === matchData.awayTeam?.id),
        official: claimsData,
      }
    : {};

  const entityClaims =
    claims[entityType === 'official' ? 'official' : selectedTeam === matchData.homeTeam?.id ? 'home' : 'away'];

  const ClaimRow = ({ entity }) => {
    return (
      <TableRow>
        {entityType === 'player' && <TableCell>{entity[schema.lineupJersey]}</TableCell>}
        <TableCell>{entity[schema.name]}</TableCell>
        <TableCell>{entity[schema.selectionType]}</TableCell>
        <TableCell>{entity.deleted !== null && <Checkbox disabled checked={entity.deleted} />}</TableCell>
        <TableCell>
          <div style={{ float: 'right' }}>
            <Button
              style={{ margin: 2, padding: 2, fontSize: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => approveClaim(entity)}>
              Approve
            </Button>
            <Button
              style={{ margin: 2, padding: 2, fontSize: 10 }}
              variant="contained"
              color="secondary"
              onClick={() => rejectClaim(entity[claimPK])}>
              Reject
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const hasEntities = entityClaims && entityClaims.length > 0;
  const unmappedClaims = unmappedClaimsData?.[unmappedClaimsPath];

  return (
    <div>
      <div className={classes.tableHeader}>
        <h4>Open Claims</h4>
        {unmappedClaims?.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'capitalize' }}
            onClick={() => setOpenModal(true)}>
            View Unmapped Claims
          </Button>
        )}
      </div>
      {unmappedClaimsLoading ? (
        <InlineProgress />
      ) : (
        <UnmappedClaimsDialog type={entityType} claims={unmappedClaims} {...{ openModal, setOpenModal }} />
      )}
      {isLoading ? (
        <InlineProgress />
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                {entityType === 'player' && <TableCell>#</TableCell>}
                <TableCell>Name</TableCell>
                <TableCell>Selection Type</TableCell>
                <TableCell>Deleted</TableCell>
                <TableCell>
                  {hasEntities && (
                    <Button
                      style={{ padding: '5 10', float: 'right' }}
                      variant="contained"
                      color="primary"
                      onClick={() => entityClaims.forEach(entity => approveClaim(entity))}>
                      Approve All Claims
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hasEntities ? (
                entityClaims.map(entity => <ClaimRow key={entity[claimPK]} {...{ entity }} />)
              ) : (
                <TableRowWithNote note={`Claims for ${entityType} will appear here`} />
              )}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

const UnmappedClaimsDialog = ({ type, claims, openModal, setOpenModal }) => {
  useEffect(() => {
    if (claims.length <= 0) {
      setOpenModal(false);
    }
  }, [claims.length]);
  return (
    <Dialog open={openModal} maxWidth="md" onClose={() => setOpenModal(false)}>
      <DialogTitle>Unmapped {type} claims</DialogTitle>
      <DialogContent dividers style={{ maxHeight: 400, padding: 0 }}>
        {claims
          .filter(claim => !claim[`mapping_${type}`])
          .map((item, index) => {
            const entityId = `provider_${type}_id`;
            const linkStr = `/unmapped/${type}?${entityId}=${item[entityId]}&provider=${item['provider']}`;
            return (
              <div key={index} style={{ padding: '10px 0 10px 5px', borderBottom: '1px solid black' }}>
                <Link href={linkStr} target="_blank" style={{ fontSize: 16 }}>
                  {item[`source_${type}`][`${type}_name`]}
                </Link>
              </div>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpenClaims;
