import React, { useEffect, useState } from 'react';
import { Route, Switch, Link, Redirect, useLocation, useHistory } from 'react-router-dom';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { ApolloProvider } from '@apollo/react-hooks';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { hasuraClient } from '../hasura';
import { SECTION_CONFIG } from '../util/consts';
import { capitalise } from '../util/string';
import Page from '../components/view/Page';
import { goldenRecordSections } from '../util/schema';
import { oktaAuthConfig } from '../AuthConfig';
import Scrape from './Scrape';
import GoldenEntity from './golden/GoldenEntity';
import Claims from './claims/Claims';
import Home from './Home';
import NotFound from './NotFound';
import * as Views from './index';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#001782',
      light: '#4b3fb3',
      dark: '#000054',
      contrastText: '#fff',
    },
    secondary: {
      main: '#eee',
      light: '#fff',
      dark: '#bcbcbc',
    },
  },
});

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  activeTab: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
}));

const Routes = () => {
  const classes = useStyles();
  const location = useLocation();

  const [currentRoute, setCurrentRoute] = useState('/');

  useEffect(() => setCurrentRoute(location.pathname), [location.pathname]);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Gatekeeper
          </Typography>
          <Button color="inherit" className={currentRoute === '/' ? classes.activeTab : ''} component={Link} to="/">
            Home
          </Button>
          <Button
            color="inherit"
            className={currentRoute === '/scrape' ? classes.activeTab : ''}
            component={Link}
            to="/scrape">
            Scrape
          </Button>
          <Button
            color="inherit"
            className={currentRoute.includes('/golden') ? classes.activeTab : ''}
            component={Link}
            to="/golden">
            Golden Records
          </Button>
          <Button
            color="inherit"
            className={currentRoute.includes('/claims') ? classes.activeTab : ''}
            component={Link}
            to="/claims">
            Claims
          </Button>
          <Button
            color="inherit"
            className={currentRoute === '/competition_structure' ? classes.activeTab : ''}
            component={Link}
            to="/competition_structure">
            Competition Structure
          </Button>
          <Button
            color="inherit"
            className={currentRoute === '/group' ? classes.activeTab : ''}
            component={Link}
            to="/group?new=new">
            Groups
          </Button>
        </Toolbar>
      </AppBar>

      <main className="content">
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="/claims" exact={true}>
            <Redirect to="/claims/player" />
          </SecureRoute>
          <SecureRoute path="/golden" exact={true}>
            <Redirect to="/golden/player" />
          </SecureRoute>
          <SecureRoute
            path="/claims"
            render={() => (
              <Page title="Claims">
                <Claims />
              </Page>
            )}
          />
          <SecureRoute
            path="/competition_structure"
            render={props => (
              <Page title="Competition Structure">
                <Views.CompetitionStructure {...props} />
              </Page>
            )}
          />
          <SecureRoute
            path="/group"
            render={props => (
              <Page title="Group">
                <Views.Group {...props} />
              </Page>
            )}
          />
          {goldenRecordSections.map((section, i) => (
            <SecureRoute
              path={section.goldenPath}
              key={i}
              exact={true}
              render={() => (
                <Page title={capitalise(section.type)}>
                  <GoldenEntity {...section} />
                </Page>
              )}
            />
          ))}

          {SECTION_CONFIG.map((section, i) => (
            <SecureRoute
              path={section.selfPath}
              key={i}
              exact={true}
              render={props => {
                const Component = Views[capitalise(section.type.replace(/_(\w)/, (_, letter) => letter.toUpperCase()))]; // eslint-disable-line import/namespace
                return (
                  <Page title={capitalise(section.type)}>
                    <Component {...props} />
                  </Page>
                );
              }}
            />
          ))}

          <SecureRoute
            path="/competition_structure"
            render={props => (
              <Page title="Competition Structure">
                <Views.CompetitionStructure {...props} />
              </Page>
            )}
          />

          <SecureRoute
            path="/group"
            render={props => (
              <Page title="Group">
                <Views.Group {...props} />
              </Page>
            )}
          />

          <SecureRoute
            path="/transfer-history"
            render={props => (
              <Page title="Transfer History">
                <Views.TransferHistory {...props} />
              </Page>
            )}
          />

          <SecureRoute
            path="/team-squad"
            render={props => (
              <Page title="Team Squad">
                <Views.TeamSquad {...props} />
              </Page>
            )}
          />

          {/* // TODO */}
          <SecureRoute
            path="/delete"
            render={props => (
              <Page title="Deletion View">
                <Views.DeletionView {...props} />
              </Page>
            )}
          />

          {SECTION_CONFIG.map(
            ({ mappingsPage: MappingsPage, type }) =>
              MappingsPage && (
                <SecureRoute
                  key={`/unmapped/${type}`}
                  path={`/unmapped/${type}`}
                  render={props => (
                    <Page title={`Unmapped ${type}`}>
                      <MappingsPage {...props} />
                    </Page>
                  )}
                />
              )
          )}

          <SecureRoute
            path="/scrape"
            render={props => (
              <Page title="Scrape">
                <Scrape {...props} />
              </Page>
            )}
          />
          <Route
            path="/"
            exact={true}
            render={props => (
              <Page title="Home">
                <Home {...props} />
              </Page>
            )}
          />
          <Route
            path="/"
            render={props => (
              <Page title="404 - Not Found">
                <NotFound {...props} />
              </Page>
            )}
          />
        </Switch>
      </main>
    </>
  );
};

const Main = () => {
  const oktaAuth = new OktaAuth(oktaAuthConfig);

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri ? originalUri : '', window.location.origin));
  };

  return (
    <ApolloProvider client={hasuraClient()}>
      <ThemeProvider theme={theme}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes />
        </Security>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default Main;
