import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { ROUND_STRUCTURE } from '../../hasura/queries/round/roundStructure';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const RoundStructure = ({ overrideChange, currentValue, columnKey, ...props }) => {
  const { loading, error, data } = useQuery(ROUND_STRUCTURE, {});

  if (loading) return <InlineProgress />;

  const nullValue = -1;

  const items = data?.statsbomb_round_structure || [];

  return (
    <>
      <Error message="Error fetching Round Structures" error={error} />

      <InputLabel id="round-structure-input-label">Round Structure</InputLabel>
      <Select
        labelId="round-structure-input-label"
        onChange={e => overrideChange(columnKey, e.target.value === nullValue ? null : e.target.value)}
        value={currentValue || nullValue}
        {...props}>
        <MenuItem value={nullValue}>None</MenuItem>
        {items.map((item, key) => (
          <MenuItem key={key} value={item.round_structure_id}>
            {item.round_structure_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { RoundStructure };
