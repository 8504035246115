import gql from 'graphql-tag';

const INSERT_GROUP = gql`
  mutation InsertTeamRoundGroup($team_id: Int) {
    insert_statsbomb_team_round_group(objects: { team_id: $team_id }) {
      returning {
        team_id
      }
    }
  }
`;
const SUGGESTED_TEAMS = gql`
  query SuggestedTeam($round_id: Int!) {
    statsbomb_team_match_aggregate(where: { round_id: { _eq: $round_id } }) {
      team_name
      team_id
      area_code
    }
  }
`;

const INSERT_GOLDEN_TEAM_ROUND_GROUP = gql`
  mutation insertGoldenTeamRoundGroup($group_name: String!, $round_id: Int!, $team_id: Int!) {
    insert_statsbomb_team_round_group(
      objects: { group_name: $group_name, round_id: $round_id, team_id: $team_id, deleted: false }
      on_conflict: { constraint: team_round_group_pkey, update_columns: deleted }
    ) {
      affected_rows
    }
  }
`;
const GOLDEN_GROUPS_AND_TEAMS = gql`
  subscription goldenGroupsAndTeams($round_id: Int!) {
    statsbomb_team_round_group(where: { round_id: { _eq: $round_id }, deleted: { _eq: false } }) {
      group_name
      team_id
      statsbomb_team {
        team_name
        team_id
      }
    }
  }
`;
const ALL_GROUP_NAME = gql`
  query AllGroupsNames {
    statsbomb_group_name {
      selection_type: group_name
    }
  }
`;
const DELETE_TEAM_ROUND_GROUP = gql`
  mutation deleteTeamRoundGroup($team_id: Int!) {
    update_statsbomb_team_round_group(where: { team_id: { _eq: $team_id } }, _set: { deleted: true }) {
      affected_rows
    }
  }
`;
const OVERRIDE_TEAM_ROUND_GROUP_CLAIM = gql`
  mutation team_round_group_override(
    $team_id: String!
    $round_id: String!
    $group_name: String!
    $source_entity: source_team_round_group_set_input!
  ) {
    insert_source_team_round_group(
      objects: {
        provider: Info_Team
        provider_team_id: $team_id
        provider_round_id: $round_id
        group_name: $group_name
      }
      on_conflict: { constraint: team_round_group_pkey, update_columns: [last_updated] }
    ) {
      returning {
        provider_round_id
        provider_team_id
      }
    }
    update_source_team_round_group(
      where: {
        provider: { _eq: Info_Team }
        provider_team_id: { _eq: $team_id }
        provider_round_id: { _eq: $round_id }
      }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;
export {
  INSERT_GROUP,
  SUGGESTED_TEAMS,
  INSERT_GOLDEN_TEAM_ROUND_GROUP,
  GOLDEN_GROUPS_AND_TEAMS,
  ALL_GROUP_NAME,
  DELETE_TEAM_ROUND_GROUP,
  OVERRIDE_TEAM_ROUND_GROUP_CLAIM,
};
