import { useQuery } from '@apollo/react-hooks';
import { CLAIMS_PLAYERS_COUNT } from '../../hasura/queries/player/playerClaims';
import { CLAIMS_TEAMS_COUNT } from '../../hasura/queries/team/teamClaims';
import { CLAIMS_COMPETITIONS_COUNT } from '../../hasura/queries/competition/competitionClaims';
import { CLAIMS_COMPETITION_SEASONS_COUNT } from '../../hasura/queries/competitionSeason/competitionSeasonClaims';
import { CLAIMS_PHASES_COUNT } from '../../hasura/queries/phase/phaseClaims';
import { CLAIMS_ROUNDS_COUNT } from '../../hasura/queries/round/roundClaims';
import { CLAIMS_LINEUPS_COUNT } from '../../hasura/queries/lineup/lineupClaims';
import { CLAIMS_MATCHES_COUNT } from '../../hasura/queries/match/matchClaims';
import { CLAIMS_OFFICIALS_COUNT } from '../../hasura/queries/official/officialClaims';
import { CLAIMS_MANAGERS_COUNT } from '../../hasura/queries/manager/managerClaims';
import { CLAIMS_STADIUMS_COUNT } from '../../hasura/queries/stadium/stadiumClaims';
import { CLAIMS_AREAS_COUNT } from '../../hasura/queries/area/areaClaims';
import { CLAIMS_TRANSFER_PLAYER_COUNT } from '../../hasura/queries/transferPlayer/transferPlayerClaims';
import { CLAIMS_TRANSFER_MANAGERS_COUNT } from '../../hasura/queries/transferManager/transferManagerClaims';

const useAllClaimsCount = schema => {
  const { loading: playerLoading, data: playerCount } = useQuery(CLAIMS_PLAYERS_COUNT);
  const { loading: teamLoading, data: teamCount } = useQuery(CLAIMS_TEAMS_COUNT);
  const { loading: competitionLoading, data: competitionCount } = useQuery(CLAIMS_COMPETITIONS_COUNT);
  const { loading: compsznLoading, data: compsznCount } = useQuery(CLAIMS_COMPETITION_SEASONS_COUNT);
  const { loading: phaseLoading, data: phaseCount } = useQuery(CLAIMS_PHASES_COUNT);
  const { loading: roundLoading, data: roundCount } = useQuery(CLAIMS_ROUNDS_COUNT);
  const { loading: matchLoading, data: matchCount } = useQuery(CLAIMS_MATCHES_COUNT);
  const { loading: lineupLoading, data: lineupCount } = useQuery(CLAIMS_LINEUPS_COUNT);
  const { loading: officialLoading, data: officialCount } = useQuery(CLAIMS_OFFICIALS_COUNT);
  const { loading: managerLoading, data: managerCount } = useQuery(CLAIMS_MANAGERS_COUNT);
  const { loading: stadiumLoading, data: stadiumCount } = useQuery(CLAIMS_STADIUMS_COUNT);
  const { loading: areaLoading, data: areaCount } = useQuery(CLAIMS_AREAS_COUNT);
  const { loading: transferPlayerLoading, data: transferPlayerCount } = useQuery(CLAIMS_TRANSFER_PLAYER_COUNT);
  const { loading: transferManagerLoading, data: transferManagerCount } = useQuery(CLAIMS_TRANSFER_MANAGERS_COUNT);

  const loading =
    playerLoading ||
    teamLoading ||
    competitionLoading ||
    compsznLoading ||
    phaseLoading ||
    roundLoading ||
    lineupLoading ||
    matchLoading ||
    officialLoading ||
    managerLoading ||
    stadiumLoading ||
    areaLoading ||
    transferPlayerLoading ||
    transferManagerLoading;

  if (loading) return;

  const claimData = {
    player: playerCount,
    team: teamCount,
    competition: competitionCount,
    competition_season: compsznCount,
    phase: phaseCount,
    round: roundCount,
    lineup: lineupCount,
    match: matchCount,
    official: officialCount,
    manager: managerCount,
    stadium: stadiumCount,
    area: areaCount,
    transfer_player: transferPlayerCount,
    transfer_manager: transferManagerCount,
  };
  return schema.reduce((acc, curr) => {
    const entityCount = claimData?.[curr][`claim_${curr}_count`][0];
    const mappedCount = entityCount?.mapped_count;
    const unmappedCount = entityCount?.total_count - mappedCount;
    const recentCount = entityCount?.recent_count;
    return {
      ...acc,
      [curr]: {
        unmappedCount,
        mappedCount,
        recentCount,
      },
    };
  }, {});
};

export { useAllClaimsCount };
