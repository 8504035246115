import gql from 'graphql-tag';

export const COMPETITION_GROUPS = gql`
  query competitionGroups {
    statsbomb_competition_group(order_by: { competition_group_name: asc }) {
      competition_group_name
      competition_group_id
    }
  }
`;
