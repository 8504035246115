import React from 'react';
import { withStyles, Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'black',
    width: 'auto',
    maxWidth: 300,
    fontSize: 12.25,
    border: '1px solid black',
  },
})(Tooltip);

const TooltipComponent = ({ tooltipTitle, tooltipText, ...props }) => {
  return (
    <CustomTooltip
      placement="left"
      title={
        <>
          {tooltipTitle && <h4>{tooltipTitle}</h4>}
          <p>{tooltipText}</p>
        </>
      }>
      {props.children}
    </CustomTooltip>
  );
};

export { TooltipComponent };
