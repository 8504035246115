import React from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const dateFormat = dt => moment(dt).format('YYYY-MM-DD');

const dateYearMinusEighteen = dt => moment(dt).subtract(18, 'years').format('YYYY-MM-DD');

const DateOverride = ({ columnKey, currentValue, overrideChange, ...props }) => {
  const { overrideProps, initialOverrideValue, ...domProps } = props;
  return (
    <TextField
      type="date"
      label="Date"
      value={currentValue ? dateFormat(currentValue) : dateFormat()}
      onChange={e => e.target.value && overrideChange(columnKey, dateFormat(e.target.value))}
      InputLabelProps={{
        shrink: true,
      }}
      {...domProps}
    />
  );
};

const TimeOverride = ({ overrideChange, columnKey, currentValue }) => {
  return (
    <TextField
      type="time"
      label="Time"
      value={currentValue}
      onChange={e => e.target.value && overrideChange(columnKey, e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export { DateOverride, dateFormat, TimeOverride, dateYearMinusEighteen };
