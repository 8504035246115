import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TextField, Checkbox } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { FlagIcon, GenderIcon } from '../util/icon';
import { truncate } from '../../util/string';
import { nestedValues } from '../../util/useful';

const useStyles = makeStyles({
  root: { marginLeft: 10, margin: '5px 0' },
  fullWidth: { width: '100%' },
  textFilterModal: {
    width: '100%',
    margin: '5px 0',
  },
  textFilterMainPage: {
    margin: 5,
  },
  dateFilterModal: { width: '100%', margin: '5px 0' },
  dateFilterMainPage: { marginRight: 10 },
  areaFilter: { padding: 0, fontSize: 8, width: 200, margin: 5 },
  listFilter: { padding: 0, fontSize: 8, width: 250, margin: 5 },
  booleanFilter: { maxHeight: 80, minWidth: 120, margin: '0px 5px' },
  booleanModal: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  option: {
    fontSize: 12,
    '& > span': {
      marginRight: 5,
      fontSize: 14,
    },
  },
});

const TextFilter = ({ filter, updateFilter, attributes, onModal, type = 'text', hint = 'Search' }) => {
  const classes = useStyles();
  const label = attributes ? attributes.displayName : hint;
  const key = attributes ? attributes.key : 'text';
  const value = filter?.[key] || '';
  return (
    <TextField
      type={type}
      className={onModal ? classes.textFilterModal : classes.textFilterMainPage}
      label={label}
      onChange={({ target }) => updateFilter(key, target.value)}
      value={value}
    />
  );
};

const DateFilter = ({ filter, attributes, updateFilter, onModal }) => {
  const classes = useStyles();

  const { key, displayName } = attributes;
  const value = filter[key] || '';

  return (
    <>
      <TextField
        label={displayName}
        className={onModal ? classes.dateFilterModal : classes.dateFilterMainPage}
        type="date"
        value={value}
        onChange={({ target }) => updateFilter(key, target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

const ListFilter = ({ filter, updateFilter, onModal, attributes, type }) => {
  const classes = useStyles();

  const { displayName: label, key, flagIconPath, genderIconPath, searchQuery, idPath, variablePaths } = attributes;
  const multipleKeyEntity = variablePaths.length > 1;
  const maxLengthString = 35;

  const { data } = useQuery(searchQuery, {});
  const entities = data?.[`statsbomb_${type}`] || [];

  const getDisplayName = entity =>
    !multipleKeyEntity
      ? entity[variablePaths[0]]
      : variablePaths
          .reduce((acc, currentValue) => {
            const newValue = nestedValues(currentValue)(entity) ? nestedValues(currentValue)(entity) + ' ' : '';
            return (acc += newValue);
          }, '')
          .trimEnd();

  const getFilterValue = entity => entity[idPath];

  const value = !multipleKeyEntity
    ? {
        [idPath]: filter[key]?.queryValue || '',
      }
    : entities.filter(entity => entity[idPath] === filter[key]?.queryValue)[0] || '';

  return (
    <Autocomplete
      className={onModal ? classes.fullWidth : classes.listFilter}
      classes={{ option: classes.option }}
      value={value}
      options={entities}
      filterOptions={createFilterOptions({ ignoreAccents: false, limit: 300 })}
      disableClearable
      getOptionLabel={entity => getDisplayName(entity)}
      getOptionSelected={(entity, value) => getFilterValue(entity) === getFilterValue(value)}
      renderOption={entity => (
        <>
          <FlagIcon code={nestedValues(flagIconPath)(entity)} />
          {genderIconPath && <GenderIcon isFemale={nestedValues(genderIconPath)(entity)} />}
          {truncate(getDisplayName(entity), maxLengthString)}
        </>
      )}
      renderInput={params => (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          id="input-with-icon-textfield"
          {...params}
          label={label}
          inputProps={{ ...params.inputProps }}
        />
      )}
      onChange={(_, entity) =>
        updateFilter(key, {
          displayValue: getDisplayName(entity),
          queryValue: getFilterValue(entity),
        })
      }
    />
  );
};

const BooleanFilter = ({ filter, updateFilter, modalOpen, attributes }) => {
  const classes = useStyles();

  const { key, displayName } = attributes;
  const value = filter[key] || false;

  return (
    <div className={modalOpen ? classes.booleanModal : classes.booleanFilter}>
      <span>{displayName}</span>
      <Checkbox color="primary" checked={value} onChange={(_, value) => updateFilter(key, value)} />
    </div>
  );
};

const PaginationFilter = ({ pages, paginationButtonHandler, curPage, ...props }) => {
  return <Pagination count={pages} page={curPage} onChange={(e, value) => paginationButtonHandler(value)} {...props} />;
};

export { TextFilter, ListFilter, DateFilter, BooleanFilter, PaginationFilter };
