import React from 'react';
import { TextField } from '@material-ui/core';
import { nestedValues } from '../util/useful';
import { AreaOverride } from '../components/override/area';
import { TextOverride } from '../components/override/text';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { CompetitionGroup } from '../components/override/competitionGroup';
import { CompetitionType } from '../components/override/competitionType';
import { CompetitionLadder } from '../components/override/competitionLadder';
import { ListFilter, TextFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { FlagIcon } from '../components/util/icon';
import { AREAS } from '../hasura/queries/area/areas';

const FULL_COMPETITION_SCHEMA = [
  { columnKey: 'claim_competition_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'competition_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  { columnKey: 'competition_name', name: 'Name', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'competition_group_name', name: 'Category', type: 'String' },
  {
    columnKey: 'competition_type_id',
    name: 'Type Id',
    type: 'String',
    mapping_function: nestedValues('statsbomb_competition_type.competition_type_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_competition_type.competition_type_name'),
    OverrideControl: CompetitionType,
  },
  { columnKey: 'competition_type_name', name: 'Type', type: 'String' },
  {
    columnKey: 'area_name',
    name: 'Area',
    type: 'String',
    icon: e => <FlagIcon code={e.area_code} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'area_id',
    name: 'Area',
    type: 'String',
    mapping_function: nestedValues('mapping_area.statsbomb_area.area_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'competition_ladder_id',
    name: 'Ladder Id',
    type: 'String',
    mapping_function: nestedValues('statsbomb_competition_ladder.competition_ladder_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_competition_ladder.competition_ladder_name'),
    OverrideControl: CompetitionLadder,
  },
  { columnKey: 'competition_ladder_name', name: 'Ladder', type: 'String' },
  {
    columnKey: 'competition_ladder_rank',
    name: 'Ladder Rank',
    type: 'Integer',
    OverrideControl: function ({ overrideChange, columnKey }) {
      return <TextField type="number" onChange={({ target: { value } }) => overrideChange(columnKey, value)} />;
    },
  },
  {
    columnKey: 'competition_group_id',
    name: 'Category',
    type: 'String',
    mapping_function: nestedValues('statsbomb_competition_group.competition_group_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_competition_group.competition_group_name'),
    OverrideControl: CompetitionGroup,
  },
  {
    columnKey: 'competition_is_female',
    name: 'Female?',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  { columnKey: 'deleted', name: 'Deleted?', type: 'Boolean' },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_competition_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const COMPETITION_ALL_CLAIMS_SCHEMA = FULL_COMPETITION_SCHEMA.filter(a =>
  [
    'claim_competition_uuid',
    'competition_name',
    'competition_type_name',
    'competition_ladder_name',
    'competition_ladder_rank',
    'competition_group_name',
    'competition_is_female',
    'provider',
    'provider_competition_id',
    'created_at',
  ].includes(a.columnKey)
);

const GOLDEN_COMPETITION_SCHEMA = FULL_COMPETITION_SCHEMA.filter(field =>
  [
    'competition_name',
    'competition_type_name',
    'competition_ladder_name',
    'competition_ladder_rank',
    'competition_group_name',
    'area_name',
    'competition_is_female',
    'min_spec',
  ].includes(field.columnKey)
);

const COMPETITION_SCHEMA = FULL_COMPETITION_SCHEMA.filter(a =>
  [
    'competition_name',
    'competition_type_id',
    'competition_ladder_id',
    'competition_ladder_rank',
    'competition_group_id',
    'competition_is_female',
    'area_id',
    'deleted',
  ].includes(a.columnKey)
);

const COMPETITION_CLAIM_SCHEMA = [
  'competition_name',
  'competition_type_id',
  'competition_ladder_id',
  'competition_ladder_rank',
  'competition_group_id',
  'area_id',
];

const COMPETITION_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
  },
  {
    type: 'area',
    Component: ListFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Area',
      flagIconPath: 'area_code',
      searchQuery: AREAS,
      idPath: 'area_name',
      variablePaths: ['area_name'],
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
];

const COMPETITION_MIN_IQ_SCHEMA = FULL_COMPETITION_SCHEMA.filter(a =>
  ['competition_name', 'competition_type_name', 'area_id'].includes(a.columnKey)
);

export {
  FULL_COMPETITION_SCHEMA,
  GOLDEN_COMPETITION_SCHEMA,
  COMPETITION_SCHEMA,
  COMPETITION_CLAIM_SCHEMA,
  COMPETITION_FILTER_SCHEMA,
  COMPETITION_MIN_IQ_SCHEMA,
  COMPETITION_ALL_CLAIMS_SCHEMA,
};
