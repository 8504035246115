import gql from 'graphql-tag';

const GOLDEN_OFFICIALS = gql`
  query getGoldenOfficials(
    $name: String
    $area: String
    $start_date: date
    $min_iq: Boolean
    $end_date: date
    $duplicate_count: bigint = 0
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_official_order_by!
  ) {
    statsbomb_golden_official(
      where: {
        _and: [
          {
            official_name: { _ilike: $name }
            official_nationality_name: { _ilike: $area }
            official_date_of_birth: { _gte: $start_date, _lte: $end_date }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      official_id
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      official_nationality_name
      official_nationality_code
      official_is_female
      official_photo_url
      deleted
      min_spec
    }
  }
`;

const GOLDEN_OFFICIALS_COUNT = gql`
  query getGoldenOfficialsCount(
    $name: String
    $area: String
    $start_date: date
    $end_date: date
    $duplicate_count: bigint = 0
    $min_iq: Boolean
    $deleted: Boolean = false
  ) {
    statsbomb_golden_official_aggregate(
      where: {
        _and: [
          {
            official_name: { _ilike: $name }
            official_nationality_name: { _ilike: $area }
            official_date_of_birth: { _gte: $start_date, _lte: $end_date }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_SOURCE_AND_MAP_OFFICIAL = gql`
  mutation insertSourceAndMapOfficial($official_id: Int!, $provider_official_id: String!, $official_name: String!) {
    insert_source_official(
      objects: {
        provider_official_id: $provider_official_id
        official_name: $official_name
        last_updated: "now()"
        provider: Info_Team
      }
    ) {
      returning {
        last_updated
      }
    }
    insert_mapping_official_one(
      object: { official_id: $official_id, provider: Info_Team, provider_official_id: $provider_official_id }
    ) {
      official_id
      provider
      provider_official_id
    }
  }
`;

const OFFICIAL_TYPES = gql`
  query getOfficialTypes {
    statsbomb_official_type(order_by: { sort_order: asc }) {
      selection_type: official_type
      display_name
    }
  }
`;

const SIMILAR_OFFICIALS = gql`
  query similarOfficials($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_officials(
      args: { official_name_var: $name, provider_var: $provider, o: $offset }
      order_by: { name_similarity: desc_nulls_last }
    ) {
      official_id
      official_name
      name_similarity
      mapped
      official_date_of_birth
      official_nationality
    }
  }
`;

const GOLDEN_OFFICIALS_FOR_MATCH = gql`
  query goldenOfficialsForMatch($match_id: Int!) {
    statsbomb_match_official(
      where: { match_id: { _eq: $match_id }, deleted: { _neq: true } }
      order_by: { statsbomb_official: { official_name: asc } }
    ) {
      match_id
      official_id
      statsbomb_official {
        official_name
      }
      official_type
      deleted
    }
  }
`;

const GOLDEN_OFFICIALS_FOR_MATCH_OFFICIALS_INCLUSIONS = gql`
  query goldenOfficialsForMatchOfficialsInclusions($search_string: String, $search_int: Int) {
    statsbomb_official(
      where: { _or: [{ official_name: { _ilike: $search_string } }, { official_id: { _eq: $search_int } }] }
      order_by: { official_name: asc }
      limit: 15
    ) {
      official_id
      official_name
      official_date_of_birth
      statsbomb_area {
        area_id
        area_code
        area_name
      }
    }
  }
`;

const SUGGESTED_OFFICIALS = gql`
  query suggestedOfficialsForLineup($round_id: Int!) {
    statsbomb_match_officials_aggregate(
      where: { match_round_id: { _eq: $round_id } }
      order_by: { official_name: asc, official_id: asc }
      distinct_on: [official_id, official_name]
    ) {
      official_id
      official_name
      appearances
    }
  }
`;
const DELETE_OFFICIAL = gql`
  mutation DeleteOfficials($official_id: Int!) {
    update_statsbomb_official_by_pk(pk_columns: { official_id: $official_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;
const GET_OFFICIALS_BY_ID = gql`
  query getOfficialsByIds($entityIds: [Int!]) {
    statsbomb_golden_official(where: { official_id: { _in: $entityIds } }) {
      official_id
      official_name
      official_nickname
      official_date_of_birth
      official_nationality
      official_nationality_name
      official_nationality_code
      official_is_female
      official_photo_url
    }
  }
`;
const DELETE_OFFICIAL_BY_ID = gql`
  mutation deleteOfficial($official_id: Int!) {
    delete_claim_official(where: { mapping_official: { official_id: { _eq: $official_id } } }) {
      affected_rows
    }
    delete_mapping_official(where: { official_id: { _eq: $official_id } }) {
      affected_rows
    }
    delete_statsbomb_official(where: { official_id: { _eq: $official_id } }) {
      affected_rows
    }
  }
`;
export {
  GOLDEN_OFFICIALS,
  GOLDEN_OFFICIALS_COUNT,
  INSERT_SOURCE_AND_MAP_OFFICIAL,
  OFFICIAL_TYPES,
  SIMILAR_OFFICIALS,
  SUGGESTED_OFFICIALS,
  GOLDEN_OFFICIALS_FOR_MATCH,
  GOLDEN_OFFICIALS_FOR_MATCH_OFFICIALS_INCLUSIONS,
  DELETE_OFFICIAL,
  GET_OFFICIALS_BY_ID,
  DELETE_OFFICIAL_BY_ID,
};
