import React from 'react';
import { Button } from '@material-ui/core';
import { nestedValues } from '../../util/useful';

const ClaimMappingButtons = ({ displayName, claim, similarRecord, schema, mapEntityFn, createNewEntityFn }) => {
  const allAttributesMapped = schema.reduce((acc, curr) => {
    if (!nestedValues(curr.mappingObjectPath)(claim)) return false;
    return acc;
  }, true);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 5px' }}>
      {allAttributesMapped ? (
        <>
          <Button variant="contained" color="secondary" onClick={createNewEntityFn}>
            Create New {displayName}
          </Button>
          {similarRecord && (
            <Button variant="contained" color="primary" onClick={mapEntityFn}>
              Map to Selected {displayName}
            </Button>
          )}
        </>
      ) : (
        schema.map((item, index) => {
          const isMapped = nestedValues(item.mappingObjectPath)(claim);
          return (
            <div key={`mapping_button_${index}`} style={{ display: 'inline-block', paddingLeft: 5 }}>
              {!isMapped && (
                <Button
                  style={{ backgroundColor: '#D7F761' }}
                  variant="contained"
                  href={`../claims/${item.type}`}
                  target="_blank">
                  {`Map ${item.displayName} to proceed`}
                </Button>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ClaimMappingButtons;
