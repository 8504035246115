import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_TRANSFER_PLAYER_BY_PROVIDER } from '../../hasura/queries/transferPlayer/transferPlayerClaims';
import TransferPlayerMapping from '../../components/transferPlayer/transferPlayerMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

const TransferPlayerMapper = () => {
  const query = new URLSearchParams(useLocation().search); //get provider playerid from the query string ~ better than using props?
  const providerTransferPlayerId = query.get('provider_transfer_player_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_TRANSFER_PLAYER_BY_PROVIDER, {
    variables: {
      provider_transfer_player_id: providerTransferPlayerId,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Transfer Player" error={error} />
      <TransferPlayerMapping
        claims={data?.claim_transfer_player || []}
        provider_transfer_player_id={providerTransferPlayerId}
      />
    </>
  );
};

export default TransferPlayerMapper;
