const textToLikeVariable = text =>
  text
    ?.split(' ')
    .map(item => `%${item}%`)
    .join('');

const createFiltersQueryObject = (filterSchema, entityFilter) => {
  if (!filterSchema) {
    return {
      name: textToLikeVariable(entityFilter?.text),
    };
  }

  return filterSchema.reduce((acc, filter) => {
    const {
      type,
      attributes: { key, queryVariableName },
    } = filter;

    if (type === 'text') {
      const isEmpty = entityFilter[key] === '' || entityFilter[key] == null;

      return {
        ...acc,
        [queryVariableName]: isEmpty ? null : textToLikeVariable(entityFilter[key]),
      };
    } else if (type === 'boolean') {
      return { ...acc, [queryVariableName]: entityFilter[key] ? true : undefined };
    } else if (type === 'date') {
      return { ...acc, [queryVariableName]: entityFilter[key] ? entityFilter[key] : undefined };
    } else if (type === 'duplicate') {
      return { ...acc, [queryVariableName]: entityFilter[key] ? 1 : 0 };
    } else {
      return { ...acc, [queryVariableName]: entityFilter[key] ? entityFilter[key].queryValue : undefined };
    }
  }, {});
};

export { createFiltersQueryObject };
