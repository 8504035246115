import gql from 'graphql-tag';

const ROUNDS_BY_PHASE = gql`
  query roundsByPhase($phase_id: Int!) {
    statsbomb_round(where: { phase_id: { _eq: $phase_id } }) {
      round_id
      statsbomb_round_structure {
        round_structure_id
        round_structure_name
      }
      statsbomb_round_type {
        round_type_id
        round_type_name
      }
    }
  }
`;

const INSERT_ROUND_SOURCE_AND_MAPPING = gql`
  mutation insertRoundSourceAndMapping(
    $provider_round_id: String!
    $provider: source_provider_enum!
    $round_id: Int!
    $phase_id: String!
    $round_type_id: Int!
    $round_structure_id: Int
  ) {
    insert_source_round(
      objects: {
        provider_round_id: $provider_round_id
        provider: Info_Team
        phase_id: $phase_id
        round_type_id: $round_type_id
        round_structure_id: $round_structure_id
        last_updated: "now()"
      }
      on_conflict: { constraint: round_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
    insert_mapping_round_one(
      object: { round_id: $round_id, provider: $provider, provider_round_id: $provider_round_id }
      on_conflict: { constraint: round_pkey, update_columns: [] }
    ) {
      round_id
      provider
      provider_round_id
    }
  }
`;

export { ROUNDS_BY_PHASE, INSERT_ROUND_SOURCE_AND_MAPPING };
