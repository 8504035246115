import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import { LINEUP_SCHEMA, LINEUP_CLAIM_SCHEMA } from '../schema/lineupSchema';
import { ClaimFilters } from '../components/claim/claimFilters';
import { Progress } from '../components/util/progress';
import {
  GOLDEN_LINEUP,
  CLAIMS_AGAINST_GOLDEN_LINEUP,
  SAVE_LINEUP_CLAIM,
  OVERRIDE_LINEUP_CLAIM,
  INSERT_GOLDEN_LINEUP,
} from '../hasura/queries/lineup/lineupClaims';

import { Error } from '../components/util/notifications';
import ClaimHistoryTable from '../components/table/claimHistoryTable';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenLineupView = ({ matchId, teamId, playerId, isNewLineup }) => {
  const ignoreEverything = {};
  LINEUP_SCHEMA.forEach(row => {
    ignoreEverything[row.columnKey] = 'Ignored';
  });

  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewLineup ? ignoreEverything : {});
  const [overrides, setOverrides] = useState({});
  const [claimMode, setClaimMode] = useState(isNewLineup ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);
  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loadingGolden, errorGolden, data: dataGolden } = useSubscription(GOLDEN_LINEUP, {
    variables: {
      match_id: matchId,
      team_id: teamId,
      player_id: playerId,
    },
  });

  const goldenEntity = dataGolden && dataGolden.statsbomb_lineup && dataGolden.statsbomb_lineup[0];

  const { loadingClaims, errorClaims, data: dataClaims } = useSubscription(CLAIMS_AGAINST_GOLDEN_LINEUP, {
    variables: {
      match_id: matchId,
      team_id: teamId,
      player_id: playerId,
    },
  });

  if (loadingGolden || loadingClaims) return <Progress />;

  let allClaims = [];
  if (dataClaims && dataClaims.statsbomb_claim_for_golden_lineup && dataClaims.statsbomb_claim_for_golden_lineup[0]) {
    allClaims = dataClaims.statsbomb_claim_for_golden_lineup;
  }

  let filteredClaims = allClaims;
  if (claimFilter === 'undecided') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }

  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_lineup_uuid === claimUUID;
    })[0];
    if (claim) {
      LINEUP_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    LINEUP_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(ignoreEverything);
  };

  const getGoldenLineup = insertedLineup => insertedLineup?.data?.insert_statsbomb_lineup?.returning?.[0];

  const claimSelected = claim => {
    selectAClaim(claim.claim_lineup_uuid);
  };
  const claim = allClaims.filter(c => {
    return c.claim_lineup_uuid === selectedClaimId;
  })[0];

  const formatBodyIndentifierValue = () => {
    if (!goldenEntity) return '';
    const { statsbomb_player, statsbomb_team, match_id } = goldenEntity;
    return `${match_id}, ${statsbomb_team?.team_name}, ${statsbomb_player?.player_name} `;
  };

  return (
    <>
      <Error message="" error={errorGolden} />
      <Error message="" error={errorClaims} />
      <div className="container-row">
        <h2>
          Lineup Singular: {isNewLineup ? ' New Lineup' : `MatchID: ${matchId} TeamID: ${teamId} PlayerID: ${playerId}`}
        </h2>

        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="lineup"
              schema={LINEUP_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{ headerValue: 'Lineup', bodyValue: formatBodyIndentifierValue() }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
              sourceCompositeKey={['provider_match_id', 'provider_team_id', 'provider_player_id']}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}

        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Lineup Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={goldenEntity}
            claim={claim}
            rowSchema={LINEUP_SCHEMA}
            claimStatuses={claimStatuses}
            setClaimStatuses={setClaimStatuses}
            selectAClaim={selectAClaim}
            overrides={overrides}
            setOverrides={setOverrides}
            saveMutation={SAVE_LINEUP_CLAIM}
            overrideMutation={OVERRIDE_LINEUP_CLAIM}
            createMutation={INSERT_GOLDEN_LINEUP}
            sourcePK={['provider_match_id', 'provider_team_id', 'provider_player_id']}
            claimPK="claim_lineup_uuid"
            goldenEntityPK={['match_id', 'team_id', 'player_id']}
            claimMode={claimMode}
            getGoldenEntityId={getGoldenLineup}
            entityType="lineup"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
          />
        </TableContainer>
      </div>
    </>
  );
};

function GoldenLineup() {
  const query = new URLSearchParams(useLocation().search);
  const isNewLineup = query.get('new');
  const matchId = isNewLineup ? '0' : query.get('match_id');
  const teamId = isNewLineup ? '0' : query.get('team_id');
  const playerId = isNewLineup ? '0' : query.get('player_id');

  return <GoldenLineupView matchId={matchId} teamId={teamId} playerId={playerId} isNewLineup={!!isNewLineup} />;
}

export default GoldenLineup;
