import React from 'react';
import { Table, TableBody, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { capitalise } from '../../util/string';
import { formatDateString } from '../../util/date';
import { TooltipComponent } from '../util/tooltip';
import { TableCell } from './table';

const useStyles = makeStyles(theme => ({
  table: {
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const ClaimHistoryTable = ({
  entities,
  schema,
  entityType,
  identifier,
  rowClickHandler,
  selectedEntityKey,
  sourceCompositeKey,
}) => {
  const classes = useStyles();

  const sortedEntities = entities.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const maxAttributeLength = 5;

  const formatProperty = property => capitalise(property.replace(`${entityType}_`, '').replace('_id', ''));

  const joinValuesFromCompositeKey = entity => sourceCompositeKey.map(pk => entity[pk]).join(', ');

  const changedAttributesFn = entity => {
    const attrArray = [];
    for (const property in entity) {
      if (entity[property] !== null && schema.includes(property)) {
        attrArray.push(formatProperty(property));
      }
    }
    return attrArray.sort();
  };

  const EntityRow = ({ entity, isSelected }) => {
    const changedAttributes = changedAttributesFn(entity);
    const providerID = sourceCompositeKey ? joinValuesFromCompositeKey(entity) : entity[`provider_${entityType}_id`];
    return (
      <TableRow className={isSelected ? ' active' : ''} onClick={() => rowClickHandler(entity)}>
        <TableCell>{formatDateString(entity.created_at)}</TableCell>
        <TableCell>{identifier.bodyValue}</TableCell>
        <TableCell>{entity.provider}</TableCell>
        <TableCell>{providerID}</TableCell>
        {changedAttributes.length <= maxAttributeLength ? (
          <TableCell>{changedAttributes.join(', ')}</TableCell>
        ) : (
          <TooltipComponent tooltipText={changedAttributes.join(', ')}>
            <TableCell>{changedAttributes.length} Attributes changed...</TableCell>
          </TooltipComponent>
        )}
        <TableCell>{(entity.approved_at && 'Approved') || (entity.rejected_at && 'Rejected')}</TableCell>
      </TableRow>
    );
  };

  return (
    <Table className={classes.table} size="small" aria-label="Claim history table">
      <TableHead>
        <TableRow>
          <TableCell>Claim Time - Date</TableCell>
          <TableCell>{capitalise(identifier.headerValue)}</TableCell>
          <TableCell>Provider</TableCell>
          <TableCell>Provider ID</TableCell>
          <TableCell>Attributes Changed</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedEntities.map(entity => {
          const entityKey = entity[`claim_${entityType}_uuid`];
          return <EntityRow key={entityKey} isSelected={selectedEntityKey === entityKey} entity={entity} />;
        })}
      </TableBody>
    </Table>
  );
};

export default ClaimHistoryTable;
