import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Select, MenuItem, InputLabel } from '@material-ui/core';

import { LINEUP_TYPES } from '../../hasura/queries/lineup/fullLineups';
import { MANAGER_TYPES } from '../../hasura/queries/manager/managers';
import { OFFICIAL_TYPES } from '../../hasura/queries/official/officials';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const PlayerLineupTypeOverride = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(LINEUP_TYPES);

  if (loading) return <InlineProgress />;

  const lineupTypes = data.statsbomb_player_lineup_selection_type || [];

  return (
    <>
      <Error message="Error fetching Player Lineup Types" error={error} />

      <InputLabel id="lineup-input">Lineup Types</InputLabel>
      <Select onChange={e => overrideChange(columnKey, e.target.value)} value={currentValue} labelId="lineup-input">
        {lineupTypes.map(({ selection_type, display_name }) => (
          <MenuItem key={selection_type} value={selection_type}>
            {display_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const ManagerTypesOverride = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(MANAGER_TYPES);

  if (loading) return <InlineProgress />;

  const managerTypes = data.statsbomb_manager_type || [];

  return (
    <>
      <Error message="Error fetching Manager Types" error={error} />

      <InputLabel id="manager-input">Manager Types</InputLabel>
      <Select onChange={e => overrideChange(columnKey, e.target.value)} value={currentValue} labelId="manager-input">
        {managerTypes.map(({ selection_type, display_name }) => (
          <MenuItem key={selection_type} value={selection_type}>
            {display_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const OfficialTypesOverride = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(OFFICIAL_TYPES);

  if (loading) return <InlineProgress />;

  const officialTypes = data.statsbomb_official_type || [];

  return (
    <>
      <Error message="Error fetching Official Types" error={error} />

      <InputLabel id="official-input">Official Types</InputLabel>
      <Select onChange={e => overrideChange(columnKey, e.target.value)} value={currentValue} labelId="official-input">
        {officialTypes.map(({ selection_type, display_name }) => (
          <MenuItem key={selection_type} value={selection_type}>
            {display_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { PlayerLineupTypeOverride, ManagerTypesOverride, OfficialTypesOverride };
