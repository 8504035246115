import React, { useState } from 'react';
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { InlineProgress } from '../components/util/progress';
import { TRANSFER_TYPE } from '../hasura/queries/transfers/transferTypes';
import { GET_ALL_TRANSFERS_BY_PLAYER, GET_PLAYER_CURRENT_TEAM } from '../hasura/queries/transferPlayer/transferPlayers';
import {
  OVERRIDE_TRANSFER_PLAYER_CLAIM,
  INSERT_GOLDEN_TRANSFER_PLAYER,
} from '../hasura/queries/transferPlayer/transferPlayerClaims';
import {
  GET_ALL_TRANSFERS_BY_MANAGER,
  GET_MANAGER_CURRENT_TEAM,
} from '../hasura/queries/transferManager/transferManagers';
import {
  OVERRIDE_TRANSFER_MANAGER_CLAIM,
  INSERT_GOLDEN_TRANSFER_MANAGER,
} from '../hasura/queries/transferManager/transferManagerClaims';
import { mutationError } from '../components/graphql/util';
import TransferHistoryComponent from '../components/transfer/transferHistory';
import { OVERRIDE_PLAYER_CLAIM } from '../hasura/queries/player/playerClaims';
import { OVERRIDE_MANAGER_CLAIM } from '../hasura/queries/manager/managerClaims';

const TransferHistory = () => {
  const query = new URLSearchParams(useLocation().search);
  const entityType = query.has('player_id') ? 'player' : 'manager';
  const id = query.get(entityType === 'player' ? 'player_id' : 'manager_id');

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const entityQueries = {
    player: {
      transfersByEntity: GET_ALL_TRANSFERS_BY_PLAYER,
      insertGoldenTransfer: INSERT_GOLDEN_TRANSFER_PLAYER,
      insertSourceAndMapTransferMutation: OVERRIDE_TRANSFER_PLAYER_CLAIM,
      insertSourceAndMapEntityMutation: OVERRIDE_PLAYER_CLAIM,
      getCurrentTeamTransfer: GET_PLAYER_CURRENT_TEAM,
    },
    manager: {
      transfersByEntity: GET_ALL_TRANSFERS_BY_MANAGER,
      insertGoldenTransfer: INSERT_GOLDEN_TRANSFER_MANAGER,
      insertSourceAndMapTransferMutation: OVERRIDE_TRANSFER_MANAGER_CLAIM,
      insertSourceAndMapEntityMutation: OVERRIDE_MANAGER_CLAIM,
      getCurrentTeamTransfer: GET_MANAGER_CURRENT_TEAM,
    },
  };

  const { data: transferTypes } = useQuery(TRANSFER_TYPE);

  const {
    transfersByEntity,
    insertGoldenTransfer,
    insertSourceAndMapTransferMutation,
    insertSourceAndMapEntityMutation,
    getCurrentTeamTransfer,
  } = entityQueries[entityType];

  const { loading: transfersLoading, data: transfersData } = useSubscription(transfersByEntity, {
    variables: {
      [`${entityType}_id`]: id,
    },
  });

  const [createGoldenRecord] = useMutation(insertGoldenTransfer, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Saving')),
    onCompleted: () => setSuccessMessage('Saved!'),
  });

  const [insertSourceAndMap] = useMutation(insertSourceAndMapTransferMutation, {
    onError: err => setErrorMessage(mutationError(err, 'Problem inserting transfer into source and mapping schemas.')),
    onCompleted: () => {
      setModalOpen(false);
      setSuccessMessage('New Transfer Record Created!');
    },
  });

  const [insertSourceAndMapEntity] = useMutation(insertSourceAndMapEntityMutation, {
    onError: err => setErrorMessage(mutationError(err, 'Problem inserting entity into source and mapping schemas')),
  });

  const { loading: currentTeamLoading, data: currentTeamData } = useSubscription(getCurrentTeamTransfer, {
    variables: {
      [`${entityType}_id`]: id,
    },
    onError: err => setErrorMessage(mutationError(err, `Couldn't get ${entityType} current team`)),
  });

  const loading = transfersLoading || currentTeamLoading;

  if (loading) return <InlineProgress />;

  const entity = transfersData[`statsbomb_${entityType}`][0];
  const currentTeamRecord = currentTeamData[`statsbomb_current_transfer_${entityType}`][0];

  return (
    <TransferHistoryComponent
      type={entityType}
      {...{
        entity,
        entityType,
        currentTeamRecord,
        transferTypes,
        modalOpen,
        setModalOpen,
        createGoldenRecord,
        insertSourceAndMap,
        insertSourceAndMapEntity,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
      }}
    />
  );
};

export default TransferHistory;
