import React from 'react';
import { TextField } from '@material-ui/core';

const TextOverride = ({ overrideChange, currentValue, columnKey, name }) => {
  const defaultText = currentValue || '';
  return (
    <TextField onChange={e => overrideChange(columnKey, e.target.value)} value={defaultText} type="text" label={name} />
  );
};

export { TextOverride };
