import gql from 'graphql-tag';

const GOLDEN_STADIUM_AND_CLAIMS = gql`
  query goldenStadiumAndClaims($stadium_id: Int!) {
    statsbomb_stadium(where: { stadium_id: { _eq: $stadium_id } }) {
      stadium_id
      stadium_name
      stadium_city
      stadium_capacity
      stadium_pitch_x
      stadium_pitch_y
      area_id
      deleted
      statsbomb_area {
        area_name
        area_code
      }
      mapping_stadiums {
        claim_stadiums {
          claim_stadium_uuid
          area_id
          source_area {
            area_name
            area_code
          }
          approved_at
          created_at
          deleted
          provider
          provider_stadium_id
          rejected_at
          stadium_capacity
          stadium_city
          stadium_name
          stadium_pitch_x
          stadium_pitch_y
          mapping_area {
            statsbomb_area {
              area_code
              area_id
              area_name
            }
          }
        }
      }
    }
  }
`;

const ALL_CLAIM_STADIUMS = gql`
  query getAllClaimStadiums(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $name: String
    $area: String
  ) {
    claim_stadium(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        stadium_name: { _ilike: $name }
        area_id: { _ilike: $area }
      }
      offset: $offset
      limit: $limit
    ) {
      approved_at
      claim_stadium_uuid
      stadium_name
      created_at
      deleted
      provider
      rejected_at
      stadium_city
      stadium_capacity
      stadium_pitch_x
      stadium_pitch_y
      provider_stadium_id
      area_id
      mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      source_area {
        area_code
        area_name
        last_updated
      }
      mapping_stadium {
        stadium_id
      }
    }
  }
`;

const MAPPED_CLAIM_STADIUMS = gql`
  query getAllClaimStadiums($offset: Int = 0, $limit: Int = 10, $name: String, $area: String) {
    claim_stadium(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        mapping_stadium: {}
        stadium_name: { _ilike: $name }
        area_id: { _ilike: $area }
      }
      offset: $offset
      limit: $limit
    ) {
      approved_at
      claim_stadium_uuid
      stadium_name
      created_at
      deleted
      provider
      rejected_at
      stadium_city
      stadium_capacity
      stadium_pitch_x
      stadium_pitch_y
      provider_stadium_id
      area_id
      mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      source_area {
        area_code
        area_name
        last_updated
      }
      mapping_stadium {
        stadium_id
      }
    }
  }
`;

const UNMAPPED_CLAIM_STADIUMS = gql`
  query getAllClaimStadiums($offset: Int = 0, $limit: Int = 10, $name: String, $area: String) {
    claim_stadium(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_stadium: {} }
        stadium_name: { _ilike: $name }
        area_id: { _ilike: $area }
      }
      offset: $offset
      limit: $limit
    ) {
      approved_at
      claim_stadium_uuid
      stadium_name
      created_at
      deleted
      provider
      rejected_at
      stadium_city
      stadium_capacity
      stadium_pitch_x
      stadium_pitch_y
      provider_stadium_id
      area_id
      mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
      source_area {
        area_code
        area_name
        last_updated
      }
      mapping_stadium {
        stadium_id
      }
    }
  }
`;

const SAVE_STADIUM_CLAIM = gql`
  mutation saveStadiumClaim($claim_stadium_uuid: uuid!, $claims: [claim_stadium_insert_input!]!) {
    delete_claim_stadium(where: { claim_stadium_uuid: { _eq: $claim_stadium_uuid } }) {
      affected_rows
    }
    insert_claim_stadium(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_STADIUM_CLAIM = gql`
  mutation infoTeamOverride(
    $stadium_id: Int!
    $provider_stadium_id: String!
    $source_entity: source_stadium_set_input!
  ) {
    insert_mapping_stadium(
      objects: {
        source_stadium: {
          data: { provider: Info_Team, provider_stadium_id: $provider_stadium_id, last_updated: "now()" }
          on_conflict: { constraint: stadium_pkey, update_columns: last_updated }
        }
        stadium_id: $stadium_id
      }
      on_conflict: { constraint: stadium_pkey, update_columns: stadium_id }
    ) {
      returning {
        stadium_id
      }
    }
    update_source_stadium(
      where: { provider: { _eq: Info_Team }, provider_stadium_id: { _eq: $provider_stadium_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_STADIUM = gql`
  mutation insertGoldenStadium($statsbomb_entity: statsbomb_stadium_insert_input!) {
    insert_statsbomb_stadium(objects: [$statsbomb_entity]) {
      returning {
        stadium_id
      }
    }
  }
`;

const CLAIMS_FOR_STADIUM_BY_PROVIDER = gql`
  subscription claimsForStadiumByProvider($provider_stadium_id: String!, $provider: source_provider_enum!) {
    claim_stadium(where: { provider_stadium_id: { _eq: $provider_stadium_id }, provider: { _eq: $provider } }) {
      stadium_name
      stadium_city
      stadium_capacity
      stadium_pitch_x
      stadium_pitch_y
      area_id
      deleted
      created_at
      provider
      rejected_at
      provider_stadium_id
      approved_at
      claim_stadium_uuid
      mapping_area {
        statsbomb_area {
          area_code
          area_id
          area_name
        }
      }
    }
  }
`;
const CLAIMS_STADIUMS_COUNT = gql`
  query claimsStadiumsCount {
    claim_stadium_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;
export {
  GOLDEN_STADIUM_AND_CLAIMS,
  ALL_CLAIM_STADIUMS,
  MAPPED_CLAIM_STADIUMS,
  UNMAPPED_CLAIM_STADIUMS,
  SAVE_STADIUM_CLAIM,
  OVERRIDE_STADIUM_CLAIM,
  INSERT_GOLDEN_STADIUM,
  CLAIMS_FOR_STADIUM_BY_PROVIDER,
  CLAIMS_STADIUMS_COUNT,
};
