import gql from 'graphql-tag';
import * as R from 'ramda';
import { hasuraClient } from '../../hasura';
import { deletionTree } from './deletion_tree';

const getEntityQueryName = R.replace('.', '_');

const queryWhereClause = dependency => {
  let whereClause = '';
  const entityLinksCount = dependency.linkId.length;

  dependency.linkId.map((id, index) => {
    if (index === entityLinksCount - 1 && entityLinksCount > 1) {
      whereClause += `{ ${id}: { _in: $entityId } }`;
      return whereClause;
    }
    whereClause += `{ ${id}: { _in: $entityId } }, `;
    return whereClause;
  });
  return whereClause;
};

const hasuraQuery = (entityQueryName, dependency) => gql`
  query DeleteDependenciesQuery($entityId: [Int!]) {
    ${entityQueryName}(
      where:
      {
        _or: [${queryWhereClause(dependency)}]
      }
    ) {      ${dependency.id}
    }
  }
`;

const queryHasuraEntity = R.curry((entityId, dependency) => {
  const client = hasuraClient();
  const entityQueryName = getEntityQueryName(dependency.name);
  const query = hasuraQuery(entityQueryName, dependency);

  return client.query({ query, variables: { entityId } }).then(R.path(['data', entityQueryName]));
});

const getDependencyKey = (entityType, node) => R.path([entityType, 'dependecies', node], deletionTree);

const nodeKey = node => deletionTree[node]?.id;

const entityDepenecies = entityType => deletionTree[entityType]?.dependecies;

const dependencyNodes = (entityType, entityId) => async dependencies => {
  const dependenciesName = R.keys(dependencies);
  const dependencyNodes = R.map(
    node => ({ name: node, id: nodeKey(node), linkId: getDependencyKey(entityType, node) }),
    dependenciesName
  );
  const entityDepeneciesPromise = R.map(async node => node.id && queryHasuraEntity(entityId, node), dependencyNodes);
  const entityDepenecies = await Promise.all(entityDepeneciesPromise);
  let filteredObj = {};
  entityDepenecies.forEach((dep, index) => {
    if (dep.length) {
      filteredObj[dependenciesName[index].substring(dependenciesName[index].indexOf('.') + 1)] = dep;
    }
  });
  return R.map(R.defaultTo([]), filteredObj);
};

export const getDependencyTree = async (entityType, entityId) => {
  // return only first layer nodes
  return await dependencyNodes(entityType, entityId)(entityDepenecies(entityType));
  // return await travereseDependencyTree(entityType, entityId);
};
