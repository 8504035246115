import gql from 'graphql-tag';

const GOLDEN_PLAYER_AND_CLAIMS = gql`
  query goldenPlayerAndClaims($player_id: Int!) {
    statsbomb_player(where: { player_id: { _eq: $player_id } }) {
      player_country_of_birth
      country_of_birth_statsbomb_area {
        area_name
        area_code
      }
      player_date_of_birth
      player_firstname
      player_height
      player_id
      player_is_female
      player_lastname
      player_name
      player_nationality
      nationality_statsbomb_area {
        area_name
        area_code
      }
      player_nickname
      player_photo_url
      player_preferred_foot
      player_weight
      player_is_retired
      deleted
      mapping_players {
        provider
        provider_player_id
        claim_players {
          claim_player_uuid
          provider
          provider_player_id
          approved_at
          created_at
          deleted
          nationality_mapping_area {
            area_id
            statsbomb_area {
              area_name
              area_code
            }
          }
          country_of_birth_mapping_area {
            area_id
            statsbomb_area {
              area_name
              area_code
            }
          }
          player_date_of_birth
          player_firstname
          player_height
          player_is_female
          player_lastname
          player_name
          player_country_of_birth
          player_nationality
          player_nickname
          player_photo_url
          player_preferred_foot
          player_weight
          player_is_retired
          rejected_at
        }
      }
    }
  }
`;

const ALL_CLAIM_PLAYERS = gql`
  query getAllClaimPlayers(
    $recent_claim_time: timestamp
    $provider_id: String
    $name: String
    $area: String
    $start_date: date
    $end_date: date
    $offset: Int = 0
    $limit: Int = 10
    $is_female: Boolean
  ) {
    claim_player(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_player_id: { _ilike: $provider_id }
        player_name: { _ilike: $name }
        player_nationality: { _ilike: $area }
        player_date_of_birth: { _gte: $start_date, _lte: $end_date }
        player_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      player_name
      player_lastname
      player_is_female
      player_height
      player_firstname
      player_date_of_birth
      player_country_of_birth
      deleted
      created_at
      claim_player_uuid
      player_nationality
      player_nickname
      player_photo_url
      player_preferred_foot
      player_weight
      provider
      rejected_at
      provider_player_id
      player_is_retired
      approved_at
      mapping_player {
        player_id
      }
      nationality_mapping_area {
        area_id
      }
      country_of_birth_mapping_area {
        area_id
      }
    }
  }
`;

const MAPPED_CLAIM_PLAYERS = gql`
  query getAllClaimPlayers(
    $offset: Int = 0
    $limit: Int = 10
    $area: String
    $name: String
    $start_date: date
    $end_date: date
    $provider_id: String
    $is_female: Boolean
  ) {
    claim_player(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        mapping_player: {}
        provider_player_id: { _ilike: $provider_id }
        player_name: { _ilike: $name }
        player_nationality: { _ilike: $area }
        player_date_of_birth: { _gte: $start_date, _lte: $end_date }
        player_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      player_name
      player_lastname
      player_is_female
      player_height
      player_firstname
      player_date_of_birth
      player_country_of_birth
      deleted
      created_at
      claim_player_uuid
      player_nationality
      player_nickname
      player_photo_url
      player_preferred_foot
      player_weight
      provider
      rejected_at
      provider_player_id
      player_is_retired
      approved_at
      mapping_player {
        player_id
      }
      nationality_mapping_area {
        area_id
      }
      country_of_birth_mapping_area {
        area_id
      }
    }
  }
`;

const UNMAPPED_CLAIM_PLAYERS = gql`
  query getAllClaimPlayers(
    $offset: Int = 0
    $limit: Int = 10
    $area: String
    $name: String
    $start_date: date
    $end_date: date
    $provider_id: String
    $is_female: Boolean
  ) {
    claim_player(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        _not: { mapping_player: {} }
        provider_player_id: { _ilike: $provider_id }
        player_name: { _ilike: $name }
        player_nationality: { _ilike: $area }
        player_date_of_birth: { _gte: $start_date, _lte: $end_date }
        player_is_female: { _eq: $is_female }
      }
      offset: $offset
      limit: $limit
    ) {
      player_name
      player_lastname
      player_is_female
      player_height
      player_firstname
      player_date_of_birth
      player_country_of_birth
      deleted
      created_at
      claim_player_uuid
      player_nationality
      player_nickname
      player_photo_url
      player_preferred_foot
      player_weight
      provider
      rejected_at
      provider_player_id
      player_is_retired
      approved_at
      mapping_player {
        player_id
      }
      nationality_mapping_area {
        area_id
      }
      country_of_birth_mapping_area {
        area_id
      }
    }
  }
`;

const CLAIMS_FOR_PLAYER_BY_PROVIDER = gql`
  subscription claimsForPlayerByProvider($provider_player_id: String!, $provider: source_provider_enum!) {
    claim_player(where: { provider_player_id: { _eq: $provider_player_id }, provider: { _eq: $provider } }) {
      player_name
      player_lastname
      player_is_female
      player_height
      player_firstname
      player_date_of_birth
      deleted
      created_at
      claim_player_uuid
      player_nationality
      player_nickname
      player_photo_url
      player_preferred_foot
      player_weight
      player_is_retired
      provider
      rejected_at
      provider_player_id
      approved_at
      nationality_mapping_area {
        area_id
        statsbomb_area {
          area_name
          area_code
        }
      }
      country_of_birth_mapping_area {
        area_id
        statsbomb_area {
          area_name
          area_code
        }
      }
      mapping_player {
        statsbomb_player {
          deleted
          player_country_of_birth
          player_date_of_birth
          player_height
          player_firstname
          player_id
          player_is_female
          player_lastname
          player_name
          player_nationality
          player_nickname
          player_photo_url
          player_preferred_foot
          player_weight
          player_is_retired
          nationality_statsbomb_area {
            area_name
          }
          country_of_birth_statsbomb_area {
            area_name
          }
        }
      }
      player_country_of_birth
    }
  }
`;

const SAVE_PLAYER_CLAIM = gql`
  mutation MyMutation($claim_player_uuid: uuid!, $claims: [claim_player_insert_input!]!) {
    delete_claim_player(where: { claim_player_uuid: { _eq: $claim_player_uuid } }) {
      affected_rows
    }
    insert_claim_player(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_PLAYER_CLAIM = gql`
  mutation infoTeamPlayerOverride(
    $player_id: Int!
    $provider_player_id: String!
    $source_entity: source_player_set_input!
  ) {
    insert_mapping_player(
      objects: {
        source_player: {
          data: { provider: Info_Team, provider_player_id: $provider_player_id, last_updated: "now()" }
          on_conflict: { constraint: player_pkey, update_columns: last_updated }
        }
        player_id: $player_id
      }
      on_conflict: { constraint: player_pkey, update_columns: player_id }
    ) {
      returning {
        player_id
      }
    }
    update_source_player(
      where: { provider: { _eq: Info_Team }, provider_player_id: { _eq: $provider_player_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;
const CLAIMS_PLAYERS_COUNT = gql`
  query claimsPlayersCount {
    claim_player_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

const INSERT_GOLDEN_PLAYER = gql`
  mutation insertGoldenPlayer($statsbomb_entity: statsbomb_player_insert_input!) {
    insert_statsbomb_player(objects: [$statsbomb_entity]) {
      returning {
        player_id
      }
    }
  }
`;

export {
  ALL_CLAIM_PLAYERS,
  MAPPED_CLAIM_PLAYERS,
  UNMAPPED_CLAIM_PLAYERS,
  CLAIMS_FOR_PLAYER_BY_PROVIDER,
  CLAIMS_PLAYERS_COUNT,
  GOLDEN_PLAYER_AND_CLAIMS,
  INSERT_GOLDEN_PLAYER,
  OVERRIDE_PLAYER_CLAIM,
  SAVE_PLAYER_CLAIM,
};
