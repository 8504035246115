import gql from 'graphql-tag';

const MAP_TO_TEAM = gql`
  mutation MyMutation($team_id: Int!, $provider: source_provider_enum!, $provider_team_id: String!) {
    insert_mapping_team(objects: { team_id: $team_id, provider: $provider, provider_team_id: $provider_team_id }) {
      returning {
        team_id
        provider
        provider_team_id
      }
    }
  }
`;

// TO-DO: CJW, check this team name usage as replicated from area but potentially incorrect
const MAKE_AND_MAP_TEAM = gql`
  mutation MyMutation($team_name: String!, $provider: source_provider_enum!, $provider_team_id: String!) {
    insert_mapping_team(
      objects: {
        statsbomb_team: { data: { team_name: $team_name } }
        provider: $provider
        provider_team_id: $provider_team_id
      }
    ) {
      returning {
        team_id
        provider
        provider_team_id
      }
    }
  }
`;

const VIEW_TEAM_MAPPINGS = gql`
  subscription viewTeamMappings($id: Int!) {
    mapping_team(where: { team_id: { _eq: $id } }) {
      source_team {
        provider
        provider_team_id
        team_colour_primary
        team_colour_secondary
        team_is_female
        team_name
        team_nickname
        area_id
      }
    }
  }
`;

const DELETE_TEAM_MAPPING = gql`
  mutation deleteTeamMappings(
    $claim_data: claim_team_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_team_one(object: $claim_data, on_conflict: { constraint: team_pkey, update_columns: [] }) {
      provider
      provider_team_id
    }

    delete_mapping_team_by_pk(provider: $provider, provider_team_id: $provider_id) {
      team_id
      provider_team_id
      provider
    }
  }
`;

export { MAP_TO_TEAM, MAKE_AND_MAP_TEAM, VIEW_TEAM_MAPPINGS, DELETE_TEAM_MAPPING };
