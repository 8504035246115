import gql from 'graphql-tag';

const COMPETITION_SEASONS = gql`
  query UndeletedCompetitionSeasons {
    statsbomb_competition_season(
      where: { deleted: { _eq: false } }
      order_by: {
        statsbomb_competition: {
          competition_is_female: asc
          statsbomb_area: { area_name: asc }
          competition_name: asc
          competition_type_id: asc
          competition_ladder_rank: asc
        }
        statsbomb_season: { season_name: asc }
      }
    ) {
      competition_season_id
      competition_id
      statsbomb_competition {
        competition_name
        competition_is_female
        statsbomb_area {
          area_id
          area_name
          area_code
        }
      }
      season_id
      statsbomb_season {
        season_name
      }
      competition_season_name
      deleted
    }
  }
`;

const SOURCE_COMPETITION_SEASON = gql`
  query CompetitionSeasons {
    source_competition_season(where: { provider: { _eq: Info_Team } }) {
      statsbomb_season {
        season_name
      }
      source_competition {
        competition_name
        source_area {
          area_code
        }
      }
    }
  }
`;

const GOLDEN_COMPETITION_SEASONS = gql`
  query goldenCompetitionSeasons(
    $name: String
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_competition_season_order_by!
  ) {
    statsbomb_golden_competition_season(
      where: { _and: [{ search_name: { _ilike: $name }, deleted: { _eq: $deleted } }] }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      competition_season_id
      competition_id
      season_id
      competition_season_name
      competition_name
      season_name
      deleted
    }
  }
`;

const GOLDEN_COMPETITION_SEASONS_COUNT = gql`
  query goldenCompetitionSeasonsCount($name: String, $deleted: Boolean = false) {
    statsbomb_golden_competition_season_aggregate(
      where: { _and: [{ search_name: { _ilike: $name }, deleted: { _eq: $deleted } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ALL_CLAIM_COMPETITION_SEASONS = gql`
  query getAllClaimCompetitionSeasons {
    claim_competition_season(where: { rejected_at: { _is_null: true }, approved_at: { _is_null: true } }) {
      competition_season_name
      competition_id
      season_id
      provider
      provider_competition_season_id
      claim_competition_season_uuid
      approved_at
      rejected_at
      created_at
      deleted
    }
  }
`;

const INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING = gql`
  mutation insertCompetitionSeasonSourceAndMapping(
    $provider_competition_season_id: String!
    $provider: source_provider_enum!
    $competition_season_id: Int!
    $competition_season_name: String
    $competition_id: String!
    $season_id: Int!
  ) {
    insert_source_competition_season(
      objects: {
        provider_competition_season_id: $provider_competition_season_id
        provider: Info_Team
        competition_id: $competition_id
        season_id: $season_id
        competition_season_name: $competition_season_name
        last_updated: "now()"
      }
      on_conflict: { constraint: competition_season_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
    insert_mapping_competition_season_one(
      object: {
        competition_season_id: $competition_season_id
        provider: $provider
        provider_competition_season_id: $provider_competition_season_id
      }
      on_conflict: { constraint: competition_season_pkey, update_columns: [] }
    ) {
      competition_season_id
      provider
      provider_competition_season_id
    }
  }
`;
const DELETE_COMPETITION_SEASON = gql`
  mutation DeleteCompetitionSeasons($competition_season_id: Int!) {
    update_statsbomb_competition_season_by_pk(
      pk_columns: { competition_season_id: $competition_season_id }
      _set: { deleted: true }
    ) {
      deleted
    }
  }
`;
const DELETE_COMPETITION_SEASON_BY_ID = gql`
  mutation deleteCompetitionSeason($competition_season_id: Int!) {
    delete_mapping_competition_season(where: { competition_season_id: { _eq: $competition_season_id } }) {
      affected_rows
    }
    delete_statsbomb_competition_season(where: { competition_season_id: { _eq: $competition_season_id } }) {
      affected_rows
    }
  }
`;
const GET_COMPETITION_SEASONS_BY_ID = gql`
  query getCompetitionSeasonsById($entityIds: [Int!]) {
    statsbomb_golden_competition_season(where: { competition_season_id: { _in: $entityIds } }) {
      competition_season_id
      competition_id
      season_id
      competition_season_name
      competition_name
      season_name
    }
  }
`;
export {
  GOLDEN_COMPETITION_SEASONS,
  GOLDEN_COMPETITION_SEASONS_COUNT,
  ALL_CLAIM_COMPETITION_SEASONS,
  SOURCE_COMPETITION_SEASON,
  COMPETITION_SEASONS,
  INSERT_COMPETITION_SEASON_SOURCE_AND_MAPPING,
  DELETE_COMPETITION_SEASON,
  DELETE_COMPETITION_SEASON_BY_ID,
  GET_COMPETITION_SEASONS_BY_ID,
};
