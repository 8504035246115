import { useEffect } from 'react';

const Page = props => {
  useEffect(() => {
    document.title = 'Gatekeeper - ' + (props.title || '');
  });
  return props.children;
};

export default Page;
