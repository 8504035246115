import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Pagination } from '@material-ui/lab';
import EntityWithClaims from '../../components/claim/entityWithClaims';
import { GoldenEntityFilters } from '../../components/filtering/goldenEntityFilters';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';
import { SECTION_CONFIG, RESULTS_PER_PAGE } from '../../util/consts';
import { capitalise, pluralise } from '../../util/string';
import { getUTCTimeXMinutesAgo, formatDateString } from '../../util/date';
import { createFiltersQueryObject } from '../../util/filters';

const Claims = ({
  type,
  fetchClaims,
  fetchMappedClaims,
  fetchUnmappedClaims,
  claimSchema,
  claimFilter,
  claimsCount = null,
}) => {
  const [query, setQuery] = useState(fetchClaims);
  const [claimViewState, setClaimViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
  });
  const emptyFilter = { text: '', page: 1 };
  const [filter, setFilter] = useState(emptyFilter);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [tempFilter, setTempFilter] = useState(emptyFilter);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const filterVariables = createFiltersQueryObject(claimFilter, filter);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
      page: 1,
    });

    setTempFilter({
      ...filter,
      [key]: value,
      page: 1,
    });
  };

  const clearFilters = () => {
    setFilter(emptyFilter);
    setTempFilter(emptyFilter);
  };

  const removeFilter = key => {
    setFilter({ ...filter, [key]: undefined });
    setTempFilter({ ...tempFilter, [key]: undefined });
  };
  const queryVariables = {
    ...filterVariables,
    limit: RESULTS_PER_PAGE,
    offset: offset,
  };
  if (claimViewState.claimFilter === 'recent')
    queryVariables['recent_claim_time'] = formatDateString(getUTCTimeXMinutesAgo(10), 'YYYY-MM-DDTHH:mm:ss');

  const { loading, error, data } = useQuery(query, {
    variables: queryVariables,
  });

  const buttonFilterMap = {
    mapped: {
      query: fetchMappedClaims,
      claimsCount: claimsCount ? claimsCount.mappedCount : 0,
    },
    unmapped: {
      query: fetchUnmappedClaims,
      claimsCount: claimsCount ? claimsCount.unmappedCount : 0,
    },
    all: {
      query: fetchClaims,
      claimsCount: claimsCount ? claimsCount.mappedCount + claimsCount.unmappedCount : 0,
    },
    recent: {
      query: fetchClaims,
      claimsCount: claimsCount ? claimsCount.recentCount : 0,
    },
  };

  const pages = Math.ceil(buttonFilterMap[claimViewState.claimFilter].claimsCount / RESULTS_PER_PAGE);
  const handlePageChange = value => {
    setPage(value);
    setOffset((value - 1) * 10);
  };

  useEffect(() => {
    setQuery(buttonFilterMap[claimViewState.claimFilter].query);
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimViewState.claimFilter]);

  return (
    <>
      <Error message="Error fetching Claims" error={error} />
      <div>
        <h2>{pluralise(capitalise(type))}</h2>
        <p>This is a list of all current {type} claims:</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GoldenEntityFilters
            {...{
              filter,
              setFilter,
              tempFilter,
              setTempFilter,
              emptyFilter,
              schema: claimFilter,
              clearFilters,
              updateFilter,
              removeFilter,
              filtersModalOpen,
              setFiltersModalOpen,
            }}
          />
        </div>
        {loading ? (
          <Progress />
        ) : (
          <>
            <EntityWithClaims
              claims={data?.[`claim_${type}`] || []}
              {...{ claimViewState, setClaimViewState, claimsCount, claimSchema, type }}
            />
            {pages > 0 && (
              <Pagination
                style={{ paddingTop: 10 }}
                page={page}
                count={pages}
                onChange={(_, val) => handlePageChange(val)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const AreaClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'area')} claimsCount={claimsCount} />
);

const TeamClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'team')} claimsCount={claimsCount} />
);

const PlayerClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'player')} claimsCount={claimsCount} />
);

const CompetitionClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'competition')} claimsCount={claimsCount} />
);

const PhaseClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'phase')} claimsCount={claimsCount} />
);

const CompetitionSeasonClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'competition_season')} claimsCount={claimsCount} />
);

const LineupClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'lineup')} claimsCount={claimsCount} />
);

const RoundClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'round')} claimsCount={claimsCount} />
);

const MatchClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'match')} claimsCount={claimsCount} />
);

const StadiumClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'stadium')} claimsCount={claimsCount} />
);

const ManagerClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'manager')} claimsCount={claimsCount} />
);

const OfficialClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'official')} claimsCount={claimsCount} />
);

const TransferPlayerClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'transfer_player')} claimsCount={claimsCount} />
);

const TransferManagerClaims = ({ claimsCount }) => (
  <Claims {...SECTION_CONFIG.find(c => c.type === 'transfer_manager')} claimsCount={claimsCount} />
);

export {
  AreaClaims,
  TeamClaims,
  PlayerClaims,
  CompetitionClaims,
  PhaseClaims,
  CompetitionSeasonClaims,
  LineupClaims,
  RoundClaims,
  MatchClaims,
  StadiumClaims,
  ManagerClaims,
  OfficialClaims,
  TransferPlayerClaims,
  TransferManagerClaims,
};
