import React, { useState } from 'react';
import _ from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  Button,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Radio,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { useHistory } from 'react-router-dom';
import { DateValue } from '../../util/date';
import { InlineProgress } from '../util/progress';
import { TableCell, CellContent } from '../table/table';
import { RepeatableError, RepeatableSuccess } from '../util/notifications';
import { mutationError } from '../graphql/util';
import { SIMILAR_PLAYERS } from '../../hasura/queries/player/playerList';
import { SIMILAR_TEAMS } from '../../hasura/queries/team/teams';
import { SIMILAR_OFFICIALS } from '../../hasura/queries/official/officials';
import { SIMILAR_STADIUMS } from '../../hasura/queries/stadium/stadiums';
import { SIMILAR_AREAS } from '../../hasura/queries/area/areas';
import { SIMILAR_MATCHES } from '../../hasura/queries/match/matches';
import { SIMILAR_MANAGERS } from '../../hasura/queries/manager/managers';

const [CLAIM_MODE_VIEW, CLAIM_MODE_EDIT, CLAIM_MODE_NEW_CLAIM, CLAIM_MODE_NEW_ENTITY] = [
  'view',
  'edit',
  'new',
  'new-entity',
];
const querySchema = {
  player: SIMILAR_PLAYERS,
  team: SIMILAR_TEAMS,
  official: SIMILAR_OFFICIALS,
  stadium: SIMILAR_STADIUMS,
  area: SIMILAR_AREAS,
  match: SIMILAR_MATCHES,
  manager: SIMILAR_MANAGERS,
};

const useStyles = makeStyles(theme => ({
  saveButton: {
    margin: 5,
    float: 'right',
  },
  cancelButton: {
    margin: 5,
    float: 'right',
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: '#DA0000',
    },
  },
  modal: {
    position: 'relative',
    height: '90%',
    top: 10,
    right: 100,
    left: 100,
    width: '55%',
    margin: 'auto',
    padding: '5px 20px',
    backgroundColor: '#FFF',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    boxShadow: '0px 3px 9px 2px rgba(0,0,0,0.1)',
  },
  modalButtons: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 0' },
  last: { width: 300 },
  duplicateTable: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const isCompositeKey = (gePK, sPK) => Array.isArray(gePK) || Array.isArray(sPK);

const isDisplayOverride = (
  goldenEntityPK,
  columnKey,
  claimMode,
  claimDecisionRequired,
  OverrideControl,
  notCompositePK
) => {
  const isPartOfCompositeKey = _.includes(goldenEntityPK, columnKey);
  const isClaimRelated = [CLAIM_MODE_NEW_CLAIM].includes(claimMode) || claimDecisionRequired;
  const isNewEntity = [CLAIM_MODE_NEW_ENTITY].includes(claimMode);

  return ((isClaimRelated && (notCompositePK || !isPartOfCompositeKey)) || isNewEntity) && !!OverrideControl;
};

const getVariablesForOverrides = (
  goldenEntityPK,
  sourcePK,
  newEntity,
  overrideAttributes,
  goldenEntity,
  getGoldenEntityId
) => {
  let variables;
  if (isCompositeKey(goldenEntityPK, sourcePK)) {
    if (newEntity) {
      variables = {
        source_entity: overrideAttributes,
      };
      goldenEntityPK.forEach(key => {
        variables[key] = overrideAttributes[key].toString();
        delete variables.source_entity[key];
      });
    } else {
      variables = {
        source_entity: overrideAttributes,
      };
      goldenEntityPK.forEach(key => {
        variables[key] = goldenEntity[key].toString();
        delete variables.source_entity[key];
      });
    }
  } else {
    if (newEntity) {
      const goldenEntityId =
        typeof newEntity !== 'object' && newEntity !== null ? newEntity : getGoldenEntityId(newEntity);

      variables = {
        [goldenEntityPK]: goldenEntityId,
        [sourcePK]: goldenEntityId?.toString(),
        source_entity: overrideAttributes,
      };
    } else {
      variables = {
        [goldenEntityPK]: goldenEntity[goldenEntityPK],
        [sourcePK]: goldenEntity[goldenEntityPK]?.toString(),
        source_entity: overrideAttributes,
      };
    }
  }
  return variables;
};

const calculateRedirectUrlForCompositeKey = (gePKs, variables, entityType) => {
  let url = `/${entityType}?`;
  gePKs.forEach(key => {
    url = url + `${key}=${variables[key]}&`;
  });
  return url;
};

const redirectIfRequired = (
  claimMode,
  newEntity,
  goldenEntityPK,
  sourcePK,
  variables,
  entityType,
  getGoldenEntityId,
  history
) => {
  if (claimMode === CLAIM_MODE_NEW_ENTITY && newEntity && !newEntity.errors) {
    const redirectUrl = isCompositeKey(goldenEntityPK, sourcePK)
      ? calculateRedirectUrlForCompositeKey(goldenEntityPK, variables, entityType)
      : `/${entityType}?${goldenEntityPK}=${getGoldenEntityId(newEntity)}`;
    history.push(redirectUrl);
  }
};
const areAllEntityPropertiesIgnored = entityProperties => {
  let isAllPropertiesIgnored = true;
  Object.keys(entityProperties).forEach(property => {
    if (entityProperties[property] !== 'Ignored') {
      isAllPropertiesIgnored = false;
    }
  });
  return isAllPropertiesIgnored;
};
const SingleClaimRow = ({
  i,
  claim,
  claimSchema: {
    columnKey,
    name,
    mapping_function,
    MappingControl,
    statsbomb_mapping_function,
    type,
    OverrideControl,
    overrideProps,
    initialOverrideValue,
    icon,
    statsbomb_icon,
    mapped_icon,
    statsbomb_mapped_icon,
    longText,
    notCompositePK = false,
  },
  goldenEntity,
  claimStatuses,
  setClaimStatus,
  overrides,
  overrideChange,
  claimMode,
  goldenEntityPK,
}) => {
  const ClaimChoiceRadio = ({ status }) => {
    const initialValue = initialOverrideValue !== undefined ? initialOverrideValue : null;
    return (
      <Radio
        className={status}
        data-columnkey={columnKey}
        onChange={() => {
          setClaimStatus(columnKey, status);
          overrideChange(columnKey, initialValue);
        }}
        name={columnKey + '_decision'}
        checked={claimStatuses[columnKey] === status}
        color="default"
      />
    );
  };

  const overrideControl = claimStatuses[columnKey] === 'Overridden' && OverrideControl && (
    <OverrideControl
      provider="Info_Team"
      currentValue={overrides[columnKey]}
      {...{ overrideChange, columnKey, name, overrideProps }}
    />
  );

  const statsbombMappedIcon = statsbomb_mapped_icon && statsbomb_mapped_icon(goldenEntity);
  const mappedSBDValue = statsbomb_mapping_function && statsbomb_mapping_function(goldenEntity);
  const mappedSBDEntity = mappedSBDValue ? (
    <>
      {statsbombMappedIcon}
      {mappedSBDValue}
    </>
  ) : null;

  const mappedValue = mapping_function && mapping_function(claim);
  const mappingIsMissing =
    mapping_function &&
    (mappedValue === null || mappedValue === undefined) &&
    claim[columnKey] !== undefined &&
    claim[columnKey] !== null &&
    MappingControl;

  const claimIcon = mapped_icon && mapped_icon(claim);
  const mappedEntity = mappedValue ? (
    <>
      {claimIcon}
      {mappedValue}
    </>
  ) : (
    mappingIsMissing && <MappingControl currentValue={claim[columnKey]} provider={claim.provider} />
  );

  const claimDecisionRequired =
    claimStatuses[columnKey] != null && claim.approved_at === null && claim.rejected_at === null;

  return (
    <TableRow key={i}>
      <TableCell>{name}</TableCell>
      <TableCell>
        <CellContent entity={goldenEntity} columnKey={columnKey} type={type} icon={statsbomb_icon} />
      </TableCell>
      <TableCell>{mappedSBDEntity}</TableCell>
      <TableCell className={longText ? 'nowrap' : ''}>
        {<CellContent entity={claim} columnKey={columnKey} type={type} icon={icon} />}
      </TableCell>
      <TableCell>{mappedEntity}</TableCell>
      <TableCell>{claim[columnKey] != null ? claim.provider : null}</TableCell>
      <TableCell>{claim[columnKey] != null ? <DateValue fromNow>{claim.created_at}</DateValue> : null}</TableCell>
      <TableCell>{claimDecisionRequired && !mappingIsMissing && <ClaimChoiceRadio status="Approved" />}</TableCell>
      <TableCell>
        {claimDecisionRequired || [CLAIM_MODE_NEW_CLAIM, CLAIM_MODE_NEW_ENTITY].includes(claimMode) ? (
          <ClaimChoiceRadio status="Ignored" />
        ) : null}
      </TableCell>
      <TableCell>{claimDecisionRequired ? <ClaimChoiceRadio status="Rejected" /> : null}</TableCell>
      <TableCell>
        {isDisplayOverride(
          goldenEntityPK,
          columnKey,
          claimMode,
          claimDecisionRequired,
          OverrideControl,
          notCompositePK
        ) && <ClaimChoiceRadio status="Overridden" />}
      </TableCell>
      <TableCell>{overrideControl}</TableCell>
    </TableRow>
  );
};

const SingleClaimTable = ({
  goldenEntity = {},
  claim = {},
  rowSchema,
  claimStatuses,
  setClaimStatuses,
  selectAClaim,
  overrides,
  setOverrides,
  saveMutation,
  overrideMutation,
  createMutation,
  sourcePK,
  claimPK,
  goldenEntityPK,
  claimMode,
  getGoldenEntityId,
  entityType,
  similarEntityTable: SimilarEntityComponent,
  similarEntityQuery,
  similarEntityQueryObject,
  setClaimModeView,
  refetch,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [similarRecordValues, setSimilarRecordValues] = useState(null);
  const similarityMinimumPercentage = 30;
  const [newEntityData, setNewEntityData] = useState(null);

  const setClaimStatus = (columnKey, value) => {
    setClaimStatuses({
      ...claimStatuses,
      [columnKey]: value,
    });
  };

  const setAllClaimStatuses = status => {
    const newDecisions = {};
    rowSchema.forEach(row => {
      if (claimMode === CLAIM_MODE_NEW_CLAIM || claimMode === CLAIM_MODE_NEW_ENTITY) {
        const limitedStatus =
          status === 'Approved' || status === 'Rejected' || row.OverrideControl == null ? 'Ignored' : status;
        newDecisions[row.columnKey] = limitedStatus;
      } else if (claim && claim[row.columnKey] != null) {
        newDecisions[row.columnKey] = status;
        if (
          (status === 'Overridden' && row.OverrideControl == null) ||
          (status === 'Approved' && row.mapping_function && row.mapping_function(claim) == null)
        ) {
          newDecisions[row.columnKey] = 'Ignored';
        }
      }
    });
    setClaimStatuses(newDecisions);
  };

  const overrideChange = (columnKey, value) => {
    setOverrides({
      ...overrides,
      [columnKey]: value,
    });
  };

  const overrideAll = overrides => {
    const overridesObject =
      Object.keys(overrides).length > 0
        ? overrides
        : rowSchema.reduce((acc, curr) => ({ ...acc, [curr.columnKey]: null }), {});

    const newOverrides = Object.keys(overridesObject).reduce((acc, property) => {
      const initialOverrideValue = rowSchema.find(claim => claim.columnKey === property).initialOverrideValue;
      return { ...acc, [property]: initialOverrideValue };
    }, {});

    setOverrides({ ...overrides, ...newOverrides });
    setAllClaimStatuses('Overridden');
  };

  const [splitClaims, { loading: splitLoading }] = useMutation(saveMutation, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Saving Claims')),
    onCompleted: () => setSuccessMessage('Claim Decisions Saved!'),
  });
  const [overrideClaim, { loading: overrideLoading }] = useMutation(overrideMutation, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Overriding Claims')),
    onCompleted: () => setSuccessMessage((successMessage || '') + ' Overrides Applied!'),
  });
  const [createEntity, { loading: createLoading }] = useMutation(createMutation, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Creating Entity')),
    onCompleted: () => setSuccessMessage('Create New Entity Successful!'),
  });
  const [similarEntities, { loading: similarLoading, data: similarData }] = useLazyQuery(similarEntityQuery, {
    onCompleted: () => checkForSimilarRecords(similarData[similarEntityQueryObject], newEntityData),
    onError: e => setErrorMessage('Error loading similar entities'),
  });
  const [createClicked, setCreateClicked] = useState(false);

  const checkForSimilarRecords = (similarDataArray, newEntity) => {
    if (similarDataArray.length > 0 && similarDataArray[0].name_similarity >= similarityMinimumPercentage)
      setShowModal(true);
    else {
      createRecord(newEntity);
    }
  };

  const createRecord = async overrideAttributes => {
    if (!overrideAttributes) return setErrorMessage('No data to create record.');
    setCreateClicked(true);

    const newEntity = await createEntity({
      variables: {
        statsbomb_entity: overrideAttributes,
      },
    });

    if (!newEntity || newEntity.errors > 0) return setCreateClicked(false);

    const variables = getVariablesForOverrides(
      goldenEntityPK,
      sourcePK,
      newEntity,
      overrideAttributes,
      goldenEntity,
      getGoldenEntityId
    );
    await overrideClaim({
      variables: {
        ...variables,
      },
    });

    redirectIfRequired(
      claimMode,
      newEntity,
      goldenEntityPK,
      sourcePK,
      variables,
      entityType,
      getGoldenEntityId,
      history
    );

    selectAClaim(null);
  };

  const getEntityIdForRedirection = entityId => (goldenEntityPK, sourcePK, entityType, overrideAttributes, history) => {
    return redirectToEntityPage(entityId, goldenEntityPK, sourcePK, entityType, overrideAttributes, history);
  };

  const redirectToEntityPage = (newEntity, goldenEntityPK, sourcePK, entityType, overrideAttributes, history) => {
    const variables = getVariablesForOverrides(
      goldenEntityPK,
      sourcePK,
      newEntity,
      overrideAttributes,
      goldenEntity,
      getGoldenEntityId
    );

    const redirectUrl = isCompositeKey(goldenEntityPK, sourcePK)
      ? calculateRedirectUrlForCompositeKey(goldenEntityPK, variables, entityType)
      : `/${entityType}?${goldenEntityPK}=${newEntity}`;

    selectAClaim(null);
    history.push(redirectUrl);
  };

  const saveClick = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);

    const [approvalAttributes, ignoreAttributes, rejectionAttributes, overrideAttributes, newClaims] = [
      {},
      {},
      {},
      {},
      [],
    ];
    Object.keys(claimStatuses).forEach(key => {
      if (claimStatuses[key] === 'Approved') {
        approvalAttributes[key] = claim[key];
      } else if (claimStatuses[key] === 'Overridden') {
        overrideAttributes[key] = overrides[key];
        rejectionAttributes[key] = claim[key];
      } else if (claimStatuses[key] === 'Rejected') {
        rejectionAttributes[key] = claim[key];
      } else {
        ignoreAttributes[key] = claim[key];
      }
    });
    if (!_.isEmpty(approvalAttributes)) {
      approvalAttributes['provider'] = claim.provider;
      approvalAttributes[sourcePK] = claim[sourcePK];
      approvalAttributes['created_at'] = claim.created_at;
      approvalAttributes['approved_at'] = 'now()';
      newClaims.push(approvalAttributes);
    }
    if (!_.isEmpty(rejectionAttributes)) {
      rejectionAttributes['provider'] = claim.provider;
      rejectionAttributes[sourcePK] = claim[sourcePK];
      rejectionAttributes['created_at'] = claim.created_at;
      rejectionAttributes['rejected_at'] = 'now()';
      newClaims.push(rejectionAttributes);
    }
    if (!_.isEmpty(ignoreAttributes)) {
      ignoreAttributes['provider'] = claim.provider;
      ignoreAttributes[sourcePK] = claim[sourcePK];
      ignoreAttributes['created_at'] = claim.created_at;
      newClaims.push(ignoreAttributes);
    }
    if (![CLAIM_MODE_NEW_CLAIM, CLAIM_MODE_NEW_ENTITY].includes(claimMode)) {
      await splitClaims({
        variables: {
          [claimPK]: claim[claimPK],
          claims: newClaims,
        },
      });
    }
    if (!_.isEmpty(overrideAttributes)) {
      let newEntity;
      if (claimMode === CLAIM_MODE_NEW_ENTITY) {
        if (!similarEntityQuery) {
          return createRecord(overrideAttributes);
        }

        setSimilarRecordValues({
          provider: 'Info_Team',
          query: querySchema[`${entityType}`],
          entityName: overrideAttributes[`${entityType}_name`],
          type: entityType,
          [`selectSimilarEntityFn`]: value =>
            getEntityIdForRedirection(value)(goldenEntityPK, sourcePK, entityType, newEntityData, history),
        });

        setNewEntityData(overrideAttributes);
        return similarEntities({
          variables: {
            provider: 'Info_Team',
            name: overrideAttributes[`${entityType}_name`],
            offset: 0,
          },
        });
      }

      const variables = getVariablesForOverrides(
        goldenEntityPK,
        sourcePK,
        newEntity,
        overrideAttributes,
        goldenEntity,
        getGoldenEntityId
      );
      await overrideClaim({
        variables: {
          ...variables,
        },
      });

      redirectIfRequired(
        claimMode,
        newEntity,
        goldenEntityPK,
        sourcePK,
        variables,
        entityType,
        getGoldenEntityId,
        history
      );
    }
    if (!areAllEntityPropertiesIgnored(claimStatuses)) selectAClaim(null);
    else setErrorMessage('You cannot create an empty claim');
    refetch();
  };

  const showSave = claimMode !== 'view' && claim.rejected_at == null && claim.approved_at == null;
  const isLoading = splitLoading || overrideLoading || createLoading;
  return (
    <>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
      <RepeatableSuccess message={successMessage} setMessage={setSuccessMessage} />
      {similarEntityQuery && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          {similarLoading === true ? (
            <InlineProgress />
          ) : (
            <div className={classes.modal}>
              <div>
                <h2 style={{ textAlign: 'center' }}>Duplicate Checker</h2>
                <p style={{ textAlign: 'center' }}>
                  The record you are trying to enter may already be in the database, please check the list below to
                  before creating. You can click the record to be redirected to it's page.
                </p>
              </div>
              <TableContainer component={Paper} classes={{ root: classes.duplicateTable }}>
                <SimilarEntityComponent {...similarRecordValues} selectedSim={null} />
              </TableContainer>
              <div className={classes.modalButtons}>
                <Button
                  disabled={createClicked}
                  variant="contained"
                  color="primary"
                  onClick={() => createRecord(newEntityData)}>
                  Create New Entity
                </Button>
                <Button variant="contained" color="primary" onClick={() => setShowModal(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Attribute</TableCell>
            <TableCell>Statsbomb Value</TableCell>
            <TableCell>Statsbomb Mapped Value</TableCell>
            <TableCell>Claim Value</TableCell>
            <TableCell>Mapped Value</TableCell>
            <TableCell>Claim Provider</TableCell>
            <TableCell>Claim Date</TableCell>
            <TableCell>
              Approve?
              <IconButton onClick={() => setAllClaimStatuses('Approved')}>
                <SelectAllIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              Ignore?
              <IconButton onClick={() => setAllClaimStatuses('Ignored')}>
                <SelectAllIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              Reject?
              <IconButton onClick={() => setAllClaimStatuses('Rejected')}>
                <SelectAllIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              Override?
              <IconButton onClick={() => overrideAll(overrides)}>
                <SelectAllIcon />
              </IconButton>
            </TableCell>
            <TableCell className={classes.last}>New Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowSchema.map((claimSchema, index) => (
            <SingleClaimRow
              key={index}
              claimSchema={claimSchema}
              goldenEntity={goldenEntity}
              claim={claim}
              claimStatuses={claimStatuses}
              setClaimStatus={setClaimStatus}
              overrideChange={overrideChange}
              overrides={overrides}
              claimMode={claimMode}
              goldenEntityPK={goldenEntityPK}
            />
          ))}
        </TableBody>
      </Table>

      {showSave &&
        (isLoading ? (
          <InlineProgress />
        ) : (
          <>
            <Button
              className={classes.cancelButton}
              variant="contained"
              onClick={() => (claimMode !== CLAIM_MODE_NEW_ENTITY ? setClaimModeView() : history.goBack())}>
              Cancel
            </Button>
            <Button className={classes.saveButton} variant="contained" color="primary" onClick={saveClick}>
              Save Claim
            </Button>
          </>
        ))}
    </>
  );
};
export {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
  isDisplayOverride,
  getVariablesForOverrides,
  redirectIfRequired,
};
