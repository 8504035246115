import React from 'react';
import { concatValues, nestedValues } from '../util/useful';
import { RoundType } from '../components/override/roundType';
import { NumberOverride } from '../components/override/number';
import { RoundStructure } from '../components/override/roundStructure';
import { PhaseModalOverride } from '../components/override/roundphase';
import { TextFilter } from '../components/table/entityTableFilters';
import { FlagIcon } from '../components/util/icon';

const FULL_ROUND_SCHEMA = [
  { columnKey: 'claim_round_uuid', name: 'Claim Round', type: 'Integer' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  {
    columnKey: 'provider_round_id',
    name: 'Provider Round',
    type: 'String',
    mapping_function: nestedValues('mapping_phase.source_phase.phase_type.phase_type_name'),
  },
  {
    columnKey: 'round_type_id',
    name: 'Round Type',
    type: 'String',
    mapping_function: nestedValues('source_round.statsbomb_round_type.round_type_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_round_type.round_type_name'),
    OverrideControl: RoundType,
  },
  {
    columnKey: 'round_type_name',
    name: 'Round Type',
    type: 'String',
  },
  {
    columnKey: 'round_structure_id',
    name: 'Round Structure',
    type: 'String',
    mapping_function: nestedValues('source_round.statsbomb_round_structure.round_structure_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_round_structure.round_structure_name'),
    OverrideControl: RoundStructure,
  },
  {
    columnKey: 'round_structure_name',
    name: 'Round Structure',
    type: 'String',
  },
  {
    columnKey: 'phase_id',
    name: 'Phase',
    type: 'String',
    mapping_function: nestedValues('mapping_phase.statsbomb_phase.statsbomb_phase_type.phase_type_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_phase.statsbomb_phase_type.phase_type_name'),
    OverrideControl: PhaseModalOverride,
  },
  {
    columnKey: 'phase_type_name',
    name: 'Phase Type',
    type: 'String',
  },
  {
    columnKey: 'competition_season_name',
    name: 'Competition season',
    type: 'String',
    mapping_function: concatValues('competition_name', 'season_name'),
    icon: e => <FlagIcon code={e.area_code} />,
  },
  { columnKey: 'round_match_weeks', name: 'Weeks', type: 'Integer' },
  { columnKey: 'round_matches', name: 'Matches', type: 'Integer', OverrideControl: NumberOverride },
  { columnKey: 'round_teams', name: 'Teams', type: 'Integer', OverrideControl: NumberOverride },
  { columnKey: 'round_last_match_date', name: 'Last match date', type: 'Timestamp' },
  {
    columnKey: 'round_order',
    name: 'Order',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  { columnKey: 'deleted', name: 'Deleted', type: 'Boolean' },

  { columnKey: 'approved_at', name: 'Approved At', type: 'Timestamp' },
  { columnKey: 'created_at', name: 'Created At', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected At', type: 'Timestamp' },
];

const GOLDEN_ROUND_SCHEMA = FULL_ROUND_SCHEMA.filter(field =>
  [
    'round_type_name',
    'phase_type_name',
    'round_structure_name',
    'round_order',
    'round_matches',
    'round_teams',
    'competition_season_name',
  ].includes(field.columnKey)
);

const ROUND_SCHEMA = FULL_ROUND_SCHEMA.filter(a =>
  [
    'round_type_id',
    'phase_id',
    'round_order',
    'round_structure_id',
    'round_matches',
    'round_teams',
    'deleted',
  ].includes(a.columnKey)
);

const ROUND_CLAIM_SCHEMA = [
  'phase_id',
  'round_first_match_date',
  'round_matches',
  'round_order',
  'round_structure_id',
  'round_type_id',
  'round_teams',
];

const ROUND_ALL_CLAIMS_SCHEMA = FULL_ROUND_SCHEMA.filter(a =>
  [
    'phase_id',
    'round_first_match_date',
    'round_matches',
    'round_order',
    'round_structure_id',
    'round_type_id',
    'round_teams',
    'claim_round_uuid',
    'provider_round_id',
    'provider',
    'created_at',
  ].includes(a.columnKey)
);

const ROUND_CLAIM_TO_MAP_SCHEMA = FULL_ROUND_SCHEMA.filter(a =>
  [
    'claim_round_uuid',
    'phase_id',
    'provider',
    'round_first_match_date',
    'round_matches',
    'round_order',
    'round_structure_id',
    'round_type_id',
    'round_teams',
    'approved_at',
    'rejected_at',
    'created_at',
  ].includes(a.columnKey)
);

const MAPPED_ROUND_SCHEMA = FULL_ROUND_SCHEMA.filter(a =>
  [
    'round_type_id',
    'phase_id',
    'round_order',
    'round_structure_id',
    'round_matches',
    'round_teams',
    'provider',
    'provider_round_id',
  ].includes(a.columnKey)
);

const ROUND_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'text',
      queryVariableName: 'name',
      displayName: 'Name',
    },
  },
];
const ROUND_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
];
export {
  FULL_ROUND_SCHEMA,
  GOLDEN_ROUND_SCHEMA,
  ROUND_SCHEMA,
  ROUND_CLAIM_SCHEMA,
  ROUND_ALL_CLAIMS_SCHEMA,
  ROUND_CLAIM_TO_MAP_SCHEMA,
  ROUND_FILTER_SCHEMA,
  MAPPED_ROUND_SCHEMA,
  ROUND_CLAIM_FILTER_SCHEMA,
};
