import gql from 'graphql-tag';

const MAP_TO_MANAGER = gql`
  mutation MapToManager($manager_id: Int!, $provider: source_provider_enum!, $provider_manager_id: String!) {
    insert_mapping_manager(
      objects: { manager_id: $manager_id, provider: $provider, provider_manager_id: $provider_manager_id }
    ) {
      returning {
        manager_id
        provider
        provider_manager_id
      }
    }
  }
`;

const MAKE_AND_MAP_MANAGER = gql`
  mutation MakeAndMapManager($manager_name: String!, $provider: source_provider_enum!, $provider_manager_id: String!) {
    insert_mapping_manager(
      objects: {
        statsbomb_manager: { data: { manager_name: $manager_name } }
        provider: $provider
        provider_manager_id: $provider_manager_id
      }
    ) {
      returning {
        manager_id
        provider
        provider_manager_id
      }
    }
  }
`;

const VIEW_MANAGER_MAPPINGS = gql`
  subscription viewTeamMappings($id: Int!) {
    mapping_manager(where: { manager_id: { _eq: $id } }) {
      source_manager {
        manager_name
        manager_firstname
        manager_lastname
        manager_nickname
        manager_country_of_birth
        manager_nationality
        manager_date_of_birth
        manager_is_female
        manager_photo_url
        provider
        provider_manager_id
      }
    }
  }
`;

const DELETE_MANAGER_MAPPING = gql`
  mutation deleteTeamMappings(
    $claim_data: claim_manager_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_manager_one(object: $claim_data, on_conflict: { constraint: manager_pkey, update_columns: [] }) {
      provider
      provider_manager_id
    }

    delete_mapping_manager_by_pk(provider: $provider, provider_manager_id: $provider_id) {
      manager_id
      provider_manager_id
      provider
    }
  }
`;

export { MAP_TO_MANAGER, MAKE_AND_MAP_MANAGER, VIEW_MANAGER_MAPPINGS, DELETE_MANAGER_MAPPING };
