import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Table, Paper, TableHead, TableRow, TableBody } from '@material-ui/core';
import { TableCell } from '../table/table';
import { SIMILAR_TRANSFER_MANAGERS } from '../../hasura/queries/transferManager/transferManagers';
import { Error } from '../util/notifications';
import { Progress } from '../util/progress';
import { formatDateString } from '../../util/date';
import { formatMoney } from '../../util/number';

const SimilarTransferManagersTable = ({ manager_id, selectSimilarTransferManagerFn, selectedSim }) => {
  const { loading, error, data } = useQuery(SIMILAR_TRANSFER_MANAGERS, {
    variables: {
      manager_id,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Similar Manager Transfers" error={error} />

      <TableContainer component={Paper}>
        <h3>Similar Manager Transfers</h3>
        <p style={{ paddingLeft: '5px' }}>Select a record from below to map to that record.</p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Manager</TableCell>
              <TableCell>Team From</TableCell>
              <TableCell>Team To</TableCell>
              <TableCell>Transfer Date</TableCell>
              <TableCell>Transfer Type</TableCell>
              <TableCell>Transfer Value</TableCell>
              <TableCell>Transfer Currency</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.statsbomb_transfer_manager.map(transfer => (
              <TableRow
                key={transfer.transfer_manager_id}
                className={transfer.transfer_manager_id === selectedSim ? 'active' : ''}
                onClick={() => selectSimilarTransferManagerFn(transfer.transfer_manager_id)}>
                <TableCell>{transfer.statsbomb_manager.manager_name}</TableCell>
                <TableCell>{transfer.team_from_statsbomb_team.team_name}</TableCell>
                <TableCell>{transfer.team_to_statsbomb_team.team_name}</TableCell>
                <TableCell>
                  {transfer.transfer_date && formatDateString(transfer.transfer_date, 'DD/MM/YYYY')}
                </TableCell>
                <TableCell>{transfer.statsbomb_transfer_type.display_name}</TableCell>
                <TableCell>
                  {transfer.transfer_value && formatMoney(transfer.transfer_value, transfer.transfer_currency)}
                </TableCell>
                <TableCell>{transfer.transfer_currency}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SimilarTransferManagersTable;
