import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import { MATCH_SCHEMA, MATCH_CLAIM_SCHEMA, MAPPED_MATCH_SCHEMA } from '../schema/matchSchema';
import { ClaimFilters } from '../components/claim/claimFilters';
import { Progress } from '../components/util/progress';
import {
  GOLDEN_MATCH_AND_CLAIMS,
  SAVE_MATCH_CLAIM,
  OVERRIDE_MATCH_CLAIM,
  INSERT_GOLDEN_MATCH,
} from '../hasura/queries/match/matchClaims';

import { Error } from '../components/util/notifications';
import ClaimHistoryTable from '../components/table/claimHistoryTable';
import MappingView from '../components/mapping/mappingView';
import { DELETE_MATCH_MAPPING, VIEW_MATCH_MAPPINGS } from '../hasura/queries/matchMapping';
import { DELETE_MATCH } from '../hasura/queries/match/matches';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenMatchView = ({ matchId, isNewMatch }) => {
  const ignoreEverything = {};
  MATCH_SCHEMA.forEach(row => {
    ignoreEverything[row.columnKey] = 'Ignored';
  });

  const history = useHistory();
  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewMatch ? ignoreEverything : {});
  const [overrides, setOverrides] = useState({});
  const [claimMode, setClaimMode] = useState(isNewMatch ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);
  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loading, error, data, refetch } = useQuery(GOLDEN_MATCH_AND_CLAIMS, {
    variables: {
      match_id: matchId,
    },
  });
  if (loading) return <Progress />;
  let allClaims = [];
  data &&
    data.statsbomb_match &&
    data.statsbomb_match[0] &&
    data.statsbomb_match[0].mapping_matches.forEach(mappingEntry => {
      allClaims = [...allClaims, ...mappingEntry.claim_matches];
    });

  let filteredClaims = allClaims;
  if (claimFilter === 'undecided') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }

  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_match_uuid === claimUUID;
    })[0];
    if (claim) {
      MATCH_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    MATCH_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(ignoreEverything);
  };

  const getGoldenMatchId = insertedMatch => insertedMatch?.data?.insert_statsbomb_match?.returning?.[0]?.match_id;

  const claimSelected = claim => {
    selectAClaim(claim.claim_match_uuid);
  };

  const matchLink = selectedMatchData =>
    selectedMatchData
      ? history.push(
          `/full_lineup?match_id=${selectedMatchData.match_id}&match_home_team_id=${selectedMatchData.match_home_team_id}&match_away_team_id=${selectedMatchData.match_away_team_id}`
        )
      : null;

  return (
    <>
      <Error message="" error={error} />
      <div className="container-row">
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={6}>
            <h2>Match Singular: {isNewMatch ? ' New Match' : matchId}</h2>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={buttonClasses.button}
              variant="contained"
              color="primary"
              onClick={e => matchLink(data.statsbomb_match[0])}>
              View Full Lineup
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className="container-row">
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="match"
              schema={MATCH_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{ headerValue: 'Match', bodyValue: data.statsbomb_match[0]?.match_name }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <MappingView
            type="match"
            recordId={matchId}
            schema={MAPPED_MATCH_SCHEMA}
            subscription={VIEW_MATCH_MAPPINGS}
            deleteMutation={DELETE_MATCH_MAPPING}
            deleteEntityMutation={DELETE_MATCH}
            entityKey="provider_match_id"
            styles={{ tableContatinerClasses, buttonClasses }}
          />
        )}
        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Match Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={data.statsbomb_match[0]}
            claim={
              allClaims.filter(c => {
                return c.claim_match_uuid === selectedClaimId;
              })[0]
            }
            rowSchema={MATCH_SCHEMA}
            saveMutation={SAVE_MATCH_CLAIM}
            overrideMutation={OVERRIDE_MATCH_CLAIM}
            createMutation={INSERT_GOLDEN_MATCH}
            sourcePK="provider_match_id"
            claimPK="claim_match_uuid"
            goldenEntityPK="match_id"
            getGoldenEntityId={getGoldenMatchId}
            entityType="match"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
            {...{
              claimStatuses,
              setClaimStatuses,
              selectAClaim,
              overrides,
              setOverrides,
              claimMode,
              refetch,
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

function Match() {
  const query = new URLSearchParams(useLocation().search);
  const isNewMatch = query.get('new');
  const matchId = isNewMatch ? '0' : query.get('match_id');

  return <GoldenMatchView matchId={matchId} isNewMatch={!!isNewMatch} />;
}

export default Match;
