import gql from 'graphql-tag';

const GOLDEN_TRANSFER_PLAYERS = gql`
  query getGoldenTransferPlayers(
    $name: String
    $duplicate_count: bigint = 0
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_transfer_player_order_by!
  ) {
    statsbomb_golden_transfer_player(
      where: {
        _and: [
          { search_name: { _ilike: $name }, duplicate_count: { _gte: $duplicate_count }, deleted: { _eq: $deleted } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      player_id
      player_name
      search_name
      team_from_id
      team_from_name
      team_to_id
      team_to_name
      transfer_currency
      transfer_date
      transfer_player_id
      transfer_type
      transfer_type_display_name
      transfer_value
      deleted
    }
  }
`;

const GOLDEN_TRANSFER_PLAYERS_COUNT = gql`
  query getGoldenTransferPlayerCount($name: String, $duplicate_count: bigint = 0, $deleted: Boolean = false) {
    statsbomb_golden_transfer_player_aggregate(
      where: {
        _and: [
          { search_name: { _ilike: $name }, duplicate_count: { _gte: $duplicate_count }, deleted: { _eq: $deleted } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_SOURCE_TRANSFER_PLAYER = gql`
  mutation insertSourceTransferPlayer(
    $provider_transfer_player_id: String
    $player_id: String
    $team_to_id: String
    $team_from_id: String
  ) {
    insert_source_transfer_player(
      objects: {
        player_id: $player_id
        team_to_id: $team_to_id
        team_from_id: $team_from_id
        last_updated: "now()"
        provider: Info_Team
        provider_transfer_player_id: $provider_transfer_player_id
      }
      on_conflict: { constraint: transfer_player_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const DELETE_TRANSFER_PLAYER = gql`
  mutation deleteTransferPlayer($transfer_player_id: Int!) {
    delete_mapping_transfer_player(where: { transfer_player_id: { _eq: $transfer_player_id } }) {
      affected_rows
    }
    delete_statsbomb_transfer_player(where: { transfer_player_id: { _eq: $transfer_player_id } }) {
      affected_rows
    }
  }
`;

const DELETE_TRANSFER_PLAYER_BY_ID = gql`
  mutation deleteTransferPlayer($transfer_player_id: Int!) {
    delete_mapping_transfer_player(where: { transfer_player_id: { _eq: $transfer_player_id } }) {
      affected_rows
    }
    delete_statsbomb_transfer_player(where: { transfer_player_id: { _eq: $transfer_player_id } }) {
      affected_rows
    }
  }
`;
// TODO : WILL delete all
// const DELETE_TRANSFER_PLAYER_BY_PLAYER = gql`
//   mutation deleteTransferPlayer($player_id: Int!) {
//     delete_statsbomb_transfer_player(where: { player_id: { _eq: $player_id } }) {
//       returning {
//         player_id
//       }
//     }
//   }
// `;

const TRANSFER_PLAYERS = gql`
  query getAllTransferPlayers {
    statsbomb_transfer_player(where: { deleted: { _eq: false } }, order_by: { player_id: asc }) {
      player_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_player_id
      transfer_type
      transfer_value
    }
  }
`;

const SIMILAR_TRANSFER_PLAYERS = gql`
  query getAllTransfersByPlayer($player_id: Int) {
    statsbomb_transfer_player(where: { player_id: { _eq: $player_id } }) {
      transfer_player_id
      player_id
      team_from_id
      team_to_id
      transfer_type
      transfer_date
      transfer_value
      transfer_currency
      statsbomb_player {
        player_name
      }
      team_from_statsbomb_team {
        team_name
        statsbomb_area {
          area_code
        }
      }
      team_to_statsbomb_team {
        team_name
        statsbomb_area {
          area_code
        }
      }
      statsbomb_transfer_type {
        display_name
      }
      mapping_transfer_players {
        provider
        provider_transfer_player_id
        transfer_player_id
      }
    }
  }
`;
const PLAYER_TRANSFERS_BY_PLAYER = gql`
  query getAllTransferPlayer($player_id: Int!) {
    statsbomb_transfer_player(where: { player_id: { _eq: $player_id } }) {
      transfer_player_id
      player_id
      team_from_id
      team_to_id
      transfer_type
      transfer_date
      transfer_value
      transfer_currency
      statsbomb_player {
        player_name
      }
      team_from_statsbomb_team {
        team_name
        statsbomb_area {
          area_code
        }
      }
      team_to_statsbomb_team {
        team_name
        statsbomb_area {
          area_code
        }
      }
      statsbomb_transfer_type {
        display_name
      }
      mapping_transfer_players {
        provider
        provider_transfer_player_id
        transfer_player_id
      }
    }
  }
`;

const GET_ALL_TRANSFERS_BY_PLAYER = gql`
  subscription getAllTransfersByPlayer($player_id: Int) {
    statsbomb_player(where: { player_id: { _eq: $player_id } }) {
      player_id
      player_name
      statsbomb_transfer_players(order_by: { transfer_date: desc }) {
        transfer_player_id
        player_id
        team_from_id
        team_to_id
        transfer_type
        transfer_date
        transfer_value
        transfer_currency
        statsbomb_player {
          player_name
        }
        team_from_statsbomb_team {
          team_name
          statsbomb_area {
            area_code
          }
        }
        team_to_statsbomb_team {
          team_name
          statsbomb_area {
            area_code
          }
        }
        statsbomb_transfer_type {
          display_name
        }
        mapping_transfer_players {
          provider
          provider_transfer_player_id
          transfer_player_id
        }
      }
    }
  }
`;

const GET_PLAYER_CURRENT_TEAM = gql`
  query getPlayerCurrentTeam($player_id: Int!) {
    statsbomb_current_transfer_player(where: { player_id: { _eq: $player_id } }) {
      player_id
      team_to_id
      transfer_date
      statsbomb_player {
        player_id
        player_name
        player_date_of_birth
        nationality_statsbomb_area {
          area_code
          area_name
        }
      }
      team_to_statsbomb_team {
        team_name
      }
    }
  }
`;

const GET_TRANSFER_PLAYERS_BY_ID = gql`
  query getTransferPlayersByIds($entityIds: [Int!]) {
    statsbomb_golden_transfer_player(where: { transfer_player_id: { _in: $entityIds } }) {
      player_id
      player_name
      search_name
      team_from_id
      team_from_name
      team_to_id
      team_to_name
      transfer_currency
      transfer_date
      transfer_player_id
      transfer_type
      transfer_type_display_name
      transfer_value
    }
  }
`;

export {
  GOLDEN_TRANSFER_PLAYERS,
  GOLDEN_TRANSFER_PLAYERS_COUNT,
  INSERT_SOURCE_TRANSFER_PLAYER,
  DELETE_TRANSFER_PLAYER,
  DELETE_TRANSFER_PLAYER_BY_ID,
  TRANSFER_PLAYERS,
  SIMILAR_TRANSFER_PLAYERS,
  GET_ALL_TRANSFERS_BY_PLAYER,
  GET_PLAYER_CURRENT_TEAM,
  PLAYER_TRANSFERS_BY_PLAYER,
  GET_TRANSFER_PLAYERS_BY_ID,
};
