import React from 'react';

import { TextOverride } from '../components/override/text';
import { DOBOverride } from '../components/override/dob';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { AreaOverride, AreaSelector } from '../components/override/area';
import { nestedValues } from '../util/useful';
import { FlagIcon } from '../components/util/icon';
import { ListFilter, TextFilter, DateFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { AREAS } from '../hasura/queries/area/areas';
import { dateYearMinusEighteen } from '../components/override/dateTime';

const FULL_MANAGER_SCHEMA = [
  { columnKey: 'claim_manager_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_manager_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'manager_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  { columnKey: 'manager_name', name: 'Name', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'manager_firstname', name: 'First Name', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'manager_lastname', name: 'Last Name', type: 'String', OverrideControl: TextOverride },
  { columnKey: 'manager_nickname', name: 'Nickname', type: 'String', OverrideControl: TextOverride },
  {
    columnKey: 'manager_country_of_birth',
    name: 'Country of Birth',
    type: 'String',
    MappingControl: AreaSelector,
    mapping_function: nestedValues('cob_mapping_area.statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('cob_mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapping_function: nestedValues('cob_statsbomb_area.area_name'),
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('cob_statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'manager_nationality',
    name: 'Nationality',
    type: 'String',
    MappingControl: AreaSelector,
    mapping_function: nestedValues('nationality_mapping_area.statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('nationality_mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapping_function: nestedValues('nationality_statsbomb_area.area_name'),
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('nationality_statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'manager_date_of_birth',
    name: 'Date of Birth',
    type: 'Date',
    OverrideControl: DOBOverride,
    initialOverrideValue: dateYearMinusEighteen(),
  },
  {
    columnKey: 'manager_is_female',
    name: 'Female?',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  { columnKey: 'manager_photo_url', name: 'Image URL', type: 'String', OverrideControl: TextOverride, longText: true },
  {
    columnKey: 'deleted',
    name: 'Delete?',
    type: 'Boolean',
  },
  {
    columnKey: 'country_of_birth_name',
    name: 'Country of Birth',
    type: 'String',
    icon: e => <FlagIcon code={e.country_of_birth_code} />,
  },
  {
    columnKey: 'nationality_name',
    name: 'Nationality',
    type: 'String',
    icon: e => <FlagIcon code={e.nationality_code} />,
  },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const GOLDEN_MANAGER_SCHEMA = FULL_MANAGER_SCHEMA.filter(a =>
  [
    'manager_name',
    'manager_firstname',
    'manager_lastname',
    'manager_nickname',
    'country_of_birth_name',
    'nationality_name',
    'manager_date_of_birth',
    'manager_is_female',
    'min_spec',
  ].includes(a.columnKey)
);

const MANAGER_SCHEMA = FULL_MANAGER_SCHEMA.filter(field =>
  [
    'manager_name',
    'manager_firstname',
    'manager_lastname',
    'manager_nickname',
    'manager_country_of_birth',
    'manager_nationality',
    'manager_date_of_birth',
    'manager_is_female',
    'manager_photo_url',
    'deleted',
  ].includes(field.columnKey)
);

const MANAGER_CLAIM_SCHEMA = [
  'manager_name',
  'manager_firstname',
  'manager_lastname',
  'manager_nickname',
  'manager_country_of_birth',
  'manager_nationality',
  'manager_date_of_birth',
];

const MANAGER_ALL_CLAIMS_SCHEMA = FULL_MANAGER_SCHEMA.filter(a =>
  [
    'manager_name',
    'manager_firstname',
    'manager_lastname',
    'manager_nickname',
    'manager_country_of_birth',
    'manager_nationality',
    'manager_date_of_birth',
    'claim_manager_uuid',
    'provider_manager_id',
    'provider',
    'created_at',
  ].includes(a.columnKey)
);

const MANAGER_CLAIM_TO_MAP_SCHEMA = FULL_MANAGER_SCHEMA.filter(field =>
  [
    'manager_name',
    'manager_firstname',
    'manager_lastname',
    'manager_nickname',
    'manager_country_of_birth',
    'manager_nationality',
    'manager_date_of_birth',
    'deleted',
    'claim_manager_uuid',
    'created_at',
    'provider',
    'provider_area_id',
    'approved_at',
    'rejected_at',
  ].includes(field.columnKey)
);

const MAPPED_MANAGER_SCHEMA = FULL_MANAGER_SCHEMA.filter(field =>
  [
    'manager_name',
    'manager_firstname',
    'manager_lastname',
    'manager_nickname',
    'manager_country_of_birth',
    'manager_nationality',
    'manager_date_of_birth',
    'manager_is_female',
    'manager_photo_url',
    'provider',
    'provider_manager_id',
  ].includes(field.columnKey)
);

const MANAGER_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'text',
      queryVariableName: 'name',
      displayName: 'Name',
    },
  },
  {
    type: 'area',
    Component: ListFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Nationality',
      flagIconPath: 'area_code',
      searchQuery: AREAS,
      idPath: 'area_name',
      variablePaths: ['area_name'],
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Birth Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Birth Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
  {
    type: 'duplicate',
    Component: BooleanFilter,
    attributes: { key: 'duplicateCount', queryVariableName: 'duplicate_count', displayName: 'Show Duplicates' },
  },
];

const MANAGER_MIN_IQ_SCHEMA = FULL_MANAGER_SCHEMA.filter(a =>
  ['manager_id', 'manager_name', 'manager_date_of_birth ', 'manager_nationality'].includes(a.columnKey)
);

const MANAGER_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'managerName',
      queryVariableName: 'name',
      displayName: 'Name',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'nationality',
      queryVariableName: 'area',
      displayName: 'Nationality',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Birth Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Birth Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'isFemale', queryVariableName: 'is_female', displayName: 'is Female?' },
  },
];

export {
  MANAGER_SCHEMA,
  GOLDEN_MANAGER_SCHEMA,
  MANAGER_CLAIM_SCHEMA,
  MANAGER_ALL_CLAIMS_SCHEMA,
  MANAGER_CLAIM_TO_MAP_SCHEMA,
  MAPPED_MANAGER_SCHEMA,
  MANAGER_FILTER_SCHEMA,
  MANAGER_MIN_IQ_SCHEMA,
  MANAGER_CLAIM_FILTER_SCHEMA,
};
