import gql from 'graphql-tag';

const SUGGESTED_PLAYERS = gql`
  query suggestedPlayersForLineup($team_ids: [Int!]) {
    statsbomb_players_lineup_aggregate(where: { team_id: { _in: $team_ids } }, order_by: { player_name: asc }) {
      appearances
      mode_jersey
      player_id
      player_name
      team_id
      player_date_of_birth
      player_nationality_code
      player_nationality_name
    }
  }
`;

const GOLDEN_FULL_LINEUPS = gql`
  query getGoldenLineups(
    $name: String!
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_full_lineup_order_by!
  ) {
    statsbomb_golden_full_lineup(
      where: { search_name: { _ilike: $name } }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      away_area_id
      away_area_code
      away_area_name
      away_team_name
      home_area_id
      home_area_code
      home_area_name
      home_team_name
      match_away_team_id
      match_date
      match_home_team_id
      match_id
      players_added
      match_name
      search_name
    }
  }
`;

const GOLDEN_FULL_LINEUPS_QUERY = gql`
  query getGoldenLineups {
    statsbomb_golden_full_lineup {
      away_area_id
      away_area_code
      away_area_name
      away_team_name
      home_area_id
      home_area_code
      home_area_name
      home_team_name
      match_away_team_id
      match_date
      match_home_team_id
      match_id
      players_added
      match_name
      search_name
    }
  }
`;

const GOLDEN_FULL_LINEUPS_COUNT = gql`
  query getGoldenLineupsCount($name: String!) {
    statsbomb_golden_full_lineup_aggregate(where: { search_name: { _ilike: $name } }) {
      aggregate {
        count
      }
    }
  }
`;

const CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_LINEUP_CLAIM = gql`
  mutation updateLineupClaimApproveAt($match_id: Int!, $team_id: Int!, $player_id: Int!, $claim_lineup_uuid: uuid!) {
    insert_statsbomb_lineup_one(
      object: { match_id: $match_id, team_id: $team_id, player_id: $player_id }
      on_conflict: { constraint: lineup_pkey, update_columns: [] }
    ) {
      match_id
      player_id
      team_id
    }
    update_claim_lineup_by_pk(pk_columns: { claim_lineup_uuid: $claim_lineup_uuid }, _set: { approved_at: "now()" }) {
      claim_lineup_uuid
    }
  }
`;

const REJECT_ENTIRE_LINEUP_CLAIM = gql`
  mutation updateLineupClaimRejectedAt($claim_lineup_uuid: uuid!) {
    update_claim_lineup_by_pk(pk_columns: { claim_lineup_uuid: $claim_lineup_uuid }, _set: { rejected_at: "now()" }) {
      claim_lineup_uuid
    }
  }
`;

const GOLDEN_PLAYERS_FOR_LINEUP_INCLUSION = gql`
  query suggestedPlayersForLineup($search_string: String, $search_int: Int) {
    statsbomb_player(
      where: { _or: [{ player_name: { _ilike: $search_string } }, { player_id: { _eq: $search_int } }] }
      order_by: { player_name: asc }
      limit: 15
    ) {
      player_id
      player_name
      nationality_statsbomb_area {
        area_code
        area_name
      }
      player_date_of_birth
    }
  }
`;

const INSERT_SOURCE_MATCH = gql`
  mutation createSourceMatch($match_id: String!, $match_home_team_id: String!, $match_away_team_id: String!) {
    insert_source_match(
      objects: {
        provider_match_id: $match_id
        match_home_team_id: $match_home_team_id
        match_away_team_id: $match_away_team_id
        last_updated: "now()"
        provider: Info_Team
      }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

// TODO: delete insert source player and map player from here
// refactor files using them to use the playerList.js and playerMapping.js

const INSERT_SOURCE_PLAYER = gql`
  mutation createSourcePlayer($player_id: String!, $player_name: String!) {
    insert_source_player(
      objects: { provider_player_id: $player_id, player_name: $player_name, last_updated: "now()", provider: Info_Team }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_MATCH = gql`
  mutation mapMatch($match_id: Int!, $provider: source_provider_enum!, $provider_match_id: String!) {
    insert_mapping_match_one(
      object: { match_id: $match_id, provider: $provider, provider_match_id: $provider_match_id }
    ) {
      match_id
      provider
      provider_match_id
    }
  }
`;

const MAP_PLAYER = gql`
  mutation mapPlayer($player_id: Int!, $provider: source_provider_enum!, $provider_player_id: String!) {
    insert_mapping_player_one(
      object: { player_id: $player_id, provider: $provider, provider_player_id: $provider_player_id }
    ) {
      player_id
      provider
      provider_player_id
    }
  }
`;

const LINEUP_TYPES = gql`
  query getAllLineupTypes {
    statsbomb_player_lineup_selection_type(order_by: { sort_order: asc }) {
      selection_type: player_lineup_selection_type
      display_name
    }
  }
`;
const DELETE_FULL_LINEUPS = gql`
  mutation MyMutation($match_id: Int!) {
    update_statsbomb_lineup(where: { match_id: { _eq: $match_id } }, _set: { deleted: true }) {
      affected_rows
    }
  }
`;
export {
  INSERT_SOURCE_MATCH,
  INSERT_SOURCE_PLAYER,
  MAP_MATCH,
  MAP_PLAYER,
  GOLDEN_PLAYERS_FOR_LINEUP_INCLUSION,
  GOLDEN_FULL_LINEUPS_QUERY,
  CREATE_GOLDEN_RECORD_AND_APPROVE_ENTIRE_LINEUP_CLAIM,
  REJECT_ENTIRE_LINEUP_CLAIM,
  SUGGESTED_PLAYERS,
  GOLDEN_FULL_LINEUPS,
  GOLDEN_FULL_LINEUPS_COUNT,
  LINEUP_TYPES,
  DELETE_FULL_LINEUPS,
};
