import React from 'react';
import Button from '@material-ui/core/Button';

const Filters = ({ claimFilter, filterResultsFn, filters, withCount }) => (
  <section className="claim-state-filters">
    {filters.map(({ id, name, claimCount }) => (
      <div key={id} style={{ position: 'relative', display: 'inline-block', marginRight: 22.5 }}>
        <Button
          variant="contained"
          style={{ paddingLeft: 25, paddingRight: 25 }}
          color={claimFilter === id ? 'primary' : 'default'}
          onClick={() => filterResultsFn(id)}>
          {name}
          {withCount && claimCount?.count > 0 && (
            <span
              style={{
                position: 'absolute',
                top: '-5px',
                right: '-18px',
                minWidth: 30,
                borderRadius: 5,
                backgroundColor: claimCount.color,
                marginLeft: 10,
                padding: '0 5px',
                color: 'white',
              }}>
              {claimCount > 0 ? '999+' : claimCount.count}
            </span>
          )}
        </Button>
      </div>
    ))}
  </section>
);

const ClaimFilterWithCount = ({ claimFilter, filterResultsFn, claimsCount, hiddenFilters }) => {
  const claims = claimsCount ? claimsCount : { unmappedCount: 0, mappedCount: 0, recentCount: 0 };
  const filters = [
    {
      id: 'all',
      name: 'All',
      claimCount: { count: claims.unmappedCount + claims.mappedCount, color: '#457b9d' },
    },
    {
      id: 'unmapped',
      name: 'Unmapped Claims',
      claimCount: { count: claims.unmappedCount, color: 'orange' },
    },
    {
      id: 'mapped',
      name: 'Mapped Claims',
      claimCount: { count: claims.mappedCount, color: '#457b9d' },
    },
    { id: 'recent', name: 'Recent Claims', claimCount: { count: claims.recentCount, color: '#457b9d' } },
  ].filter(item => !hiddenFilters.includes(item.id));

  return <Filters withCount {...{ filters, claimFilter, filterResultsFn }} />;
};

const ClaimFilters = ({ claimFilter, filterResultsFn }) => {
  const filters = [
    { id: 'all', name: 'All' },
    { id: 'undecided', name: 'Undecided' },
    { id: 'approved', name: 'Approved' },
    { id: 'rejected', name: 'Rejected' },
  ];
  return <Filters {...{ claimFilter, filterResultsFn, filters }} />;
};

export { Filters, ClaimFilters, ClaimFilterWithCount };
