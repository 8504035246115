import gql from 'graphql-tag';

const GOLDEN_MATCH_AND_CLAIMS = gql`
  query goldenMatchAndClaims($match_id: Int!) {
    statsbomb_match(where: { match_id: { _eq: $match_id } }) {
      match_attendance
      match_away_penalty_score
      match_away_score
      match_home_penalty_score
      match_home_score
      match_local_kick_off
      match_name
      match_play_status
      match_weather
      match_week
      stadium_id
      round_id
      match_id
      match_home_team_id
      match_away_team_id
      match_date
      match_neutral_ground
      match_behind_closed_doors
      deleted
      statsbomb_round {
        statsbomb_round_structure {
          round_structure_name
        }
        statsbomb_round_type {
          round_type_name
        }
        statsbomb_phase {
          statsbomb_phase_type {
            phase_type_name
          }
          statsbomb_competition_season {
            statsbomb_season {
              season_name
            }
            statsbomb_competition {
              competition_name
              statsbomb_area {
                area_code
              }
              competition_is_female
            }
          }
        }
      }
      statsbomb_stadium {
        stadium_name
        statsbomb_area {
          area_code
        }
      }
      away_statsbomb_team {
        team_name
      }
      home_statsbomb_team {
        team_name
      }
      mapping_matches {
        match_id
        provider
        provider_match_id
        claim_matches {
          approved_at
          claim_match_uuid
          created_at
          deleted
          match_attendance
          match_away_penalty_score
          match_away_score
          match_away_team_id
          match_behind_closed_doors
          match_date
          match_home_penalty_score
          match_home_score
          match_home_team_id
          match_local_kick_off
          match_name
          match_neutral_ground
          match_play_status
          match_weather
          match_week
          provider
          provider_match_id
          rejected_at
          round_id
          stadium_id
          source_stadium {
            stadium_name
          }
          away_source_team {
            team_name
          }
          home_source_team {
            team_name
          }
          source_round {
            statsbomb_round_structure {
              round_structure_name
            }
            statsbomb_round_type {
              round_type_name
            }
            source_phase {
              statsbomb_phase_type {
                phase_type_name
              }
              source_competition_season {
                statsbomb_season {
                  season_name
                }
                source_competition {
                  competition_name
                  source_area {
                    area_code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ALL_CLAIM_MATCHES = gql`
  query getAllClaimMatches(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
  ) {
    claim_match(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_match_id: { _ilike: $provider_id }
        match_name: { _ilike: $name }
        match_date: { _gte: $start_date, _lte: $end_date }
      }
      limit: $limit
      offset: $offset
    ) {
      claim_match_uuid
      provider_match_id
      deleted
      match_name
      match_home_team_id
      match_away_team_id
      match_date
      stadium_id
      match_week
      match_play_status
      match_local_kick_off
      round_id
      mapping_match {
        match_id
      }
      mapping_stadium {
        stadium_id
      }
      away_mapping_team {
        team_id
      }
      home_mapping_team {
        team_id
      }
      approved_at
      created_at
      deleted
      provider
      rejected_at
    }
  }
`;

const MAPPED_CLAIM_MATCHES = gql`
  query getMappedClaimMatches(
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
  ) {
    claim_match(
      where: {
        approved_at: { _is_null: true }
        rejected_at: { _is_null: true }
        mapping_match: {}
        provider_match_id: { _ilike: $provider_id }
        match_name: { _ilike: $name }
        match_date: { _gte: $start_date, _lte: $end_date }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_match_uuid
      provider_match_id
      deleted
      match_name
      match_home_team_id
      match_away_team_id
      match_date
      stadium_id
      match_week
      mapping_match {
        match_id
      }
      mapping_stadium {
        stadium_id
      }
      away_mapping_team {
        team_id
      }
      home_mapping_team {
        team_id
      }
      approved_at
      created_at
      deleted
      provider
      rejected_at
    }
  }
`;

const UNMAPPED_CLAIM_MATCHES = gql`
  query getUnmappedClaimMatches(
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
    $name: String
    $start_date: date
    $end_date: date
  ) {
    claim_match(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_match: {} }
        provider_match_id: { _ilike: $provider_id }
        match_name: { _ilike: $name }
        match_date: { _gte: $start_date, _lte: $end_date }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_match_uuid
      provider_match_id
      deleted
      match_name
      match_home_team_id
      match_away_team_id
      match_date
      stadium_id
      match_week
      mapping_match {
        match_id
      }
      mapping_stadium {
        stadium_id
      }
      away_mapping_team {
        team_id
      }
      home_mapping_team {
        team_id
      }
      approved_at
      created_at
      deleted
      provider
      rejected_at
    }
  }
`;

const SAVE_MATCH_CLAIM = gql`
  mutation SaveMatch($claim_match_uuid: uuid!, $claims: [claim_match_insert_input!]!) {
    delete_claim_match(where: { claim_match_uuid: { _eq: $claim_match_uuid } }) {
      affected_rows
    }
    insert_claim_match(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_MATCH_CLAIM = gql`
  mutation infoTeamOverride($match_id: Int!, $provider_match_id: String!, $source_entity: source_match_set_input!) {
    insert_mapping_match(
      objects: {
        source_match: {
          data: { provider: Info_Team, provider_match_id: $provider_match_id, last_updated: "now()" }
          on_conflict: { constraint: match_pkey, update_columns: last_updated }
        }
        match_id: $match_id
      }
      on_conflict: { constraint: match_pkey, update_columns: match_id }
    ) {
      returning {
        match_id
      }
    }
    update_source_match(
      where: { provider: { _eq: Info_Team }, provider_match_id: { _eq: $provider_match_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_MATCH = gql`
  mutation insertGoldenMatch($statsbomb_entity: statsbomb_match_insert_input!) {
    insert_statsbomb_match(objects: [$statsbomb_entity]) {
      returning {
        match_id
      }
    }
  }
`;

const CLAIMS_FOR_MATCH_BY_PROVIDER = gql`
  subscription claimsForMatchByProvider($provider_match_id: String!, $provider: source_provider_enum!) {
    claim_match(where: { provider_match_id: { _eq: $provider_match_id }, provider: { _eq: $provider } }) {
      claim_match_uuid
      provider_match_id
      match_name
      match_home_team_id
      match_away_team_id
      match_date
      match_local_kick_off
      match_play_status
      stadium_id
      match_week
      match_attendance
      match_weather
      match_neutral_ground
      match_behind_closed_doors
      round_id
      away_source_team {
        team_name
      }
      home_source_team {
        team_name
      }
      source_stadium {
        stadium_name
      }
      source_round {
        provider_round_id
      }
      home_mapping_team {
        statsbomb_team {
          team_id
        }
      }
      away_mapping_team {
        statsbomb_team {
          team_id
        }
      }
      source_stadium {
        stadium_name
      }
      mapping_stadium {
        statsbomb_stadium {
          stadium_id
        }
      }
      mapping_round {
        statsbomb_round {
          round_id
        }
      }
      source_round {
        provider_round_id
        statsbomb_round_type {
          round_type_name
        }
        statsbomb_round_structure {
          round_structure_name
        }
        source_phase {
          statsbomb_phase_type {
            phase_type_name
          }
          source_competition_season {
            statsbomb_season {
              season_name
            }
            source_competition {
              competition_name
            }
          }
        }
      }
      deleted
      created_at
      provider
      rejected_at
      approved_at
    }
  }
`;

const CLAIM_MATCH = gql`
  query checkMatchIsApproved($provider_match_id: String!) {
    claim_match(
      where: { _and: { provider_match_id: { _eq: $provider_match_id }, provider: { _eq: Soccerway } } }
      order_by: { approved_at: asc }
    ) {
      round_id
      provider_match_id
      approved_at
    }
  }
`;

const CLAIMS_MATCHES_COUNT = gql`
  query claimsMatchesCount {
    claim_match_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  ALL_CLAIM_MATCHES,
  MAPPED_CLAIM_MATCHES,
  UNMAPPED_CLAIM_MATCHES,
  OVERRIDE_MATCH_CLAIM,
  SAVE_MATCH_CLAIM,
  CLAIMS_FOR_MATCH_BY_PROVIDER,
  CLAIMS_MATCHES_COUNT,
  CLAIM_MATCH,
  GOLDEN_MATCH_AND_CLAIMS,
  INSERT_GOLDEN_MATCH,
};
