import gql from 'graphql-tag';

export const ROUND_STRUCTURE = gql`
  query roundStructure {
    statsbomb_round_structure(order_by: { round_structure_name: asc }) {
      round_structure_name
      round_structure_id
    }
  }
`;
