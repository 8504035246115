import gql from 'graphql-tag';

const GOLDEN_TRANSFER_MANAGERS = gql`
  query getGoldenTransferManagers(
    $name: String
    $duplicate_count: bigint = 0
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_transfer_manager_order_by!
  ) {
    statsbomb_golden_transfer_manager(
      where: {
        _and: [
          { search_name: { _ilike: $name }, duplicate_count: { _gte: $duplicate_count }, deleted: { _eq: $deleted } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      manager_id
      manager_name
      search_name
      team_from_id
      team_from_name
      team_to_id
      team_to_name
      transfer_currency
      transfer_date
      transfer_manager_id
      transfer_type
      transfer_type_display_name
      transfer_value
      deleted
    }
  }
`;

const GOLDEN_TRANSFER_MANAGERS_COUNT = gql`
  query getGoldenTransferManagerCount($name: String, $duplicate_count: bigint = 0, $deleted: Boolean = false) {
    statsbomb_golden_transfer_manager_aggregate(
      where: {
        _and: [
          { search_name: { _ilike: $name }, duplicate_count: { _gte: $duplicate_count }, deleted: { _eq: $deleted } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_SOURCE_TRANSFER_MANAGER = gql`
  mutation insertSourceTransferManager(
    $provider_transfer_manager_id: String
    $manager_id: String
    $team_to_id: String
    $team_from_id: String
  ) {
    insert_source_transfer_manager(
      objects: {
        manager_id: $manager_id
        team_to_id: $team_to_id
        team_from_id: $team_from_id
        last_updated: "now()"
        provider: Info_Team
        provider_transfer_manager_id: $provider_transfer_manager_id
      }
      on_conflict: { constraint: transfer_manager_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const DELETE_TRANSFER_MANAGER = gql`
  mutation deleteTransferManager($transfer_manager_id: Int!) {
    update_statsbomb_transfer_manager_by_pk(
      pk_columns: { transfer_manager_id: $transfer_manager_id }
      _set: { deleted: true }
    ) {
      deleted
    }
  }
`;

const SIMILAR_TRANSFER_MANAGERS = gql`
  query getAllTransfersByManager($manager_id: Int) {
    statsbomb_transfer_manager(where: { manager_id: { _eq: $manager_id } }) {
      transfer_manager_id
      manager_id
      team_from_id
      team_to_id
      transfer_type
      transfer_date
      transfer_value
      transfer_currency
      statsbomb_manager {
        manager_name
      }
      team_from_statsbomb_team {
        team_name
        statsbomb_area {
          area_code
        }
      }
      team_to_statsbomb_team {
        team_name
        statsbomb_area {
          area_code
        }
      }
      statsbomb_transfer_type {
        display_name
      }
      mapping_transfer_managers {
        provider
        provider_transfer_manager_id
        transfer_manager_id
      }
    }
  }
`;

const GET_ALL_TRANSFERS_BY_MANAGER = gql`
  subscription getAllTransfersByManager($manager_id: Int) {
    statsbomb_manager(where: { manager_id: { _eq: $manager_id } }) {
      manager_id
      manager_name
      statsbomb_transfer_managers(order_by: { transfer_date: desc }) {
        transfer_manager_id
        manager_id
        team_from_id
        team_to_id
        transfer_type
        transfer_date
        transfer_value
        transfer_currency
        statsbomb_manager {
          manager_name
        }
        team_from_statsbomb_team {
          team_name
          statsbomb_area {
            area_code
          }
        }
        team_to_statsbomb_team {
          team_name
          statsbomb_area {
            area_code
          }
        }
        statsbomb_transfer_type {
          display_name
        }
        mapping_transfer_managers {
          provider
          provider_transfer_manager_id
          transfer_manager_id
        }
      }
    }
  }
`;

const GET_MANAGER_CURRENT_TEAM = gql`
  query getManagerCurrentTeam($manager_id: Int!) {
    statsbomb_current_transfer_manager(where: { manager_id: { _eq: $manager_id } }) {
      manager_id
      team_to_id
      transfer_date
      statsbomb_manager {
        manager_id
        manager_name
        manager_date_of_birth
        nationality_statsbomb_area {
          area_code
          area_name
        }
      }
      team_to_statsbomb_team {
        team_name
      }
    }
  }
`;

const DELETE_TRANSFER_MANAGER_BY_ID = gql`
  mutation deleteTransferManager($transfer_manager_id: Int!) {
    delete_mapping_transfer_manager(where: { transfer_manager_id: { _eq: $transfer_manager_id } }) {
      affected_rows
    }
    delete_statsbomb_transfer_manager(where: { transfer_manager_id: { _eq: $transfer_manager_id } }) {
      affected_rows
    }
  }
`;
const GET_TRANSFER_MANAGERS_BY_ID = gql`
  query getTransferManagersByIds($entityIds: [Int!]) {
    statsbomb_golden_transfer_manager(where: { transfer_manager_id: { _in: $entityIds } }) {
      manager_id
      manager_name
      search_name
      team_from_id
      team_from_name
      team_to_id
      team_to_name
      transfer_currency
      transfer_date
      transfer_manager_id
      transfer_type
      transfer_type_display_name
      transfer_value
    }
  }
`;
export {
  GOLDEN_TRANSFER_MANAGERS,
  GOLDEN_TRANSFER_MANAGERS_COUNT,
  INSERT_SOURCE_TRANSFER_MANAGER,
  DELETE_TRANSFER_MANAGER,
  SIMILAR_TRANSFER_MANAGERS,
  GET_ALL_TRANSFERS_BY_MANAGER,
  GET_MANAGER_CURRENT_TEAM,
  DELETE_TRANSFER_MANAGER_BY_ID,
  GET_TRANSFER_MANAGERS_BY_ID,
};
