import React from 'react';
import { nestedValues } from '../util/useful';
import { TextOverride } from '../components/override/text';
import { NumberOverride } from '../components/override/number';
import { AreaOverride, AreaSelector } from '../components/override/area';
import { ListFilter, TextFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { AREAS } from '../hasura/queries/area/areas';
import { FlagIcon } from '../components/util/icon';

const FULL_STADIUM_SCHEMA = [
  {
    columnKey: 'claim_stadium_uuid',
    name: 'Claim UUID',
    type: 'String',
  },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_stadium_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'stadium_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  {
    columnKey: 'stadium_name',
    name: 'Stadium Name',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'area_id',
    name: 'Area',
    type: 'Integer',
    mapping_function: nestedValues('mapping_area.statsbomb_area.area_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('statsbomb_area.area_code')(e)} />,
    MappingControl: AreaSelector,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'area_name',
    name: 'Country',
    type: 'String',
    mapped_icon: e => <FlagIcon code={e.area_code} />,
  },
  {
    columnKey: 'stadium_city',
    name: 'City',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'stadium_capacity',
    name: 'Capacity',
    type: 'Integer',
    OverrideControl: NumberOverride,
    initialOverrideValue: 0,
  },
  {
    columnKey: 'stadium_pitch_x',
    name: 'Pitch X',
    type: 'Integer',
    OverrideControl: NumberOverride,
    initialOverrideValue: 0,
  },
  {
    columnKey: 'stadium_pitch_y',
    name: 'Pitch Y',
    type: 'Integer',
    OverrideControl: NumberOverride,
    initialOverrideValue: 0,
  },
  {
    columnKey: 'deleted',
    name: 'Deleted?',
    type: 'Boolean',
  },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },

  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const GOLDEN_STADIUM_SCHEMA = FULL_STADIUM_SCHEMA.filter(field =>
  ['stadium_name', 'stadium_city', 'area_name', 'stadium_capacity', 'min_spec'].includes(field.columnKey)
);

const STADIUM_SCHEMA = FULL_STADIUM_SCHEMA.filter(field =>
  [
    'stadium_name',
    'area_id',
    'stadium_city',
    'stadium_capacity',
    'stadium_pitch_x',
    'stadium_pitch_y',
    'deleted',
  ].includes(field.columnKey)
);

const STADIUM_CLAIM_SCHEMA = [
  'stadium_name',
  'area_id',
  'stadium_city',
  'stadium_capacity',
  'stadium_pitch_x',
  'stadium_pitch_y',
];

const STADIUM_ALL_CLAIMS_SCHEMA = FULL_STADIUM_SCHEMA.filter(a =>
  [
    'stadium_name',
    'area_id',
    'stadium_city',
    'stadium_capacity',
    'stadium_pitch_x',
    'stadium_pitch_y',
    'claim_stadium_uuid',
    'provider_stadium_id',
    'provider',
    'created_at',
  ].includes(a.columnKey)
);

const STADIUM_CLAIM_TO_MAP_SCHEMA = FULL_STADIUM_SCHEMA.filter(field =>
  [
    'stadium_name',
    'area_id',
    'stadium_city',
    'stadium_capacity',
    'stadium_pitch_x',
    'stadium_pitch_y',
    'deleted',
    'claim_stadium_uuid',
    'created_at',
    'provider',
    'approved_at',
    'rejected_at',
  ].includes(field.columnKey)
);

const MAPPED_STADIUM_SCHEMA = FULL_STADIUM_SCHEMA.filter(a =>
  [
    'provider',
    'provider_stadium_id',
    'stadium_name',
    'area_id',
    'stadium_city',
    'stadium_capacity',
    'stadium_pitch_x',
    'stadium_pitch_y',
  ].includes(a.columnKey)
);

const STADIUM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
  },
  {
    type: 'area',
    Component: ListFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Country',
      flagIconPath: 'area_code',
      searchQuery: AREAS,
      idPath: 'area_name',
      variablePaths: ['area_name'],
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
  {
    type: 'duplicate',
    Component: BooleanFilter,
    attributes: { key: 'duplicateCount', queryVariableName: 'duplicate_count', displayName: 'Show Duplicates' },
  },
];

const STADIUM_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Area',
    },
    inputProps: {
      type: 'text',
    },
  },
];
const STADIUM_MIN_IQ_SCHEMA = FULL_STADIUM_SCHEMA.filter(a =>
  ['stadium_id', 'stadium_name', 'area_id'].includes(a.columnKey)
);

export {
  FULL_STADIUM_SCHEMA,
  GOLDEN_STADIUM_SCHEMA,
  STADIUM_SCHEMA,
  STADIUM_CLAIM_SCHEMA,
  STADIUM_ALL_CLAIMS_SCHEMA,
  STADIUM_CLAIM_TO_MAP_SCHEMA,
  STADIUM_FILTER_SCHEMA,
  STADIUM_MIN_IQ_SCHEMA,
  MAPPED_STADIUM_SCHEMA,
  STADIUM_CLAIM_FILTER_SCHEMA,
};
