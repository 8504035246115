import React from 'react';
import { Chip, Button, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const useStyle = makeStyles(theme => ({
  componentOnModal: {
    minHeight: 80,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 10,
  },
  componentOnMainPage: {
    minWidth: '40%',
    maxWidth: '50%',
    display: 'flex',
    alignItems: 'center',
    margin: '10px 10px 10px auto',
  },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    marginLeft: 'auto',
    height: 30,
    padding: 5,
    marginRight: 10,
  },
  filterChip: {
    backgroundColor: 'white',
    margin: 10,
  },
  showAllFiltersChip: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      color: 'white',
    },
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 10,
  },
}));

const FiltersList = ({ schema, filter, removeFilter }) => {
  const classes = useStyle();

  const getDisplayValue = (filter, { displayName, searchQuery, key }) =>
    `${displayName}: ${(searchQuery ? filter[key].displayValue : filter[key]).toString().replace(/%/g, '')}`;

  const showChip = (attributes, filter) => attributes && filter[attributes.key];

  return (
    <>
      {schema.map(({ attributes }, index) => {
        return (
          showChip(attributes, filter) && (
            <Chip
              className={classes.filterChip}
              key={`Selected Filters Chip: ${index}`}
              label={getDisplayValue(filter, attributes)}
              onDelete={() => removeFilter(attributes.key)}
            />
          )
        );
      })}
    </>
  );
};

const SelectedFilters = ({
  label,
  filter,
  schema,
  showButton,
  clearFilters,
  removeFilter,
  setFiltersModalOpen,
  onModal,
  maxFilters,
}) => {
  const classes = useStyle();

  const countDefinedFilters = () =>
    Object.keys(filter).reduce(
      (filterCount, curr) => (filter[curr] && curr !== 'page' ? (filterCount += 1) : filterCount),
      0
    );

  const filtersCount = countDefinedFilters();

  return (
    <div className={onModal ? classes.componentOnModal : classes.componentOnMainPage}>
      <div
        style={{
          minHeight: 40,
          width: '100%',
          backgroundColor: '#eee',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        className="MuiPaper-root Component-root-8 MuiPaper-elevation1 MuiPaper-rounded">
        <span style={{ position: 'relative', marginLeft: 10 }}>
          <strong>{label}:</strong>
        </span>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {onModal || filtersCount <= maxFilters ? (
            <FiltersList {...{ schema, filter, removeFilter }} />
          ) : (
            <Chip
              className={classes.showAllFiltersChip}
              variant="outline"
              label={`You have ${filtersCount} filters selected. View Filters`}
              clickable
              onClick={() => setFiltersModalOpen(true)}
            />
          )}
        </div>
        {showButton && (
          <Button
            onClick={clearFilters}
            className={classes.clearButton}
            style={{ textTransform: 'none' }}
            variant="contained"
            size="small">
            <span>Clear</span>
            <Delete style={{ width: 18 }} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SelectedFilters;
