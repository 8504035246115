import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const SnackAlert = ({ message, severity, setMessage }) => {
  return (
    <Snackbar open={!!message} autoHideDuration={4000} onClose={() => setMessage(null)}>
      <Alert variant="filled" severity={severity} onClose={() => setMessage(null)}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const RepeatableError = ({ message, setMessage }) => {
  return <SnackAlert message={message} severity="error" setMessage={setMessage} />;
};

const RepeatableSuccess = ({ message, setMessage }) => {
  return <SnackAlert message={message} severity="success" setMessage={setMessage} />;
};

const Error = ({ error, message }) => {
  const [open, showError] = useState();
  if (error && open === undefined) showError(true);
  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={() => showError(false)}>
      <Alert variant="filled" severity="error" onClose={() => showError(false)}>
        {message} : {error?.message}
      </Alert>
    </Snackbar>
  );
};

export { Error, RepeatableError, RepeatableSuccess };
