import axios from 'axios';

const {
  REACT_APP_TRAWLER_HOST,
  REACT_APP_TRAWLER_PORT,
  REACT_APP_TRAWLER_PATH,
  REACT_APP_TRAWLER_USERNAME,
  REACT_APP_TRAWLER_PASSWORD,
} = process.env;
const protocol = process.env.REACT_APP_TRAWLER_HOST === 'localhost' ? 'http' : 'https';
const trawlerEndpoint = `${protocol}://${REACT_APP_TRAWLER_HOST}:${REACT_APP_TRAWLER_PORT}${REACT_APP_TRAWLER_PATH}`;

const scrapePlayerFromSource = id => {
  return postQuery(
    `query{
        scrape_player(
          provider: {
            name: "Soccerway",
            id: "2"
          },
          params: { player_id: "` +
      id +
      `" }
        )
      }`
  );
};

const scrapeManagerFromSource = id => {
  return postQuery(
    `query{
        scrape_manager(
          provider: {
            name: "Soccerway",
            id: "2"
          },
          params: { manager_id: "` +
      id +
      `" }
        )
      }`
  );
};

const scrapeOfficialFromSource = id => {
  return postQuery(
    `query{
        scrape_official(
          provider: {
            name: "Soccerway",
            id: "2"
          },
          params: { official_id: "` +
      id +
      `" }
        )
      }`
  );
};

const postQuery = async query => {
  return axios.post(
    trawlerEndpoint,
    { query },
    {
      auth: {
        username: REACT_APP_TRAWLER_USERNAME,
        password: REACT_APP_TRAWLER_PASSWORD,
      },
    }
  );
};

export { scrapePlayerFromSource, scrapeManagerFromSource, scrapeOfficialFromSource };
