import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SingleClaimTable,
  CLAIM_MODE_VIEW,
  CLAIM_MODE_EDIT,
  CLAIM_MODE_NEW_CLAIM,
  CLAIM_MODE_NEW_ENTITY,
} from '../components/claim/singleClaimTable';
import { ROUND_SCHEMA, ROUND_CLAIM_SCHEMA, MAPPED_ROUND_SCHEMA } from '../schema/roundSchema';
import { ClaimFilters } from '../components/claim/claimFilters';
import {
  GOLDEN_ROUND_AND_CLAIMS,
  SAVE_ROUND_CLAIM,
  OVERRIDE_ROUND_CLAIM,
  INSERT_GOLDEN_ROUND,
} from '../hasura/queries/round/roundClaims';
import { VIEW_ROUND_MAPPINGS, DELETE_ROUND_MAPPING } from '../hasura/queries/round/roundMapping';

import { Progress } from '../components/util/progress';

import { Error } from '../components/util/notifications';
import ClaimHistoryTable from '../components/table/claimHistoryTable';
import MappingView from '../components/mapping/mappingView';
import { DELETE_ROUND } from '../hasura/queries/round/rounds';

const useStyle = makeStyles({
  root: {
    marginTop: 20,

    '& h3': {
      paddingLeft: 5,
    },
  },
});
const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const GoldenRoundView = ({ roundId, isNewRound }) => {
  const ignoreEverything = {};
  ROUND_SCHEMA.forEach(row => {
    ignoreEverything[row.columnKey] = 'Ignored';
  });

  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState(isNewRound ? ignoreEverything : {});
  const [overrides, setOverrides] = useState({});
  //new Round mode has a 0 id
  const [claimMode, setClaimMode] = useState(isNewRound ? CLAIM_MODE_NEW_ENTITY : CLAIM_MODE_VIEW);

  const tableContatinerClasses = useStyle();
  const buttonClasses = useStyleButton();
  const { loading, error, data, refetch } = useQuery(GOLDEN_ROUND_AND_CLAIMS, {
    variables: {
      round_id: roundId,
    },
  });

  if (loading) return <Progress />;

  let allClaims = [];
  data &&
    data.statsbomb_round &&
    data.statsbomb_round[0] &&
    data.statsbomb_round[0].mapping_rounds.forEach(mappingEntry => {
      allClaims = [...allClaims, ...mappingEntry.claim_rounds];
    });

  let filteredClaims = allClaims;
  if (claimFilter === 'unmapped') {
    filteredClaims = allClaims.filter(c => {
      return c.approved_at === null && c.rejected_at === null;
    });
  } else if (claimFilter === 'approved') {
    filteredClaims = allClaims.filter(c => c.approved_at != null);
  } else if (claimFilter === 'rejected') {
    filteredClaims = allClaims.filter(c => c.rejected_at != null);
  }

  const selectAClaim = claimUUID => {
    setSelectedClaimId(claimUUID);
    const currentDecisions = {};

    const claim = allClaims.filter(c => {
      return c.claim_round_uuid === claimUUID;
    })[0];
    if (claim) {
      ROUND_SCHEMA.forEach(row => {
        if (claim && claim[row.columnKey] != null) {
          currentDecisions[row.columnKey] =
            row.mapping_function && row.mapping_function(claim) == null ? 'Ignored' : 'Approved';
        }
      });
    }
    setClaimStatuses(currentDecisions);

    const initialOverrides = {};
    ROUND_SCHEMA.forEach(row => {
      initialOverrides[row.columnKey] = null;
    });
    setOverrides(initialOverrides);

    setClaimMode(claimUUID ? CLAIM_MODE_EDIT : CLAIM_MODE_VIEW);
  };

  const changeClaimFilter = claimFilter => {
    selectAClaim(null);
    setClaimFilter(claimFilter);
  };

  const newClaim = () => {
    selectAClaim(null);
    setClaimMode(CLAIM_MODE_NEW_CLAIM);
    setClaimStatuses(ignoreEverything);
  };

  const getGoldenRoundId = insertedRound => insertedRound?.data?.insert_statsbomb_round?.returning?.[0]?.round_id;
  const claimSelected = claim => {
    selectAClaim(claim.claim_round_uuid);
  };

  const formatBodyIndentifierValue = () => {
    const {
      statsbomb_round_type,
      statsbomb_round_structure,
      statsbomb_phase: { statsbomb_phase_type, statsbomb_competition_season },
    } = data.statsbomb_round[0];

    const roundTypeName = statsbomb_round_type?.round_type_name;
    const roundStructureName = statsbomb_round_structure?.round_structure_name
      ? `(${statsbomb_round_structure?.round_structure_name})`
      : '';
    const phaseTypeName = statsbomb_phase_type?.phase_type_name;
    const competitionName = statsbomb_competition_season?.statsbomb_competition?.competition_name;
    const seasonName = statsbomb_competition_season?.statsbomb_season?.season_name;

    return `${roundTypeName} ${roundStructureName} - ${competitionName} ${seasonName} ${phaseTypeName}`;
  };

  return (
    <>
      <Error message="" error={error} />
      <div className="container-row">
        <h2>Round Singular: {isNewRound ? ' New Round' : roundId}</h2>

        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <TableContainer component={Paper}>
            <ClaimFilters claimFilter={claimFilter} filterResultsFn={changeClaimFilter} />
            <ClaimHistoryTable
              entityType="round"
              schema={ROUND_CLAIM_SCHEMA}
              entities={filteredClaims}
              identifier={{
                headerValue: 'Round',
                bodyValue: data.statsbomb_round[0] ? formatBodyIndentifierValue() : '',
              }}
              rowClickHandler={claimSelected}
              selectedEntityKey={selectedClaimId}
            />
            <Button className={buttonClasses.button} variant="contained" color="primary" onClick={() => newClaim()}>
              New Claim
            </Button>
          </TableContainer>
        )}
        {claimMode !== CLAIM_MODE_NEW_ENTITY && (
          <MappingView
            type="round"
            recordId={roundId}
            schema={MAPPED_ROUND_SCHEMA}
            subscription={VIEW_ROUND_MAPPINGS}
            deleteMutation={DELETE_ROUND_MAPPING}
            deleteEntityMutation={DELETE_ROUND}
            entityKey="provider_round_id"
            styles={{ tableContatinerClasses, buttonClasses }}
          />
        )}
        <TableContainer classes={tableContatinerClasses} component={Paper}>
          <h3>Round Attributes {selectedClaimId && ' And Claim Values'}</h3>

          <SingleClaimTable
            goldenEntity={data.statsbomb_round[0]}
            claim={
              allClaims.filter(c => {
                return c.claim_round_uuid === selectedClaimId;
              })[0]
            }
            rowSchema={ROUND_SCHEMA}
            saveMutation={SAVE_ROUND_CLAIM}
            overrideMutation={OVERRIDE_ROUND_CLAIM}
            createMutation={INSERT_GOLDEN_ROUND}
            sourcePK="provider_round_id"
            claimPK="claim_round_uuid"
            goldenEntityPK="round_id"
            getGoldenEntityId={getGoldenRoundId}
            entityType="Round"
            setClaimModeView={() => setClaimMode(CLAIM_MODE_VIEW)}
            {...{
              claimStatuses,
              setClaimStatuses,
              selectAClaim,
              overrides,
              setOverrides,
              claimMode,
              refetch,
            }}
          />
        </TableContainer>
      </div>
    </>
  );
};

function Round(props) {
  const query = new URLSearchParams(useLocation().search);
  const isNewRound = query.get('new');
  const roundId = isNewRound ? '0' : query.get('round_id');

  return <GoldenRoundView roundId={roundId} isNewRound={!!isNewRound} />;
}

export default Round;
