import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_PLAYER_BY_PROVIDER } from '../../hasura/queries/player/playerClaims';
import { PlayerMapping } from '../../components/player/playerMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

function PlayerMapper(props) {
  const query = new URLSearchParams(useLocation().search); //get provider playerid from the query string ~ better than using props?
  const provider_player_id = query.get('provider_player_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_PLAYER_BY_PROVIDER, {
    variables: {
      provider_player_id: provider_player_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Player" error={error} />
      <PlayerMapping claims={data?.claim_player || []} provider_player_id={provider_player_id} />
    </>
  );
}

export default PlayerMapper;
