import gql from 'graphql-tag';

const MAP_TO_PLAYER = gql`
  mutation MyMutation($player_id: Int!, $provider: source_provider_enum!, $provider_player_id: String!) {
    insert_mapping_player(
      objects: { player_id: $player_id, provider: $provider, provider_player_id: $provider_player_id }
    ) {
      returning {
        player_id
        provider
        provider_player_id
      }
    }
  }
`;

const MAKE_AND_MAP_PLAYER = gql`
  mutation MyMutation($player_name: String!, $provider: source_provider_enum!, $provider_player_id: String!) {
    insert_mapping_player(
      objects: {
        statsbomb_player: { data: { player_name: $player_name } }
        provider: $provider
        provider_player_id: $provider_player_id
      }
    ) {
      returning {
        player_id
        provider
        provider_player_id
      }
    }
  }
`;

// id = player_id
const VIEW_PLAYER_MAPPINGS = gql`
  subscription viewPlayerMappings($id: Int!) {
    mapping_player(where: { player_id: { _eq: $id } }) {
      source_player {
        player_name
        player_firstname
        player_lastname
        player_nickname
        player_date_of_birth
        player_country_of_birth
        player_nationality
        player_height
        player_weight
        player_is_female
        player_photo_url
        player_preferred_foot
        provider_player_id
        player_is_retired
        provider
      }
    }
  }
`;

const MAP_PLAYER = gql`
  mutation mapPlayer($player_id: Int!, $provider: source_provider_enum!, $provider_player_id: String!) {
    insert_mapping_player_one(
      object: { player_id: $player_id, provider: $provider, provider_player_id: $provider_player_id }
    ) {
      player_id
      provider
      provider_player_id
    }
  }
`;

const DELETE_PLAYER_MAPPING = gql`
  mutation deletePlayerMappings(
    $claim_data: claim_player_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_player_one(object: $claim_data, on_conflict: { constraint: player_pkey, update_columns: [] }) {
      provider
      provider_player_id
    }

    delete_mapping_player_by_pk(provider: $provider, provider_player_id: $provider_id) {
      player_id
      provider_player_id
      provider
    }
  }
`;

export { MAP_TO_PLAYER, MAKE_AND_MAP_PLAYER, VIEW_PLAYER_MAPPINGS, MAP_PLAYER, DELETE_PLAYER_MAPPING };
