import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

const ColourPicker = ({ overrideChange, columnKey, currentValue, name }) => {
  const [currentSelection, setCurrentSelection] = useState({
    displayColorPicker: false,
    color: {
      r: '0',
      g: '0',
      b: '0',
      a: '1',
    },
  });

  const handleClick = () => {
    setCurrentSelection({ displayColorPicker: !currentSelection.displayColorPicker });
  };

  const handleClose = () => {
    setCurrentSelection({ displayColorPicker: false });
  };

  const handleChange = color => {
    setCurrentSelection({ displayColorPicker: true, color: color.rgb });
    overrideChange(columnKey, color.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${currentSelection.color?.r}, ${currentSelection.color?.g}, ${currentSelection.color?.b}, ${currentSelection.color?.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {currentSelection.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={currentSelection.color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export { ColourPicker };
