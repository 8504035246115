import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { PHASE_TYPE } from '../../hasura/queries/phase/phaseType';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const PhaseType = ({ overrideChange, currentValue, columnKey, ...props }) => {
  const { loading, error, data } = useQuery(PHASE_TYPE, {});

  if (loading) return <InlineProgress />;

  const items = data?.statsbomb_phase_type || [];

  return (
    <>
      <Error message="Error fetching Phase Types" error={error} />

      <Select
        error={!currentValue}
        onChange={e => overrideChange(columnKey, e.target.value)}
        value={currentValue || 0}
        {...props}>
        <MenuItem disabled value="0">
          Phase Type
        </MenuItem>
        {items.map((item, key) => (
          <MenuItem key={key} value={item.phase_type_id}>
            {item.phase_type_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { PhaseType };
