import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { CLAIMS_FOR_STADIUM_BY_PROVIDER } from '../../hasura/queries/stadium/stadiumClaims';
import { StadiumMapping } from '../../components/stadium/stadiumMapping';
import { Error } from '../../components/util/notifications';
import { Progress } from '../../components/util/progress';

function StadiumMapper(props) {
  const query = new URLSearchParams(useLocation().search);
  const provider_stadium_id = query.get('provider_stadium_id');
  const provider = query.get('provider');

  const { loading, error, data } = useSubscription(CLAIMS_FOR_STADIUM_BY_PROVIDER, {
    variables: {
      provider_stadium_id: provider_stadium_id,
      provider,
    },
  });

  if (loading) return <Progress />;

  return (
    <>
      <Error message="Error fetching Claims for Stadium" error={error} />
      <StadiumMapping claims={data?.claim_stadium || []} provider_stadium_id={provider_stadium_id} />
    </>
  );
}

export default StadiumMapper;
