import gql from 'graphql-tag';

const MAP_TO_OFFICIAL = gql`
  mutation MapToOfficial($official_id: Int!, $provider: source_provider_enum!, $provider_official_id: String!) {
    insert_mapping_official(
      objects: { official_id: $official_id, provider: $provider, provider_official_id: $provider_official_id }
    ) {
      returning {
        official_id
        provider
        provider_official_id
      }
    }
  }
`;

const MAKE_AND_MAP_OFFICIAL = gql`
  mutation MakeAndMapOfficial(
    $official_name: String!
    $provider: source_provider_enum!
    $provider_official_id: String!
  ) {
    insert_mapping_official(
      objects: {
        statsbomb_official: { data: { official_name: $official_name } }
        provider: $provider
        provider_official_id: $provider_official_id
      }
    ) {
      returning {
        official_id
        provider
        provider_official_id
      }
    }
  }
`;

const VIEW_OFFICIAL_MAPPINGS = gql`
  subscription viewOfficialMappings($id: Int!) {
    mapping_official(where: { official_id: { _eq: $id } }) {
      source_official {
        official_name
        official_nickname
        official_date_of_birth
        official_nationality
        official_is_female
        official_photo_url
        provider
        provider_official_id
      }
    }
  }
`;

const DELETE_OFFICIAL_MAPPING = gql`
  mutation deleteOfficialMappings(
    $claim_data: claim_official_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_official_one(object: $claim_data, on_conflict: { constraint: official_pkey, update_columns: [] }) {
      provider
      provider_official_id
    }

    delete_mapping_official_by_pk(provider: $provider, provider_official_id: $provider_id) {
      official_id
      provider_official_id
      provider
    }
  }
`;

export { MAP_TO_OFFICIAL, MAKE_AND_MAP_OFFICIAL, VIEW_OFFICIAL_MAPPINGS, DELETE_OFFICIAL_MAPPING };
