import React from 'react';
import AllFiltersModal from './allFiltersModal';
import SelectedFilters from './selectedFilters';

const GoldenEntityFilters = ({
  schema,
  filter,
  setFilter,
  tempFilter,
  setTempFilter,
  emptyFilter,
  clearFilters,
  updateFilter,
  removeFilter,
  filtersModalOpen,
  setFiltersModalOpen,
}) => {
  const maxFilters = 5;

  const hasFilter = () => {
    for (const item in filter) {
      if (item !== 'page') {
        if (filter[item] != null && filter[item] !== '' && filter[item] !== false) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      {schema.map(
        ({ label, type, Component, attributes, inputProps }, index) =>
          index < maxFilters && (
            <Component
              key={index}
              {...{
                label,
                type,
                filter,
                setFilter,
                attributes,
                clearFilters,
                updateFilter,
                ...inputProps,
              }}
            />
          )
      )}
      {schema.length > maxFilters && (
        <AllFiltersModal
          {...{
            schema,
            filter,
            setFilter,
            tempFilter,
            setTempFilter,
            emptyFilter,
            clearFilters,
            removeFilter,
            filtersModalOpen,
            setFiltersModalOpen,
          }}
        />
      )}
      {hasFilter() === true && (
        <SelectedFilters
          label="Filters Selected"
          showButton
          onModal={false}
          {...{
            filter,
            setFilter,
            emptyFilter,
            schema,
            clearFilters,
            removeFilter,
            filtersModalOpen,
            setFiltersModalOpen,
            maxFilters,
          }}
        />
      )}
    </>
  );
};

export { GoldenEntityFilters };
