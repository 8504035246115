import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { AREAS } from '../../hasura/queries/area/areas';
import { Error } from '../util/notifications';
import { FlagIcon } from '../util/icon';
import { InlineProgress } from '../util/progress';

const useStyles = makeStyles({
  option: {
    fontSize: 10,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 12,
    '& label': {
      fontSize: 12,
    },
  },
});

const AreaSelection = ({ areaSelected, title: label }) => {
  const { loading, error, data } = useQuery(AREAS, {});
  const classes = useStyles();

  if (loading) return <InlineProgress />;

  const areas = data?.statsbomb_area || [];

  return (
    <>
      <Error message="Error fetching Areas" error={error} />

      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        classes={{ option: classes.option, root: classes.root }}
        options={areas}
        autoHighlight
        openOnFocus
        disableClearable
        getOptionLabel={area => area.area_name}
        renderOption={area => (
          <>
            <FlagIcon code={area.area_code} />
            {area.area_name}
          </>
        )}
        renderInput={params => <TextField {...params} label={label} inputProps={{ ...params.inputProps }} />}
        onChange={(event, area) => {
          areaSelected(area);
        }}
      />
    </>
  );
};

export { AreaSelection };
