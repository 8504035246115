import { React, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Checkbox, TableContainer, Table, Paper, TableHead, TableRow, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell } from '../table/table';
import { Error } from '../util/notifications';
import { Progress } from '../util/progress';
import { PaginationFilter } from '../table/entityTableFilters';
import { RESULTS_PER_PAGE, SECTION_CONFIG } from '../../util/consts';
import { pluralise } from '../../util/string';

const useStyle = makeStyles({
  root: { padding: 0 },
});

const SimilarEntity = ({ provider, entityName, selectSimilarEntityFn, selectedSim, type }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    offset: 0,
  });
  const { fetchEntityCount, similarEntities, minIqAttributes } = { ...SECTION_CONFIG.find(c => c.type === type) };
  const { loading, error, data } = useQuery(similarEntities, {
    variables: {
      name: entityName || '',
      provider: provider,
      offset: pagination?.offset,
    },
  });
  const { loading: countLoading, error: countError, data: countData } = useQuery(fetchEntityCount);
  const entityCount = countData ? countData[`statsbomb_golden_${type}_aggregate`]?.aggregate?.count : 0;
  const entityData = data ? data[`statsbomb_similar_${pluralise(type)}`] : [];
  const pages = Math.ceil(entityCount / (RESULTS_PER_PAGE * 2));
  const classes = useStyle();
  if (loading || countLoading) return <Progress />;
  const paginationButtonHandler = page => {
    setPagination({
      ...pagination,
      page,
      offset: (page - 1) * RESULTS_PER_PAGE,
    });
  };
  const spRows = entityData.map(similarEntity => (
    <TableRow
      key={similarEntity[`${type}_id`]}
      className={similarEntity[`${type}_id`] === selectedSim ? 'active' : ''}
      onClick={() => selectSimilarEntityFn(similarEntity[`${type}_id`])}>
      {minIqAttributes.map(attribute => {
        return <TableCell key={attribute.columnKey}>{similarEntity[`${attribute.columnKey}`]}</TableCell>;
      })}
      <TableCell key="name_similarity">{similarEntity.name_similarity}%</TableCell>

      <TableCell>
        <Checkbox classes={classes} disabled checked={similarEntity.mapped} />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Error message="Error fetching Similar entities`" error={error || countError} />
      {entityData && (
        <TableContainer component={Paper}>
          <h3>Similar {type}</h3>
          <Table>
            <TableHead>
              <TableRow>
                {minIqAttributes.map(attribute => {
                  return <TableCell key={attribute.columnKey}>{attribute.name}</TableCell>;
                })}
                <TableCell>Name Similarity</TableCell>
                <TableCell>Has existing Mapping?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{spRows}</TableBody>
          </Table>
        </TableContainer>
      )}
      <PaginationFilter {...{ pages, paginationButtonHandler }} curPage={pagination.page} />
    </>
  );
};

export { SimilarEntity };
