import gql from 'graphql-tag';

const TRANSFER_PLAYERS_AND_CLAIMS = gql`
  query transferPlayersAndClaims($transfer_player_id: Int!) {
    statsbomb_transfer_player(where: { transfer_player_id: { _eq: $transfer_player_id } }) {
      player_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_player_id
      transfer_type
      transfer_value
      deleted
      statsbomb_player {
        player_name
      }
      team_from_statsbomb_team {
        team_name
      }
      team_to_statsbomb_team {
        team_name
      }
      mapping_transfer_players {
        provider_transfer_player_id
        provider
        transfer_player_id
        claim_transfer_players {
          claim_transfer_player_uuid
          player_id
          team_from_id
          team_from_mapping_team {
            team_id
            statsbomb_team {
              team_name
            }
          }
          team_to_id
          team_to_mapping_team {
            team_id
            statsbomb_team {
              team_name
            }
          }
          transfer_currency
          transfer_date
          transfer_type
          transfer_value
          provider
          provider_transfer_player_id
          approved_at
          rejected_at
          created_at
          mapping_transfer_player {
            provider_transfer_player_id
            provider
            transfer_player_id
          }
        }
      }
    }
  }
`;

const ALL_CLAIM_TRANSFER_PLAYERS = gql`
  query getAllTransferPlayerClaims(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
  ) {
    claim_transfer_player(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_transfer_player_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_transfer_player_uuid
      provider_transfer_player_id
      provider
      player_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      created_at
      approved_at
      rejected_at
      deleted
      mapping_transfer_player {
        provider_transfer_player_id
        provider
        transfer_player_id
      }
    }
  }
`;

const MAPPED_CLAIM_TRANSFER_PLAYERS = gql`
  query getAllTransferPlayerClaims($offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_transfer_player(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        mapping_transfer_player: {}
        provider_transfer_player_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_transfer_player_uuid
      provider_transfer_player_id
      provider
      player_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      created_at
      approved_at
      rejected_at
      deleted
      mapping_transfer_player {
        provider_transfer_player_id
        provider
        transfer_player_id
      }
    }
  }
`;

const UNMAPPED_CLAIM_TRANSFER_PLAYERS = gql`
  query getAllTransferPlayerClaims($offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_transfer_player(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_transfer_player: {} }
        provider_transfer_player_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_transfer_player_uuid
      provider_transfer_player_id
      provider
      player_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      created_at
      approved_at
      rejected_at
      deleted
      mapping_transfer_player {
        provider_transfer_player_id
        provider
        transfer_player_id
      }
    }
  }
`;

const SAVE_TRANSFER_PLAYER_CLAIM = gql`
  mutation saveTransferPlayersClaim(
    $claim_transfer_player_uuid: uuid!
    $claims: [claim_transfer_player_insert_input!]!
  ) {
    delete_claim_transfer_player(where: { claim_transfer_player_uuid: { _eq: $claim_transfer_player_uuid } }) {
      affected_rows
    }
    insert_claim_transfer_player(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_TRANSFER_PLAYER_CLAIM = gql`
  mutation infoTeamOverrideTransferPlayer(
    $transfer_player_id: Int!
    $provider_transfer_player_id: String!
    $source_entity: source_transfer_player_set_input!
  ) {
    insert_mapping_transfer_player(
      objects: {
        source_transfer_player: {
          data: {
            provider: Info_Team
            provider_transfer_player_id: $provider_transfer_player_id
            last_updated: "now()"
          }
          on_conflict: { constraint: transfer_player_pkey, update_columns: last_updated }
        }
        transfer_player_id: $transfer_player_id
      }
      on_conflict: { constraint: transfer_player_pkey, update_columns: transfer_player_id }
    ) {
      returning {
        transfer_player_id
      }
    }
    update_source_transfer_player(
      where: { provider: { _eq: Info_Team }, provider_transfer_player_id: { _eq: $provider_transfer_player_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_TRANSFER_PLAYER = gql`
  mutation insertGoldenTransferPlayer($statsbomb_entity: statsbomb_transfer_player_insert_input!) {
    insert_statsbomb_transfer_player(objects: [$statsbomb_entity]) {
      returning {
        transfer_player_id
      }
    }
  }
`;

const CLAIMS_FOR_TRANSFER_PLAYER_BY_PROVIDER = gql`
  subscription claimsForTransferPlayerByProvider(
    $provider_transfer_player_id: String!
    $provider: source_provider_enum!
  ) {
    claim_transfer_player(
      where: { provider_transfer_player_id: { _eq: $provider_transfer_player_id }, provider: { _eq: $provider } }
    ) {
      claim_transfer_player_uuid
      player_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      provider
      provider_transfer_player_id
      approved_at
      rejected_at
      created_at
      mapping_player {
        player_id
        statsbomb_player {
          player_id
        }
      }
      team_from_mapping_team {
        team_id
        statsbomb_team {
          team_id
        }
      }
      team_to_mapping_team {
        team_id
        statsbomb_team {
          team_id
        }
      }
    }
  }
`;
const CLAIMS_TRANSFER_PLAYER_COUNT = gql`
  query claimsTransferPlayerCount {
    claim_transfer_player_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;
export {
  TRANSFER_PLAYERS_AND_CLAIMS,
  ALL_CLAIM_TRANSFER_PLAYERS,
  MAPPED_CLAIM_TRANSFER_PLAYERS,
  UNMAPPED_CLAIM_TRANSFER_PLAYERS,
  SAVE_TRANSFER_PLAYER_CLAIM,
  OVERRIDE_TRANSFER_PLAYER_CLAIM,
  INSERT_GOLDEN_TRANSFER_PLAYER,
  CLAIMS_FOR_TRANSFER_PLAYER_BY_PROVIDER,
  CLAIMS_TRANSFER_PLAYER_COUNT,
};
