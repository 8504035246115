import gql from 'graphql-tag';

const GET_PLAYERS_BY_CURRENT_TRANSFER = gql`
  subscription getPlayersByCurrentTransfer($team_id: Int!) {
    statsbomb_current_transfer_player(
      where: { _and: [{ team_to_id: { _eq: $team_id } }, { player_is_retired: { _eq: false } }] }
    ) {
      player_id
      team_to_id
      transfer_date
      player_is_retired
      statsbomb_transfer_type {
        transfer_type
        display_name
      }
      statsbomb_player {
        player_id
        player_name
        player_date_of_birth
        nationality_statsbomb_area {
          area_code
          area_name
        }
      }
      team_to_statsbomb_team {
        team_name
      }
    }
  }
`;

const GET_MANAGERS_BY_CURRENT_TRANSFER = gql`
  subscription getManagersByCurrentTransfer($team_id: Int!) {
    statsbomb_current_transfer_manager(
      where: { _and: [{ team_to_id: { _eq: $team_id } }, { manager_is_retired: { _eq: false } }] }
    ) {
      manager_id
      team_to_id
      transfer_date
      manager_is_retired
      statsbomb_transfer_type {
        transfer_type
        display_name
      }
      statsbomb_manager {
        manager_id
        manager_name
        manager_date_of_birth
        nationality_statsbomb_area {
          area_code
          area_name
        }
      }
      team_to_statsbomb_team {
        team_name
      }
    }
  }
`;

export { GET_PLAYERS_BY_CURRENT_TRANSFER, GET_MANAGERS_BY_CURRENT_TRANSFER };
