import React from 'react';
import { PhaseType } from '../components/override/phaseType';
import { NumberOverride } from '../components/override/number';
import { CompetitionSeasonOverride } from '../components/override/competitionSeason';
import { concatValues, nestedValues } from '../util/useful';
import { FlagIcon } from '../components/util/icon';
import { TextFilter } from '../components/table/entityTableFilters';

const FULL_PHASE_SCHEMA = [
  { columnKey: 'phase_id', name: 'ID', type: 'String' },
  {
    columnKey: 'phase_type_id',
    name: 'Phase Type',
    type: 'Integer',
    mapping_function: nestedValues('statsbomb_phase_type.phase_type_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_phase_type.phase_type_name'),
    OverrideControl: PhaseType,
  },
  { columnKey: 'phase_type_name', name: 'Phase type name ', type: 'String' },
  {
    columnKey: 'competition_season_id',
    name: 'Competition Season',
    type: 'String',
    mapping_function: concatValues(
      'mapping_competition_season.statsbomb_competition_season.statsbomb_competition.competition_name',
      'mapping_competition_season.statsbomb_competition_season.statsbomb_season.season_name'
    ),
    statsbomb_mapping_function: concatValues(
      'statsbomb_competition_season.statsbomb_competition.competition_name',
      'statsbomb_competition_season.statsbomb_season.season_name'
    ),
    mapped_icon: e => (
      <FlagIcon
        code={nestedValues(
          'mapping_competition_season.statsbomb_competition_season.statsbomb_competition.statsbomb_area.area_code'
        )(e)}
      />
    ),
    statsbomb_mapped_icon: e => (
      <FlagIcon code={nestedValues('statsbomb_competition_season.statsbomb_competition.statsbomb_area.area_code')(e)} />
    ),
    OverrideControl: CompetitionSeasonOverride,
  },
  {
    columnKey: 'phase_order',
    name: 'Phase Order',
    type: 'Integer',
    OverrideControl: NumberOverride,
    initialOverrideValue: 0,
  },
  {
    columnKey: 'competition_season_name',
    name: 'Competition season',
    type: 'String',
    mapping_function: concatValues('competition_name', 'season_name'),
    icon: e => <FlagIcon code={e.area_code} />,
  },

  { columnKey: 'deleted', name: 'Delete?', type: 'Boolean' },
  { columnKey: 'claim_phase_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_phase_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const PHASE_SCHEMA = FULL_PHASE_SCHEMA.filter(a =>
  ['phase_type_id', 'competition_season_id', 'phase_order', 'deleted'].includes(a.columnKey)
);

const PHASE_CLAIM_SCHEMA = ['phase_type_id', 'phase_order', 'competition_season_id'];

const PHASE_ALL_CLAIMS_SCHEMA = FULL_PHASE_SCHEMA.filter(a =>
  [
    'phase_type_id',
    'phase_order',
    'competition_season_id',
    'claim_phase_uuid',
    'created_at',
    'provider',
    'provider_phase_id',
  ].includes(a.columnKey)
);

const GOLDEN_PHASE_SCHEMA = FULL_PHASE_SCHEMA.filter(a =>
  ['phase_type_name', 'phase_order', 'competition_season_name'].includes(a.columnKey)
);

const PHASE_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'text',
      queryVariableName: 'name',
      displayName: 'Name',
    },
  },
];

export { PHASE_SCHEMA, GOLDEN_PHASE_SCHEMA, PHASE_ALL_CLAIMS_SCHEMA, PHASE_CLAIM_SCHEMA, PHASE_FILTER_SCHEMA };
