import gql from 'graphql-tag';

const CURRENCY = gql`
  query getAllCurrencies {
    statsbomb_currency {
      currency
    }
  }
`;

export { CURRENCY };
