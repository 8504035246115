import React from 'react';
import { TextField } from '@material-ui/core';

const NumberOverride = ({ overrideChange, currentValue, columnKey, overrideProps }) => {
  const defaultNumber = currentValue || '';

  return (
    <TextField
      type="number"
      onChange={({ target: { value } }) => overrideChange(columnKey, value)}
      value={defaultNumber}
      placeholder="0"
      inputProps={{ min: 0, step: overrideProps?.step || 1 }}
    />
  );
};

export { NumberOverride };
