import gql from 'graphql-tag';

export const GOLDEN_PHASE_AND_CLAIMS = gql`
  query goldenPhaseAndClaims($phase_id: Int!) {
    statsbomb_phase(where: { phase_id: { _eq: $phase_id } }) {
      phase_id
      phase_type_id
      deleted
      statsbomb_phase_type {
        phase_type_name
      }
      competition_season_id
      statsbomb_competition_season {
        statsbomb_competition {
          statsbomb_area {
            area_code
          }
          competition_name
        }
        statsbomb_season {
          season_name
        }
      }
      phase_order
      deleted
      mapping_phases {
        claim_phases {
          claim_phase_uuid
          provider
          provider_phase_id
          phase_type_id
          phase_order
          statsbomb_phase_type {
            phase_type_name
          }
          competition_season_id
          mapping_competition_season {
            competition_season_id
            statsbomb_competition_season {
              statsbomb_competition {
                statsbomb_area {
                  area_code
                }
                competition_name
              }
              statsbomb_season {
                season_name
              }
            }
          }
          created_at
          approved_at
          rejected_at
        }
      }
    }
  }
`;

export const SAVE_PHASE_CLAIM = gql`
  mutation SavePhase($claim_phase_uuid: uuid!, $claims: [claim_phase_insert_input!]!) {
    delete_claim_phase(where: { claim_phase_uuid: { _eq: $claim_phase_uuid } }) {
      affected_rows
    }
    insert_claim_phase(objects: $claims) {
      affected_rows
    }
  }
`;

export const OVERRIDE_PHASE_CLAIM = gql`
  mutation infoPhaseOverride($phase_id: Int!, $provider_phase_id: String!, $source_entity: source_phase_set_input!) {
    insert_mapping_phase(
      objects: {
        source_phase: {
          data: { provider: Info_Team, provider_phase_id: $provider_phase_id, last_updated: "now()" }
          on_conflict: { constraint: phase_pkey, update_columns: last_updated }
        }
        phase_id: $phase_id
      }
      on_conflict: { constraint: phase_pkey, update_columns: phase_id }
    ) {
      returning {
        phase_id
      }
    }
    update_source_phase(
      where: { provider: { _eq: Info_Team }, provider_phase_id: { _eq: $provider_phase_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

export const INSERT_GOLDEN_PHASE = gql`
  mutation insertGoldenPhase($statsbomb_entity: statsbomb_phase_insert_input!) {
    insert_statsbomb_phase(objects: [$statsbomb_entity]) {
      returning {
        phase_id
      }
    }
  }
`;

export const CLAIMS_FOR_PHASE_BY_PROVIDER = gql`
  subscription claimsForPhaseByProvider($provider_phase_id: String!, $provider: source_provider_enum!) {
    claim_phase(where: { provider_phase_id: { _eq: $provider_phase_id }, provider: { _eq: $provider } }) {
      claim_phase_uuid
      phase_type_id
      provider
      provider_phase_id
      competition_season_id
      mapping_competition_season {
        competition_season_id
        statsbomb_competition_season {
          statsbomb_competition {
            competition_name
          }
          statsbomb_season {
            season_name
          }
        }
      }
      claim_phase_type_sbd_phase_type {
        phase_type_name
      }
      approved_at
      deleted
    }
  }
`;
export const CLAIMS_PHASES_COUNT = gql`
  query claimsPhasesCount {
    claim_phase_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;
