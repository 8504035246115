import gql from 'graphql-tag';

export const GOLDEN_COMPETITION_AND_CLAIMS = gql`
  query goldenCompetitionAndClaims($competition_id: Int!) {
    statsbomb_competition(where: { competition_id: { _eq: $competition_id } }) {
      competition_group_id
      competition_id
      competition_is_female
      competition_ladder_id
      competition_ladder_rank
      competition_name
      competition_type_id
      deleted
      area_id
      statsbomb_area {
        area_code
        area_name
      }
      statsbomb_competition_group {
        competition_group_name
      }
      statsbomb_competition_ladder {
        competition_ladder_name
      }
      statsbomb_competition_type {
        competition_type_name
      }
      mapping_competitions {
        provider
        provider_competition_id
        claim_competitions {
          provider
          claim_competition_uuid
          competition_group_id
          competition_is_female
          competition_ladder_id
          competition_ladder_rank
          competition_name
          competition_type_id
          created_at
          deleted
          rejected_at
          approved_at
          area_id
          mapping_area {
            area_id
            statsbomb_area {
              area_code
              area_name
            }
          }
          statsbomb_competition_group {
            competition_group_name
          }
          statsbomb_competition_ladder {
            competition_ladder_name
          }
          statsbomb_competition_type {
            competition_type_name
          }
        }
      }
    }
  }
`;

export const SAVE_COMPETITION_CLAIM = gql`
  mutation SaveCompetition($claim_competition_uuid: uuid!, $claims: [claim_competition_insert_input!]!) {
    delete_claim_competition(where: { claim_competition_uuid: { _eq: $claim_competition_uuid } }) {
      affected_rows
    }
    insert_claim_competition(objects: $claims) {
      affected_rows
    }
  }
`;

export const OVERRIDE_COMPETITION_CLAIM = gql`
  mutation infoTeamOverride(
    $competition_id: Int!
    $provider_competition_id: String!
    $source_entity: source_competition_set_input!
  ) {
    insert_mapping_competition(
      objects: {
        source_competition: {
          data: { provider: Info_Team, provider_competition_id: $provider_competition_id, last_updated: "now()" }
          on_conflict: { constraint: competition_pkey, update_columns: last_updated }
        }
        competition_id: $competition_id
      }
      on_conflict: { constraint: competition_pkey, update_columns: competition_id }
    ) {
      returning {
        competition_id
      }
    }
    update_source_competition(
      where: { provider: { _eq: Info_Team }, provider_competition_id: { _eq: $provider_competition_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

export const INSERT_GOLDEN_COMPETITION = gql`
  mutation insertGoldenCompetition($statsbomb_entity: statsbomb_competition_insert_input!) {
    insert_statsbomb_competition(objects: [$statsbomb_entity]) {
      returning {
        competition_id
      }
    }
  }
`;

export const CLAIMS_COMPETITIONS_COUNT = gql`
  query claimsCompetitionsCount {
    claim_competition_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;
