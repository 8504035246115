import gql from 'graphql-tag';

const PHASES_BY_COMPETITION_SEASON = gql`
  query phasesByCompetitionSeason($competition_season_id: Int!) {
    statsbomb_phase(where: { competition_season_id: { _eq: $competition_season_id } }, order_by: { phase_order: asc }) {
      phase_id
      statsbomb_phase_type {
        phase_type_id
        phase_type_name
      }
      phase_order
    }
  }
`;

const INSERT_PHASE_SOURCE_AND_MAPPING = gql`
  mutation insertPhaseSourceAndMapping(
    $provider_phase_id: String!
    $provider: source_provider_enum!
    $phase_id: Int!
    $phase_type_id: Int
    $competition_season_id: String!
  ) {
    insert_source_phase(
      objects: {
        provider_phase_id: $provider_phase_id
        provider: Info_Team
        phase_type_id: $phase_type_id
        competition_season_id: $competition_season_id
        last_updated: "now()"
      }
      on_conflict: { constraint: phase_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
    insert_mapping_phase_one(
      object: { provider: $provider, provider_phase_id: $provider_phase_id, phase_id: $phase_id }
      on_conflict: { constraint: phase_pkey, update_columns: [] }
    ) {
      phase_id
      provider
      provider_phase_id
    }
  }
`;

export { PHASES_BY_COMPETITION_SEASON, INSERT_PHASE_SOURCE_AND_MAPPING };
