import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CompetitionSeasonOverride } from './competitionSeasonModal';
import { PhaseByCompSznOverride } from './phaseModal';

const PhaseModalOverride = ({ overrideChange, columnKey, provider }) => {
  const [open, setOpen] = useState(false);

  const emptyCompSzn = {
    competition_season_id: null,
    statsbomb_season: {
      season_name: '',
    },
    statsbomb_competition: {
      competition_name: '',
    },
  };
  const emptyPhase = { value: null, name: null };
  const [compSznState, setCompSznState] = useState(emptyCompSzn);
  const [phaseState, setPhaseState] = useState(emptyPhase);

  // Saved state: when save is clicked
  const [savedState, setSavedState] = useState({
    saved: false,
    compSzn: emptyCompSzn,
    phase: emptyPhase,
  });

  const handleSave = async () => {
    setSavedState({
      saved: true,
      compSzn: compSznState,
      phase: phaseState,
    });

    overrideChange(columnKey, phaseState.value.toString());
    setOpen(false);
  };

  const handleCancel = () => {
    // If there's a saved state, set state to the saved state else reset
    if (savedState.saved !== false) {
      setCompSznState(savedState.compSzn);
      setPhaseState(savedState.phase);
    } else resetCompSznState();

    setOpen(false);
  };

  const resetCompSznState = () => {
    setCompSznState(emptyCompSzn);
    resetPhaseState();
  };
  const resetPhaseState = () => setPhaseState(emptyPhase);

  const displayPhaseName = (compSzn, phase) =>
    `${compSzn.statsbomb_season.season_name} ${compSzn.statsbomb_competition.competition_name}, ${phase.name}`;

  return (
    <>
      {savedState.saved && (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>{displayPhaseName(savedState.compSzn, savedState.phase)}</p>
        </>
      )}
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Override Phase
      </Button>
      <Dialog open={open} aria-labelledby="phase-dialog-title" maxWidth="sm" fullWidth disableBackdropClick>
        <DialogTitle id="phase-dialog-title">Override Phase</DialogTitle>
        <DialogContent>
          <CompetitionSeasonOverride
            overrideChange={setCompSznState}
            resetChildState={resetPhaseState}
            value={compSznState}
            provider={provider}
          />
          {compSznState.competition_season_id != null && (
            <PhaseByCompSznOverride
              overrideChange={setPhaseState}
              resetChildState={() => {
                return;
              }}
              value={phaseState.value}
              compSznId={compSznState.competition_season_id}
              provider={provider}
            />
          )}
          {phaseState.value != null && (
            <>
              <p style={{ fontSize: 14, fontWeight: 'bold' }}>{displayPhaseName(compSznState, phaseState)}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          {phaseState.value != null && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export { PhaseModalOverride };
