import React, { useState } from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { has } from 'ramda';
import { TableCell, CellContent } from '../table/table';
import { DeleteDialog } from '../../components/util/DeleteDialog';
import { TooltipComponent } from '../util/tooltip';
import { getDependencyTree } from '../../components/util/tree';

const useStyles = makeStyles(theme => ({
  sizeSmall: { padding: 5 },
  checkbox: { padding: 0 },
  table: {
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  clearButton: {
    '&:hover': {
      color: '#990000',
    },
    fontSize: '30px',
    color: '#DA0000',
    height: 30,
    padding: 5,
    marginLeft: 7,
  },
  button: { margin: theme.spacing(), float: 'right' },
}));

const EntityRow = ({
  entity,
  schema,
  rowClickHandler,
  classes,
  entityKey,
  selectedEntityKey,
  type,
  entityIsFullLineup,
  entityIsLineup,
  deleteEntityMutation,
  isNotMappingView,
  minIqAttributes,
  dontUseMappedFunction,
  refetchData,
  refetchCount,
}) => {
  const [dependencies, setDependencies] = useState({});

  const [open, setOpen] = useState(false);
  const getDependencies = async (type, entity_id) => {
    setDependencies(await getDependencyTree(`statsbomb.${type}`, entity_id));
    setOpen(true);
  };
  const checkEntityVariable = goldenEntity => {
    const lineupVariables = {
      match_id: goldenEntity.match_id,
      player_id: goldenEntity.player_id,
      team_id: goldenEntity.team_id,
    };
    const fullLineupVariables = { match_id: goldenEntity.match_id };
    const entityIdVariable = { [`${type}_id`]: goldenEntity[`${type}_id`] };
    const entityVariables =
      type === 'match' ? { match_name: `${goldenEntity['match_id']}-review`, ...entityIdVariable } : entityIdVariable;
    return entityIsLineup ? lineupVariables : entityIsFullLineup ? fullLineupVariables : entityVariables;
  };

  const deleteVariables = checkEntityVariable(entity);

  const rowIsSelected = entityKey && selectedEntityKey && entity && entity[entityKey] === selectedEntityKey;

  const tabelRowLinkUrl = (entity, type_id) =>
    type === 'full_lineup'
      ? `/${type}?match_id=${entity.match_id}&match_home_team_id=${entity.match_home_team_id}&match_away_team_id=${entity.match_away_team_id}`
      : has(type_id, entity)
      ? `/${type}?${type}_id=${entity[`${type_id}`]}`
      : null;

  return (
    <>
      {isNotMappingView && !entityIsFullLineup && (
        <DeleteDialog
          variables={deleteVariables}
          handleClose={() => setOpen(false)}
          dependencyStructure={dependencies}
          entityId={entity[`${type}_id`]}
          {...{ type, deleteEntityMutation, open, entityIsFullLineup, refetchData, refetchCount }}
        />
      )}
      <TableRow
        component={Link}
        href={tabelRowLinkUrl(entity, `${type}_id`)}
        underline="none"
        color="inherit"
        target="_blank"
        hover={true}
        className={rowIsSelected ? ' active' : ''}
        onClick={() => rowClickHandler(entity)}>
        {schema.map((col, j) => (
          <TableCell key={j}>
            {col.columnKey !== 'min_spec' ? (
              <CellContent
                entity={entity}
                columnKey={col.columnKey}
                type={col.type}
                icon={col.icon}
                mappedIcon={col.mapped_icon}
                mappingFn={col.mapping_function}
                dontUseMappedFunction={dontUseMappedFunction}
              />
            ) : (
              <TooltipComponent
                tooltipTitle="Min IQ Attributes:"
                tooltipText={minIqAttributes.map(item => item.name).join(', ')}>
                <div>
                  <CellContent
                    entity={entity}
                    columnKey={col.columnKey}
                    type={col.type}
                    icon={col.icon}
                    mappedIcon={col.mapped_icon}
                    mappingFn={col.mapping_function}
                  />
                </div>
              </TooltipComponent>
            )}
          </TableCell>
        ))}
        {isNotMappingView && !entityIsFullLineup && (
          <TableCell>
            <DeleteIcon
              onClick={e => {
                e.stopPropagation();
                getDependencies(type, entity[`${type}_id`]);
              }}
              className={classes.clearButton}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

const EntityLinkTable = ({
  entities,
  schema,
  rowClickHandler,
  entityKey,
  selectedEntityKey,
  sort,
  setSort,
  type,
  deleteEntityMutation,
  isNotMappingView,
  minIqAttributes,
  dontUseMappedFunction = false,
  refetchData,
  refetchCount,
}) => {
  const classes = useStyles();
  const entityIsFullLineup = type === 'full_lineup';
  const entityIsLineup = type === 'lineup';
  const getSortStyle = (column, sortOrder) => {
    return {
      fontSize: '10px',
      color: sort?.column === column && sort?.order === sortOrder ? 'red' : 'black',
    };
  };
  const cellHeaderClicked = column => {
    const order = sort.column === column && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ column: column, order });
  };

  const isNotCompSzn = columnKey => columnKey !== 'competition_season_name';
  const isNotMinIQ = columnKey => columnKey !== 'min_spec';
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="Golden Players Table">
        <TableHead>
          <TableRow>
            {schema.map(({ columnKey, name }, key) => (
              <TableCell key={key}>
                {isNotMinIQ(columnKey) || !minIqAttributes ? (
                  <span onClick={() => isNotCompSzn(columnKey) && cellHeaderClicked(columnKey)}>{name}</span>
                ) : (
                  <TooltipComponent
                    tooltipTitle="Min IQ Attributes:"
                    tooltipText={minIqAttributes.map(item => item.name).join(', ')}>
                    <span>{name}</span>
                  </TooltipComponent>
                )}
                {isNotCompSzn(columnKey) && isNotMinIQ(columnKey) && (
                  <span>
                    <ArrowUpward
                      style={getSortStyle(columnKey, 'asc')}
                      onClick={() => setSort({ column: columnKey, order: 'asc' })}
                    />
                    <ArrowDownward
                      style={getSortStyle(columnKey, 'desc')}
                      onClick={() => setSort({ column: columnKey, order: 'desc' })}
                    />
                  </span>
                )}
              </TableCell>
            ))}
            {isNotMappingView && !entityIsFullLineup && (
              <TableCell style={{ padding: 10 }}>
                <span onClick={() => cellHeaderClicked('deleted')}>Delete</span>
                {setSort && (
                  <span>
                    <ArrowUpward
                      style={getSortStyle('deleted', 'asc')}
                      onClick={() => setSort({ column: 'deleted', order: 'asc' })}
                    />
                    <ArrowDownward
                      style={getSortStyle('deleted', 'desc')}
                      onClick={() => setSort({ column: 'deleted', order: 'desc' })}
                    />
                  </span>
                )}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {entities.map((entity, key) => (
            <EntityRow
              {...{
                key,
                entity,
                schema,
                rowClickHandler,
                classes,
                entityKey,
                selectedEntityKey,
                type,
                entityIsFullLineup,
                entityIsLineup,
                isNotMappingView,
                deleteEntityMutation,
                minIqAttributes,
                dontUseMappedFunction,
                refetchData,
                refetchCount,
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { EntityLinkTable };
