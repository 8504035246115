import React from 'react';
import { TextOverride } from '../components/override/text';
import { NumberOverride } from '../components/override/number';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { TeamOverride } from '../components/override/team';
import { DateOverride, TimeOverride, dateFormat } from '../components/override/dateTime';
import { WeatherStatusOverride } from '../components/override/weather';
import { MatchPlayStatusOverride } from '../components/override/matchPlayStatus';
import { StadiumOverride } from '../components/override/stadium';
import { RoundModalOverride } from '../components/override/round';
import { nestedValues } from '../util/useful';
import { TextFilter, BooleanFilter, DateFilter, ListFilter } from '../components/table/entityTableFilters';
import { FlagIcon } from '../components/util/icon';
import { TEAMS } from '../hasura/queries/team/teams';
import { COMPETITION_SEASONS } from '../hasura/queries/competitionSeason/competitionSeasons';

const FULL_MATCH_SCHEMA = [
  { columnKey: 'claim_match_uuid', name: 'Claim UUID', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'string' },
  { columnKey: 'provider_match_id', name: 'Provider ID', type: 'string' },
  { columnKey: 'match_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  {
    columnKey: 'match_name',
    name: 'Match Name',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'match_home_team_id',
    name: 'Home Team',
    type: 'Integer',
    mapping_function: nestedValues('home_source_team.team_name'),
    statsbomb_mapping_function: nestedValues('home_statsbomb_team.team_name'),
    OverrideControl: TeamOverride,
  },
  {
    columnKey: 'match_away_team_id',
    name: 'Away Team',
    type: 'Integer',
    mapping_function: nestedValues('away_source_team.team_name'),
    statsbomb_mapping_function: nestedValues('away_statsbomb_team.team_name'),
    OverrideControl: TeamOverride,
  },
  {
    columnKey: 'home_team_name',
    name: 'Home Team',
    type: 'String',
  },
  {
    columnKey: 'away_team_name',
    name: 'Away Team',
    type: 'String',
  },
  {
    columnKey: 'match_date',
    name: 'Match Date',
    type: 'Date',
    OverrideControl: DateOverride,
    initialOverrideValue: dateFormat(new Date()),
  },
  {
    columnKey: 'match_local_kick_off',
    name: 'Kick Off Time',
    type: 'Time',
    OverrideControl: TimeOverride,
    initialOverrideValue: '15:00:00',
  },
  {
    columnKey: 'round_id',
    name: 'Competition Round',
    type: 'Integer',
    mapping_function: ({ source_round }) => {
      if (!source_round) return '';
      const { source_phase, statsbomb_round_type, statsbomb_round_structure } = source_round;

      const competitionName =
        source_phase?.source_competition_season?.source_competition?.competition_name || 'Missing Competition';
      const seasonName = source_phase?.source_competition_season?.statsbomb_season.season_name || 'Missing Season';
      const phaseTypeName = source_phase?.statsbomb_phase_type?.phase_type_name || 'Missing Phase';
      const roundTypeName = statsbomb_round_type?.round_type_name || 'Missing Round Type';
      const roundStructureName = statsbomb_round_structure?.round_structure_name
        ? `(${statsbomb_round_structure?.round_structure_name})`
        : '';
      const roundId = source_round?.provider_round_id;

      return `${competitionName}, ${seasonName}, ${phaseTypeName}, Round Id: ${roundId}, ${roundTypeName} ${roundStructureName}`;
    },
    mapped_icon: e => (
      <FlagIcon
        code={nestedValues(
          'source_round.source_phase.source_competition_season.source_competition.source_area.area_code'
        )(e)}
      />
    ),
    statsbomb_mapping_function: ({ statsbomb_round }) => {
      if (!statsbomb_round) return '';

      const { statsbomb_phase, statsbomb_round_type, statsbomb_round_structure } = statsbomb_round;

      const competitionName = statsbomb_phase.statsbomb_competition_season.statsbomb_competition.competition_name;
      const seasonName = statsbomb_phase.statsbomb_competition_season.statsbomb_season.season_name;
      const phaseTypeName = statsbomb_phase.statsbomb_phase_type.phase_type_name;
      const roundTypeName = statsbomb_round_type.round_type_name;
      const roundStructureName = statsbomb_round_structure?.round_structure_name
        ? `(${statsbomb_round_structure.round_structure_name})`
        : '';

      return `${competitionName}, ${seasonName}, ${phaseTypeName}, ${roundTypeName} ${roundStructureName}`;
    },
    statsbomb_mapped_icon: e => (
      <FlagIcon
        code={nestedValues(
          'statsbomb_round.statsbomb_phase.statsbomb_competition_season.statsbomb_competition.statsbomb_area.area_code'
        )(e)}
      />
    ),
    OverrideControl: RoundModalOverride,
  },
  {
    columnKey: 'stadium_id',
    name: 'Stadium ID',
    type: 'Integer',
    mapping_function: nestedValues('source_stadium.stadium_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_stadium.stadium_name'),
    OverrideControl: StadiumOverride,
  },
  {
    columnKey: 'match_play_status',
    name: 'Match Status',
    type: 'String',
    OverrideControl: MatchPlayStatusOverride,
    initialOverrideValue: 'Normal',
  },
  {
    columnKey: 'match_week',
    name: 'Match Week',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  {
    columnKey: 'match_attendance',
    name: 'Match Attendance',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  {
    columnKey: 'match_weather',
    name: 'Match Weather',
    type: 'String',
    OverrideControl: WeatherStatusOverride,
  },
  {
    columnKey: 'match_home_score',
    name: 'Home Score',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  {
    columnKey: 'match_away_score',
    name: 'Away Score',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  {
    columnKey: 'match_home_penalty_score',
    name: 'Home Penalty Score',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  {
    columnKey: 'match_away_penalty_score',
    name: 'Away Penalty Score',
    type: 'Integer',
    OverrideControl: NumberOverride,
  },
  {
    columnKey: 'match_neutral_ground',
    name: 'Neutral Ground',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  {
    columnKey: 'match_behind_closed_doors',
    name: 'Behind Closed Doors',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },

  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
  {
    columnKey: 'deleted',
    name: 'Delete?',
    type: 'Boolean',
  },
];
const GOLDEN_MATCH_SCHEMA = FULL_MATCH_SCHEMA.filter(field =>
  [
    'match_id',
    'match_name',
    'home_team_name',
    'away_team_name',
    'match_date',
    'match_local_kick_off',
    'match_week',
    'min_spec',
  ].includes(field.columnKey)
);

const MATCH_SCHEMA = FULL_MATCH_SCHEMA.filter(a =>
  [
    'match_id',
    'deleted',
    'match_name',
    'round_id',
    'match_home_team_id',
    'match_away_team_id',
    'match_date',
    'match_play_status',
    'stadium_id',
    'match_week',
    'match_local_kick_off',
    'match_attendance',
    'match_weather',
    'match_neutral_ground',
    'match_behind_closed_doors',
    'phase',
  ].includes(a.columnKey)
);

const MATCH_CLAIMS_TO_MAP_SCHEMA = FULL_MATCH_SCHEMA.filter(a =>
  [
    'match_name',
    'round_id',
    'match_home_team_id',
    'match_away_team_id',
    'match_date',
    'match_play_status',
    'stadium_id',
    'match_week',
    'match_local_kick_off',
    'match_attendance',
    'match_weather',
    'match_neutral_ground',
    'match_behind_closed_doors',
    'phase',
  ].includes(a.columnKey)
);

const MATCH_CLAIM_SCHEMA = [
  'match_name',
  'round_id',
  'match_home_team_id',
  'match_away_team_id',
  'match_date',
  'match_play_status',
  'stadium_id',
  'match_week',
  'match_local_kick_off',
  'match_attendance',
  'match_weather',
  'match_neutral_ground',
  'match_behind_closed_doors',
  'phase',
];

const MATCH_ALL_CLAIMS_SCHEMA = FULL_MATCH_SCHEMA.filter(a =>
  [
    'claim_match_uuid',
    'provider',
    'provider_match_id',
    'created_at',
    'provider',
    'provider_match_id',
    'match_name',
    'round_id',
    'match_home_team_id',
    'match_away_team_id',
    'match_date',
    'match_play_status',
    'stadium_id',
    'match_week',
    'match_local_kick_off',
    'phase',
  ].includes(a.columnKey)
);

const MAPPED_MATCH_SCHEMA = FULL_MATCH_SCHEMA.filter(a =>
  [
    'match_id',
    'match_name',
    'round_id',
    'match_home_team_id',
    'match_away_team_id',
    'match_date',
    'match_play_status',
    'stadium_id',
    'match_week',
    'match_local_kick_off',
    'match_attendance',
    'match_weather',
    'match_neutral_ground',
    'match_behind_closed_doors',
    'phase',
    'provider',
    'provider_match_id',
  ].includes(a.columnKey)
);

const MATCH_MIN_IQ_SCHEMA = FULL_MATCH_SCHEMA.filter(a =>
  ['match_id', 'match_name', 'home_team_name', 'away_team_name', 'match_date'].includes(a.columnKey)
);

const MATCH_DEPENDANCY_MAPPING_SCHEMA = [
  { type: 'team', displayName: 'home team', mappingObjectPath: 'home_mapping_team.statsbomb_team.team_id' },
  { type: 'team', displayName: 'away team', mappingObjectPath: 'away_mapping_team.statsbomb_team.team_id' },
  { type: 'round', displayName: 'round', mappingObjectPath: 'mapping_round.statsbomb_round.round_id' },
];

const MATCH_FILTER_SCHEMA = [
  {
    type: 'team',
    Component: ListFilter,
    attributes: {
      key: 'homeTeam',
      queryVariableName: 'home_team',
      displayName: 'Home Team',
      flagIconPath: 'statsbomb_area.area_code',
      genderIconPath: 'team_is_female',
      searchQuery: TEAMS,
      idPath: 'team_name',
      variablePaths: ['team_name'],
    },
  },
  {
    type: 'team',
    Component: ListFilter,
    attributes: {
      key: 'awayTeam',
      queryVariableName: 'away_team',
      displayName: 'Away Team',
      flagIconPath: 'statsbomb_area.area_code',
      genderIconPath: 'team_is_female',
      searchQuery: TEAMS,
      idPath: 'team_name',
      variablePaths: ['team_name'],
    },
  },
  {
    type: 'competition_season',
    Component: ListFilter,
    attributes: {
      key: 'competitionSeason',
      queryVariableName: 'competition_season',
      displayName: 'Competition Season',
      flagIconPath: 'statsbomb_competition.statsbomb_area.area_code',
      genderIconPath: 'statsbomb_competition.competition_is_female',
      searchQuery: COMPETITION_SEASONS,
      idPath: 'competition_season_id',
      variablePaths: ['statsbomb_season.season_name', 'statsbomb_competition.competition_name'],
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Match Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Match Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
];
const MATCH_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'name',
      queryVariableName: 'name',
      displayName: 'Match Name',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Match Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Match Date To',
    },
  },
];
export {
  FULL_MATCH_SCHEMA,
  GOLDEN_MATCH_SCHEMA,
  MATCH_SCHEMA,
  MATCH_CLAIM_SCHEMA,
  MATCH_ALL_CLAIMS_SCHEMA,
  MATCH_CLAIMS_TO_MAP_SCHEMA,
  MATCH_DEPENDANCY_MAPPING_SCHEMA,
  MATCH_FILTER_SCHEMA,
  MATCH_MIN_IQ_SCHEMA,
  MAPPED_MATCH_SCHEMA,
  MATCH_CLAIM_FILTER_SCHEMA,
};
