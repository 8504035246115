import { HIDDEN_CLAIMS_SECTIONS, HIDDEN_GOLDEN_RECORDS_SECTIONS, SECTION_CONFIG } from './consts';

const blankSchema = schema => schema.reduce((acc, val) => ({ ...acc, [val.columnKey]: 'Ignored' }), {});

const filterSection = hiddenSections =>
  SECTION_CONFIG.reduce((acc, curr) => {
    !hiddenSections.includes(curr.type) && acc.push(curr);
    return acc;
  }, []);

const goldenRecordSections = filterSection(HIDDEN_GOLDEN_RECORDS_SECTIONS);

const claimSections = filterSection(HIDDEN_CLAIMS_SECTIONS);

export { blankSchema, goldenRecordSections, claimSections };
