import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MAP_TO_MATCH, MAKE_AND_MAP_MATCH } from '../../hasura/queries/matchMapping';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import { MATCH_CLAIMS_TO_MAP_SCHEMA, MATCH_DEPENDANCY_MAPPING_SCHEMA } from '../../schema/matchSchema';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import { SIMILAR_MATCHES } from '../../hasura/queries/match/matches';
import { SimilarEntity } from '../mapping/SimilarEntity';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const MatchMapping = props => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [matchMapViewState, setMatchMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter => {
    setMatchMapViewState({
      ...matchMapViewState,
      claimFilter,
    });
  };

  const filterClaims = claims => {
    if (matchMapViewState.claimFilter === 'undecided') {
      return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    } else if (matchMapViewState.claimFilter === 'approved') {
      return claims.filter(c => c.approved_at != null);
    } else if (matchMapViewState.claimFilter === 'rejected') {
      return claims.filter(c => c.rejected_at != null);
    } else {
      return claims;
    }
  };

  const selectClaim = selectedClaim => {
    setMatchMapViewState({
      ...matchMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarMatch = selectedSim => {
    setMatchMapViewState({
      ...matchMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(props.claims);

  const returnToClaimsList = () => {
    history.push('/claims/match');
  };

  const [mapToMatch] = useMutation(MAP_TO_MATCH, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Match Claim to Statsbomb Match')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapMatch] = useMutation(MAKE_AND_MAP_MATCH, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Match Claim to Statsbomb Match')),
    onCompleted: returnToClaimsList,
  });

  const selClaim = props.claims.filter(c => c.claim_match_uuid === matchMapViewState.selectedClaim)[0] || {};
  const createMatchMapFn = async e => {
    e.preventDefault();
    try {
      await mapToMatch({
        variables: {
          match_id: matchMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_match_id: selClaim.provider_match_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createMatchAndMapFn = async e => {
    e.preventDefault();
    try {
      await makeAndMapMatch({
        variables: {
          match_name: selClaim.match_name,
          provider: selClaim.provider,
          provider_match_id: selClaim.provider_match_id,
          match_home_team_id: selClaim.home_mapping_team.statsbomb_team.team_id,
          match_away_team_id: selClaim.away_mapping_team.statsbomb_team.team_id,
          round_id: selClaim.mapping_round.statsbomb_round.round_id,
          match_date: selClaim.match_date,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Match Mapper. Provider Match Id: {props.provider_match_id}</h2>
        <h3>Select A Claim to view Possible Matches</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={matchMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={MATCH_CLAIMS_TO_MAP_SCHEMA}
            rowClickHandler={claim => {
              selectClaim(claim.claim_match_uuid);
            }}
            entityKey="claim_match_uuid"
            selectedEntityKey={matchMapViewState?.selectedClaim}
          />
        </TableContainer>

        {matchMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarEntity
              provider="Soccerway"
              entityName={selClaim.match_name}
              selectSimilarEntityFn={selectSimilarMatch}
              selectedSim={matchMapViewState.selectedSim}
              type="match"
              query={SIMILAR_MATCHES}
            />
            <ClaimMappingButtons
              displayName="Match"
              claim={selClaim}
              similarRecord={matchMapViewState.selectedSim}
              schema={MATCH_DEPENDANCY_MAPPING_SCHEMA}
              mapEntityFn={createMatchMapFn}
              createNewEntityFn={createMatchAndMapFn}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export { MatchMapping };
