const { REACT_APP_OKTA_ISSUER, REACT_APP_OKTA_APP_CLIENT_ID } = process.env;

const oktaAuthConfig = {
  issuer: REACT_APP_OKTA_ISSUER,
  clientId: REACT_APP_OKTA_APP_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'groups', 'offline_access'],
};

export { oktaAuthConfig };
