import gql from 'graphql-tag';

const INSERT_COMPETITION_STRUCTURE = gql`
  mutation InsertCompetitionStructure(
    $competition_id: Int
    $competition_season_name: String
    $season_id: Int
    $phases: [statsbomb_phase_insert_input!]!
  ) {
    insert_statsbomb_competition_season(
      objects: {
        competition_id: $competition_id
        competition_season_name: $competition_season_name
        season_id: $season_id
        statsbomb_phases: { data: $phases }
      }
    ) {
      returning {
        competition_season_id
      }
    }
  }
`;

const COMPETITION_STRUCTURE = gql`
  query getCompetitionStructure($competition_season_id: Int) {
    statsbomb_competition_season(where: { competition_season_id: { _eq: $competition_season_id } }) {
      competition_season_name
      competition_season_id
      season_id
      competition_id
      deleted
      statsbomb_competition {
        competition_id
        competition_name
        competition_group_id
        competition_is_female
        competition_ladder_id
        competition_ladder_rank
        competition_type_id
        statsbomb_area {
          area_name
          area_code
        }
        deleted
      }
      statsbomb_season {
        season_id
        season_name
        deleted
      }
      statsbomb_phases {
        phase_id
        phase_order
        phase_type_id
        statsbomb_rounds {
          round_id
          round_type_id
          round_order
          round_structure_id
          round_teams
          round_matches
          round_match_weeks
          round_first_match_date
          round_last_match_date
          deleted
          phase_id
        }
        deleted
        competition_season_id
      }
    }
  }
`;

export { INSERT_COMPETITION_STRUCTURE, COMPETITION_STRUCTURE };
