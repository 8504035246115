import gql from 'graphql-tag';

const MAP_TRANSFER_PLAYER = gql`
  mutation insertMapTransferPlayer(
    $transfer_player_id: Int!
    $provider: source_provider_enum!
    $provider_transfer_player_id: String!
  ) {
    insert_mapping_transfer_player_one(
      object: {
        transfer_player_id: $transfer_player_id
        provider: $provider
        provider_transfer_id: $provider_transfer_id
      }
      on_conflict: { constraint: transfer_player_pkey, update_columns: [] }
    ) {
      transfer_player_id
      provider
      provider_transfer_player_id
    }
  }
`;

const MAP_TO_TRANSFER_PLAYER = gql`
  mutation mapToTransferPlayer(
    $transfer_player_id: Int!
    $provider: source_provider_enum!
    $provider_transfer_player_id: String!
  ) {
    insert_mapping_transfer_player(
      objects: {
        transfer_player_id: $transfer_player_id
        provider: $provider
        provider_transfer_player_id: $provider_transfer_player_id
      }
    ) {
      returning {
        provider_transfer_player_id
        provider
        transfer_player_id
      }
    }
  }
`;

const MAKE_AND_MAP_TRANSFER_PLAYER = gql`
  mutation makeAndMapTransferPlayer(
    $provider: source_provider_enum!
    $provider_transfer_player_id: String!
    $player_id: Int!
    $team_from_id: Int!
    $team_to_id: Int!
    $transfer_currency: String
    $transfer_value: float8
    $transfer_type: String
    $transfer_date: String
  ) {
    insert_mapping_transfer_player(
      objects: {
        statsbomb_transfer_player: {
          data: {
            player_id: $player_id
            team_from_id: $team_from_id
            team_to_id: $team_to_id
            transfer_currency: $transfer_currency
            transfer_value: $transfer_value
            transfer_type: $transfer_type
            transfer_date: $transfer_date
          }
        }
        provider: $provider
        provider_transfer_player_id: $provider_transfer_player_id
      }
    ) {
      returning {
        provider_transfer_player_id
        provider
        transfer_player_id
      }
    }
  }
`;

const VIEW_TRANSFER_PLAYER_MAPPINGS = gql`
  subscription viewTransferPlayerMappings($id: Int!) {
    mapping_transfer_player(where: { transfer_player_id: { _eq: $id } }) {
      source_transfer_player {
        provider_transfer_player_id
        provider
        player_id
        team_to_id
        team_from_id
        transfer_date
        transfer_type
        transfer_currency
        transfer_value
      }
    }
  }
`;

const DELETE_TRANSFER_PLAYER_MAPPING = gql`
  mutation deleteTransferPlayerMappings(
    $claim_data: claim_transfer_player_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_transfer_player_one(
      object: $claim_data
      on_conflict: { constraint: transfer_player_pkey, update_columns: [] }
    ) {
      provider
      provider_transfer_player_id
    }

    delete_mapping_transfer_player_by_pk(provider: $provider, provider_transfer_player_id: $provider_id) {
      transfer_player_id
      provider_transfer_player_id
      provider
    }
  }
`;

export {
  MAP_TRANSFER_PLAYER,
  MAP_TO_TRANSFER_PLAYER,
  MAKE_AND_MAP_TRANSFER_PLAYER,
  VIEW_TRANSFER_PLAYER_MAPPINGS,
  DELETE_TRANSFER_PLAYER_MAPPING,
};
