const formatDateForComp = date => date.toISOString().split('T')[0];
const newRound = () => ({
  matches: 0,
  teams: 0,
  weeks: 0,
  firstMatchDate: formatDateForComp(new Date()),
  lastMatchDate: formatDateForComp(new Date()),
});
const newPhase = () => ({ rounds: [newRound()] });

const formatStructureForSave = ({
  competitionSeasonName: competition_season_name,
  competitionId: competition_id,
  seasonId: season_id,
  phases,
}) => ({
  competition_season_name,
  competition_id,
  season_id,
  phases: phases.map(({ type: phase_type_id, rounds }, phase_order) => ({
    phase_type_id,
    phase_order,
    statsbomb_rounds: {
      data: rounds.map((round, round_order) => ({
        round_structure_id: round.structure,
        round_type_id: round.type,
        round_teams: round.teams,
        round_matches: round.matches,
        round_match_weeks: round.weeks,
        round_first_match_date: round.firstMatchDate,
        round_last_match_date: round.lastMatchDate,
        round_order,
      })),
    },
  })),
});

export { newPhase, newRound, formatStructureForSave, formatDateForComp };
