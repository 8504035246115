/* eslint-disable no-console */
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AreaSelection } from '../area/areaSelection';
import { INSERT_SOURCE_AREA, MAP_AREA } from '../../hasura/queries/area/areas';

const AreaOverride = ({ overrideChange, columnKey, currentValue, provider }) => {
  const [mapArea] = useMutation(MAP_AREA, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [insertSourceArea] = useMutation(INSERT_SOURCE_AREA, {
    onError: () => {},
    onCompleted: () => {},
  });

  const areaOverrideSelected = async area => {
    const variables = {
      area_name: area.area_name,
      area_code: area.area_code,
      provider_area_id: area.area_id.toString(),
    };
    await insertSourceArea({
      variables,
    });

    await mapArea({
      variables: {
        area_id: area.area_id.toString(),
        provider_area_id: area.area_id.toString(),
        provider: provider,
      },
    });
    overrideChange(columnKey, area.area_id.toString());
  };

  return (
    <AreaSelection areaSelected={areaOverrideSelected} title="Pick Alternative Area" currentValue={currentValue} />
  );
};

const AreaSelector = ({ currentValue, provider }) => {
  const [mapArea] = useMutation(MAP_AREA, {
    onError: () => {},
    onCompleted: () => {},
  });

  const mapAreaSelected = async ({ area_id }) => {
    await mapArea({
      variables: {
        area_id,
        provider_area_id: currentValue,
        provider: provider,
      },
    });
  };

  return <AreaSelection areaSelected={mapAreaSelected} title={'Map ' + currentValue} />;
};

export { AreaOverride, AreaSelector };
