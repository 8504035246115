import gql from 'graphql-tag';

export const PHASE_TYPE = gql`
  query phaseType {
    statsbomb_phase_type(order_by: { phase_type_name: asc }) {
      phase_type_name
      phase_type_id
    }
  }
`;
