import React from 'react';
import { TextOverride } from '../components/override/text';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { dateYearMinusEighteen, DateOverride } from '../components/override/dateTime';
import { AreaSelector, AreaOverride } from '../components/override/area';
import { FlagIcon } from '../components/util/icon';
import { nestedValues } from '../util/useful';
import { ListFilter, TextFilter, DateFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { AREAS } from '../hasura/queries/area/areas';

const FULL_OFFICIAL_SCHEMA = [
  {
    columnKey: 'claim_official_uuid',
    name: 'Claim Official UUID',
    type: 'String',
  },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_official_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'official_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  {
    columnKey: 'official_name',
    name: 'Name',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'official_nickname',
    name: 'Nickname',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'official_date_of_birth',
    name: 'Date of birth',
    type: 'Date',
    OverrideControl: DateOverride,
    initialOverrideValue: dateYearMinusEighteen(),
  },
  {
    columnKey: 'official_nationality',
    name: 'Nationality',
    type: 'Integer',
    MappingControl: AreaSelector,
    mapping_function: nestedValues('mapping_area.statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapping_function: nestedValues('statsbomb_area.area_name'),
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'official_nationality_name',
    name: 'Nationality',
    type: 'String',
    icon: e => <FlagIcon code={nestedValues('official_nationality_code')(e)} />,
  },
  {
    columnKey: 'official_is_female',
    name: 'Is Female?',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  {
    columnKey: 'official_photo_url',
    name: 'Photo URL',
    type: 'String',
    OverrideControl: TextOverride,
  },
  {
    columnKey: 'deleted',
    name: 'Deleted?',
    type: 'Boolean',
  },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const GOLDEN_OFFICIAL_SCHEMA = FULL_OFFICIAL_SCHEMA.filter(field =>
  [
    'official_name',
    'official_nickname',
    'official_date_of_birth',
    'official_nationality_name',
    'official_is_female',
    'min_spec',
  ].includes(field.columnKey)
);

const OFFICIAL_SCHEMA = FULL_OFFICIAL_SCHEMA.filter(field =>
  [
    'official_name',
    'official_nickname',
    'official_date_of_birth',
    'official_nationality',
    'official_is_female',
    'official_photo_url',
    'deleted',
  ].includes(field.columnKey)
);

const OFFICIAL_CLAIM_SCHEMA = [
  'official_name',
  'official_nickname',
  'official_date_of_birth',
  'official_nationality',
  'official_is_female',
  'official_photo_url',
];

const OFFICIAL_ALL_CLAIMS_SCHEMA = FULL_OFFICIAL_SCHEMA.filter(a =>
  [
    'official_name',
    'official_nickname',
    'official_date_of_birth',
    'official_nationality',
    'official_is_female',
    'official_photo_url',
    'claim_official_uuid',
    'provider_official_id',
    'provider',
    'created_at',
  ].includes(a.columnKey)
);

const OFFICIAL_CLAIM__TO_MAP_SCHEMA = FULL_OFFICIAL_SCHEMA.filter(field =>
  [
    'claim_official_uuid',
    'official_name',
    'official_nickname',
    'official_date_of_birth',
    'official_nationality',
    'official_is_female',
    'official_photo_url',
    'provider_official_id',
    'provider',
    'deleted',
    'created_at',
    'approved_at',
    'rejected_at',
  ].includes(field.columnKey)
);

const MAPPED_OFFICIAL_SCHEMA = FULL_OFFICIAL_SCHEMA.filter(a =>
  [
    'official_name',
    'official_nickname',
    'official_date_of_birth',
    'official_nationality',
    'official_is_female',
    'official_photo_url',
    'provider',
    'provider_official_id',
  ].includes(a.columnKey)
);

const OFFICIAL_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
  },
  {
    type: 'area',
    Component: ListFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Nationality',
      flagIconPath: 'area_code',
      searchQuery: AREAS,
      idPath: 'area_name',
      variablePaths: ['area_name'],
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Birth Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Birth Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
  {
    type: 'duplicate',
    Component: BooleanFilter,
    attributes: { key: 'duplicateCount', queryVariableName: 'duplicate_count', displayName: 'Show Duplicates' },
  },
];

const OFFICIAL_MIN_IQ_SCHEMA = FULL_OFFICIAL_SCHEMA.filter(a =>
  ['official_id', 'official_name', 'official_date_of_birth', 'official_nationality'].includes(a.columnKey)
);
const OFFICIAL_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'officialName',
      queryVariableName: 'name',
      displayName: 'Name',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'nationality',
      queryVariableName: 'area',
      displayName: 'Nationality',
    },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: { key: 'startDate', queryVariableName: 'start_date', displayName: 'Birth Date From' },
  },
  {
    type: 'date',
    Component: DateFilter,
    attributes: {
      key: 'endDate',
      queryVariableName: 'end_date',
      displayName: 'Birth Date To',
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'isFemale', queryVariableName: 'is_female', displayName: 'is Female?' },
  },
];

export {
  FULL_OFFICIAL_SCHEMA,
  GOLDEN_OFFICIAL_SCHEMA,
  OFFICIAL_SCHEMA,
  OFFICIAL_CLAIM_SCHEMA,
  OFFICIAL_ALL_CLAIMS_SCHEMA,
  OFFICIAL_CLAIM__TO_MAP_SCHEMA,
  OFFICIAL_FILTER_SCHEMA,
  OFFICIAL_MIN_IQ_SCHEMA,
  MAPPED_OFFICIAL_SCHEMA,
  OFFICIAL_CLAIM_FILTER_SCHEMA,
};
