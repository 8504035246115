import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import { LinkButton } from '../components/util/buttons';
import { FlagIcon, GenderIcon } from '../components/util/icon';
import {
  GET_PLAYERS_BY_CURRENT_TRANSFER,
  GET_MANAGERS_BY_CURRENT_TRANSFER,
} from '../hasura/queries/teamSquad/teamSquad';
import { InlineProgress } from '../components/util/progress';
import { GOLDEN_TEAM_AND_CLAIMS } from '../hasura/queries/team/teamClaims';
import { TeamOverride } from '../components/override/team';
import { DateOverride } from '../components/override/dateTime';
import { NumberOverride } from '../components/override/number';
import EnumOverride from '../components/override/enum';
import { TRANSFER_TYPE } from '../hasura/queries/transfers/transferTypes';
import { CURRENCY } from '../hasura/queries/currency/currency';
import { PlayerOverride } from '../components/override/player';
import TeamSquadComponent from '../components/teamSquad/teamSquad';
import TeamSquadModal from '../components/teamSquad/teamSquadModal';
import ManagerOverride from '../components/override/manager';

const TeamSquad = () => {
  const query = new URLSearchParams(useLocation().search);
  const teamId = query.get('team_id');

  const [modalOpen, setModalOpen] = useState(false);
  const [transferType, setTransferType] = useState({ transferType: 'transfer', action: 'in', type: 'player' });
  const [initialTransferDetails, setInitialTransferDetails] = useState({
    entity_id: null,
    team_from_id: null,
    team_to_id: null,
  });

  const { loading, data } = useSubscription(GOLDEN_TEAM_AND_CLAIMS, {
    variables: {
      team_id: teamId,
    },
  });

  useEffect(() => {
    if (!modalOpen) {
      setInitialTransferDetails({ ...initialTransferDetails, entity_id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  if (loading) return <InlineProgress />;

  const team = data.statsbomb_team[0];

  const modalSchema = [
    {
      label: 'Player',
      columnKey: 'player_id',
      Component: PlayerOverride,
    },
    {
      label: 'Manager',
      columnKey: 'manager_id',
      Component: ManagerOverride,
    },
    {
      label: 'Transfer From',
      columnKey: 'team_from_id',
      Component: TeamOverride,
    },
    {
      label: 'Transfer To',
      columnKey: 'team_to_id',
      Component: TeamOverride,
    },
    {
      label: 'Transfer Date',
      columnKey: 'transfer_date',
      Component: DateOverride,
    },
    {
      label: 'Transfer Value',
      columnKey: 'transfer_value',
      Component: NumberOverride,
      overrideProps: {
        step: 0.01,
      },
    },
    {
      label: 'Transfer Type',
      columnKey: 'transfer_type',
      Component: EnumOverride,
      overrideProps: {
        query: TRANSFER_TYPE,
        statsbombPath: 'statsbomb_transfer_type',
        path: 'transfer_type',
        label: 'Transfer Type',
      },
    },
    {
      label: 'Transfer Currency',
      columnKey: 'transfer_currency',
      Component: EnumOverride,
      overrideProps: {
        query: CURRENCY,
        statsbombPath: 'statsbomb_currency',
        path: 'currency',
        label: 'Currency',
      },
    },
  ];

  const filterModalSchema = (schema, { action, type }) => {
    const componentsToRemove = {
      player: {
        in: ['manager_id', 'team_to_id'],
        out: ['player_id', 'manager_id', 'team_from_id'],
      },
      manager: {
        in: ['player_id', 'team_to_id'],
        out: ['player_id', 'manager_id', 'team_from_id'],
      },
    };

    return schema.filter(item => !componentsToRemove[type][action].includes(item.columnKey));
  };

  const openModalWithType = (transferTypeObject, transferDetailsObject) => {
    setTransferType({
      ...transferType,
      ...transferTypeObject,
    });
    setInitialTransferDetails({
      ...initialTransferDetails,
      ...transferDetailsObject,
    });
    setModalOpen(true);
  };

  const filteredModalSchema = filterModalSchema(modalSchema, transferType);

  return (
    <>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={6}>
          <h2>
            <FlagIcon code={team.statsbomb_area?.area_code} /> <GenderIcon isFemale={team.team_is_female} />{' '}
            {team.team_name}
          </h2>
          <p>This is {team.team_name}'s current squad.</p>
        </Grid>
        <Grid item xs={6}>
          <Link
            to={{
              pathname: `/team`,
              search: `?team_id=${teamId}`,
            }}
            component={LinkButton}
            label="View Club"
          />
        </Grid>
      </Grid>
      <TeamSquadComponent
        type="manager"
        teamId={team.team_id}
        query={GET_MANAGERS_BY_CURRENT_TRANSFER}
        queryPath="statsbomb_current_transfer_manager"
        openModalWithType={openModalWithType}
      />
      <TeamSquadComponent
        type="player"
        teamId={team.team_id}
        query={GET_PLAYERS_BY_CURRENT_TRANSFER}
        queryPath="statsbomb_current_transfer_player"
        openModalWithType={openModalWithType}
      />
      <TeamSquadModal
        teamName={team.team_name}
        schema={filteredModalSchema}
        {...{ transferType, initialTransferDetails, setModalOpen, modalOpen }}
      />
    </>
  );
};

export default TeamSquad;
