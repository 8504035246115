import gql from 'graphql-tag';

const GOLDEN_ROUNDS = gql`
  query getGoldenRounds(
    $name: String
    $deleted: Boolean = false
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_round_order_by!
  ) {
    statsbomb_golden_round(
      where: { _and: [{ search_name: { _ilike: $name }, deleted: { _eq: $deleted } }] }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      round_type_name
      round_structure_name
      round_structure_id
      phase_type_id
      phase_id
      phase_type_name
      round_first_match_date
      round_id
      round_last_match_date
      round_match_weeks
      round_matches
      round_order
      round_teams
      search_name
      competition_name
      season_name
      area_code
      deleted
    }
  }
`;

const GOLDEN_ROUNDS_COUNT = gql`
  query getGoldenRoundsCount($name: String, $deleted: Boolean = false) {
    statsbomb_golden_round_aggregate(
      where: { _and: [{ search_name: { _ilike: $name }, deleted: { _eq: $deleted } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const SIMILAR_ROUNDS = gql`
  query similarRounds($n: String!, $p: String!, $offset: Int!) {
    statsbomb_similar_rounds(args: { n: $n, p: $p, offset: $offset }) {
      name_similarity
      round_id
      round_type_name
      phase_type_name
      mapped
    }
  }
`;
const DELETE_ROUND = gql`
  mutation DeleteRounds($round_id: Int!) {
    update_statsbomb_round_by_pk(pk_columns: { round_id: $round_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;
const DELETE_ROUND_BY_ID = gql`
  mutation deleteRound($round_id: Int!) {
    delete_claim_round(where: { mapping_round: { round_id: { _eq: $round_id } } }) {
      affected_rows
    }
    delete_mapping_round(where: { round_id: { _eq: $round_id } }) {
      affected_rows
    }
    delete_statsbomb_round(where: { round_id: { _eq: $round_id } }) {
      affected_rows
    }
  }
`;
const GET_ROUNDS_BY_ID = gql`
  query getRoundsById($entityIds: [Int!]) {
    statsbomb_golden_round(where: { round_id: { _in: $entityIds } }) {
      round_type_name
      round_structure_name
      round_structure_id
      phase_type_id
      phase_id
      phase_type_name
      round_first_match_date
      round_id
      round_last_match_date
      round_match_weeks
      round_matches
      round_order
      round_teams
      search_name
      competition_name
      season_name
      area_code
      deleted
    }
  }
`;
export { GOLDEN_ROUNDS, GOLDEN_ROUNDS_COUNT, SIMILAR_ROUNDS, DELETE_ROUND, DELETE_ROUND_BY_ID, GET_ROUNDS_BY_ID };
