import React, { forwardRef } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyleButton = makeStyles({
  button: { margin: 5, float: 'right' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    height: 30,
    padding: 5,
    float: 'right',
    margin: 5,
  },
});

const LinkButton = forwardRef(({ navigate, label, ...rest }, ref) => {
  const buttonClasses = useStyleButton();
  return (
    <Button ref={ref} {...rest} className={buttonClasses.button} variant="contained" color="primary">
      {label}
    </Button>
  );
});

const SmallButton = props => (
  <Button variant="contained" style={{ margin: 5, padding: '2px 5px', fontSize: 10 }} {...{ ...props }}>
    {props.text}
  </Button>
);

export { SmallButton, LinkButton };
