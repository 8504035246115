import gql from 'graphql-tag';

const TRANSFER_MANAGERS_AND_CLAIMS = gql`
  query transferManagersAndClaims($transfer_manager_id: Int!) {
    statsbomb_transfer_manager(where: { transfer_manager_id: { _eq: $transfer_manager_id } }) {
      manager_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_manager_id
      transfer_type
      transfer_value
      deleted
      statsbomb_manager {
        manager_name
      }
      team_from_statsbomb_team {
        team_name
      }
      team_to_statsbomb_team {
        team_name
      }
      mapping_transfer_managers {
        provider_transfer_manager_id
        provider
        transfer_manager_id
        claim_transfer_managers {
          claim_transfer_manager_uuid
          manager_id
          team_from_id
          team_from_mapping_team {
            team_id
            statsbomb_team {
              team_name
            }
          }
          team_to_id
          team_to_mapping_team {
            team_id
            statsbomb_team {
              team_name
            }
          }
          transfer_currency
          transfer_date
          transfer_type
          transfer_value
          provider
          provider_transfer_manager_id
          approved_at
          rejected_at
          created_at
          mapping_transfer_manager {
            provider_transfer_manager_id
            provider
            transfer_manager_id
          }
        }
      }
    }
  }
`;

const ALL_CLAIM_TRANSFER_MANAGERS = gql`
  query getAllTransferManagerClaims(
    $recent_claim_time: timestamp
    $offset: Int = 0
    $limit: Int = 10
    $provider_id: String
  ) {
    claim_transfer_manager(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        created_at: { _gt: $recent_claim_time }
        provider_transfer_manager_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_transfer_manager_uuid
      provider_transfer_manager_id
      provider
      manager_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      created_at
      approved_at
      rejected_at
      deleted
      mapping_transfer_manager {
        provider_transfer_manager_id
        provider
        transfer_manager_id
      }
    }
  }
`;

const MAPPED_CLAIM_TRANSFER_MANAGERS = gql`
  query getAllTransferManagerClaims($offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_transfer_manager(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        mapping_transfer_manager: {}
        provider_transfer_manager_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_transfer_manager_uuid
      provider_transfer_manager_id
      provider
      manager_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      created_at
      approved_at
      rejected_at
      deleted
      mapping_transfer_manager {
        provider_transfer_manager_id
        provider
        transfer_manager_id
      }
    }
  }
`;

const UNMAPPED_CLAIM_TRANSFER_MANAGERS = gql`
  query getAllTransferManagerClaims($offset: Int = 0, $limit: Int = 10, $provider_id: String) {
    claim_transfer_manager(
      where: {
        rejected_at: { _is_null: true }
        approved_at: { _is_null: true }
        _not: { mapping_transfer_manager: {} }
        provider_transfer_manager_id: { _like: $provider_id }
      }
      offset: $offset
      limit: $limit
    ) {
      claim_transfer_manager_uuid
      provider_transfer_manager_id
      provider
      manager_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      created_at
      approved_at
      rejected_at
      deleted
      mapping_transfer_manager {
        provider_transfer_manager_id
        provider
        transfer_manager_id
      }
    }
  }
`;

const SAVE_TRANSFER_MANAGER_CLAIM = gql`
  mutation saveTransferManagersClaim(
    $claim_transfer_manager_uuid: uuid!
    $claims: [claim_transfer_manager_insert_input!]!
  ) {
    delete_claim_transfer_manager(where: { claim_transfer_manager_uuid: { _eq: $claim_transfer_manager_uuid } }) {
      affected_rows
    }
    insert_claim_transfer_manager(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_TRANSFER_MANAGER_CLAIM = gql`
  mutation infoTeamOverrideTransferManager(
    $transfer_manager_id: Int!
    $provider_transfer_manager_id: String!
    $source_entity: source_transfer_manager_set_input!
  ) {
    insert_mapping_transfer_manager(
      objects: {
        source_transfer_manager: {
          data: {
            provider: Info_Team
            provider_transfer_manager_id: $provider_transfer_manager_id
            last_updated: "now()"
          }
          on_conflict: { constraint: transfer_manager_pkey, update_columns: last_updated }
        }
        transfer_manager_id: $transfer_manager_id
      }
      on_conflict: { constraint: transfer_manager_pkey, update_columns: transfer_manager_id }
    ) {
      returning {
        transfer_manager_id
      }
    }
    update_source_transfer_manager(
      where: { provider: { _eq: Info_Team }, provider_transfer_manager_id: { _eq: $provider_transfer_manager_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_TRANSFER_MANAGER = gql`
  mutation insertGoldenTransferManager($statsbomb_entity: statsbomb_transfer_manager_insert_input!) {
    insert_statsbomb_transfer_manager(objects: [$statsbomb_entity]) {
      returning {
        transfer_manager_id
      }
    }
  }
`;

const CLAIMS_FOR_TRANSFER_MANAGER_BY_PROVIDER = gql`
  subscription claimsForTransferManagerByProvider(
    $provider_transfer_manager_id: String!
    $provider: source_provider_enum!
  ) {
    claim_transfer_manager(
      where: { provider_transfer_manager_id: { _eq: $provider_transfer_manager_id }, provider: { _eq: $provider } }
    ) {
      claim_transfer_manager_uuid
      manager_id
      team_from_id
      team_to_id
      transfer_currency
      transfer_date
      transfer_type
      transfer_value
      provider
      provider_transfer_manager_id
      approved_at
      rejected_at
      created_at
      mapping_manager {
        manager_id
        statsbomb_manager {
          manager_id
        }
      }
      team_from_mapping_team {
        team_id
        statsbomb_team {
          team_id
        }
      }
      team_to_mapping_team {
        team_id
        statsbomb_team {
          team_id
        }
      }
    }
  }
`;
const CLAIMS_TRANSFER_MANAGERS_COUNT = gql`
  query claimsTransferManagersCount {
    claim_transfer_manager_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;
export {
  TRANSFER_MANAGERS_AND_CLAIMS,
  ALL_CLAIM_TRANSFER_MANAGERS,
  MAPPED_CLAIM_TRANSFER_MANAGERS,
  UNMAPPED_CLAIM_TRANSFER_MANAGERS,
  SAVE_TRANSFER_MANAGER_CLAIM,
  OVERRIDE_TRANSFER_MANAGER_CLAIM,
  INSERT_GOLDEN_TRANSFER_MANAGER,
  CLAIMS_FOR_TRANSFER_MANAGER_BY_PROVIDER,
  CLAIMS_TRANSFER_MANAGERS_COUNT,
};
