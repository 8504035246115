import React from 'react';
import { nestedValues } from '../util/useful';
import { AreaOverride } from '../components/override/area';
import { TextOverride } from '../components/override/text';
import { LabelledCheckbox } from '../components/override/labelledCheckbox';
import { ColourPicker } from '../components/override/colourPicking';
import { ColourIcon, FlagIcon } from '../components/util/icon';
import { ListFilter, TextFilter, BooleanFilter } from '../components/table/entityTableFilters';
import { AREAS } from '../hasura/queries/area/areas';

const FULL_TEAM_SCHEMA = [
  { columnKey: 'claim_team_uuid', name: 'Claim UUId', type: 'String' },
  { columnKey: 'provider', name: 'Provider', type: 'String' },
  { columnKey: 'provider_team_id', name: 'Provider Id', type: 'String' },
  { columnKey: 'team_id', name: 'ID', type: 'Integer', OverrideControl: TextOverride },
  { columnKey: 'team_name', name: 'Name', type: 'String', OverrideControl: TextOverride },
  {
    columnKey: 'area_name',
    name: 'Area',
    type: 'String',
    mapped_icon: e => <FlagIcon code={e.area_code}></FlagIcon>,
    OverrideControl: AreaOverride,
  },
  {
    columnKey: 'area_id',
    name: 'Area',
    type: 'String',
    mapping_function: nestedValues('mapping_area.statsbomb_area.area_name'),
    statsbomb_mapping_function: nestedValues('statsbomb_area.area_name'),
    mapped_icon: e => <FlagIcon code={nestedValues('mapping_area.statsbomb_area.area_code')(e)} />,
    statsbomb_mapped_icon: e => <FlagIcon code={nestedValues('statsbomb_area.area_code')(e)} />,
    OverrideControl: AreaOverride,
  },
  { columnKey: 'team_nickname', name: 'Nickname', type: 'String', OverrideControl: TextOverride },
  {
    columnKey: 'team_colour_primary',
    name: 'Primary Colour',
    type: 'String',
    OverrideControl: ColourPicker,
    icon: e => <ColourIcon colour={e.team_colour_primary} />,
    statsbomb_icon: e => <ColourIcon colour={e.team_colour_primary} />,
  },
  {
    columnKey: 'team_colour_secondary',
    name: 'Secondary Colour',
    type: 'String',
    OverrideControl: ColourPicker,
    icon: e => <ColourIcon colour={e.team_colour_secondary} />,
    statsbomb_icon: e => <ColourIcon colour={e.team_colour_secondary} />,
  },
  {
    columnKey: 'team_is_female',
    name: 'Female?',
    type: 'Boolean',
    OverrideControl: LabelledCheckbox,
    initialOverrideValue: false,
  },
  { columnKey: 'deleted', name: 'Delete?', type: 'Boolean' },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
  { columnKey: 'min_spec', name: 'Min-IQ?', type: 'Boolean' },
];

const TEAM_ALL_CLAIMS_SCHEMA = FULL_TEAM_SCHEMA.filter(a =>
  [
    'claim_team_uuid',
    'team_name',
    'area_name',
    'provider',
    'provider_team_id',
    'team_colour_primary',
    'team_colour_secondary',
    'team_is_female',
    'created_at',
  ].includes(a.columnKey)
);

const GOLDEN_TEAM_SCHEMA = FULL_TEAM_SCHEMA.filter(field =>
  [
    'team_name',
    'team_nickname',
    'team_colour_primary',
    'team_colour_secondary',
    'team_is_female',
    'area_name',
    'min_spec',
  ].includes(field.columnKey)
);

const TEAM_SCHEMA = FULL_TEAM_SCHEMA.filter(a =>
  [
    'team_name',
    'team_nickname',
    'team_colour_primary',
    'team_colour_secondary',
    'team_is_female',
    'area_id',
    'deleted',
  ].includes(a.columnKey)
);

const TEAM_CLAIM_SCHEMA = [
  'team_name',
  'team_nickname',
  'team_colour_primary',
  'team_colour_secondary',
  'team_is_female',
  'area_id',
];

const TEAM_CLAIM_TO_MAP_SCHEMA = FULL_TEAM_SCHEMA.filter(a =>
  [
    'team_name',
    'team_nickname',
    'team_colour_primary',
    'team_colour_secondary',
    'team_is_female',
    'area_id',
    'deleted',
    'claim_team_uuid',
    'created_at',
    'provider',
    'approved_at',
    'rejected_at',
  ].includes(a.columnKey)
);

const MAPPED_TEAM_SCHEMA = FULL_TEAM_SCHEMA.filter(a =>
  [
    'provider',
    'provider_team_id',
    'team_name',
    'team_nickname',
    'team_colour_primary',
    'team_colour_secondary',
    'team_is_female',
    'area_id',
  ].includes(a.columnKey)
);

const TEAM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
  },
  {
    type: 'area',
    Component: ListFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Area',
      flagIconPath: 'area_code',
      searchQuery: AREAS,
      idPath: 'area_name',
      variablePaths: ['area_name'],
    },
  },
  {
    type: 'boolean',
    Component: BooleanFilter,
    attributes: { key: 'minIQ', queryVariableName: 'min_iq', displayName: 'Not Min-IQ' },
  },
  {
    type: 'duplicate',
    Component: BooleanFilter,
    attributes: { key: 'duplicateCount', queryVariableName: 'duplicate_count', displayName: 'Show Duplicates' },
  },
];
const TEAM_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: { key: 'text', queryVariableName: 'name', displayName: 'Name' },
    inputProps: {
      type: 'text',
    },
  },
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'area',
      queryVariableName: 'area',
      displayName: 'Nationality',
    },
    inputProps: {
      type: 'text',
    },
  },
];

const TEAM_MIN_IQ_SCHEMA = FULL_TEAM_SCHEMA.filter(a => ['team_id', 'team_name', 'area_id'].includes(a.columnKey));

export {
  FULL_TEAM_SCHEMA,
  GOLDEN_TEAM_SCHEMA,
  TEAM_SCHEMA,
  TEAM_CLAIM_SCHEMA,
  TEAM_CLAIM_TO_MAP_SCHEMA,
  TEAM_FILTER_SCHEMA,
  TEAM_MIN_IQ_SCHEMA,
  TEAM_ALL_CLAIMS_SCHEMA,
  MAPPED_TEAM_SCHEMA,
  TEAM_CLAIM_FILTER_SCHEMA,
};
