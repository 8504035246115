import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { STADIUMS, MAP_STADIUM, INSERT_SOURCE_STADIUM } from '../../hasura/queries/stadium/stadiums';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';
import { FlagIcon } from '../util/icon';

const useStyles = makeStyles({
  option: {
    fontSize: 10,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
  },
  root: {
    fontSize: 12,
    '& label': {
      fontSize: 12,
    },
  },
});

const StadiumOverride = ({ overrideChange, currentValue, columnKey, provider }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(STADIUMS, {});

  const [mapStadium] = useMutation(MAP_STADIUM, {
    onError: () => {},
    onCompleted: () => {},
  });

  const [insertSourceStadium] = useMutation(INSERT_SOURCE_STADIUM, {
    onError: () => {},
    onCompleted: () => {},
  });

  if (loading) return <InlineProgress />;

  const stadiumOverrideSelected = async stadium => {
    const variables = {
      stadium_name: stadium.stadium_name,
      provider_stadium_id: stadium.stadium_id.toString(),
    };

    await insertSourceStadium({
      variables,
    });
    await mapStadium({
      variables: {
        stadium_id: stadium.stadium_id,
        provider_stadium_id: stadium.stadium_id.toString(),
        provider: provider,
      },
    });

    overrideChange(columnKey, stadium.stadium_id.toString());
  };

  const stadiums = data?.statsbomb_stadium || [];

  return (
    <>
      <Error message="Error fetching stadiums" error={error} />

      <Autocomplete
        style={{ padding: 0, fontSize: 8 }}
        classes={{ option: classes.option, root: classes.root }}
        options={stadiums}
        autoHighlight
        openOnFocus
        disableClearable
        getOptionLabel={stadium => stadium.stadium_name}
        renderOption={stadium => (
          <>
            {stadium.statsbomb_area != null ? <FlagIcon code={stadium.statsbomb_area.area_code} /> : <></>}
            {stadium.stadium_name}
          </>
        )}
        renderInput={params => <TextField {...params} label={'Pick a stadium'} inputProps={{ ...params.inputProps }} />}
        onChange={(event, stadium) => {
          stadiumOverrideSelected(stadium);
        }}
      />
    </>
  );
};

export { StadiumOverride };
