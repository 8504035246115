import gql from 'graphql-tag';

const MAP_TO_MATCH = gql`
  mutation MapToMatch($match_id: Int!, $provider: source_provider_enum!, $provider_match_id: String!) {
    insert_mapping_match(objects: { match_id: $match_id, provider: $provider, provider_match_id: $provider_match_id }) {
      returning {
        match_id
        provider
        provider_match_id
      }
    }
  }
`;

const MAKE_AND_MAP_MATCH = gql`
  mutation MakeAndMapMatch(
    $match_name: String!
    $provider: source_provider_enum!
    $provider_match_id: String!
    $match_home_team_id: Int
    $match_away_team_id: Int
    $round_id: Int
    $match_date: date
  ) {
    insert_mapping_match(
      objects: {
        statsbomb_match: {
          data: {
            match_name: $match_name
            round_id: $round_id
            match_date: $match_date
            match_home_team_id: $match_home_team_id
            match_away_team_id: $match_away_team_id
          }
        }
        provider: $provider
        provider_match_id: $provider_match_id
      }
    ) {
      returning {
        match_id
        provider
        provider_match_id
      }
    }
  }
`;

const VIEW_MATCH_MAPPINGS = gql`
  subscription viewMatchMappings($id: Int!) {
    mapping_match(where: { match_id: { _eq: $id } }) {
      source_match {
        match_name
        round_id
        match_home_team_id
        match_away_team_id
        match_date
        match_play_status
        stadium_id
        match_week
        match_local_kick_off
        match_attendance
        match_weather
        match_neutral_ground
        match_behind_closed_doors
        provider
        provider_match_id
      }
    }
  }
`;

const DELETE_MATCH_MAPPING = gql`
  mutation deleteMatchMappings(
    $claim_data: claim_match_insert_input!
    $provider: source_provider_enum!
    $provider_id: String!
  ) {
    insert_claim_match_one(object: $claim_data, on_conflict: { constraint: match_pkey, update_columns: [] }) {
      provider
      provider_match_id
    }

    delete_mapping_match_by_pk(provider: $provider, provider_match_id: $provider_id) {
      match_id
      provider_match_id
      provider
    }
  }
`;

export { MAP_TO_MATCH, MAKE_AND_MAP_MATCH, VIEW_MATCH_MAPPINGS, DELETE_MATCH_MAPPING };
