import gql from 'graphql-tag';

const GOLDEN_TEAMS = gql`
  query getGoldenTeams(
    $name: String
    $area: String
    $min_iq: Boolean
    $deleted: Boolean = false
    $duplicate_count: bigint = 0
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_team_order_by!
  ) {
    statsbomb_golden_team(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            area_name: { _ilike: $area }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      team_id
      deleted
      team_name
      team_nickname
      team_colour_primary
      team_colour_secondary
      team_is_female
      area_name
      area_code
      min_spec
    }
  }
`;

const GOLDEN_TEAMS_COUNT = gql`
  query getGoldenTeamsCount(
    $name: String
    $area: String
    $duplicate_count: bigint = 0
    $min_iq: Boolean
    $deleted: Boolean = false
  ) {
    statsbomb_golden_team_aggregate(
      where: {
        _and: [
          {
            search_name: { _ilike: $name }
            area_name: { _ilike: $area }
            duplicate_count: { _gte: $duplicate_count }
            min_spec: { _neq: $min_iq }
            deleted: { _eq: $deleted }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const SIMILAR_TEAMS = gql`
  query similarTeams($name: String!, $provider: String!, $offset: Int!) {
    statsbomb_similar_teams(
      args: { n: $name, p: $provider, o: $offset }
      order_by: { name_similarity: desc_nulls_last }
    ) {
      team_id
      team_name
      name_similarity
      area_code
      team_nickname
      team_is_female
      mapped
      area_id
    }
  }
`;

const TEAMS = gql`
  query getTeams {
    statsbomb_team(where: { deleted: { _eq: false } }, order_by: { team_name: asc }) {
      team_name
      team_id
      area_id
      team_is_female
      statsbomb_area {
        area_code
      }
    }
  }
`;

const INSERT_SOURCE_TEAM = gql`
  mutation insertSourceTeam($team_name: String!, $provider_team_id: String!) {
    insert_source_team(
      objects: {
        team_name: $team_name
        last_updated: "now()"
        provider: Info_Team
        provider_team_id: $provider_team_id
      }
      on_conflict: { constraint: team_pkey, update_columns: last_updated }
    ) {
      returning {
        last_updated
      }
    }
  }
`;

const MAP_TEAM = gql`
  mutation insertMapTeam($team_id: Int!, $provider: source_provider_enum!, $provider_team_id: String!) {
    insert_mapping_team_one(
      object: { team_id: $team_id, provider: $provider, provider_team_id: $provider_team_id }
      on_conflict: { constraint: team_pkey, update_columns: [] }
    ) {
      team_id
      provider
      provider_team_id
    }
  }
`;
const DELETE_TEAM = gql`
  mutation DeleteTeams($team_id: Int!) {
    update_statsbomb_team_by_pk(pk_columns: { team_id: $team_id }, _set: { deleted: true }) {
      deleted
    }
  }
`;

const GET_TEAM_BY_ID = gql`
  query getTeamById($team_id: Int!) {
    statsbomb_team(where: { team_id: { _eq: $team_id } }) {
      team_id
      team_name
      statsbomb_area {
        area_code
        area_name
      }
    }
  }
`;

const GET_TEAMS_BY_ID = gql`
  query getTeamsByIds($entityIds: [Int!]) {
    statsbomb_team(where: { team_id: { _in: $entityIds } }) {
      team_id
    }
  }
`;

const DELETE_TEAM_BY_ID = gql`
  mutation deleteCompetition($team_id: Int!) {
    delete_claim_team(where: { mapping_team: { team_id: { _eq: $team_id } } }) {
      affected_rows
    }
    delete_mapping_team(where: { team_id: { _eq: $team_id } }) {
      affected_rows
    }
    delete_statsbomb_team(where: { team_id: { _eq: $team_id } }) {
      affected_rows
    }
  }
`;

export {
  GOLDEN_TEAMS,
  GOLDEN_TEAMS_COUNT,
  SIMILAR_TEAMS,
  TEAMS,
  INSERT_SOURCE_TEAM,
  MAP_TEAM,
  DELETE_TEAM,
  GET_TEAM_BY_ID,
  GET_TEAMS_BY_ID,
  DELETE_TEAM_BY_ID,
};
