import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { EntityLinkTable } from '../../components/table/entityLinkTable';
import { TextFilter, PaginationFilter } from '../../components/table/entityTableFilters';
import { QSError } from '../../components/graphql/util';
import Tabs from '../../components/util/tabs';
import { GoldenEntityFilters } from '../../components/filtering/goldenEntityFilters';
import { RESULTS_PER_PAGE } from '../../util/consts';
import { goldenRecordSections } from '../../util/schema';
import { capitalise } from '../../util/string';
import { InlineProgress } from '../../components/util/progress';
import { createFiltersQueryObject } from '../../util/filters';

const useStyle = makeStyles(theme => ({
  button: { margin: theme.spacing(), float: 'right' },
  filters: { margin: theme.spacing() },
  resultsCount: { margin: theme.spacing(), display: 'inline-block', height: 48, lineHeight: '35px' },
  pagination: { float: 'left' },
  clearButton: {
    '&:hover': {
      backgroundColor: '#DA0000',
    },
    backgroundColor: '#FF0000',
    color: 'white',
    marginLeft: 'auto',
    height: 50,
    top: 10,
    padding: 5,
    right: 10,
  },
}));

const redirect = (goldenEntity, entityPK, type) => {
  if (Array.isArray(entityPK)) {
    const urlParams = entityPK.reduce((acc, currValue) => acc + `${currValue}=${goldenEntity[currValue]}&`, '');
    window.open(`/${type}?${urlParams}`).focus();
  } else {
    window.open(`/${type}?${entityPK}=` + goldenEntity[entityPK]).focus();
  }
};

const GoldenEntityDataView = ({
  entities,
  schema,
  entityPK,
  sort,
  setSort,
  type,
  deleteEntityMutation,
  minIqAttributes,
  currentPage,
  pages,
  paginationButtonHandler,
  entitiesCountText,
  refetchData,
  refetchCount,
}) => {
  const { filters, pagination, resultsCount } = useStyle();

  return (
    <>
      <EntityLinkTable
        isNotMappingView
        rowClickHandler={goldenEntity => redirect(goldenEntity, entityPK, type)}
        {...{ entities, schema, sort, setSort, type, deleteEntityMutation, minIqAttributes, refetchData, refetchCount }}
      />
      <PaginationFilter
        pages={pages}
        paginationButtonHandler={paginationButtonHandler}
        curPage={currentPage}
        className={[filters, pagination].join(' ')}
      />
      <span className={resultsCount}>{entitiesCountText}</span>
    </>
  );
};

const GoldenEntityStateView = ({
  dataEntities,
  dataEntitiesCount,
  loading,
  filter,
  setFilter,
  emptyFilter,
  sort,
  setSort,
  type,
  deleteEntityMutation,
  entityPK,
  schema,
  typeDisplay,
  goldenFilterSchema,
  minIqAttributes,
  preventNewGoldenEntity,
  refetchData,
  refetchCount,
}) => {
  const classes = useStyle();

  const [tempFilter, setTempFilter] = useState(filter);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const updateFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
      page: 1,
    });

    setTempFilter({
      ...filter,
      [key]: value,
      page: 1,
    });
  };

  const clearFilters = () => {
    setFilter(emptyFilter);
    setTempFilter(emptyFilter);
  };

  const removeFilter = key => {
    setFilter({ ...filter, [key]: undefined });
    setTempFilter({ ...tempFilter, [key]: undefined });
  };

  const paginationButtonHandler = page =>
    setFilter({
      ...filter,
      page,
    });

  const getEntitiesCountText = count =>
    !count
      ? 'No Results'
      : `Displaying results ${(filter.page - 1) * 10 + 1} to ${Math.min(filter.page * 10, count)} of ${count}`;

  return (
    <>
      <TableContainer component={Paper}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {goldenFilterSchema ? (
            <GoldenEntityFilters
              {...{
                filter,
                setFilter,
                tempFilter,
                setTempFilter,
                emptyFilter,
                schema: goldenFilterSchema,
                clearFilters,
                updateFilter,
                removeFilter,
                filtersModalOpen,
                setFiltersModalOpen,
              }}
            />
          ) : (
            <TextFilter
              label="Name"
              className={classes.filters}
              filter={filter}
              updateFilter={(key, value) => setFilter({ ...filter, [key]: value, page: 1 })}
            />
          )}
        </div>
        {loading ? (
          <InlineProgress />
        ) : (
          <GoldenEntityDataView
            entities={dataEntities}
            currentPage={filter.page}
            pages={Math.ceil(dataEntitiesCount / RESULTS_PER_PAGE)}
            entitiesCountText={getEntitiesCountText(dataEntitiesCount)}
            {...{
              schema,
              entityPK,
              sort,
              setSort,
              type,
              deleteEntityMutation,
              minIqAttributes,
              paginationButtonHandler,
              refetchData,
              refetchCount,
            }}
          />
        )}
        {!preventNewGoldenEntity && (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            href={`/${type}?new=new`}
            target="_blank">
            New {typeDisplay}
          </Button>
        )}
      </TableContainer>
    </>
  );
};

const GoldenEntity = ({
  type,
  fetchEntities,
  fetchEntityCount,
  deleteEntityMutation,
  entityPK,
  schema,
  typeDisplay,
  preventNewGoldenEntity,
  goldenFilterSchema,
  minIqAttributes,
}) => {
  const typeFormatted = capitalise(typeDisplay);

  const defaultOrder = type === 'full_lineup' ? { match_id: 'asc' } : { [`${type}_id`]: 'asc' };
  const emptyFilter = { text: '', page: 1, order_by: defaultOrder };

  const [filter, setFilter] = useState(emptyFilter);
  const [sort, setSort] = useState({});

  const filterQueryVariables = createFiltersQueryObject(goldenFilterSchema, filter);
  const fullQueryVariables = {
    ...filterQueryVariables,
    limit: RESULTS_PER_PAGE,
    offset: (filter.page - 1) * RESULTS_PER_PAGE,
    order_by: { [sort.column]: sort.order, ...defaultOrder },
  };

  const { loading, error, data, refetch: refetchData } = useQuery(fetchEntities, {
    variables: fullQueryVariables,
  });

  const { loading: loadingCount, error: errorCount, data: dataCount, refetch: refetchCount } = useQuery(
    fetchEntityCount,
    {
      variables: filterQueryVariables,
    }
  );

  const isLoading = loading || loadingCount;

  if (error || errorCount) {
    return <QSError errors={[error, errorCount]} />;
  }

  return (
    <>
      <Tabs type={type} tabs={goldenRecordSections.map(t => ({ ...t, path: t.goldenPath }))} />
      <h2>Golden {typeFormatted}</h2>
      <p>
        This is a list of all Statsbomb {typeFormatted}. Use the filter below to find {typeFormatted} with matching
        names.
      </p>
      <GoldenEntityStateView
        dataEntities={data?.[`statsbomb_golden_${type}`] || []}
        dataEntitiesCount={dataCount?.[`statsbomb_golden_${type}_aggregate`]?.aggregate?.count || 0}
        loading={isLoading}
        {...{
          filter,
          setFilter,
          emptyFilter,
          sort,
          setSort,
          type,
          deleteEntityMutation,
          entityPK,
          schema,
          typeDisplay,
          preventNewGoldenEntity,
          goldenFilterSchema,
          minIqAttributes,
          refetchData,
          refetchCount,
        }}
      />
    </>
  );
};

export default GoldenEntity;
