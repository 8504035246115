import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { PHASES_BY_COMPETITION_SEASON, INSERT_PHASE_SOURCE_AND_MAPPING } from '../../hasura/queries/phase/phase';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const PhaseByCompSznOverride = ({ overrideChange, value, compSznId, resetChildState, provider }) => {
  const [insertPhaseSourceAndMapping] = useMutation(INSERT_PHASE_SOURCE_AND_MAPPING, {
    onError: () => {},
    onCompleted: () => {},
  });

  const onChange = async phase => {
    await insertPhaseSourceAndMapping({
      variables: {
        provider_phase_id: phase.value.toString(),
        provider: provider,
        phase_id: phase.value,
        phase_type_id: phase.obj.statsbomb_phase_type.phase_type_id,
        competition_season_id: compSznId.toString(),
      },
    });
    resetChildState();
    overrideChange({ value: phase.value, name: phase.obj.statsbomb_phase_type.phase_type_name });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <PhaseByCompSznSelector {...{ compSznId, value, onChange }} />
    </div>
  );
};

function PhaseByCompSznSelector({ compSznId, onChange, value }) {
  const { loading, error, data } = useQuery(PHASES_BY_COMPETITION_SEASON, {
    variables: {
      competition_season_id: compSznId,
    },
  });

  if (loading) return <InlineProgress />;

  const phases = data.statsbomb_phase || [];

  return (
    <>
      <Error message="Error fetching Phases By Comp Szn" error={error} />

      <InputLabel id="phase-input">Phase</InputLabel>
      <Select
        placeholder="Select Phase"
        onChange={(e, phase) => onChange(phase.props)}
        value={value}
        labelId="phase-input">
        {phases.map(phase => (
          <MenuItem key={phase.phase_id} value={phase.phase_id} obj={phase}>
            {phase.phase_order}: {phase.statsbomb_phase_type.phase_type_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export { PhaseByCompSznOverride, PhaseByCompSznSelector };
