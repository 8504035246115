import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { RepeatableError, RepeatableSuccess } from '../util/notifications';
import { FlagIcon } from '../util/icon';
import { formatMoney } from '../../util/number';
import { formatDateString } from '../../util/date';
import { LinkButton } from '../util/buttons';
import { TRANSFER_TYPE } from '../../hasura/queries/transfers/transferTypes';
import EnumOverride from '../override/enum';
import { TeamOverride } from '../override/team';
import { DateOverride } from '../override/dateTime';
import { NumberOverride } from '../override/number';
import { CURRENCY } from '../../hasura/queries/currency/currency';
import { filterSchema } from './transfers';
import TransferHistoryModal from './transferHistoryModal';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: 10,
  },
  tableTitle: {
    marginLeft: 10,
  },
  tableHeader: {
    background: theme.palette.secondary.main,
  },
  actionButton: {
    marginRight: 10,
  },
  modalParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  modal: {
    padding: 10,
    borderRadius: 5,
    minWidth: '35%',
    maxWidth: '45%',
    backgroundColor: 'white',
    boxShadow: 'rgba(0,23,130, 0.5) 0px 8px 24px',
  },
  modalGrid: {
    borderRadius: 5,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  },
  gridRow: {
    padding: 20,
    '&:nth-last-child(1)': {
      borderRadius: '0 0 5px 5px',
    },
    '&:nth-child(even)': {
      background: theme.palette.secondary.main,
    },
  },
  gridItemLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItemRight: {
    display: 'grid',
    marginLeft: 'auto',
  },
  linkStyle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const TeamLink = ({ team_id, label, className }) => (
  <Link
    className={className}
    to={{
      pathname: `/team`,
      search: `?team_id=${team_id}`,
    }}>
    {label}
  </Link>
);

const TransferHistoryComponent = ({
  entity,
  entityType,
  currentTeamRecord,
  type,
  modalOpen,
  setModalOpen,
  transferTypes,
  createGoldenRecord,
  insertSourceAndMap,
  insertSourceAndMapEntity,
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
}) => {
  const classes = useStyles();

  const entityName = entity[`${type}_name`];
  const entityId = entity[`${type}_id`];
  const currentTeam = currentTeamRecord?.team_to_id;

  const initialTransferDetails = {
    [`${entityType}_id`]: entityId,
    team_from_id: currentTeam,
    team_to_id: null,
    transfer_date: new Date(),
    transfer_value: 0,
    transfer_type: null,
    transfer_currency: null,
  };

  const [editing, setEditing] = useState(false);
  const [selectedTransferId, setSelectedTransferId] = useState(0);
  const [transferDetails, setTransferDetails] = useState(initialTransferDetails);
  const modalSchema = [
    {
      label: 'Transfer From',
      columnKey: 'team_from_id',
      Component: TeamOverride,
      currentValue: transferDetails.team_from_id,
      provider: 'Info_Team',
    },
    {
      label: 'Transfer To',
      columnKey: 'team_to_id',
      Component: TeamOverride,
      currentValue: transferDetails.team_to_id,
      provider: 'Info_Team',
    },
    {
      label: 'Transfer Date',
      columnKey: 'transfer_date',
      Component: DateOverride,
    },
    {
      label: 'Transfer Value',
      columnKey: 'transfer_value',
      Component: NumberOverride,
      overrideProps: {
        step: 0.01,
      },
    },
    {
      label: 'Transfer Type',
      columnKey: 'transfer_type',
      Component: EnumOverride,
      overrideProps: {
        query: TRANSFER_TYPE,
        statsbombPath: 'statsbomb_transfer_type',
        path: 'transfer_type',
        label: 'Transfer Type',
      },
    },
    {
      label: 'Transfer Currency',
      columnKey: 'transfer_currency',
      Component: EnumOverride,
      overrideProps: {
        query: CURRENCY,
        statsbombPath: 'statsbomb_currency',
        path: 'currency',
        label: 'Currency',
      },
    },
  ];

  const resetTransferDetails = () => setTransferDetails(initialTransferDetails);

  const filterTransferButtons = entity =>
    transferTypes.statsbomb_transfer_type.reduce((acc, transfer) => {
      if (entity === 'manager' && transfer.transfer_type !== 'transfer') {
        return acc;
      }
      acc.push(transfer);
      return acc;
    }, []);

  const filteredTransferButtons = filterTransferButtons(type);

  const modalComponentsToRemove = () => {
    const componentsToRemove = [];
    type === 'manager' && componentsToRemove.push('transfer_type');

    if (editing) return componentsToRemove;

    currentTeam != null && componentsToRemove.push('team_from_id');
    return componentsToRemove;
  };

  const filteredSchema = filterSchema(modalSchema, modalComponentsToRemove());

  const openModalWithValues = (values, editing = false) => {
    setEditing(editing);

    setSelectedTransferId(values[`transfer_${type}_id`] || 0);

    const transferValues = Object.keys(transferDetails).reduce((acc, curr) => ({ ...acc, [curr]: values[curr] }), {});
    setTransferDetails(transferValues);

    setModalOpen(true);
  };

  return (
    <div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
      <RepeatableSuccess message={successMessage} setMessage={setSuccessMessage} />
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={6}>
          <h2>{entityName}</h2>
          <p>This is {entityName}'s transfer history.</p>
        </Grid>
        <Grid item xs={6}>
          <Link
            to={{
              pathname: `/${type}`,
              search: `?${type}_id=${entityId}`,
            }}
            component={LinkButton}
            label="View Profile"
          />
        </Grid>
      </Grid>
      {filteredTransferButtons.map(({ transfer_type, display_name }) => (
        <Button
          key={transfer_type}
          className={classes.actionButton}
          variant="contained"
          color="primary"
          onClick={() => openModalWithValues({ ...initialTransferDetails, transfer_type }, false)}>
          {display_name}
        </Button>
      ))}
      <TableContainer className={classes.table} component={Paper}>
        <h3 className={classes.tableTitle}>Transfer History</h3>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Transfer Date</TableCell>
              <TableCell>Team From</TableCell>
              <TableCell>Team To</TableCell>
              <TableCell>Transfer Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entity[`statsbomb_transfer_${type}s`].map(item => (
              <TableRow key={item[`transfer_${type}_id`]}>
                <TableCell>{formatDateString(item.transfer_date, 'DD/MM/YYYY')}</TableCell>
                <TableCell>
                  <FlagIcon code={item.team_from_statsbomb_team?.statsbomb_area?.area_code} />{' '}
                  <TeamLink
                    team_id={item.team_from_id}
                    label={item.team_from_statsbomb_team.team_name}
                    className={classes.linkStyle}
                  />
                </TableCell>
                <TableCell>
                  <FlagIcon code={item.team_to_statsbomb_team?.statsbomb_area?.area_code} />{' '}
                  <TeamLink
                    team_id={item.team_to_id}
                    label={item.team_to_statsbomb_team.team_name}
                    className={classes.linkStyle}
                  />
                </TableCell>
                <TableCell>{item.statsbomb_transfer_type.display_name}</TableCell>
                <TableCell>
                  {formatMoney(item.transfer_value, item.transfer_currency ? item.transfer_currency : 'GBP')}
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => openModalWithValues(item, true)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TransferHistoryModal
        modalSchema={filteredSchema}
        {...{
          classes,
          entity,
          transferDetails,
          resetTransferDetails,
          editing,
          setEditing,
          selectedTransferId,
          currentTeam,
          type,
          createGoldenRecord,
          insertSourceAndMap,
          insertSourceAndMapEntity,
          modalOpen,
          setModalOpen,
          setErrorMessage,
        }}
      />
    </div>
  );
};

export default TransferHistoryComponent;
