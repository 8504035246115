import gql from 'graphql-tag';

export const COMPETITION_LADDERS = gql`
  query competitionLadders {
    statsbomb_competition_ladder(order_by: { competitions_are_female: asc, competition_ladder_name: asc }) {
      area_id
      competition_ladder_id
      competition_ladder_name
      competitions_are_female
    }
  }
`;
