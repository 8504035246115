import { nestedValues } from '../util/useful';
import { NumberOverride } from '../components/override/number';
import { PlayerOverride } from '../components/override/player';
import { TeamOverride } from '../components/override/team';
import EnumOverride from '../components/override/enum';
import { TRANSFER_TYPE } from '../hasura/queries/transfers/transferTypes';
import { CURRENCY } from '../hasura/queries/currency/currency';
import { dateFormat, DateOverride } from '../components/override/dateTime';
import { BooleanFilter, TextFilter } from '../components/table/entityTableFilters';

const FULL_TRANSFER_PLAYER_SCHEMA = [
  {
    columnKey: 'claim_transfer_player_uuid',
    name: 'Claim UUID',
    type: 'String',
  },
  {
    columnKey: 'provider',
    name: 'Provider',
    type: 'String',
  },
  {
    columnKey: 'provider_transfer_player_id',
    name: 'Provider Id',
    type: 'String',
  },
  {
    columnKey: 'player_id',
    name: 'Player',
    type: 'Integer',
    notCompositePK: true,
    statsbomb_mapping_function: nestedValues('statsbomb_player.player_name'),
    OverrideControl: PlayerOverride,
  },
  {
    columnKey: 'player_name',
    name: 'Player',
    type: 'String',
  },
  {
    columnKey: 'team_from_id',
    name: 'Team from',
    type: 'Integer',
    mapping_function: nestedValues('team_from_mapping_team.statsbomb_team.team_name'),
    statsbomb_mapping_function: nestedValues('team_from_statsbomb_team.team_name'),
    OverrideControl: TeamOverride,
  },
  {
    columnKey: 'team_from_name',
    name: 'Team From',
    type: 'String',
  },
  {
    columnKey: 'team_to_id',
    name: 'Team to',
    type: 'Integer',
    mapping_function: nestedValues('team_to_mapping_team.statsbomb_team.team_name'),
    statsbomb_mapping_function: nestedValues('team_to_statsbomb_team.team_name'),
    OverrideControl: TeamOverride,
  },
  {
    columnKey: 'team_to_name',
    name: 'Team To',
    type: 'String',
  },
  {
    columnKey: 'transfer_date',
    name: 'Transfer Date',
    type: 'Date',
    OverrideControl: DateOverride,
    initialOverrideValue: dateFormat(new Date()),
  },
  {
    columnKey: 'transfer_type',
    name: 'Type',
    type: 'String',
    OverrideControl: EnumOverride,
    overrideProps: {
      query: TRANSFER_TYPE,
      statsbombPath: 'statsbomb_transfer_type',
      path: 'transfer_type',
      label: 'Transfer Type',
    },
    initialOverrideValue: 'transfer',
  },
  {
    columnKey: 'transfer_type_display_name',
    name: 'Type',
    type: 'String',
  },
  {
    columnKey: 'transfer_currency',
    name: 'Currency',
    type: 'String',
    OverrideControl: EnumOverride,
    overrideProps: {
      query: CURRENCY,
      statsbombPath: 'statsbomb_currency',
      path: 'currency',
      label: 'Currency',
    },
    initialOverrideValue: 'GBP',
  },
  {
    columnKey: 'transfer_value',
    name: 'Value',
    type: 'Float',
    OverrideControl: NumberOverride,
    overrideProps: {
      step: 0.01,
    },
    initialOverrideValue: 0,
  },
  {
    columnKey: 'deleted',
    name: 'Deleted?',
    type: 'Boolean',
  },
  { columnKey: 'created_at', name: 'Created', type: 'Timestamp' },
  { columnKey: 'approved_at', name: 'Approved', type: 'Timestamp' },
  { columnKey: 'rejected_at', name: 'Rejected', type: 'Timestamp' },
];

const GOLDEN_TRANSFER_PLAYER_SCHEMA = FULL_TRANSFER_PLAYER_SCHEMA.filter(field =>
  [
    'player_name',
    'team_to_name',
    'team_from_name',
    'transfer_date',
    'transfer_type_display_name',
    'transfer_value',
    'transfer_currency',
  ].includes(field.columnKey)
);

const TRANSFER_PLAYER_SCHEMA = FULL_TRANSFER_PLAYER_SCHEMA.filter(field =>
  [
    'player_id',
    'team_to_id',
    'team_from_id',
    'transfer_date',
    'transfer_type',
    'transfer_currency',
    'transfer_value',
    'deleted',
  ].includes(field.columnKey)
);

const TRANSFER_PLAYER_CLAIM_SCHEMA = [
  'player_id',
  'team_to_id',
  'team_from_id',
  'transfer_date',
  'transfer_type',
  'transfer_currency',
  'transfer_value',
];

const TRANSFER_PLAYER_ALL_CLAIMS_SCHEMA = FULL_TRANSFER_PLAYER_SCHEMA.filter(a =>
  [
    'player_id',
    'team_to_id',
    'team_from_id',
    'transfer_date',
    'transfer_type',
    'transfer_currency',
    'transfer_value',
    'claim_transfer_player_uuid',
    'provider_transfer_player_id',
    'provider',
    'created_at',
  ].includes(a.columnKey)
);

const TRANSFER_PLAYER_CLAIM_TO_MAP_SCHEMA = FULL_TRANSFER_PLAYER_SCHEMA.filter(field =>
  [
    'player_id',
    'team_to_id',
    'team_from_id',
    'transfer_date',
    'transfer_type',
    'transfer_currency',
    'transfer_value',
    'created_at',
    'approved_at',
    'rejected_at',
  ].includes(field.columnKey)
);

const MAPPED_TRANSFER_PLAYER_SCHEMA = FULL_TRANSFER_PLAYER_SCHEMA.filter(field =>
  [
    'provider_transfer_player_id',
    'provider',
    'player_id',
    'team_to_id',
    'team_from_id',
    'transfer_date',
    'transfer_type',
    'transfer_currency',
    'transfer_value',
  ].includes(field.columnKey)
);

const TRANSFER_PLAYER_DEPENDANCY_MAPPING_SCHEMA = [
  { type: 'player', displayName: 'player', mappingObjectPath: 'mapping_player.statsbomb_player.player_id' },
  { type: 'team', displayName: 'team from', mappingObjectPath: 'team_from_mapping_team.statsbomb_team.team_id' },
  { type: 'team', displayName: 'team to', mappingObjectPath: 'team_to_mapping_team.statsbomb_team.team_id' },
];

const TRANSFER_PLAYER_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'text',
      queryVariableName: 'name',
      displayName: 'Name',
    },
  },
  {
    type: 'duplicate',
    Component: BooleanFilter,
    attributes: { key: 'duplicateCount', queryVariableName: 'duplicate_count', displayName: 'Show Duplicates' },
  },
];

const TRANSFER_PLAYER_CLAIM_FILTER_SCHEMA = [
  {
    type: 'text',
    Component: TextFilter,
    attributes: {
      key: 'providerId',
      queryVariableName: 'provider_id',
      displayName: 'Provider ID',
    },
    inputProps: {
      type: 'number',
    },
  },
];
export {
  GOLDEN_TRANSFER_PLAYER_SCHEMA,
  TRANSFER_PLAYER_SCHEMA,
  TRANSFER_PLAYER_ALL_CLAIMS_SCHEMA,
  TRANSFER_PLAYER_CLAIM_SCHEMA,
  TRANSFER_PLAYER_CLAIM_TO_MAP_SCHEMA,
  TRANSFER_PLAYER_DEPENDANCY_MAPPING_SCHEMA,
  MAPPED_TRANSFER_PLAYER_SCHEMA,
  TRANSFER_PLAYER_FILTER_SCHEMA,
  TRANSFER_PLAYER_CLAIM_FILTER_SCHEMA,
};
