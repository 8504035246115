import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClaimFilters } from '../claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import {
  TRANSFER_PLAYER_CLAIM_TO_MAP_SCHEMA,
  TRANSFER_PLAYER_DEPENDANCY_MAPPING_SCHEMA,
} from '../../schema/transferPlayerSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import {
  MAP_TO_TRANSFER_PLAYER,
  MAKE_AND_MAP_TRANSFER_PLAYER,
} from '../../hasura/queries/transferPlayer/transferPlayerMappings';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import SimilarTransferPlayersTable from './similarTransferPlayer';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const TransferPlayerMapping = ({ claims, provider_transfer_player_id }) => {
  const classes = useStyles();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [transferPlayerMapViewState, setTransferPlayerMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter =>
    setTransferPlayerMapViewState({
      ...transferPlayerMapViewState,
      claimFilter,
    });

  const filterClaims = claims => {
    const { claimFilter } = transferPlayerMapViewState;
    if (claimFilter === 'undecided') return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    else if (claimFilter === 'approved') return claims.filter(c => c.approved_at != null);
    else if (claimFilter === 'rejected') return claims.filter(c => c.rejected_at != null);
    else return claims;
  };

  const selectClaim = async selectedClaim => {
    setTransferPlayerMapViewState({
      ...transferPlayerMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarTransferPlayer = selectedSim => {
    setTransferPlayerMapViewState({
      ...transferPlayerMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(claims);

  const returnToClaimsList = () => history.push('/claims/transfer_player');

  const [mapToTransferPlayer] = useMutation(MAP_TO_TRANSFER_PLAYER, {
    onError: err =>
      setErrorMessage(mutationError(err, 'Problem Mapping Transfer Player Claim to Statsbomb Transfer Player')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapTransferPlayer] = useMutation(MAKE_AND_MAP_TRANSFER_PLAYER, {
    onError: err =>
      setErrorMessage(mutationError(err, 'Problem Mapping Transfer Player Claim to Statsbomb Transfer Player')),
    onCompleted: returnToClaimsList,
  });

  const selClaim =
    claims.filter(c => c.claim_transfer_player_uuid === transferPlayerMapViewState.selectedClaim)[0] || {};

  const createTransferPlayerMapping = async e => {
    e.preventDefault();
    try {
      await mapToTransferPlayer({
        variables: {
          transfer_player_id: transferPlayerMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_transfer_player_id: selClaim.provider_transfer_player_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createTransferPlayerAndMap = async e => {
    e.preventDefault();
    try {
      await makeAndMapTransferPlayer({
        variables: {
          provider: selClaim.provider,
          provider_transfer_player_id: selClaim.provider_transfer_player_id,
          player_id: selClaim.mapping_player.player_id,
          team_from_id: selClaim.team_from_mapping_team.team_id,
          team_to_id: selClaim.team_to_mapping_team.team_id,
          transfer_type: selClaim.transfer_type || 'transfer',
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Transfer Player Mapper. Provider Transfer Player Id: {provider_transfer_player_id}</h2>
        <h3>Select A Claim to view Possible Player Transfers</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={transferPlayerMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={TRANSFER_PLAYER_CLAIM_TO_MAP_SCHEMA}
            rowClickHandler={claim => selectClaim(claim.claim_transfer_player_uuid)}
            entityKey="claim_transfer_player_uuid"
            selectedEntityKey={transferPlayerMapViewState?.selectedClaim}
          />
        </TableContainer>

        {transferPlayerMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarTransferPlayersTable
              selectSimilarTransferPlayerFn={selectSimilarTransferPlayer}
              selectedSim={transferPlayerMapViewState.selectedSim}
              player_id={selClaim?.mapping_player?.player_id}
            />

            <ClaimMappingButtons
              displayName="Transfer Player"
              claim={selClaim}
              similarRecord={transferPlayerMapViewState.selectedSim}
              schema={TRANSFER_PLAYER_DEPENDANCY_MAPPING_SCHEMA}
              mapEntityFn={createTransferPlayerMapping}
              createNewEntityFn={createTransferPlayerAndMap}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export default TransferPlayerMapping;
