import gql from 'graphql-tag';

const ADD_SCRAPE_REQUEST = gql`
  mutation MyMutation($data: jsonb!, $type: String!) {
    insert_scheduler_requests(objects: { data: $data, request_type: $type, status: "new" }) {
      returning {
        id
      }
    }
  }
`;

const SCHEDULED_REQUESTS = gql`
  subscription MySubscription {
    scheduler_requests(where: { status: { _nin: ["completed", "deleted"] } }) {
      id
      data
      request_type
      status
      started_at
    }
  }
`;

const DELETE_SCRAPE_REQUEST = gql`
  mutation DeleteScheduledRequest($id: Int!) {
    update_scheduler_requests(where: { id: { _eq: $id } }, _set: { status: "deleted" }) {
      returning {
        id
      }
    }
  }
`;

export { ADD_SCRAPE_REQUEST, SCHEDULED_REQUESTS, DELETE_SCRAPE_REQUEST };
