import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Grid,
} from '@material-ui/core';
import { nestedValues } from '../../util/useful';
import { FlagIcon } from '../util/icon';
import { formatDateString } from '../../util/date';
import { capitalise, pluralise } from '../../util/string';
import { InlineProgress } from '../util/progress';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: 10,
  },
  tableTitle: {
    marginLeft: 10,
  },
  tableHeader: {
    background: theme.palette.secondary.main,
  },
  actionButton: {
    marginRight: 10,
  },
  modalParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  modal: {
    padding: 10,
    borderRadius: 5,
    minWidth: '35%',
    maxWidth: '45%',
    backgroundColor: 'white',
    boxShadow: 'rgba(0,23,130, 0.5) 0px 8px 24px',
  },
  modalGrid: {
    borderRadius: 5,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  },
  gridRow: {
    padding: 20,
    '&:nth-last-child(1)': {
      borderRadius: '0 0 5px 5px',
    },
    '&:nth-child(even)': {
      background: theme.palette.secondary.main,
    },
  },
  gridItemLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItemRight: {
    display: 'grid',
    marginLeft: 'auto',
  },
  linkStyle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const TeamSquadComponent = ({ type, query, queryPath, teamId, openModalWithType }) => {
  const classes = useStyles();

  const { loading, data } = useSubscription(query, {
    variables: {
      team_id: teamId,
    },
  });

  if (loading) return <InlineProgress />;

  const records = data[queryPath];

  const tableSchema = [
    { displayName: 'Name', path: `statsbomb_${type}.${type}_name`, displayType: 'link' },
    {
      displayName: 'Nationality',
      path: `statsbomb_${type}.nationality_statsbomb_area.area_name`,
      areaCode: `statsbomb_${type}.nationality_statsbomb_area.area_code`,
      displayType: 'area',
    },
    {
      displayName: 'Date Of Birth',
      path: `statsbomb_${type}.${type}_date_of_birth`,
      displayType: 'date',
    },
    {
      displayName: 'Transfer Type',
      path: 'statsbomb_transfer_type.display_name',
      displayType: 'text',
    },
    {
      displayName: 'Transfer Date',
      path: 'transfer_date',
      displayType: 'date',
    },
  ];

  const formatData = ({ item, entity, type }) => {
    const { path, areaCode, displayType } = item;
    const linkUrl = `/${type}?${type}_id=${entity[`${type}_id`]}`;

    const mapping = {
      link: (
        <Link href={linkUrl} target="_blank">
          {nestedValues(path)(entity)}
        </Link>
      ),
      date: formatDateString(nestedValues(path)(entity), 'MMMM Do YYYY'),
      area: (
        <>
          <FlagIcon code={nestedValues(areaCode)(entity)} /> {nestedValues(path)(entity)}
        </>
      ),
      text: nestedValues(path)(entity),
    };

    return mapping[displayType];
  };

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Grid container spacing={0} justify="space-between" alignItems="center">
        <Grid item xs={6}>
          <h2 className={classes.tableTitle}>{capitalise(pluralise(type))}</h2>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            key="transfer_in"
            className={classes.actionButton}
            variant="contained"
            color="primary"
            onClick={() => openModalWithType({ transferType: 'transfer', action: 'in', type }, { team_to_id: teamId })}>
            Transfer {type} In
          </Button>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            {tableSchema.map(({ displayName }) => (
              <TableCell key={displayName}>{displayName}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map(entity => (
            <TableRow key={entity[`${type}_id`]}>
              {tableSchema.map((item, index) => (
                <TableCell key={`${entity[`${type}_id`]}_${index}`}>
                  {nestedValues(item.path)(entity) && formatData({ item, entity, type })}
                </TableCell>
              ))}
              <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  key="transfer_out"
                  className={classes.actionButton}
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    openModalWithType(
                      { transferType: 'transfer', action: 'out', type },
                      { entity_id: entity[`${type}_id`], team_from_id: teamId }
                    )
                  }>
                  Transfer Out
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamSquadComponent;
