import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MAP_TO_STADIUM, MAKE_AND_MAP_STADIUM } from '../../hasura/queries/stadium/stadiumMapping';
import { ClaimFilters } from '../../components/claim/claimFilters';
import { EntityLinkTable } from '../table/entityLinkTable';
import { STADIUM_CLAIM_TO_MAP_SCHEMA } from '../../schema/stadiumSchema';
import { RepeatableError } from '../util/notifications';
import { mutationError } from '../graphql/util';
import ClaimMappingButtons from '../mapping/claimMappingButtons';
import { SIMILAR_STADIUMS } from '../../hasura/queries/stadium/stadiums';
import { SimilarEntity } from '../mapping/SimilarEntity';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),

    '& h3': {
      paddingLeft: 5,
    },
    '& button': {
      margin: '10px 5px',
      float: 'right',
    },
    '& tr': {
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));
const StadiumMapping = props => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [stadiumMapViewState, setStadiumMapViewState] = useState({
    claimFilter: 'all',
    selectedClaim: null,
    selectedSim: null,
  });

  const filterResults = claimFilter => {
    setStadiumMapViewState({
      ...stadiumMapViewState,
      claimFilter,
    });
  };

  const filterClaims = claims => {
    if (stadiumMapViewState.claimFilter === 'undecided') {
      return claims.filter(c => c.approved_at === null && c.rejected_at === null);
    } else if (stadiumMapViewState.claimFilter === 'approved') {
      return claims.filter(c => c.approved_at != null);
    } else if (stadiumMapViewState.claimFilter === 'rejected') {
      return claims.filter(c => c.rejected_at != null);
    } else {
      return claims;
    }
  };

  const selectClaim = selectedClaim => {
    setStadiumMapViewState({
      ...stadiumMapViewState,
      selectedClaim,
    });
  };

  const selectSimilarStadium = selectedSim => {
    setStadiumMapViewState({
      ...stadiumMapViewState,
      selectedSim,
    });
  };

  const filteredClaims = filterClaims(props.claims);

  const returnToClaimsList = () => {
    history.push('/claims/stadium');
  };

  const [mapToStadium] = useMutation(MAP_TO_STADIUM, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Stadium Claim to Statsbomb Stadium')),
    onCompleted: returnToClaimsList,
  });

  const [makeAndMapStadium] = useMutation(MAKE_AND_MAP_STADIUM, {
    onError: err => setErrorMessage(mutationError(err, 'Problem Mapping Stadium Claim to Statsbomb Stadium')),
    onCompleted: returnToClaimsList,
  });

  const selClaim = props.claims.filter(c => c.claim_stadium_uuid === stadiumMapViewState.selectedClaim)[0] || {};

  const createStadiumMapFn = async e => {
    e.preventDefault();
    try {
      await mapToStadium({
        variables: {
          stadium_id: stadiumMapViewState.selectedSim,
          provider: selClaim.provider,
          provider_stadium_id: selClaim.provider_stadium_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  const createStadiumAndMapFn = async e => {
    e.preventDefault();
    try {
      await makeAndMapStadium({
        variables: {
          stadium_name: selClaim.stadium_name,
          provider: selClaim.provider,
          provider_stadium_id: selClaim.provider_stadium_id,
        },
      });
    } catch (err) {
      console.error({ 'mapping failed': err });
    }
  };

  return (
    <>
      <div className="container-row">
        <h2>Unmapped Stadium Mapper. Provider Stadium Id: {props.provider_stadium_id}</h2>
        <h3>Select A Claim to view Possible Stadiums</h3>
        <TableContainer component={Paper}>
          <ClaimFilters claimFilter={stadiumMapViewState.claimFilter} filterResultsFn={filterResults} />

          <EntityLinkTable
            entities={filteredClaims}
            schema={STADIUM_CLAIM_TO_MAP_SCHEMA}
            rowClickHandler={claim => {
              selectClaim(claim.claim_stadium_uuid);
            }}
            entityKey="claim_stadium_uuid"
            selectedEntityKey={stadiumMapViewState?.selectedClaim}
          />
        </TableContainer>

        {stadiumMapViewState.selectedClaim && (
          <TableContainer component={Paper} classes={classes}>
            <SimilarEntity
              provider="Soccerway"
              entityName={selClaim.stadium_name}
              selectSimilarEntityFn={selectSimilarStadium}
              selectedSim={stadiumMapViewState.selectedSim}
              type="stadium"
              query={SIMILAR_STADIUMS}
            />

            <ClaimMappingButtons
              displayName="Stadium"
              claim={selClaim}
              similarRecord={stadiumMapViewState.selectedSim}
              schema={[]}
              mapEntityFn={createStadiumMapFn}
              createNewEntityFn={createStadiumAndMapFn}
            />
          </TableContainer>
        )}
      </div>
      <RepeatableError message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

export { StadiumMapping };
