import React from 'react';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { COMPETITION_GROUPS } from '../../hasura/queries/competition/competitionGroup';
import { InlineProgress } from '../util/progress';
import { Error } from '../util/notifications';

const CompetitionGroup = ({ overrideChange, currentValue, columnKey }) => {
  const { loading, error, data } = useQuery(COMPETITION_GROUPS, {});

  if (loading) return <InlineProgress />;

  const competitionGroups = data?.statsbomb_competition_group || [];

  return (
    <>
      <Error message="Error fetching Competition Groups" error={error} />

      <InputLabel id="comp-group-input">Category</InputLabel>
      <Select onChange={e => overrideChange(columnKey, e.target.value)} value={currentValue} labelId="comp-group-input">
        {competitionGroups.map((compGroup, key) => (
          <MenuItem key={key} value={compGroup.competition_group_id}>
            {compGroup.competition_group_name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { CompetitionGroup };
