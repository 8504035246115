import gql from 'graphql-tag';

const GOLDEN_COMPETITION_SEASON_AND_CLAIMS = gql`
  query goldenCompetitionSeasonAndClaims($competition_season_id: Int!) {
    statsbomb_competition_season(where: { competition_season_id: { _eq: $competition_season_id } }) {
      competition_season_id
      season_id
      competition_season_name
      competition_id
      deleted
      statsbomb_competition {
        competition_name
      }
      statsbomb_season {
        season_name
      }
      mapping_competition_seasons {
        provider_competition_season_id
        provider
        competition_season_id
        claim_competition_seasons {
          claim_competition_season_uuid
          competition_season_name
          competition_id
          season_id
          approved_at
          created_at
          rejected_at
          provider
          provider_competition_season_id
          statsbomb_season {
            season_name
          }
          source_competition {
            competition_name
          }
        }
      }
    }
  }
`;

const SAVE_COMPETITION_SEASON_CLAIM = gql`
  mutation saveCompetitionSeasonClaim(
    $claim_competition_season_uuid: uuid!
    $claims: [claim_competition_season_insert_input!]!
  ) {
    delete_claim_competition_season(where: { claim_competition_season_uuid: { _eq: $claim_competition_season_uuid } }) {
      affected_rows
    }
    insert_statsbomb_competition_season(objects: $claims) {
      affected_rows
    }
  }
`;

const OVERRIDE_COMPETITION_SEASON_CLAIM = gql`
  mutation overrideCompetionSeasonClaim(
    $competition_season_id: Int!
    $provider_competition_season_id: String!
    $source_entity: source_competition_season_set_input!
  ) {
    insert_mapping_competition_season(
      objects: {
        source_competition_season: {
          data: {
            provider: Info_Team
            provider_competition_season_id: $provider_competition_season_id
            last_updated: "now()"
          }
          on_conflict: { constraint: competition_season_pkey, update_columns: last_updated }
        }
        competition_season_id: $competition_season_id
      }
      on_conflict: { constraint: competition_season_pkey, update_columns: competition_season_id }
    ) {
      returning {
        competition_season_id
      }
    }
    update_source_competition_season(
      where: { provider: { _eq: Info_Team }, provider_competition_season_id: { _eq: $provider_competition_season_id } }
      _set: $source_entity
    ) {
      affected_rows
    }
  }
`;

const INSERT_GOLDEN_COMPETITION_SEASON = gql`
  mutation insertGoldenCompetitionSeason($statsbomb_entity: statsbomb_competition_season_insert_input!) {
    insert_statsbomb_competition_season(objects: [$statsbomb_entity]) {
      returning {
        competition_season_id
      }
    }
  }
`;
const CLAIMS_COMPETITION_SEASONS_COUNT = gql`
  query claimsCompetitionSeasonCount {
    claim_competition_season_count {
      total_count
      mapped_count
      recent_count
    }
  }
`;

export {
  GOLDEN_COMPETITION_SEASON_AND_CLAIMS,
  SAVE_COMPETITION_SEASON_CLAIM,
  OVERRIDE_COMPETITION_SEASON_CLAIM,
  INSERT_GOLDEN_COMPETITION_SEASON,
  CLAIMS_COMPETITION_SEASONS_COUNT,
};
