import gql from 'graphql-tag';

const GOLDEN_LINEUPS = gql`
  subscription getGoldenLineups(
    $name: String!
    $limit: Int = 10
    $offset: Int = 0
    $order_by: statsbomb_golden_lineup_order_by!
  ) {
    statsbomb_golden_lineup(
      where: { search_name: { _ilike: $name } }
      limit: $limit
      offset: $offset
      order_by: [$order_by]
    ) {
      team_id
      team_name
      match_id
      match_date
      player_id
      player_name
      lineup_jersey
      player_lineup_selection_type
      selection_type_display_name
      deleted
    }
  }
`;

const GOLDEN_LINEUPS_COUNT = gql`
  subscription getGoldenLineupsCount($name: String!) {
    statsbomb_golden_lineup_aggregate(where: { search_name: { _ilike: $name } }) {
      aggregate {
        count
      }
    }
  }
`;

const DELETE_LINEUP = gql`
  mutation DeleteLineups($player_id: Int!, $team_id: Int!, $match_id: Int!) {
    update_statsbomb_lineup_by_pk(
      pk_columns: { player_id: $player_id, team_id: $team_id, match_id: $match_id }
      _set: { deleted: true }
    ) {
      deleted
    }
  }
`;
const DELETE_LINEUP_BY_ID = gql`
  mutation deleteLineup($player_id: Int!, $team_id: Int!, $match_id: Int!) {
    delete_statsbomb_lineup(
      where: { player_id: { _eq: $player_id }, team_id: { _eq: $team_id }, match_id: { _eq: $match_id } }
    ) {
      affected_rows
    }
  }
`;
const GET_LINEUP_BY_ID = gql`
  query getLineupsByIds($entityIds: [Int!]) {
    statsbomb_golden_lineup(where: { team_id: { _in: $entityIds } }) {
      team_id
      team_name
      match_id
      match_date
      player_id
      player_name
      lineup_jersey
      player_lineup_selection_type
      selection_type_display_name
    }
  }
`;

const GET_LINEUP_BY_PLAYER = gql`
  query getLineupsByPlayer($player_id: Int!) {
    statsbomb_golden_lineup(where: { player_id: { _eq: $player_id } }) {
      team_id
      team_name
      match_id
      match_date
      player_id
      player_name
      lineup_jersey
      player_lineup_selection_type
      selection_type_display_name
    }
  }
`;

const GET_LINEUP_BY_TEAM = gql`
  query getLineupsByTeam($team_id: Int!) {
    statsbomb_golden_lineup(where: { team_id: { _eq: $team_id } }) {
      team_id
      team_name
      match_id
      match_date
      player_id
      player_name
      lineup_jersey
      player_lineup_selection_type
      selection_type_display_name
    }
  }
`;

const GET_LINEUP_BY_MATCH = gql`
  query getLineupsByMatch($match_id: Int!) {
    statsbomb_golden_lineup(where: { match_id: { _eq: $match_id } }) {
      team_id
      team_name
      match_id
      match_date
      player_id
      player_name
      lineup_jersey
      player_lineup_selection_type
      selection_type_display_name
    }
  }
`;

export {
  GOLDEN_LINEUPS,
  GOLDEN_LINEUPS_COUNT,
  DELETE_LINEUP,
  DELETE_LINEUP_BY_ID,
  GET_LINEUP_BY_ID,
  GET_LINEUP_BY_MATCH,
  GET_LINEUP_BY_PLAYER,
  GET_LINEUP_BY_TEAM,
};
